import React, { useState, useEffect, useRef, useLayoutEffect, useContext, } from "react";
import { useParams } from "react-router-dom";
import PathImage from "../../../Assets/Images/path-image.png";
import Location from "../../../Assets/Images/location-blue.png";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import Scores from "../../../Components/Scores/Scores";
import QRCode from "react-qr-code";
import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import holySpiritImage from "../../../Assets/Images/holy-spirit.svg";
import evangelizationImage from "../../../Assets/Images/evangelization.svg";
import leadershipImage from "../../../Assets/Images/leadership.svg";
import Discover from "../../../Components/Discover/Discover";
import PieChart from "../../../Components/Charts/PieChart";
import MilestoneBar from "../../../Components/MilestoneBar";
import { dashboardStyle } from "../../../Components/htmlRender/htmlDashboard";
//mui 
import { CircularProgress } from "@mui/material";
import DashboardContext from "../../../Store/DashboardContext";
import LanguageContext from "../../../Store/LanguageContext";
import DesignationContext from "../../../Store/DesignationContext";
import { translate } from "../../../Components/translate/translate";


function DashboardOverview({ selectedLabel, shouldObserve, setRenderOverview }) {

  const { windowWidth } = useWindowDimensions();
  const { id } = useParams();

  const [tabIndex, setTabIndex] = useState(0);
  const [apiData, setApiData] = useState();
  const [score, setScore] = useState(0);
  const [parishScore, setParishScore] = useState(0);
  const [leaderScore, setLeaderScore] = useState(0);
  const [parishionerScore, setParishionerScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const [htmlContent, setHtmlContent] = useState('');

  const [labelClicked, setlabelClicked] = useState([]);

  const componentRef = useRef(null);
  const observerRef = useRef(null);
  const mutationTimeoutRef = useRef(null);

  const [textCopied, setTextCopied] = useState(false)

  const dashboardCtx = useContext(DashboardContext);
  const langCtx = useContext(LanguageContext)
  const designationCtx = useContext(DesignationContext)

  const apiData1 = dashboardCtx.parishAPIData;
  const pieChartData = dashboardCtx.parishOverviewAPIData?.data || {};

  useEffect(() => {
    //TODO:- Temp. code. Remove if condition.
    if (dashboardCtx.isSpin) {
      fetchResults(dashboardCtx.dashboardId);
      getParishOverviewData(dashboardCtx.dashboardId);
    } else {
      dashboardCtx.setDashboardId(id)
      fetchResults(id);
      getParishOverviewData(id);
    }

  }, [id]);

  const extractBodyContent = (htmlString) => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      if (!doc.body) {
        console.error('No body element found in the HTML string.');
        return '';
      }

      const bodyContent = doc.body.innerHTML;
      console.log('Extracted body content:', bodyContent); // Debug log
      return bodyContent;
    } catch (error) {
      console.error('Error parsing HTML string:', error);
      return '';
    }
  };
  const [test, setTest] = useState([]);

  const onAppendHtml = () => {
    return new Promise((resolve) => {
      dashboardCtx.updateHtmlString(htmlContent)
      resolve()
    });
  };



  useEffect(() => {
    console.log('Updated state:', dashboardCtx.htmlString);
  }, [dashboardCtx.htmlString]);

  const downloadPdfLocal = async (finalHTML) => {
    try {
      await html2pdf()
        .from(finalHTML)
        .set({
          filename: 'Results.pdf',
          jsPDF: {
            unit: 'mm',
            format: [279, 432],
            orientation: 'portrait',
          },
          html2canvas: {
            scale: 2,
            useCORS: true
          }
        })
        .save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };




  // const downloadPdf = async (pdfLink) => {
  //   // const proxyUrl = pdfLink;
  //   const proxyUrl = "https://cors-anywhere.herokuapp.com/" + pdfLink;

  //   try {
  //     const response = await fetch(proxyUrl);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const blob = await response.blob();
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = 'Results.pdf';
  //     link.click();
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Error fetching PDF:', error);
  //   }
  // };



  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
      setTextCopied(true)
      setTimeout(() => {
        setTextCopied(false)
      }, 2000)
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  // Effect to handle selectedLabel change
  useEffect(() => {
    if (selectedLabel === "My Results") {
      setTabIndex(3);
    }
    else if (selectedLabel === "Parish Dashboard") {
      setTabIndex(0);
    }
    else if (selectedLabel === "Leadership Dashboard") {
      setTabIndex(1);
    }
    else if (selectedLabel === "Parishioner Dashboard") {
      setTabIndex(2);
    }
  }, [selectedLabel]);

  // UseLayoutEffect to set up the MutationObserver and ensure it runs synchronously after DOM mutations
  useLayoutEffect(() => {
    if (shouldObserve && componentRef.current) {
      const callback = () => {
        if (mutationTimeoutRef.current) {
          clearTimeout(mutationTimeoutRef.current);
        }

        // Delay the execution to ensure all mutations are settled
        mutationTimeoutRef.current = setTimeout(() => {
          setHtmlContent(componentRef.current.innerHTML);
        }, 50); // You can adjust the timeout value as needed
      };

      observerRef.current = new MutationObserver(callback);

      observerRef.current.observe(componentRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
        if (mutationTimeoutRef.current) {
          clearTimeout(mutationTimeoutRef.current);
        }
      };
    }
  }, [tabIndex, shouldObserve]);

  // Effect to call onDownloadPDF after htmlContent is updated
  useEffect(() => {
    if (htmlContent && shouldObserve) {
      onAppendHtml()
        .then(() => {
          if (selectedLabel === "Parishioner Dashboard") {

            dashboardCtx.onDownloadPDF()
            dashboardCtx.resHtmlString()
          }
          setRenderOverview(false)
        })
        .catch((error) => {
          setRenderOverview(false)
          console.error('Error downloading PDF:', error);
        });
    }
  }, [htmlContent, shouldObserve]);

  const fetchResults = (id) => {
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setApiData(data);
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch:", error);
        setIsLoading(false)
      });

    fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/parish", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          dashboardCtx.setParishAPIData(data.data);
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch:", error);
        setIsLoading(false)
      });
  };

  // console.log(apiData1?.counts)

  useEffect(() => {
    if (apiData?.variables) {
      let total = apiData?.variables?.find((obj) => obj.variable === "totalscorenon");
      console.log(total);
      if (total) {
        if (total.score < 70 && total.score >= 60) {
          setScore(1);
        } else if (total.score < 75 && total.score >= 60) {
          setScore(2);
        } else if (total.score < 80 && total.score >= 60) {
          setScore(3);
        } else if (total.score < 85 && total.score >= 60) {
          setScore(4);
        } else if (total.score < 100 && total.score >= 60) {
          setScore(5);
        } else {
          setScore(0);
        }
      }
    }
    if (apiData1?.myParish) {
      let total = apiData1?.myParish;
      if (total) {
        console.log(apiData1?.avgLeaderScore);
        console.log(apiData1?.avgParishionerScore);


        let parishionerBucket = 1;
        if (apiData1?.avgLeaderScore < 70 && apiData1?.avgLeaderScore >= 60) {
          parishionerBucket = 2;
        } else if (apiData1?.avgLeaderScore < 75 && apiData1?.avgLeaderScore >= 60) {
          parishionerBucket = 3;
        } else if (apiData1?.avgLeaderScore < 80 && apiData1?.avgLeaderScore >= 60) {
          parishionerBucket = 4;
        } else if (apiData1?.avgLeaderScore < 85 && apiData1?.avgLeaderScore >= 60) {
          parishionerBucket = 5;
        } else if (apiData1?.avgLeaderScore < 100 && apiData1?.avgLeaderScore >= 60) {
          parishionerBucket = 6;
        }

        let leaderBucket = 1;
        if (apiData1?.avgParishionerScore < 70 && apiData1?.avgParishionerScore >= 60) {
          leaderBucket = 2;
        } else if (apiData1?.avgParishionerScore < 75 && apiData1?.avgParishionerScore >= 60) {
          leaderBucket = 3;
        } else if (apiData1?.avgParishionerScore < 80 && apiData1?.avgParishionerScore >= 60) {
          leaderBucket = 4;
        } else if (apiData1?.avgParishionerScore < 85 && apiData1?.avgParishionerScore >= 60) {
          leaderBucket = 5;
        } else if (apiData1?.avgParishionerScore < 100 && apiData1?.avgParishionerScore >= 60) {
          leaderBucket = 6;
        }

        if (leaderBucket == 6 && parishionerBucket == 6) {
          setParishScore(5);
          dashboardCtx.updateLocation("6");
        } else if (leaderBucket == 6 && parishionerBucket == 5) {
          setParishScore(4);
          dashboardCtx.updateLocation("5");
        } else if (leaderBucket == 6 && parishionerBucket == 4) {
          setParishScore(4);
          dashboardCtx.updateLocation("5");
        } else if (leaderBucket == 6 && parishionerBucket == 3) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 6 && parishionerBucket == 2) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 6 && parishionerBucket == 1) {
          setParishScore(0);
          dashboardCtx.updateLocation("1");
        } else if (leaderBucket == 5 && parishionerBucket == 6) {
          setParishScore(4);
          dashboardCtx.updateLocation("5");
        } else if (leaderBucket == 5 && parishionerBucket == 5) {
          setParishScore(4);
          dashboardCtx.updateLocation("5");
        } else if (leaderBucket == 5 && parishionerBucket == 4) {
          setParishScore(4);
          dashboardCtx.updateLocation("5");
        } else if (leaderBucket == 5 && parishionerBucket == 3) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 5 && parishionerBucket == 2) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 5 && parishionerBucket == 1) {
          setParishScore(0);
          dashboardCtx.updateLocation("1");
        } else if (leaderBucket == 4 && parishionerBucket == 6) {
          setParishScore(3);
          dashboardCtx.updateLocation("4");
        } else if (leaderBucket == 4 && parishionerBucket == 5) {
          setParishScore(3);
          dashboardCtx.updateLocation("4");
        } else if (leaderBucket == 4 && parishionerBucket == 4) {
          setParishScore(3);
          dashboardCtx.updateLocation("4");
        } else if (leaderBucket == 4 && parishionerBucket == 3) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 4 && parishionerBucket == 2) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 4 && parishionerBucket == 1) {
          setParishScore(0);
          dashboardCtx.updateLocation("1");
        } else if (leaderBucket == 3 && parishionerBucket == 6) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 3 && parishionerBucket == 5) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 3 && parishionerBucket == 4) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 3 && parishionerBucket == 3) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 3 && parishionerBucket == 2) {
          setParishScore(2);
          dashboardCtx.updateLocation("3");
        } else if (leaderBucket == 3 && parishionerBucket == 1) {
          setParishScore(0);
          dashboardCtx.updateLocation("1");
        } else if (leaderBucket == 2 && parishionerBucket == 6) {
          setParishScore(1);
          dashboardCtx.updateLocation("2");
        } else if (leaderBucket == 2 && parishionerBucket == 5) {
          setParishScore(1);
          dashboardCtx.updateLocation("2");
        } else if (leaderBucket == 2 && parishionerBucket == 4) {
          setParishScore(1);
          dashboardCtx.updateLocation("2");
        } else if (leaderBucket == 2 && parishionerBucket == 3) {
          setParishScore(1);
          dashboardCtx.updateLocation("2");
        } else if (leaderBucket == 2 && parishionerBucket == 2) {
          setParishScore(1);
          dashboardCtx.updateLocation("2");
        } else if (leaderBucket == 2 && parishionerBucket == 1) {
          setParishScore(0);
          dashboardCtx.updateLocation("1");
        } else {
          setParishScore(0);
          dashboardCtx.updateLocation("1");
        }
      }
    }
    if (apiData1?.avgLeaderScore) {
      let total = apiData1?.avgLeaderScore;
      if (total) {
        if (total < 60) {
          setLeaderScore(0);
        } else if (total < 70) {
          setLeaderScore(1);
        } else if (total < 75) {
          setLeaderScore(2);
        } else if (total < 80) {
          setLeaderScore(3);
        } else if (total < 85) {
          setLeaderScore(4);
        } else {
          setLeaderScore(5);
        }
      }
    }
    if (apiData1?.avgParishionerScore) {
      let total = apiData1?.avgParishionerScore;
      if (total) {
        if (total < 60) {
          setParishionerScore(0);
        } else if (total < 70) {
          setParishionerScore(1);
        } else if (total < 75) {
          setParishionerScore(2);
        } else if (total < 80) {
          setParishionerScore(3);
        } else if (total < 85) {
          setParishionerScore(4);
        } else {
          setParishionerScore(5);
        }
      }
    }
  }, [apiData, apiData1])

  // console.log(parishScore)
  // console.log(parishionerScore)
  // console.log(leaderScore)
  // console.log(score)
  // console.log("apiData", apiData)

  // console.log(apiData1?.avgLeaderScore)

  const showPriestLeaderResultsText = () => {
    if (tabIndex === 0 && parishScore === 0) {
      return <>
        <h3>{handleTranslate("Your parish is Onlookers", "your-parish-is-onlookers")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as in the early stages of renewal and is experiencing a healthy discontent with how things are. It seems that as they observe from a distance, your parish leaders are cautious and, at the same time, intrigued about renewal in other parishes. While there might be some hesitation to change, there is also an attraction to the potential for positive transformation. There is a growing desire for change, and a belief that things can be different is beginning to take root.", "onlookers-info")}</p>
      </>
    }
    if (tabIndex === 0 && parishScore === 1) {
      return <>
        <h3>{handleTranslate("Your parish is On the Shore", "your-parish-is-on-the-shore")}</h3>
        <p>{handleTranslate('You seem to perceive your parish as moving on to a stage where priests and leaders acknowledge that renewal is possible and worth trying. It seems that your parish leaders are more aware of their holy discontent and deep longing for change but need more inspiration and encouragement to decide on and fully embrace renewal. Their mindset is shifting from "things can be different" to "things will be different."', 'on-the-shore-info')}</p>
      </>
    }
    if (tabIndex === 0 && parishScore === 2) {
      return <>
        <h3>{handleTranslate("Your parish is Casting Off", "your-parish-is-casting-off")}</h3>
        <p>{handleTranslate("Your parish’s overall perceptions seem to indicate that it is actively on the parish renewal journey. While not all of the details may have been figured out, the decisive choice to change seems to have already been made. Currently, your parish appears to be fully involved in shaping a vision, identifying allies, and investing in the three keys that unlock renewal in a parish.", "casting-off-info")}</p>
      </>
    }
    if (tabIndex === 0 && parishScore === 3) {
      return <>
        <h3>{handleTranslate("Your parish is Raising the Sails", "your-parish-is-raising-the-sails")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as now reaping the benefits of prior changes. Currently, you seem to perceive that following the decision to pursue renewal, your parish is putting together the necessary elements for the mission. Even though there might be pushback and chaos, you may be seeing the early fruits and lives changing, and they might be inspiring you to keep going. Addressing an unhealthy culture triggers some resistance, and the realization that change is not fleeting begins to settle in.", "raising-the-sails-info")}</p>
      </>
    }
    if (tabIndex === 0 && parishScore === 4) {
      return <>
        <h3>{handleTranslate("Your parish is Sailing the Waves", "your-parish-is-sailing-the-waves")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as thriving. It seems that through the power of the Holy Spirit, applying leadership principles, and prioritizing evangelization, your parish is actively making new disciples. As you continue to bear fruit, you also encounter new challenges: burnout becomes a potential concern, and you see the need to implement structural changes to keep the mission going.", "sailing-the-waves-info")}</p>
      </>
    }
    if (tabIndex === 0 && parishScore === 5) {
      return <>
        <h3>{handleTranslate("Your parish is a Beacon Parish", "your-parish-is-beacon-parish")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as actively shaping a plan for long-term health and fruitfulness. It seems that your parish cast a vision, formed a leadership team, established an evangelization program, and developed a leadership pipeline—all part of an active parish renewal journey. Continuous discernment, pruning, and refining of structures, processes, and tools for the mission are ongoing. Your parish is on its way toward becoming an influential multiplier and movement leader, guiding other parishes in this transformative journey.", "beacon-parish-info")}</p>
      </>
    }
    if (tabIndex === 1 && leaderScore === 0) {
      return <>
        <h3>{handleTranslate("Your leaders seem to perceive your parish as Onlookers", "leaders-onlookers")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as in the early stages of renewal and is experiencing a healthy discontent with how things are. It seems that as they observe from a distance, your parish leaders are cautious and, at the same time, intrigued about renewal in other parishes. While there might be some hesitation to change, there is also an attraction to the potential for positive transformation. There is a growing desire for change, and a belief that things can be different is beginning to take root.", "onlookers-info")}</p>
      </>
    } if (tabIndex === 1 && leaderScore === 1) {
      return <>
        <h3>{handleTranslate("Your leaders seem to perceive that your parish is On the Shore", "leaders-on-the-shore")}</h3>
        <p>{handleTranslate('You seem to perceive your parish as moving on to a stage where priests and leaders acknowledge that renewal is possible and worth trying. It seems that your parish leaders are more aware of their holy discontent and deep longing for change but need more inspiration and encouragement to decide on and fully embrace renewal. Their mindset is shifting from "things can be different" to "things will be different."', 'on-the-shore-info')}</p>
      </>
    } if (tabIndex === 1 && leaderScore === 2) {
      return <>
        <h3>{handleTranslate("Your leaders seem to perceive that your parish is Casting Off", "leaders-casting-off")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as actively on the parish renewal journey. While not all of the details have been figured out, the decisive choice to change has already been made. Currently, you seem to perceive your parish as fully involved in shaping a vision, identifying allies, and investing in the three keys that unlock renewal in a parish.", "casting-off")}</p>
      </>
    } if (tabIndex === 1 && leaderScore === 3) {
      return <>
        <h3>{handleTranslate("Your leaders seem to perceive that your parish is Raising the Sails", "leaders-raising-the-sails")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as now reaping the benefits of prior changes. Currently, you seem to perceive that following the decision to pursue renewal, your parish is putting together the necessary elements for the mission. Even though there might be pushback and chaos, you may be seeing the early fruits and lives changing, and they might be inspiring you to keep going. Addressing an unhealthy culture triggers some resistance, and the realization that change is not fleeting begins to settle in.", "raising-the-sails-info")}</p>
      </>
    } if (tabIndex === 1 && leaderScore === 4) {
      return <>
        <h3>{handleTranslate("Your leaders seem to perceive that your parish is Sailing the Waves", "leaders-sailing-the-waves")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as thriving. It seems that through the power of the Holy Spirit, applying leadership principles, and prioritizing evangelization, your parish is actively making new disciples. As you continue to bear fruit, you also encounter new challenges: burnout becomes a potential concern, and you see the need to implement structural changes to keep the mission going.", "sailing-the-waves-info")}</p>
      </>
    } if (tabIndex === 1 && leaderScore === 5) {
      return <>
        <h3>{handleTranslate("Your leaders seem to perceive that your parish is Beacon Parish", "leaders-beacon-parish")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as actively shaping a plan for long-term health and fruitfulness. It seems that your parish cast a vision, formed a leadership team, established an evangelization program, and developed a leadership pipeline—all part of an active parish renewal journey. Continuous discernment, pruning, and refining of structures, processes, and tools for the mission are ongoing. Your parish is on its way toward becoming an influential multiplier and movement leader, guiding other parishes in this transformative journey.", "beacon-parish-info")}</p>
      </>
    } if (tabIndex === 2 && parishionerScore === 0) {
      return <>
        <h3>{handleTranslate("Your parishioners seem to perceive your parish as Onlookers", "parishioners-onlookers")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as in the early stages of renewal and is experiencing a healthy discontent with how things are. It seems that as they observe from a distance, your parish leaders are cautious and, at the same time, intrigued about renewal in other parishes. While there might be some hesitation to change, there is also an attraction to the potential for positive transformation. There is a growing desire for change, and a belief that things can be different is beginning to take root.", "onlookers-info")}</p>
      </>
    } if (tabIndex === 2 && parishionerScore === 1) {
      return <>
        <h3>{handleTranslate("Your parishioners seem to perceive your parish as On the Shore", "parishioners-on-the-shore")}</h3>
        <p>{handleTranslate('You seem to perceive your parish as moving on to a stage where priests and leaders acknowledge that renewal is possible and worth trying. It seems that your parish leaders are more aware of their holy discontent and deep longing for change but need more inspiration and encouragement to decide on and fully embrace renewal. Their mindset is shifting from "things can be different" to "things will be different."', 'on-the-shore-info')}</p>
      </>
    } if (tabIndex === 2 && parishionerScore === 2) {
      return <>
        <h3>{handleTranslate("Your parishioners seem to perceive your parish as Casting Off", "parishioners-casting-off")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as actively on the parish renewal journey. While not all of the details have been figured out, the decisive choice to change has already been made. Currently, you seem to perceive your parish as fully involved in shaping a vision, identifying allies, and investing in the three keys that unlock renewal in a parish.", "casting-off-info")}</p>
      </>
    } if (tabIndex === 2 && parishionerScore === 3) {
      return <>
        <h3>{handleTranslate("Your parishioners seem to perceive your parish as Raising the Sails", "parishioners-raising-the-sails")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as now reaping the benefits of prior changes. Currently, you seem to perceive that following the decision to pursue renewal, your parish is putting together the necessary elements for the mission. Even though there might be pushback and chaos, you may be seeing the early fruits and lives changing, and they might be inspiring you to keep going. Addressing an unhealthy culture triggers some resistance, and the realization that change is not fleeting begins to settle in.", "raising-the-sails-info")}</p>
      </>
    } if (tabIndex === 2 && parishionerScore === 4) {
      return <>
        <h3>{handleTranslate("Your parishioners seem to perceive your parish as Sailing the Waves", "parishioners-sailing-the-waves")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as thriving. It seems that through the power of the Holy Spirit, applying leadership principles, and prioritizing evangelization, your parish is actively making new disciples. As you continue to bear fruit, you also encounter new challenges: burnout becomes a potential concern, and you see the need to implement structural changes to keep the mission going.", "sailing-the-waves")}</p>
      </>
    } if (tabIndex === 2 && parishionerScore === 5) {
      return <>
        <h3>{handleTranslate("Your parishioners seem to perceive your parish as a Beacon Parish", "parishioners-beacon-parish")}</h3>
        <p>{handleTranslate("You seem to perceive your parish as actively shaping a plan for long-term health and fruitfulness. It seems that your parish cast a vision, formed a leadership team, established an evangelization program, and developed a leadership pipeline—all part of an active parish renewal journey. Continuous discernment, pruning, and refining of structures, processes, and tools for the mission are ongoing. Your parish is on its way toward becoming an influential multiplier and movement leader, guiding other parishes in this transformative journey.", "beacon-parish-info")}</p>
      </>
    }
    else {
      if (score == 0) {
        return <>
          <h3>{handleTranslate("You seem to perceive your parish as Onlookers", "my-results-onlookers-heading")}</h3>
          <p>
            {handleTranslate("You see your parish as being in the early stages of renewal and as experiencing a healthy discontent with the way things currently are. You see your parish leaders as observing from a distance, being cautious and, at the same time, intrigued about renewal in other parishes. While there might be some hesitation to change, there's also an attraction to the 'what if' potential for positive transformation. You perceive a growing desire for change, and a belief that things can be different is beginning to take root. Overall score – mean of mean scale scores.', 'onlookers-info")}
          </p>
        </>
      }
      else if (score == 1) {
        return <>
          <h3>{handleTranslate("You seem to perceive your parish as On the Shore", "my-results-on-the-shore-heading")}</h3>
          <p>
            {handleTranslate('You see your parish as moving on to a stage where priests and leaders acknowledge that renewal is possible and worth trying. You see the parish leaders as more aware of their holy discontent and their deep longing for change but they still need more inspiration and encouragement to make a decision and fully embrace renewal. At this point, you see the mindset as shifting from "things can be different" to "things will be different."', 'on-the-shore-info')}
          </p>
        </>
      }
      else if (score == 2) {
        return <>
          <h3>{handleTranslate("You seem to perceive your parish as Casting Off", "my-results-casting-off-heading")}</h3>
          <p>
            {handleTranslate("You see your parish as actively on the parish renewal journey. While not all of the details have been figured out, the decisive choice to change has already been made. Currently, you perceive your parish as fully involved in shaping a vision, identifying allies, and investing in the three keys that unlock renewal in a parish. Overall score – mean of mean scale scores.", "casting-off-info")}
          </p>
        </>
      }
      else if (score == 3) {
        return <>
          <h3>{handleTranslate("You seem to perceive your parish as Raising the Sails", "my-results-raising-the-sails-heading")}</h3>
          <p>{handleTranslate("You see your parish as reaping the benefits of prior changes. Following the decision to pursue renewal, you see the parish as putting together the necessary elements for mission. Even though there may be some pushback and chaos, you see the early fruits and people’s lives being changed — and they inspire you to keep going. Addressing unhealthy culture triggers some resistance, and the realization that change is not fleeting begins to settle in.", "raising-the-sails-info")}</p>
        </>
      }
      else if (score == 4) {
        return <>
          <h3>{handleTranslate("You seem to perceive your parish as Sailing the Waves", "my-results-sailing-the-waves-heading")}</h3>
          <p>{handleTranslate("You see your parish as thriving. Through the empowerment of the Holy Spirit, applying leadership principles, and giving priority to evangelization, you see the parish actively making new disciples. As you continue to bear fruit, you also encounter new challenges: burnout becomes a potential concern, and you see the need to implement structural changes to keep the mission going. Overall score – mean of mean scale scores.", "sailing-the-waves-info")}</p>
        </>
      }
      else if (score == 5) {
        return <>
          <h3>{handleTranslate("You seem to perceive your parish as Beacon Parish", "my-results-beacon-parish-heading")}</h3>
          <p>{handleTranslate("You see your parish as actively shaping a plan for long-term health and fruitfulness. You perceive a parish that casts a vision, has formed a leadership team, established an evangelization program, and developed a leadership pipeline—all part of an active parish renewal journey. Continuous discernment, pruning, and refining of structures, processes, and tools for the mission are ongoing. You see the parish as positioned to be influential multipliers and movement leaders, guiding other parishes in this transformative journey.", "beacon-parish-info")}</p>
        </>
      }
    }
  }

  const getIconImage = (title) => {
    if (title === "Experience of the Holy Spirit" || title === "Asking & Discerning in Faith" || title === "Responding in Faith") {
      return "https://40parables-assets.s3.amazonaws.com/holy-spirit.svg";
    }
    if (title === "Resources Aligned" || title === "Primacy of Programs" || title === "Cultural Reinforcement") {
      return "https://40parables-assets.s3.amazonaws.com/evangelization.svg";
    }
    if (title === "Vision" || title === "Planning and Priorities" || title === "Leading out of a team" || title === "Growing Leadership Skills") {
      return "https://40parables-assets.s3.amazonaws.com/leadership.svg";
    }
    else{
      return "https://40parables-assets.s3.amazonaws.com/holy-spirit.svg";
    }
  }

  const getStrengthQuestions = () => {

    const apiDataArray = apiData?.topQuestions.slice(0, 3);

    return apiDataArray?.map((el, index) => {
      return <div key={index} className="content-div">
        <img src={getIconImage(el.sectionTitle)} />
        <p>{el.question}</p>
      </div>
    })
  }

  const getInviteParishionerLeader = (id, title) => {

    const generateQRCodeSVGString = (value) => {
      const size = 1024;
      const padding = 100;
      const qrSize = size - (padding * 2);

      return ReactDOMServer.renderToStaticMarkup(
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="white" />
          <g transform={`translate(${padding}, ${padding})`}>
            <QRCode
              value={value}
              size={qrSize}
              level="H"
              renderas="svg"
            />
          </g>
        </svg>
      );
    };

    const downloadSVGAsPNG = (svgString, filename) => {
      const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const img = new Image();
      img.src = svgUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(svgUrl);

        const pngUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngUrl;
        link.download = `${filename}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    };

    const leaderSvgString = generateQRCodeSVGString(`${window.location.host}/start/l/${id}`);
    const parishionerSvgString = generateQRCodeSVGString(`${window.location.host}/start/p/${id}`);

    return (
      <>
        <div className="invite-head" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <h1>
            {title?.length > 0 ? handleTranslate(title, "qr-code-heading") : handleTranslate("Invite your parishioners and leaders to take this assessment", "qr-code-heading")}
          </h1>
          {
            (apiData1?.counts?.leaders <= 0 && apiData1?.counts?.priests <= 0) || apiData1?.counts?.parishioners <= 0 ?
              <p className="invite-para" dangerouslySetInnerHTML={{ __html: handleTranslate(`Ensure that at least 10 parishioners and 1 leader complete this assessment to gain access to the individual and <br/> group scores of your invitees. This will help you find out how your parish views itself.`, "qr-code-sub-heading-least") }}>
              </p> : <p className="invite-para" dangerouslySetInnerHTML={{ __html: handleTranslate(`Invite all your parishioners and leaders to take this assessment and view their individual and group scores to find out where you are aligned and where you need to align to maximize your Kingdom impact.`, "qr-code-sub-heading") }}>
              </p>
          }
        </div>

        <div className="invite-pl-container">
          <div className="container">
            <h1 className="f-28">{
              handleTranslate("For Leaders", "for-leaders")
            }</h1>
            <QRCode value={`${window.location.host}/start/l/${id}`} size={256} style={{ height: "auto", maxWidth: "50%", width: "50%", marginLeft: '50%', transform: 'translateX(-50%)' }} />
            <div className="copy-url-container">
              <p className="download-qr" onClick={() => downloadSVGAsPNG(leaderSvgString, 'leader-qr')}>
                {handleTranslate("Download QR Code", "download-qr-code")}
              </p>
            </div>
            <p>{handleTranslate("or", "or")}</p>
            <div className="copy-url-container">
              <p className="copy-text" onClick={() => handleCopy(`${window.location.host}/start/l/${id}`)}>
                {handleTranslate("Copy Link", "copy-link-text")}
              </p>
            </div>
          </div>
          <div className="dotted-line"></div>
          <div className="container">
            <h1 className="f-28">{
              handleTranslate("For Parishioners", "for-parishioners")
            }</h1>
            <QRCode value={`${window.location.host}/start/p/${id}`} size={256} style={{ height: "auto", maxWidth: "50%", width: "50%", marginLeft: '50%', transform: 'translateX(-50%)' }} />
            <div className="copy-url-container">
              <p className="download-qr" onClick={() => downloadSVGAsPNG(parishionerSvgString, 'parishioner-qr')}>
                {handleTranslate("Download QR Code", "download-qr-code")}
              </p>
            </div>
            <p>{handleTranslate("or", "or")}</p>
            <div className="copy-url-container">
              <p className="copy-text" onClick={() => handleCopy(`${window.location.host}/start/p/${id}`)}>
                {handleTranslate("Copy Link", "copy-link-text")}
              </p>
            </div>
          </div>
        </div>
        {
          textCopied === true ?
            <div className="copied-msg" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <p style={{ height: "46px", width: "120px", background: "#F0F0F0", color: "#000", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                Copied..!!
              </p>
            </div>
            : null
        }
        <div className="qr-recommend">
          <p>{handleTranslate("Use these QR codes to share the Missional Waypoint Tool with a large group in person.", "qr-recommend")}</p>
        </div>
      </>
    );
  }

  const getInviteLeader = (id, title) => {

    const generateQRCodeSVGString = (value) => {
      const size = 1024;
      const padding = 100;
      const qrSize = size - (padding * 2);

      return ReactDOMServer.renderToStaticMarkup(
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="white" />
          <g transform={`translate(${padding}, ${padding})`}>
            <QRCode
              value={value}
              size={qrSize}
              level="H"
              renderas="svg"
            />
          </g>
        </svg>
      );
    };

    const downloadSVGAsPNG = (svgString, filename) => {
      const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const img = new Image();
      img.src = svgUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(svgUrl);

        const pngUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngUrl;
        link.download = `${filename}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    };

    const leaderSvgString = generateQRCodeSVGString(`${window.location.host}/start/l/${id}`);

    return (
      <>
        <div className="invite-head" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <h1>
            {title?.length > 0 ? handleTranslate(title, "qr-code-heading-leader") : handleTranslate("Invite your leaders to take this assessment", "qr-code-heading-leader")}
          </h1>
          <p className="invite-para"
            dangerouslySetInnerHTML={{
              __html: handleTranslate(
                `Ensure that at least 10 parishioners and 1 leader complete this assessment to gain access to the individual and <br/> group scores of your invitees. This will help you find out how your parish views itself.`,
                "qr-code-sub-heading-least"
              )
            }}
          >
          </p>
        </div>

        <div className="invite-pl-container">
          <div className="container">
            <h1 className="f-28">{
              handleTranslate("For Leaders", "for-leaders")
            }</h1>
            <QRCode value={`${window.location.host}/start/l/${id}`} size={256} style={{ height: "auto", maxWidth: "25%", width: "50%", marginLeft: '50%', transform: 'translateX(-50%)' }} />
            <div className="copy-url-container">
              <p className="download-qr" style={{ width: "30%" }} onClick={() => downloadSVGAsPNG(leaderSvgString, 'leader-qr')}>
                {handleTranslate("Download QR Code", "download-qr-code")}
              </p>
            </div>
            <p>{handleTranslate("or", "or")}</p>
            <div className="copy-url-container">
              <p className="copy-text" style={{ width: "30%" }} onClick={() => handleCopy(`${window.location.host}/start/l/${id}`)}>
                {handleTranslate("Copy Link", "copy-link-text")}
              </p>
            </div>
          </div>
        </div>
        <div className="qr-recommend">
          <p>{handleTranslate("Use this QR code to share the Missional Waypoint Tool with a large group in person.", "qr-recommend-this")}</p>
        </div>
      </>
    )
  }

  const getInviteParishioner = (id, title) => {

    const generateQRCodeSVGString = (value) => {
      const size = 1024;
      const padding = 100;
      const qrSize = size - (padding * 2);

      return ReactDOMServer.renderToStaticMarkup(
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="white" />
          <g transform={`translate(${padding}, ${padding})`}>
            <QRCode
              value={value}
              size={qrSize}
              level="H"
              renderas="svg"
            />
          </g>
        </svg>
      );
    };

    const downloadSVGAsPNG = (svgString, filename) => {
      const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const img = new Image();
      img.src = svgUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(svgUrl);

        const pngUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngUrl;
        link.download = `${filename}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    };

    const parishionerSvgString = generateQRCodeSVGString(`${window.location.host}/start/p/${id}`);

    return (
      <>
        <div className="invite-head" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          <h1>
            {title?.length > 0 ? handleTranslate(title, "qr-code-heading-parishioner-zero") : handleTranslate("Invite your leaders to take this assessment", "qr-code-heading-parishioner-zero")}
          </h1>
          <p className="invite-para"
            dangerouslySetInnerHTML={{
              __html: handleTranslate(
                `Ensure that at least 10 parishioners and 1 leader complete this assessment to gain access to the individual and <br/> group scores of your invitees. This will help you find out how your parish views itself.`,
                "qr-code-sub-heading-least"
              )
            }}
          >
          </p>
        </div>

        <div className="invite-pl-container">
          <div className="container">
            <h1 className="f-28">{
              handleTranslate("For Parishioners", "for-parishioners")
            }</h1>
            <QRCode value={`${window.location.host}/start/p/${id}`} size={256} style={{ height: "auto", maxWidth: "25%", width: "50%", marginLeft: '50%', transform: 'translateX(-50%)' }} />
            <div className="copy-url-container">
              <p className="download-qr" style={{ width: "30%" }} onClick={() => downloadSVGAsPNG(parishionerSvgString, 'parishioner-qr')}>
                {handleTranslate("Download QR Code", "download-qr-code")}
              </p>
            </div>
            <p>{handleTranslate("or", "or")}</p>
            <div className="copy-url-container">
              <p className="copy-text" style={{ width: "30%" }} onClick={() => handleCopy(`${window.location.host}/start/p/${id}`)}>
                {handleTranslate("Copy Link", "copy-link-text")}
              </p>
            </div>
          </div>
        </div>
        <div className="qr-recommend">
          <p>{handleTranslate("Use this QR code to share the Missional Waypoint Tool with a large group in person.", "qr-recommend-this")}</p>
        </div>
      </>
    )
  }

  const getStrengthTranslatedQuestion = () => {

    const apiDataArray = [
      ...(apiData?.topQuestions || []),
      ...(apiData?.bottomQuestions || [])
    ]

    console.log("apiDataArray", apiDataArray )

    const strengthQuestionsInfo = (question) => {
      if (
        question?.id === 1078 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("You recognize the experience of the transformational power of the Holy Spirit in your parish.", "que-one-positive-statement")}`;
      } 
      else if (
        question?.id === 1079 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your recognition and appreciation of the gifts of the Holy Spirit within your own life or those around you greatly enrich your parish's spiritual journey.", "que-two-positive-statement")}`
      }
      else if (
        question?.id === 1084 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your ability to discern the voice of the Holy Spirit amidst life's complexities is a testament to your deep spiritual sensitivity within your parish.", "que-three-positive-statement")}`
      }
      else if (
        question?.id === 1085 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your intentional commitment to reflecting on the promptings of the Holy Spirit enriches your parish's collective discernment process.", "que-four-positive-statement")}`
      }
      else if (
        question?.id === 1087 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your dedication to differentiating between human wisdom and the wisdom of the Holy Spirit fosters a culture of spiritual depth within your parish.", "que-five-positive-statement")}`
      }
      else if (
        question?.id === 1088 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your willingness to take risks in alignment with the Holy Spirit's direction inspires and emboldens your parish.", "que-six-positive-statement")}`
      } 
      else if (
        question?.id === 1091 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your consistent demonstration of faith through courageous risks taken in pursuit of the Holy Spirit's guidance is a source of strength and inspiration for your parish.", "que-seven-positive-statement")}`
      }
      else if (
        question?.id === 1092 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your steadfast reliance on the Holy Spirit, evident in how you courageously face risks, is a cornerstone of your parish's spiritual vitality.", "que-eight-positive-statement")}`
      }
      else if (
        question?.id === 1099 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your commitment to prioritizing evangelization when planning programs reflects your dedication to spreading the good news within your parish.", "que-nine-positive-statement")}`
      }
      else if (
        question?.id === 1100 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your steadfast focus on presenting the good news in all our programs highlights your passion for sharing the message of Jesus within your parish.", "que-ten-positive-statement")}`
      }
      else if (
        question?.id === 1103 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your understanding that sharing the good news is a collective responsibility underscores your active participation in fostering a culture of evangelization within your parish.", "que-eleven-positive-statement")}`
      }
      else if (
        question?.id === 1107 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your belief in providing opportunities for others to respond to the good news demonstrates your commitment to nurturing spiritual growth and transformation within your parish.", "que-twelve-positive-statement")}`
      }
      else if (
        question?.id === 1126 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Your willingness to recognize areas for potential growth in your leadership abilities demonstrates a humble and reflective approach to serving your parish.", "que-thirteen-positive-statement")}`
      }
      else if (
        question?.id === 1127 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Your self-awareness regarding your leadership skills and areas for growth reflects your commitment to continuous improvement and effective leadership within your parish.", "que-fourteen-positive-statement")}`
      }
      return null
    }

    const strengthMessages = apiDataArray
    .map(question => ({ question, message: strengthQuestionsInfo(question) }))
    .filter(entry => entry.message !== null); 

    if (strengthMessages.length === 0) {
      return <p style={{ padding: "0 33px", margin: "22px auto" }}>{handleTranslate("You see your parish as being very early on its journey to renewal. This is an exciting time, as your personal opportunities are endless as your parish grows. Spending time in prayer for your priest and parish will prepare you for the upcoming changes.", "generic-no-strength")}</p>
    }
    return (
      <div className="content-div">
        {strengthMessages.map((entry, index) => (
          <div className="content" key={index}>
            <img src={getIconImage(entry.question?.sectionTitle)} alt="icon" />
            <p style={{ color: '#000000' }}>{entry.message}</p>
          </div>
        ))}
      </div>
    );
  }

  const showText = (value) => {
    if (value === "Passenger") {
      return (
        handleTranslate(`Your parish is actively shaping a plan for long-term health and fruitfulness. Your parish cast a vision, formed a leadership team, established an evangelization program, and developed a leadership pipeline—all part of an active parish renewal journey. Continuous discernment, pruning, and refining of structures, processes, and tools for the mission are ongoing. Your parish is on its way toward becoming an influential multiplier and movement leader, guiding other parishes in this transformative journey.`, `passenger-info`)
      )
    }
    else if (value === "Ship's Officer") {
      return (
        handleTranslate(`Like a ship’s officer, you see your leadership ability as something that could be offered and beneficial to the parish’s journey toward mission. Your openness to the Holy Spirit and your desire to evangelize mean you are aligned with the parish mission to “make disciples” and may be able to serve in several different ministries.`, `ship-officer-info`)
      )
    }
    else if (value === "Helm") {
      return (
        handleTranslate(`Like the helm officer on a ship, the way you see your leadership and your openness to the Holy Spirit suggest that you may have an aptitude for steering things in the right direction. This could be in support of parish leadership and the vision for the parish, or possibly overseeing a ministry.`, `helm-info`)
      )
    }
    else if (value === "Steward") {
      return (
        handleTranslate(`Like the chief steward on a ship, the way you see your leadership ability and your desire for evangelization suggests you have strengths in welcoming people and making them feel at home. This is crucial in a missional parish. Serving on evangelization teams, programs like Alpha, or in parish hospitality may be a good fit for you.`, `steward-info`)
      )
    }
    else if (value === "Sailor") {
      return (
        handleTranslate(`Sailors are the backbone of the ship’s crew and work in many different areas. You see your relationship with the Holy Spirit and your desire to evangelize as strengths that can serve your parish. Areas like prayer ministry, evangelization teams, or programs like Alpha may be a good fit for you.`, `sailor-info`)
      )
    }
    else if (value === "Deckhand") {
      return (
        handleTranslate(`Deckhands are a ship’s crew. You see your experience of the Holy Spirit as a strength that can contribute to the mission of your parish. Working in prayer ministry, in particular, may be a good fit for you.`, `deckhand-holy-spirit-info`)
      )
    }
  }
  const showTextHeader = (value) => {
    if (value === "Passenger") {
      return (
        handleTranslate(`Passenger`, `passenger-heading`)
      )
    }
    else if (value === "Ship's Officer") {
      return (
        handleTranslate(`Ship's Officer`, `ship-officer-heading`)
      )
    }
    else if (value === "Helm") {
      return (
        handleTranslate(`Helm`, `helm-heading`)
      )
    }
    else if (value === "Steward") {
      return (
        handleTranslate(`Steward`, `steward-heading`)
      )
    }
    else if (value === "Sailor") {
      return (
        handleTranslate(`Sailor`, `sailor-heading`)
      )
    }
    else if (value === "Deckhand") {
      return (
        handleTranslate(`Deckhand`, `deckhand-heading`)
      )
    }
  }

  const getWeaknessTranslatedQuestions = () => {
    const apiDataArray = [
      ...(apiData?.topQuestions || []),
      ...(apiData?.bottomQuestions || [])
    ]
    const weaknessQuestionInfo = (question) => {
      if (
        question.id === 1078 && 
        (question.answerText === "Strongly Disagree" || question.answerText === "Disagree")
      ) {
        return `${handleTranslate("Both personal and parish transformations are only possible through God’s grace and the power of the Holy Spirit. Look for opportunities to pray with others and for others to specifically ask the Holy Spirit to come into power.", "que-one-negative-statement")}`
      }
      else if (
        question?.id === 1079 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("The Holy Spirit is always active, but we also need to be looking for what God is doing in our own lives and in our parish. Actively look for what God is doing in your own life and in the lives of others, and identify it when you see it. Name it when you see it in your life and have conversations with those where you recognize the activity of the Holy Spirit.", "que-two-negative-statement")}`
      }
      else if (
        question?.id === 1084 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Because there are so many voices in this world that compete for our attention, it can be difficult to distinguish God’s voice from all the others. Taking time daily to discern His voice by being open, patient, and attentive to subtle promptings will make it easier over time. There are some wonderful resources to help with the process of discernment that you may wish to read or share with others.", "que-three-negative-statement")}`
      }
      else if (
        question?.id === 1085 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Listening to God’s voice is challenging, even when we actively seek it. Not taking time to specifically reflect on what God is saying to us makes it almost impossible. Personally, making that effort and asking for that time in groups or meetings can help to create a culture where that becomes the norm.", "que-four-negative-statement")}`
      }
      else if (
        question?.id === 1087 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("In our personal lives and in the life of our parish, it is important to recognize that what God is calling us to do may seem to be contrary to our own wisdom. Not leaving room for God and relying only on ourselves cannot lead to the kind of transformation God calls for. Create opportunities for yourself and for others to prayerfully discern if God is calling you to “think bigger” or “think differently” than what human wisdom alone can offer.", "que-five-negative-statement")}`
      }
      else if (
        question?.id === 1088 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Risk-taking, on its own, is not inherently virtuous, but when it aligns with the true discernment of the Spirit, it can be courageous. As Christians, we are called to live and dream courageously, not because of our own capability but because of our trust and reliance on God.", "que-six-negative-statement")}`
      } 
      else if (
        question?.id === 1091 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Risk-taking, on its own, is not inherently virtuous, but when it aligns with the true discernment of the Spirit, it can be courageous. As Christians, we are called to live and dream courageously, not because of our own capability but because of our trust and reliance on God.", "que-seven-negative-statement")}`
      }
      else if (
        question?.id === 1092 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Every person and every parish will face risks that are outside of their control. But if our mission and our vision are truly God-centered, we can face those risks with confidence and without compromising our identity.", "que-eight-negative-statement")}`
      }
      else if (
        question?.id === 1099 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Evangelization is the primary mission of the Church, and, while there are many ways to evangelize, being intentional about how the parish programs and services proclaim the Good News in everything is fundamental to a missional parish.", "que-nine-negative-statement")}`
      }
      else if (
        question?.id === 1100 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("In planning, we may consider how a particular program or service is a tool of evangelization, but it needs to be executed in that way as well. “What is the best way for you to present the Good News in your context?”", "que-ten-negative-statement")}`
      }
      else if (
        question?.id === 1103 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Sharing the Good News is a fundamental commandment of Jesus; it is called the Great Commission. But, along with this commission, if we have experienced the full joy of knowing Jesus in our own lives, how could we not want others to experience it as well? Programs like Alpha can make sharing Christ with others easier, but it requires everyone to invite and share the opportunity with others.", "que-eleven-negative-statement")}`
      }
      else if (
        question?.id === 1107 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("Having a dedicated program for evangelization, like Alpha, can go a long way toward normalizing the encounter with Christ. But it cannot remain an isolated program. Praying for the participants as a parish, celebrating new encounters together, and having the active participation of many different parishioners will help make this a normal part of parish life.", "que-twelve-negative-statement")}`
      }
      else if (
        question?.id === 1126 &&
        (question?.answerText === "Strongly Agree" || question?.answerText === "Agree")
      ) {
        return `${handleTranslate("Parish renewal will only happen when the gifts and talents of parishioners are unleashed. Seeking out opportunities, even when they may not seem obvious, can help you and others ignite the renewal process.", "que-thirteen-negative-statement")}`
      }
      else if (
        question?.id === 1127 &&
        (question?.answerText === "Strongly Disagree" || question?.answerText === "Disagree")
      ) {
        return `${handleTranslate("There are several tools that can help you identify your gifts and strengths, which can be developed to help you grow as a leader. If you haven’t already, consider taking the APEST assessment or StrengthsFinder.", "que-fourteen-negative-statement")}`
      }
      return null
    }

    const weaknessMessages = apiDataArray
    .map(question => ({ question, message: weaknessQuestionInfo(question) }))
    .filter(entry => entry.message !== null); 

    if (weaknessMessages.length === 0) {
      return <p style={{ padding: "0 33px", margin: "22px auto" }}>{handleTranslate("You see your parish as being very missional and far along its journey to renewal. Your parish has faced challenges as it has grown, and you've grown along with it. Keep encouraging your priest and leadership by continuing to pray for them.", "generic-no-growth-areas")}</p>
    }
    return (
      <div className="content-div">
        {weaknessMessages.map((entry, index) => (
          <div className="content" key={index}>
            <img src={getIconImage(entry.question?.sectionTitle)} alt="icon" />
            <p style={{ color: "#000000" }}>{entry.message}</p>
          </div>
        ))}
      </div>
    );
  }

  const getWeaknessQuestions = () => {

    const apiDataArray = apiData?.bottomQuestions.slice(0, 3);;

    return apiDataArray?.map((el, index) => {
      return <div key={index} className="content-div">
        <img src={getIconImage(el.sectionTitle)} />
        <p>{el.question}</p>
      </div>
    })
  }

  const handleTranslate = (text, type) => {


    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "dashboardOverviewDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  const getParishOverviewData = (id) => {
    fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/parishOverview", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          dashboardCtx.setParishOverviewAPIData({ data: data?.data || {}, fields: data?.fields || [] })
        } else {
          // setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.error('There was a problem with the fetch:', error);

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

      });
  }

  // console.log(apiData1)

  // console.log(apiData1?.counts);
  // console.log(apiData1?.counts?.parishioners);

  const Tabs = () => {
    if (tabIndex === 0) {
      return (
        <div>
          {isLoading ?
            <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', maxWidth: "100%", width: "94%" }}><CircularProgress style={{ color: '#84161D' }} size={60} /></div> :
            <>
              {
                (apiData1?.counts?.leaders <= 0 && apiData1?.counts?.priests <= 0) || apiData1?.counts?.parishioners <= 0 ?
                  // <div className="discover-container"><p className="sub-heading">There is not enough data yet to display your results.</p><p className="sub-heading">Please use the QR code / link below to invite parishioners and leaders</p></div>
                  <p className="sub-heading">{handleTranslate("Invite your parishioners and leadership team to take this assessment to view their results.", "no-data-info-line")}</p>
                  :
                  <>
                    <p className="sub-heading">{handleTranslate("See how your parish perceives itself, where it might think it is doing well, and where it might hope for improvement.", "my-parish-info")}</p>
                    <div className="box">
                      <MilestoneBar location={parishScore} from={'Dashboard'} />
                      <img className="path-img" src={"https://40parables-assets.s3.amazonaws.com/results.jpg"} alt="" />
                      <div className="about">
                        {showPriestLeaderResultsText()}
                      </div>
                    </div>
                  </>
              }
              {
                (apiData1?.counts?.leaders <= 0 && apiData1?.counts?.priests <= 0) || apiData1?.counts?.parishioners <= 0 ? null : <>
                  <div className="box">
                    <h2>{handleTranslate("Your parish's collective scores", "your-parish-collective-score")}</h2>
                    <Scores apiData={apiData1} type='parish' />
                    <div className="donut-chart-container">
                      <h2>{handleTranslate("Type of Parishioners", "type-of-parishioners")}</h2>
                      <div className="chart-container">
                        {
                          Object.keys(pieChartData || {}).length > 0 ?
                            (<>
                              <div className="donut-chart">
                                <PieChart windowWidth={windowWidth} data={pieChartData} setlabelClicked={setlabelClicked} showTextHeader={showTextHeader} />
                              </div>
                              <div className="info">
                                <div className="parishioner-type">
                                  <div className="header">{showTextHeader(labelClicked[0])}: {labelClicked[1]}%</div>
                                  <p>
                                    {showText(labelClicked[0])}
                                  </p>
                                </div>
                              </div>
                            </>) : (<div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', maxWidth: "100%", width: "94%" }}><CircularProgress style={{ color: '#84161D' }} size={60} /></div>)
                        }
                      </div>
                    </div>
                  </div>
                </>
              }
              {(apiData1?.counts?.leaders <= 0 && apiData1?.counts?.priests <= 0) || apiData1?.counts?.parishioners <= 0 ? null : <Discover />}
              <div className="qr-container">
                {getInviteParishionerLeader(id, "Invite your parishioners and leaders to take this assessment")}
              </div>
            </>
          }
        </div>
      );
    }
    else if (tabIndex === 1) {
      return (
        <div>
          {isLoading ?
            <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', maxWidth: "100%", width: "94%" }}><CircularProgress style={{ color: '#84161D' }} size={60} /></div> :
            <>
              {(apiData1?.counts?.leaders === 0 || apiData1?.counts?.leaders === undefined) &&
                (apiData?.counts?.priest === 0 || apiData?.counts?.priest === undefined) ?
                <>
                  <p className="sub-heading">{handleTranslate("Invite your leadership team to take this assessment to view their results.", "leader-count-zero")}</p>
                  <div className="qr-container">
                    {getInviteLeader(id, "Invite your leaders to take this assessment")}
                  </div>
                </>
                :

                <>
                  <p className="sub-heading">{handleTranslate("See how your leaders perceive your parish, where they might think it is doing well, and where they might hope for it to improve.", "leaders-info")}</p>
                  <div>
                    <div className="box">
                      <MilestoneBar location={leaderScore} from={'Dashboard'} />
                      <img className="path-img" src={"https://40parables-assets.s3.amazonaws.com/results.jpg"} alt="" />
                      <div className="about">
                        {showPriestLeaderResultsText()}
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <h2>Your leaderships’ scores </h2>
                    <Scores apiData={apiData1} type='leaders' />
                  </div>
                  <Discover />
                </>
              }
            </>
          }
        </div>
      );
    }
    else if (tabIndex === 2) {
      return (
        <div>
          {isLoading ?
            <div className="" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh', maxWidth: "100%", width: "94%" }}><CircularProgress style={{ color: '#84161D' }} size={60} /></div> :
            <>
              {apiData1?.counts?.parishioners === 0 ?
                <>
                  <p className="sub-heading">{handleTranslate("Invite your parishioners to take this assessment to view their results.", "parishioners-count-zero")}</p>
                  <div className="qr-container">
                    {getInviteParishioner(id, "Invite your parishioners to take this assessment")}
                  </div>
                </>
                :
                <>
                  <p className="sub-heading">{handleTranslate("See how your parishioners perceive your parish, where they might think it is doing well, and where they might hope for it to improve.", "parishioners-info")}</p>
                  <div className="box">
                    <MilestoneBar location={parishionerScore} from={'Dashboard'} />
                    <img className="path-img" src={"https://40parables-assets.s3.amazonaws.com/results.jpg"} alt="" />
                    <div className="about">
                      {showPriestLeaderResultsText()}
                    </div>
                  </div>
                  <div className="box">
                    <h2>Your parishioner’s scores </h2>
                    <Scores apiData={apiData1} type='parishioners' />
                  </div>
                </>
              }
            </>
          }
        </div>
      );
    }
    else if (tabIndex === 3) {
      return (
        <div>
          <p className="sub-heading">{handleTranslate("See how you seem to perceive your parish, where you might think it is doing well, and where you might hope for it to improve.", "my-results-info")}</p>
          <div className="box">
            <MilestoneBar location={score} from={'Dashboard'} />
            <img className="path-img" src={"https://40parables-assets.s3.amazonaws.com/results.jpg"} alt="" />
            <div className="about">
              {showPriestLeaderResultsText()}
            </div>
          </div>
          <div className="box">
            <h2>Your scores</h2>
            <Scores apiData={apiData} type='results' />
            <div className="green-container">
              <h4>{handleTranslate("Observations of your strengths in your parish", "result-details-title")}</h4>
              {getStrengthTranslatedQuestion()}
            </div>
            <div className="red-container">
              <h4>{handleTranslate("Areas in which you may want to grow", "result-details-title-2")}</h4>
              {getWeaknessTranslatedQuestions()}
            </div>
            {
              designationCtx.designation === "priest" && getInviteParishionerLeader(id)
            }
          </div>
          <Discover />
        </div>
      );
    }
  }

  return (
    <div className="dashboard-overview">
      <div className="overview-board">
        <div className="overview-header">
          <a className={`over-tab ${tabIndex === 0 && "active"}`} onClick={() => setTabIndex(0)}>
            {handleTranslate("My Parish", "my-parish")}
          </a>
          <a className={`over-tab ${tabIndex === 1 && "active"}`} onClick={() => setTabIndex(1)}>
            {handleTranslate("Leadership", "leaders")}
          </a>
          <a className={`over-tab ${tabIndex === 2 && "active"}`} onClick={() => setTabIndex(2)}>
            {handleTranslate("Parishioners", "parishioners")}
          </a>
          <a className={`over-tab ${tabIndex === 3 && "active"}`} onClick={() => setTabIndex(3)}>
            {handleTranslate("My Results", "my-results")}
          </a>
        </div>
        <div ref={componentRef}>
          <div className="boxes">
            {Tabs()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardOverview;
