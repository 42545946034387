export const ScoresDutch = (text, type, section) => {
  switch (section) {
    case "holy-spirit":
      return "Heilige Geest";
    case "evangelization":
      return "evangelisatie";
    case "leadership":
      return "leiderschap";
    case "needs-work":
      return "Vraagt aandacht";
    case "getting-there":
      return "Wordt zichtbaar";
    case "doing-well":
      return "Gaat goed";
    case "experience-of-the-spirit":
      return "Ervaring van de Heilige Geest";
    case "asking-and-discerning-in-faith":
      return "Vragen en onderscheiden in geloof";
    case "responding-in-faith":
      return " Vol geloof reageren";
    case "resources-aligned":
      return "Besteding middelen"
    case "primacy-of-programs":
      return "Voorrang geven aan trajecten"
    case "cultural-reinforcement":
      return "Cultuurversterking"
    case "vision":
      return "Visie"
    case "planning-and-priorities":
      return " Planning en prioriteiten"
    case "leading-out-of-a-team":
      return "Leiden vanuit een team"
    case "growing-leadership-skills":
      return "Leiderschapskwaliteiten ontwikkelen"
    case "experience-definition":
      return " De parochie en het leven van de mensen in de parochie is veranderd door de ervaring van de Heilige Geest."
    case "asking-and-discerning-definition":
      return "Er is een openheid en gevoeligheid om te horen wat de Heilige Geest de parochie wil zeggen. Er wordt opzettelijk tijd vrijgemaakt om te luisteren naar de richting van de Geest voor de visie, strategie en praktijken binnen de parochie."
    case "responding-definition":
      return "De parochie laat zien dat zij afhankelijk van en gehoorzaam aan de Heilige Geest wil zijn door het nemen van risico’s."
    case "resources-definition":
      return " Een groot deel van de middelen van de parochie worden gebruikt om mensen binnen en buiten de parochie te helpen het goede nieuws te horen en ervoor te kiezen Jezus te volgen (bijvoorbeeld door Alpha)."
    case "primacy-definition":
      return " De trajecten voor evangelisatie krijgen prioriteit bij de planning en uitvoering binnen de parochie."
    case "cultural-definition":
      return " De cultuur en focus binnen de parochie moedigen iedereen en alles aan om evangelisatie te bevorderen."
    case "vision-definition":
      return "Het onderscheiden, verwoorden en uitdragen van een visie op zo'n manier dat het duidelijk is waar God de parochie naartoe roept."
    case "planning-definition":
      return "Duidelijke plannen samenstellen zodat de visie werkelijkheid wordt."
    case "leading-definition":
      return "Leiderschap delen door anderen uit te nodigen om leiding te nemen vanuit hun gaven en een rol te spelen in het tot leven brengen van de visie."
    case "growing-definition":
      return " De voortdurende ontwikkeling van de eigen en andermans leiderschapskwaliteiten."
    default:
      return text;
  }
}