export const insightsStyle =`<style>
.cover {
  background-color: #fff;
  width: 100%;
}
.cover .dashboard-insigts {
  width: 100%;
  background-color: #f5f5f5;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  border-top-left-radius: 1rem;
}
.cover .dashboard-insigts .tabs {
  max-width: 100%;
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 0.3rem;
  margin-left: 3%;
  margin-top: 2rem;
}
.cover .dashboard-insigts .tabs .tab {
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  flex-grow: 0;
  flex-wrap: nowrap;
}
.cover .dashboard-insigts .tabs .tab p {
  color: #5f5f5f;
  font-size: 17px;
  margin: 8px 10px;
  font-weight: 300;
  font-family: "Helvetica Now Display";
}
.cover .dashboard-insigts .tabs .active0 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.cover .dashboard-insigts .tabs .active0 p {
  color: #97a97c;
  font-weight: 700;
  border-bottom: 1px solid #97a97c;
  margin-bottom: 20px;
}
.cover .dashboard-insigts .tabs .active1 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.cover .dashboard-insigts .tabs .active1 p {
  color: #84161d;
  font-weight: 700;
  border-bottom: 1px solid #84161d;
  margin-bottom: 20px;
}
.cover .dashboard-insigts .tabs .active2 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.cover .dashboard-insigts .tabs .active2 p {
  color: #44546a;
  font-weight: 700;
  border-bottom: 1px solid #44546a;
  margin-bottom: 20px;
}
.cover .dashboard-insigts .box {
  max-width: 100%;
  width: 94%;
  margin: auto;
  border-radius: 15px;
  background-color: #fff;
  padding-bottom: 1rem;
}
.cover .dashboard-insigts .box .leppa-container .lpp {
  font-weight: 300;
  align-self: flex-start;
  margin-left: 2%;
  font-size: 18px;
  font-family: "Helvetica Now Display";
  color: #3b3939;
}
.cover .dashboard-insigts .box .leppa-container .lepa-container {
  display: flex;
  gap: 2rem;
}
.cover .dashboard-insigts .box .leppa-container .lepa-container .lp-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover .dashboard-insigts .box .leppa-container .lepa-container .lp-container p {
  font-family: "Helvetica Now Display";
  font-size: 21px;
  font-weight: 300;
  color: #3b3939;
  display: inline-flex;
  margin: 0;
}
.cover .dashboard-insigts .box .leppa-container .lepa-container .lp-container img {
  object-fit: contain;
  width: 33px;
  margin-right: 0.4rem;
}
@media only screen and (max-width: 600px) {
  .cover .dashboard-insigts {
    border-radius: 0;
 }
  .cover .dashboard-insigts .tabs .tab p {
    font-size: 12px;
 }
  .cover .dashboard-insigts .box .leppa-container {
    flex-direction: column;
    margin-right: 5%;
    gap: 0;
 }
  .cover .dashboard-insigts .box .leppa-container .lepa-container {
    gap: 0rem;
    justify-self: end;
    margin-left: auto;
 }
  .cover .dashboard-insigts .box .leppa-container .lp-container p {
    font-size: 12px;
    line-height: 15px;
    text-wrap: wrap;
    width: 50%;
    text-align: center;
 }
  .cover .dashboard-insigts .box .leppa-container .lepa-container .lp-container {
    right: 10%;
 }
}
.matrix {
font-family: "Helvetica Now Display";
width: 96%;
margin: auto;
margin-bottom: 1rem;
border-radius: 12px;
padding: 0;
border-collapse: collapse;
}
.matrix .head .row {
display: flex;
}
.matrix .head .h-cell {
font-size: 21px;
line-height: 30px;
color: #fff;
text-align: start;
font-weight: 700;
padding: 8px 8px 8px 16px;
margin: 0;
display: flex;
align-items: center;
font-family: "Helvetica Now Display";
}
.matrix .head .alignment-text {
font-size: 16px;
font-weight: 300;
text-wrap: nowrap;
margin: 0;
}
.matrix .head .thead-img {
background-color: #fff;
border-radius: 100%;
padding: 4px;
object-fit: contain;
height: 24px;
margin-right: 0.8rem;
}
.matrix .head .collapse-img {
object-fit: contain;
height: 18px;
text-align: center;
cursor: pointer;
}
.matrix .head .h-cell:first-child {
border-top-left-radius: 10px;
}
.matrix .head .h-cell:last-child {
border-top-right-radius: 10px;
}
.matrix .head .superscript {
display:none;
font-family: "Helvetica Now Display";
font-size: 19px;
font-weight: 700;
vertical-align: top;
position: relative;
top: -3px;
left: 1px;
}
.matrix .body .row {
display: flex;
min-height: 60px;
}
.matrix .body .b-cell {
display: flex;
align-items: center;
color: #343333;
font-size: 18px;
line-height: 20px;
font-weight: 300;
text-align: start;
padding: 4px 8px 4px 16px;
font-family: "Helvetica Now Display";
}
.matrix .body .b-cell:not(:first-child) {
justify-content: center;
}
.matrix .body .row:nth-child(odd) {
background-color: #f0f0f0;
}
.matrix .body .row:last-child {
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
}
.collapsed .head .h-cell:first-child {
border-bottom-left-radius: 10px;
}
.collapsed .head .h-cell:last-child {
border-bottom-right-radius: 10px;
}
.green {
border: 2px solid #97a97c;
}
.green .head .h-cell {
background-color: #97a97c;
}
.green .head .h-cell:nth-child(2) {
border-left: 2px solid #d0dcbf;
text-align: center;
}
.green .head .h-cell:nth-child(3) {
border-left: 2px solid #d0dcbf;
border-right: 2px solid #d0dcbf;
text-align: center;
}
.green .body .b-cell:nth-child(2) {
border-left: 2px solid #d0dcbf;
text-align: center;
}
.green .body .b-cell:nth-child(3) {
border-left: 2px solid #d0dcbf;
border-right: 2px solid #d0dcbf;
text-align: center;
}
.green1 {
border: 2px solid #97a97c;
}
.green1 .head .h-cell {
background-color: #97a97c;
}
.green1 .body .b-cell:nth-child(2) {
border-left: 2px solid #d0dcbf;
text-align: center;
}
.green1 .body .b-cell:nth-child(3) {
border-left: 2px solid #d0dcbf;
border-right: 2px solid #d0dcbf;
text-align: center;
}
.red {
border: 2px solid #84161d;
}
.red .head .h-cell {
background-color: #84161d;
}
.red .body .b-cell:nth-child(2) {
border-left: 2px solid #bbc9d2;
text-align: center;
}
.red .body .b-cell:nth-child(3) {
border-left: 2px solid #bbc9d2;
border-right: 2px solid #bbc9d2;
text-align: center;
}
.blue {
border: 2px solid #44546a;
}
.blue .head .h-cell {
background-color: #44546a;
}
.blue .body .b-cell:nth-child(2) {
border-left: 2px solid #bbc9d2;
text-align: center;
}
.blue .body .b-cell:nth-child(3) {
border-left: 2px solid #bbc9d2;
border-right: 2px solid #bbc9d2;
text-align: center;
}
@media only screen and (max-width: 600px) {
.matrix {
  width: 98%;
}
.matrix .head .h-cell {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  padding-left: 4px;
  padding-right: 4px;
}
.matrix .head .h-cell:nth-child(1) {
  width: 45% !important;
}
.matrix .head .h-cell:nth-child(2) {
  width: 25% !important;
  padding: 0 2px;
}
.matrix .head .h-cell:nth-child(3) {
  width: 25% !important;
  padding: 0 2px;
}
.matrix .head .h-cell:nth-child(4) {
  width: 5% !important;
  padding: 0 4px;
}
.matrix .body .b-cell {
  font-size: 12px;
  line-height: 20px;
  padding: 4px;
}
.matrix .body .b-cell:not(:first-child) {
  font-size: 15px;
}
.matrix .body .b-cell:nth-child(1) {
  width: 45% !important;
}
.matrix .body .b-cell:nth-child(2) {
  width: 25% !important;
  padding: 0 2px;
}
.matrix .body .b-cell:nth-child(3) {
  width: 25% !important;
  padding: 0 2px;
}
.matrix .body .b-cell:nth-child(4) {
  width: 5% !important;
  padding: 0 4px;
}
}
.discover-container {
margin: 2rem auto;
padding: 20px;
font-family: Helvetica Now Display;
font-size: 21px;
line-height: 32px;
color: #343333;
background-color: #fff;
text-align: center;
width: 94%;
box-sizing: border-box;
border-radius: 20px;
}
.discover-container .discover-container-spacer h3 {
font-size: 30px;
font-weight: 700;
line-height: 43px;
margin: 10px;
color: #343333;
font-family: Helvetica Now Display;
}
.discover-container p {
font-weight: 300;
font-size: 21px;
line-height: 25.2px;
color: #343333;
font-family: Helvetica Now Display;
margin: 0px auto;
max-width: 75ch;
}
.discover-container .cta-btn {
display: inline-block;
margin: 20px auto 0;
color: #fff;
padding: 0;
border-radius: 5px;
width: 100%;
max-width: 280px;
}
.discover-container .cta-btn a {
display: block;
text-decoration: none;
color: #fff;
}
.discover-container .cta-btn button {
width: max-content;
max-width: 100%;
min-width: 238px;
display: inline-block;
background-color: #84161d;
padding: 5px 25px;
color: #fff;
font-family: Helvetica Now Display;
font-size: 21px;
font-weight: 700;
height: 50px;
border-radius: 25px;
border: none;
cursor: pointer;
}
.discover-container .cta-btn button:hover {
background-color: #84161d;
transition: 0.3s;
color: #fff;
}   
p.lpp{
    font-weight: 300;
    align-self: flex-start;
    margin-left: 2%;
    font-size: 18px;
    font-family: "Helvetica Now Display";
    color: #3B3939;
}
.lp-container p {
    font-family: "Helvetica Now Display";
    font-size: 21px;
    font-weight: 300;
    color: #3b3939;
    display: inline-flex;
    margin: 0;
  }
  .lp-container img {
    object-fit: contain;
    width: 33px;
    margin-right: 0.4rem;
}
</style>     `