export const DashboardInsightsDutch = (text, type, section) => {
  switch (section) {
    case "areas-of-excellence":
      return "gebieden waarin jullie uitblinken";
    case "areas-of-growth":
      return "gebieden waar groei mogelijk is";
    case "areas-needing-greater-alignment":
      return "gebieden die om betere afstemming vragen";
    case "areas-of-excellence-info":
      return "Dit zijn gebieden waarop de parochie het goed lijkt te doen."
    case "areas-of-growth-info":
      return "Dit zijn gebieden waarop de parochie hoopt te verbeteren."
    case "areas-needing-greater-alignment-info":
      return "Dit zijn gebieden waarop parochianen van mening verschillen."
    case "leaders":
      return "Leiders"
    case "parishioners":
      return "parochianen"
    case "experience-of-the-holy-spirit":
      return "Ervaring van de Heilige Geest"
    case "asking-discerning-in-faith":
      return "Vragen en onderscheiden in geloof"
    case "responding-in-faith":
      return "Vol geloof reageren"
    case "resources-aligned":
      return "Besteding middelen"
    case "primacy-of-programs":
      return "Voorrang voor trajecten"
    case "cultural-reinforcement":
      return "Cultuurversterking"
    case "vision":
      return "visie"
    case "planning-and-priorities":
      return "Planning en prioriteiten"
    case "leading-out-of-a-team":
      return "Leiden vanuit een team"
    case "growing-leadership-skills":
      return "Groeiende leiderschapsvaardigheden"
    case "areas-needing-greater-alignment-heading":
      return "Dit zijn gebieden waarop parochianen van mening verschillen."
    case "not-enough-leaders-or-parishioners" :
      return "Nodig uw parochianen en leiders uit om de vragenlijst in te vullen om hun resultaten te bekijken."
    default:
      return text;
  }
}