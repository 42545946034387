// const countryValues = [
//   {
//     "value": "Australia",
//     "label": "Australia"
//   },
//   {
//     "value": "Canada",
//     "label": "Canada"
//   },
//   {
//     "value": "Austria",
//     "label": "Austria"
//   },
//   {
//     "value": "Germany",
//     "label": "Germany"
//   },
//   {
//     "value": "Liechtenstein",
//     "label": "Liechtenstein"
//   },
//   {
//     "value": "Luxembourg",
//     "label": "Luxembourg"
//   },
//   {
//     "value": "Switzerland",
//     "label": "Switzerland"
//   },
//   {
//     "value": "United Kingdom",
//     "label": "United Kingdom"
//   },
//   {
//     "value": "United States",
//     "label": "United States"
//   },
//   {
//     "value": "Afghanistan",
//     "label": "Afghanistan"
//   },
//   {
//     "value": "Albania",
//     "label": "Albania"
//   },
//   {
//     "value": "Algeria",
//     "label": "Algeria"
//   },
//   {
//     "value": "American Samoa",
//     "label": "American Samoa"
//   },
//   {
//     "value": "Andorra",
//     "label": "Andorra"
//   },
//   {
//     "value": "Angola",
//     "label": "Angola"
//   },
//   {
//     "value": "Anguilla",
//     "label": "Anguilla"
//   },
//   {
//     "value": "Antigua and Barbuda",
//     "label": "Antigua and Barbuda"
//   },
//   {
//     "value": "Argentina",
//     "label": "Argentina"
//   },
//   {
//     "value": "Armenia",
//     "label": "Armenia"
//   },
//   {
//     "value": "Aruba",
//     "label": "Aruba"
//   },
//   {
//     "value": "Azerbaijan",
//     "label": "Azerbaijan"
//   },
//   {
//     "value": "Bahamas",
//     "label": "Bahamas"
//   },
//   {
//     "value": "Bahrain",
//     "label": "Bahrain"
//   },
//   {
//     "value": "Bangladesh",
//     "label": "Bangladesh"
//   },
//   {
//     "value": "Barbados",
//     "label": "Barbados"
//   },
//   {
//     "value": "Belarus",
//     "label": "Belarus"
//   },
//   {
//     "value": "Belgium",
//     "label": "Belgium"
//   },
//   {
//     "value": "Belize",
//     "label": "Belize"
//   },
//   {
//     "value": "Benin",
//     "label": "Benin"
//   },
//   {
//     "value": "Bermuda",
//     "label": "Bermuda"
//   },
//   {
//     "value": "Bhutan",
//     "label": "Bhutan"
//   },
//   {
//     "value": "Bolivia",
//     "label": "Bolivia"
//   },
//   {
//     "value": "Bosnia and Herzegovina",
//     "label": "Bosnia and Herzegovina"
//   },
//   {
//     "value": "Botswana",
//     "label": "Botswana"
//   },
//   {
//     "value": "Brazil",
//     "label": "Brazil"
//   },
//   {
//     "value": "British Virgin Islands",
//     "label": "British Virgin Islands"
//   },
//   {
//     "value": "Brunei",
//     "label": "Brunei"
//   },
//   {
//     "value": "Bulgaria",
//     "label": "Bulgaria"
//   },
//   {
//     "value": "Burkina Faso",
//     "label": "Burkina Faso"
//   },
//   {
//     "value": "Burundi",
//     "label": "Burundi"
//   },
//   {
//     "value": "Cambodia",
//     "label": "Cambodia"
//   },
//   {
//     "value": "Cameroon",
//     "label": "Cameroon"
//   },
//   {
//     "value": "Cape Verde",
//     "label": "Cape Verde"
//   },
//   {
//     "value": "Caribbean Netherlands",
//     "label": "Caribbean Netherlands"
//   },
//   {
//     "value": "Cayman Islands",
//     "label": "Cayman Islands"
//   },
//   {
//     "value": "Central African Republic",
//     "label": "Central African Republic"
//   },
//   {
//     "value": "Chad",
//     "label": "Chad"
//   },
//   {
//     "value": "Channel Islands",
//     "label": "Channel Islands"
//   },
//   {
//     "value": "Chile",
//     "label": "Chile"
//   },
//   {
//     "value": "China",
//     "label": "China"
//   },
//   {
//     "value": "Christmas Island",
//     "label": "Christmas Island"
//   },
//   {
//     "value": "Cocos (Keeling) Islands",
//     "label": "Cocos (Keeling) Islands"
//   },
//   {
//     "value": "Colombia",
//     "label": "Colombia"
//   },
//   {
//     "value": "Comoros",
//     "label": "Comoros"
//   },
//   {
//     "value": "Congo",
//     "label": "Congo"
//   },
//   {
//     "value": "Cook Islands",
//     "label": "Cook Islands"
//   },
//   {
//     "value": "Costa Rica",
//     "label": "Costa Rica"
//   },
//   {
//     "value": "Croatia",
//     "label": "Croatia"
//   },
//   {
//     "value": "Cuba",
//     "label": "Cuba"
//   },
//   {
//     "value": "Curaçao",
//     "label": "Curaçao"
//   },
//   {
//     "value": "Cyprus",
//     "label": "Cyprus"
//   },
//   {
//     "value": "Czech Republic",
//     "label": "Czech Republic"
//   },
//   {
//     "value": "Denmark",
//     "label": "Denmark"
//   },
//   {
//     "value": "Djibouti",
//     "label": "Djibouti"
//   },
//   {
//     "value": "Dominica",
//     "label": "Dominica"
//   },
//   {
//     "value": "Dominican Republic",
//     "label": "Dominican Republic"
//   },
//   {
//     "value": "DR Congo",
//     "label": "DR Congo"
//   },
//   {
//     "value": "East Timor",
//     "label": "East Timor"
//   },
//   {
//     "value": "Ecuador",
//     "label": "Ecuador"
//   },
//   {
//     "value": "Egypt",
//     "label": "Egypt"
//   },
//   {
//     "value": "El Salvador",
//     "label": "El Salvador"
//   },
//   {
//     "value": "Equatorial Guinea",
//     "label": "Equatorial Guinea"
//   },
//   {
//     "value": "Eritrea",
//     "label": "Eritrea"
//   },
//   {
//     "value": "Estonia",
//     "label": "Estonia"
//   },
//   {
//     "value": "Eswatini",
//     "label": "Eswatini"
//   },
//   {
//     "value": "Ethiopia",
//     "label": "Ethiopia"
//   },
//   {
//     "value": "Falkland Islands",
//     "label": "Falkland Islands"
//   },
//   {
//     "value": "Faroe Islands",
//     "label": "Faroe Islands"
//   },
//   {
//     "value": "Fiji",
//     "label": "Fiji"
//   },
//   {
//     "value": "Finland",
//     "label": "Finland"
//   },
//   {
//     "value": "France",
//     "label": "France"
//   },
//   {
//     "value": "French Guiana",
//     "label": "French Guiana"
//   },
//   {
//     "value": "French Polynesia",
//     "label": "French Polynesia"
//   },
//   {
//     "value": "French Southern Territories",
//     "label": "French Southern Territories"
//   },
//   {
//     "value": "Gabon",
//     "label": "Gabon"
//   },
//   {
//     "value": "Gambia",
//     "label": "Gambia"
//   },
//   {
//     "value": "Georgia",
//     "label": "Georgia"
//   },
//   {
//     "value": "Ghana",
//     "label": "Ghana"
//   },
//   {
//     "value": "Gibraltar",
//     "label": "Gibraltar"
//   },
//   {
//     "value": "Greece",
//     "label": "Greece"
//   },
//   {
//     "value": "Greenland",
//     "label": "Greenland"
//   },
//   {
//     "value": "Grenada",
//     "label": "Grenada"
//   },
//   {
//     "value": "Guadeloupe",
//     "label": "Guadeloupe"
//   },
//   {
//     "value": "Guam",
//     "label": "Guam"
//   },
//   {
//     "value": "Guatemala",
//     "label": "Guatemala"
//   },
//   {
//     "value": "Guinea",
//     "label": "Guinea"
//   },
//   {
//     "value": "Guinea-Bissau",
//     "label": "Guinea-Bissau"
//   },
//   {
//     "value": "Guyana",
//     "label": "Guyana"
//   },
//   {
//     "value": "Haiti",
//     "label": "Haiti"
//   },
//   {
//     "value": "Honduras",
//     "label": "Honduras"
//   },
//   {
//     "value": "Hong Kong",
//     "label": "Hong Kong"
//   },
//   {
//     "value": "Hungary",
//     "label": "Hungary"
//   },
//   {
//     "value": "Iceland",
//     "label": "Iceland"
//   },
//   {
//     "value": "India",
//     "label": "India"
//   },
//   {
//     "value": "Indonesia",
//     "label": "Indonesia"
//   },
//   {
//     "value": "Iran",
//     "label": "Iran"
//   },
//   {
//     "value": "Iraq",
//     "label": "Iraq"
//   },
//   {
//     "value": "Ireland",
//     "label": "Ireland"
//   },
//   {
//     "value": "Isle of Man",
//     "label": "Isle of Man"
//   },
//   {
//     "value": "Israel",
//     "label": "Israel"
//   },
//   {
//     "value": "Italy",
//     "label": "Italy"
//   },
//   {
//     "value": "Ivory Coast",
//     "label": "Ivory Coast"
//   },
//   {
//     "value": "Jamaica",
//     "label": "Jamaica"
//   },
//   {
//     "value": "Japan",
//     "label": "Japan"
//   },
//   {
//     "value": "Jordan",
//     "label": "Jordan"
//   },
//   {
//     "value": "Kazakhstan",
//     "label": "Kazakhstan"
//   },
//   {
//     "value": "Kenya",
//     "label": "Kenya"
//   },
//   {
//     "value": "Kiribati",
//     "label": "Kiribati"
//   },
//   {
//     "value": "Kosovo",
//     "label": "Kosovo"
//   },
//   {
//     "value": "Kuwait",
//     "label": "Kuwait"
//   },
//   {
//     "value": "Kyrgyzstan",
//     "label": "Kyrgyzstan"
//   },
//   {
//     "value": "Laos",
//     "label": "Laos"
//   },
//   {
//     "value": "Latvia",
//     "label": "Latvia"
//   },
//   {
//     "value": "Lebanon",
//     "label": "Lebanon"
//   },
//   {
//     "value": "Lesotho",
//     "label": "Lesotho"
//   },
//   {
//     "value": "Liberia",
//     "label": "Liberia"
//   },
//   {
//     "value": "Libya",
//     "label": "Libya"
//   },
//   {
//     "value": "Lithuania",
//     "label": "Lithuania"
//   },
//   {
//     "value": "Macao",
//     "label": "Macao"
//   },
//   {
//     "value": "Madagascar",
//     "label": "Madagascar"
//   },
//   {
//     "value": "Malawi",
//     "label": "Malawi"
//   },
//   {
//     "value": "Malaysia",
//     "label": "Malaysia"
//   },
//   {
//     "value": "Maldives",
//     "label": "Maldives"
//   },
//   {
//     "value": "Mali",
//     "label": "Mali"
//   },
//   {
//     "value": "Malta",
//     "label": "Malta"
//   },
//   {
//     "value": "Marshall Islands",
//     "label": "Marshall Islands"
//   },
//   {
//     "value": "Martinique",
//     "label": "Martinique"
//   },
//   {
//     "value": "Mauritania",
//     "label": "Mauritania"
//   },
//   {
//     "value": "Mauritius",
//     "label": "Mauritius"
//   },
//   {
//     "value": "Mayotte",
//     "label": "Mayotte"
//   },
//   {
//     "value": "Mexico",
//     "label": "Mexico"
//   },
//   {
//     "value": "Micronesia",
//     "label": "Micronesia"
//   },
//   {
//     "value": "Moldova",
//     "label": "Moldova"
//   },
//   {
//     "value": "Monaco",
//     "label": "Monaco"
//   },
//   {
//     "value": "Mongolia",
//     "label": "Mongolia"
//   },
//   {
//     "value": "Montenegro",
//     "label": "Montenegro"
//   },
//   {
//     "value": "Montserrat",
//     "label": "Montserrat"
//   },
//   {
//     "value": "Morocco",
//     "label": "Morocco"
//   },
//   {
//     "value": "Mozambique",
//     "label": "Mozambique"
//   },
//   {
//     "value": "Myanmar",
//     "label": "Myanmar"
//   },
//   {
//     "value": "Namibia",
//     "label": "Namibia"
//   },
//   {
//     "value": "Nauru",
//     "label": "Nauru"
//   },
//   {
//     "value": "Nepal",
//     "label": "Nepal"
//   },
//   {
//     "value": "Netherlands",
//     "label": "Netherlands"
//   },
//   {
//     "value": "Netherlands Antilles",
//     "label": "Netherlands Antilles"
//   },
//   {
//     "value": "New Caledonia",
//     "label": "New Caledonia"
//   },
//   {
//     "value": "New Zealand",
//     "label": "New Zealand"
//   },
//   {
//     "value": "Nicaragua",
//     "label": "Nicaragua"
//   },
//   {
//     "value": "Niger",
//     "label": "Niger"
//   },
//   {
//     "value": "Nigeria",
//     "label": "Nigeria"
//   },
//   {
//     "value": "Niue",
//     "label": "Niue"
//   },
//   {
//     "value": "Northern Mariana Islands",
//     "label": "Northern Mariana Islands"
//   },
//   {
//     "value": "North Korea",
//     "label": "North Korea"
//   },
//   {
//     "value": "North Macedonia",
//     "label": "North Macedonia"
//   },
//   {
//     "value": "Norway",
//     "label": "Norway"
//   },
//   {
//     "value": "Oman",
//     "label": "Oman"
//   },
//   {
//     "value": "Pakistan",
//     "label": "Pakistan"
//   },
//   {
//     "value": "Palau",
//     "label": "Palau"
//   },
//   {
//     "value": "Palestine",
//     "label": "Palestine"
//   },
//   {
//     "value": "Panama",
//     "label": "Panama"
//   },
//   {
//     "value": "Papua New Guinea",
//     "label": "Papua New Guinea"
//   },
//   {
//     "value": "Paraguay",
//     "label": "Paraguay"
//   },
//   {
//     "value": "Peru",
//     "label": "Peru"
//   },
//   {
//     "value": "Philippines",
//     "label": "Philippines"
//   },
//   {
//     "value": "Pitcairn Island",
//     "label": "Pitcairn Island"
//   },
//   {
//     "value": "Poland",
//     "label": "Poland"
//   },
//   {
//     "value": "Portugal",
//     "label": "Portugal"
//   },
//   {
//     "value": "Puerto Rico",
//     "label": "Puerto Rico"
//   },
//   {
//     "value": "Qatar",
//     "label": "Qatar"
//   },
//   {
//     "value": "Réunion",
//     "label": "Réunion"
//   },
//   {
//     "value": "Romania",
//     "label": "Romania"
//   },
//   {
//     "value": "Russia",
//     "label": "Russia"
//   },
//   {
//     "value": "Rwanda",
//     "label": "Rwanda"
//   },
//   {
//     "value": "Saint Helena",
//     "label": "Saint Helena"
//   },
//   {
//     "value": "Saint Kitts and Nevis",
//     "label": "Saint Kitts and Nevis"
//   },
//   {
//     "value": "Saint Lucia",
//     "label": "Saint Lucia"
//   },
//   {
//     "value": "Saint Pierre and Miquelon",
//     "label": "Saint Pierre and Miquelon"
//   },
//   {
//     "value": "Saint Vincent and the Grenadines",
//     "label": "Saint Vincent and the Grenadines"
//   },
//   {
//     "value": "Samoa",
//     "label": "Samoa"
//   },
//   {
//     "value": "San Marino",
//     "label": "San Marino"
//   },
//   {
//     "value": "Sao Tome and Principe",
//     "label": "Sao Tome and Principe"
//   },
//   {
//     "value": "Saudi Arabia",
//     "label": "Saudi Arabia"
//   },
//   {
//     "value": "Senegal",
//     "label": "Senegal"
//   },
//   {
//     "value": "Serbia",
//     "label": "Serbia"
//   },
//   {
//     "value": "Seychelles",
//     "label": "Seychelles"
//   },
//   {
//     "value": "Sierra Leone",
//     "label": "Sierra Leone"
//   },
//   {
//     "value": "Singapore",
//     "label": "Singapore"
//   },
//   {
//     "value": "Slovakia",
//     "label": "Slovakia"
//   },
//   {
//     "value": "Slovenia",
//     "label": "Slovenia"
//   },
//   {
//     "value": "Solomon Islands",
//     "label": "Solomon Islands"
//   },
//   {
//     "value": "Somalia",
//     "label": "Somalia"
//   },
//   {
//     "value": "South Africa",
//     "label": "South Africa"
//   },
//   {
//     "value": "South Korea",
//     "label": "South Korea"
//   },
//   {
//     "value": "South Sudan",
//     "label": "South Sudan"
//   },
//   {
//     "value": "Spain",
//     "label": "Spain"
//   },
//   {
//     "value": "Sri Lanka",
//     "label": "Sri Lanka"
//   },
//   {
//     "value": "Sudan",
//     "label": "Sudan"
//   },
//   {
//     "value": "Suriname",
//     "label": "Suriname"
//   },
//   {
//     "value": "Sweden",
//     "label": "Sweden"
//   },
//   {
//     "value": "Syria",
//     "label": "Syria"
//   },
//   {
//     "value": "Taiwan",
//     "label": "Taiwan"
//   },
//   {
//     "value": "Tajikistan",
//     "label": "Tajikistan"
//   },
//   {
//     "value": "Tanzania",
//     "label": "Tanzania"
//   },
//   {
//     "value": "Thailand",
//     "label": "Thailand"
//   },
//   {
//     "value": "Tibet",
//     "label": "Tibet"
//   },
//   {
//     "value": "Togo",
//     "label": "Togo"
//   },
//   {
//     "value": "Tokelau",
//     "label": "Tokelau"
//   },
//   {
//     "value": "Tonga",
//     "label": "Tonga"
//   },
//   {
//     "value": "Trinidad and Tobago",
//     "label": "Trinidad and Tobago"
//   },
//   {
//     "value": "Tunisia",
//     "label": "Tunisia"
//   },
//   {
//     "value": "Turkey",
//     "label": "Turkey"
//   },
//   {
//     "value": "Turkmenistan",
//     "label": "Turkmenistan"
//   },
//   {
//     "value": "Turks and Caicos Islands",
//     "label": "Turks and Caicos Islands"
//   },
//   {
//     "value": "Tuvalu",
//     "label": "Tuvalu"
//   },
//   {
//     "value": "U.S. Virgin Islands",
//     "label": "U.S. Virgin Islands"
//   },
//   {
//     "value": "Uganda",
//     "label": "Uganda"
//   },
//   {
//     "value": "Ukraine",
//     "label": "Ukraine"
//   },
//   {
//     "value": "United Arab Emirates",
//     "label": "United Arab Emirates"
//   },
//   {
//     "value": "Uruguay",
//     "label": "Uruguay"
//   },
//   {
//     "value": "Uzbekistan",
//     "label": "Uzbekistan"
//   },
//   {
//     "value": "Vanuatu",
//     "label": "Vanuatu"
//   },
//   {
//     "value": "Vatican City",
//     "label": "Vatican City"
//   },
//   {
//     "value": "Venezuela",
//     "label": "Venezuela"
//   },
//   {
//     "value": "Vietnam",
//     "label": "Vietnam"
//   },
//   {
//     "value": "Wallis and Futuna",
//     "label": "Wallis and Futuna"
//   },
//   {
//     "value": "Western Sahara",
//     "label": "Western Sahara"
//   },
//   {
//     "value": "Yemen",
//     "label": "Yemen"
//   },
//   {
//     "value": "Zambia",
//     "label": "Zambia"
//   },
//   {
//     "value": "Zimbabwe",
//     "label": "Zimbabwe"
//   }
// ];

// const countryValues = [{
//     "value": "Australia",
//     "label": "Australia"
//   },
//   {
//     "value": "Canada",
//     "label": "Canada"
//   },
//   {
//     "value": "Austria",
//     "label": "Austria"
//   },
//   {
//     "value": "Germany",
//     "label": "Germany"
//   },
//   {
//     "value": "Liechtenstein",
//     "label": "Liechtenstein"
//   },
//   {
//     "value": "Luxembourg",
//     "label": "Luxembourg"
//   },
//   {
//     "value": "Switzerland",
//     "label": "Switzerland"
//   },
//   {
//     "value": "United Kingdom",
//     "label": "United Kingdom"
//   },
//   {
//     "value": "United States",
//     "label": "United States"
//   },
//   {
//     "value": "Afghanistan",
//     "label": "Afghanistan"
//   },
//   {
//     "value": "Algeria",
//     "label": "Algeria"
//   },
//   {
//     "value": "Andorra",
//     "label": "Andorra"
//   },
//   {
//     "value": "Angola",
//     "label": "Angola"
//   },
//   {
//     "value": "Antigua and Barbuda",
//     "label": "Antigua and Barbuda"
//   },
//   {
//     "value": "Argentina",
//     "label": "Argentina"
//   },
//   {
//     "value": "Armenia",
//     "label": "Armenia"
//   },
//   {
//     "value": "Australia",
//     "label": "Australia"
//   },
//   {
//     "value": "Austria",
//     "label": "Austria"
//   },
//   {
//     "value": "Azerbaijan",
//     "label": "Azerbaijan"
//   },
//   {
//     "value": "Bahamas",
//     "label": "Bahamas"
//   },
//   {
//     "value": "Bahrain",
//     "label": "Bahrain"
//   },
//   {
//     "value": "Bangladesh",
//     "label": "Bangladesh"
//   },
//   {
//     "value": "Barbados",
//     "label": "Barbados"
//   },
//   {
//     "value": "Belarus",
//     "label": "Belarus"
//   },
//   {
//     "value": "Belgium",
//     "label": "Belgium"
//   },
//   {
//     "value": "Belize",
//     "label": "Belize"
//   },
//   {
//     "value": "Benin",
//     "label": "Benin"
//   },
//   {
//     "value": "Bermuda",
//     "label": "Bermuda"
//   },
//   {
//     "value": "Bhutan",
//     "label": "Bhutan"
//   },
//   {
//     "value": "Bolivia",
//     "label": "Bolivia"
//   },
//   {
//     "value": "Bosnia and Herzegovina",
//     "label": "Bosnia and Herzegovina"
//   },
//   {
//     "value": "Botswana",
//     "label": "Botswana"
//   },
//   {
//     "value": "Brazil",
//     "label": "Brazil"
//   },
//   {
//     "value": "Bulgaria",
//     "label": "Bulgaria"
//   },
//   {
//     "value": "Burkina Faso",
//     "label": "Burkina Faso"
//   },
//   {
//     "value": "Burundi",
//     "label": "Burundi"
//   },
//   {
//     "value": "Cambodia",
//     "label": "Cambodia"
//   },
//   {
//     "value": "Cameroon",
//     "label": "Cameroon"
//   },
//   {
//     "value": "Cape Verde",
//     "label": "Cape Verde"
//   },
//   {
//     "value": "Central African Republic",
//     "label": "Central African Republic"
//   },
//   {
//     "value": "Chad",
//     "label": "Chad"
//   },
//   {
//     "value": "Channel Islands",
//     "label": "Channel Islands"
//   },
//   {
//     "value": "Chile",
//     "label": "Chile"
//   },
//   {
//     "value": "China",
//     "label": "China"
//   },
//   {
//     "value": "Colombia",
//     "label": "Colombia"
//   },
//   {
//     "value": "Comoros",
//     "label": "Comoros"
//   },
//   {
//     "value": "Congo",
//     "label": "Congo"
//   },
//   {
//     "value": "Costa Rica",
//     "label": "Costa Rica"
//   },
//   {
//     "value": "Croatia",
//     "label": "Croatia"
//   },
//   {
//     "value": "Cuba",
//     "label": "Cuba"
//   },
//   {
//     "value": "Cyprus",
//     "label": "Cyprus"
//   },
//   {
//     "value": "Czech Republic",
//     "label": "Czech Republic"
//   },
//   {
//     "value": "Denmark",
//     "label": "Denmark"
//   },
//   {
//     "value": "Djibouti",
//     "label": "Djibouti"
//   },
//   {
//     "value": "Dominica",
//     "label": "Dominica"
//   },
//   {
//     "value": "Dominican Republic",
//     "label": "Dominican Republic"
//   },
//   {
//     "value": "DR Congo",
//     "label": "DR Congo"
//   },
//   {
//     "value": "East Timor",
//     "label": "East Timor"
//   },
//   {
//     "value": "Ecuador",
//     "label": "Ecuador"
//   },
//   {
//     "value": "Egypt",
//     "label": "Egypt"
//   },
//   {
//     "value": "Equatorial Guinea",
//     "label": "Equatorial Guinea"
//   },
//   {
//     "value": "Eritrea",
//     "label": "Eritrea"
//   },
//   {
//     "value": "Estonia",
//     "label": "Estonia"
//   },
//   {
//     "value": "Eswatini",
//     "label": "Eswatini"
//   },
//   {
//     "value": "Ethiopia",
//     "label": "Ethiopia"
//   },
//   {
//     "value": "Fiji",
//     "label": "Fiji"
//   },
//   {
//     "value": "Finland",
//     "label": "Finland"
//   },
//   {
//     "value": "France",
//     "label": "France"
//   },
//   {
//     "value": "Gabon",
//     "label": "Gabon"
//   },
//   {
//     "value": "Gambia",
//     "label": "Gambia"
//   },
//   {
//     "value": "Georgia",
//     "label": "Georgia"
//   },
//   {
//     "value": "Ghana",
//     "label": "Ghana"
//   },
//   {
//     "value": "Gibraltar",
//     "label": "Gibraltar"
//   },
//   {
//     "value": "Greece",
//     "label": "Greece"
//   },
//   {
//     "value": "Greenland",
//     "label": "Greenland"
//   },
//   {
//     "value": "Grenada",
//     "label": "Grenada"
//   },
//   {
//     "value": "Guadeloupe",
//     "label": "Guadeloupe"
//   },
//   {
//     "value": "Guatemala",
//     "label": "Guatemala"
//   },
//   {
//     "value": "Guinea",
//     "label": "Guinea"
//   },
//   {
//     "value": "Guinea-Bissau",
//     "label": "Guinea-Bissau"
//   },
//   {
//     "value": "Guyana",
//     "label": "Guyana"
//   },
//   {
//     "value": "Haiti",
//     "label": "Haiti"
//   },
//   {
//     "value": "Honduras",
//     "label": "Honduras"
//   },
//   {
//     "value": "Hong Kong",
//     "label": "Hong Kong"
//   },
//   {
//     "value": "Hungary",
//     "label": "Hungary"
//   },
//   {
//     "value": "Iceland",
//     "label": "Iceland"
//   },
//   {
//     "value": "India",
//     "label": "India"
//   },
//   {
//     "value": "Indonesia",
//     "label": "Indonesia"
//   },
//   {
//     "value": "Iran",
//     "label": "Iran"
//   },
//   {
//     "value": "Iraq",
//     "label": "Iraq"
//   },
//   {
//     "value": "Ireland",
//     "label": "Ireland"
//   },
//   {
//     "value": "Israel",
//     "label": "Israel"
//   },
//   {
//     "value": "Italy",
//     "label": "Italy"
//   },
//   {
//     "value": "Jamaica",
//     "label": "Jamaica"
//   },
//   {
//     "value": "Japan",
//     "label": "Japan"
//   },
//   {
//     "value": "Jordan",
//     "label": "Jordan"
//   },
//   {
//     "value": "Kazakhstan",
//     "label": "Kazakhstan"
//   },
//   {
//     "value": "Kenya",
//     "label": "Kenya"
//   },
//   {
//     "value": "Kiribati",
//     "label": "Kiribati"
//   },
//   {
//     "value": "Kosovo",
//     "label": "Kosovo"
//   },
//   {
//     "value": "Kuwait",
//     "label": "Kuwait"
//   },
//   {
//     "value": "Kyrgyzstan",
//     "label": "Kyrgyzstan"
//   },
//   {
//     "value": "Laos",
//     "label": "Laos"
//   },
//   {
//     "value": "Latvia",
//     "label": "Latvia"
//   },
//   {
//     "value": "Lebanon",
//     "label": "Lebanon"
//   },
//   {
//     "value": "Lesotho",
//     "label": "Lesotho"
//   },
//   {
//     "value": "Liberia",
//     "label": "Liberia"
//   },
//   {
//     "value": "Libya",
//     "label": "Libya"
//   },
//   {
//     "value": "Lithuania",
//     "label": "Lithuania"
//   },
//   {
//     "value": "Macao",
//     "label": "Macao"
//   },
//   {
//     "value": "Madagascar",
//     "label": "Madagascar"
//   },
//   {
//     "value": "Malawi",
//     "label": "Malawi"
//   },
//   {
//     "value": "Malaysia",
//     "label": "Malaysia"
//   },
//   {
//     "value": "Maldives",
//     "label": "Maldives"
//   },
//   {
//     "value": "Mali",
//     "label": "Mali"
//   },
//   {
//     "value": "Malta",
//     "label": "Malta"
//   },
//   {
//     "value": "Marshall Islands",
//     "label": "Marshall Islands"
//   },
//   {
//     "value": "Martinique",
//     "label": "Martinique"
//   },
//   {
//     "value": "Mauritania",
//     "label": "Mauritania"
//   },
//   {
//     "value": "Mauritius",
//     "label": "Mauritius"
//   },
//   {
//     "value": "Mexico",
//     "label": "Mexico"
//   },
//   {
//     "value": "Micronesia",
//     "label": "Micronesia"
//   },
//   {
//     "value": "Monaco",
//     "label": "Monaco"
//   },
//   {
//     "value": "Mongolia",
//     "label": "Mongolia"
//   },
//   {
//     "value": "Montenegro",
//     "label": "Montenegro"
//   },
//   {
//     "value": "Montserrat",
//     "label": "Montserrat"
//   },
//   {
//     "value": "Morocco",
//     "label": "Morocco"
//   },
//   {
//     "value": "Mozambique",
//     "label": "Mozambique"
//   },
//   {
//     "value": "Myanmar",
//     "label": "Myanmar"
//   },
//   {
//     "value": "Namibia",
//     "label": "Namibia"
//   },
//   {
//     "value": "Nauru",
//     "label": "Nauru"
//   },
//   {
//     "value": "Nepal",
//     "label": "Nepal"
//   },
//   {
//     "value": "Netherlands",
//     "label": "Netherlands"
//   },
//   {
//     "value": "New Caledonia",
//     "label": "New Caledonia"
//   },
//   {
//     "value": "New Zealand",
//     "label": "New Zealand"
//   },
//   {
//     "value": "Nicaragua",
//     "label": "Nicaragua"
//   },
//   {
//     "value": "Niger",
//     "label": "Niger"
//   },
//   {
//     "value": "Nigeria",
//     "label": "Nigeria"
//   },
//   {
//     "value": "Niue",
//     "label": "Niue"
//   },
//   {
//     "value": "Norway",
//     "label": "Norway"
//   },
//   {
//     "value": "Oman",
//     "label": "Oman"
//   },
//   {
//     "value": "Pakistan",
//     "label": "Pakistan"
//   },
//   {
//     "value":"Palau",
//     "label": "Palau"
//   },
//   {
//     "value": "Palestine",
//     "label": "Palestine"
//   },
//   {
//     "value": "Panama",
//     "label": "Panama"
//   },
//   {
//     "value": "Papua New Guinea",
//     "label": "Papua New Guinea"
//   },
//   {
//     "value": "Paraguay",
//     "label": "Paraguay"
//   },
//   {
//     "value": "Peru",
//     "label": "Peru"
//   },
//   {
//     "value": "Philippines",
//     "label": "Philippines"
//   },
//   {
//     "value": "Pitcairn Island",
//     "label": "Pitcairn Island"
//   },
//   {
//     "value": "Poland",
//     "label": "Poland"
//   },
//   {
//     "value": "Portugal",
//     "label": "Portugal"
//   },
//   {
//     "value": "Puerto Rico",
//     "label": "Puerto Rico"
//   },
//   {
//     "value": "Qatar",
//     "label": "Qatar"
//   },
//   {
//     "value": "Réunion",
//     "label": "Réunion"
//   },
//   {
//     "value": "Romania",
//     "label": "Romania"
//   },
//   {
//     "value": "Russia",
//     "label": "Russia"
//   },
//   {
//     "value": "Rwanda",
//     "label": "Rwanda"
//   },
//   {
//     "value": "Saint Helena",
//     "label": "Saint Helena"
//   },
//   {
//     "value": "Saint Kitts and Nevis",
//     "label": "Saint Kitts and Nevis"
//   },
//   {
//     "value": "Saint Lucia",
//     "label": "Saint Lucia"
//   },
//   {
//     "value": "Saint Pierre and Miquelon",
//     "label": "Saint Pierre and Miquelon"
//   },
//   {
//     "value": "Saint Vincent and the Grenadines",
//     "label": "Saint Vincent and the Grenadines"
//   },
//   {
//     "value": "Samoa",
//     "label": "Samoa"
//   },
//   {
//     "value": "San Marino",
//     "label": "San Marino"
//   },
//   {
//     "value": "Sao Tome and Principe",
//     "label": "Sao Tome and Principe"
//   },
//   {
//     "value": "Saudi Arabia",
//     "label": "Saudi Arabia"
//   },
//   {
//     "value": "Senegal",
//     "label": "Senegal"
//   },
//   {
//     "value": "Serbia",
//     "label": "Serbia"
//   },
//   {
//     "value": "Seychelles",
//     "label": "Seychelles"
//   },
//   {
//     "value": "Sierra Leone",
//     "label": "Sierra Leone"
//   },
//   {
//     "value": "Singapore",
//     "label": "Singapore"
//   },
//   {
//     "value": "Slovakia",
//     "label": "Slovakia"
//   },
//   {
//     "value": "Slovenia",
//     "label": "Slovenia"
//   },
//   {
//     "value": "Solomon Islands",
//     "label": "Solomon Islands"
//   },
//   {
//     "value": "Somalia",
//     "label": "Somalia"
//   },
//   {
//     "value": "South Africa",
//     "label": "South Africa"
//   },
//   {
//     "value": "South Korea",
//     "label": "South Korea"
//   },
//   {
//     "value": "South Sudan",
//     "label": "South Sudan"
//   },
//   {
//     "value": "Spain",
//     "label": "Spain"
//   },
//   {
//     "value": "Sri Lanka",
//     "label": "Sri Lanka"
//   },
//   {
//     "value": "Sudan",
//     "label": "Sudan"
//   },
//   {
//     "value": "Suriname",
//     "label": "Suriname"
//   },
//   {
//     "value": "Sweden",
//     "label": "Sweden"
//   },
//   {
//     "value": "Syria",
//     "label": "Syria"
//   },
//   {
//     "value": "Taiwan",
//     "label": "Taiwan"
//   },
//   {
//     "value": "Tajikistan",
//     "label": "Tajikistan"
//   },
//   {
//     "value": "Tanzania",
//     "label": "Tanzania"
//   },
//   {
//     "value": "Thailand",
//     "label": "Thailand"
//   },
//   {
//     "value": "Togo",
//     "label": "Togo"
//   },
//   {
//     "value": "Tokelau",
//     "label": "Tokelau"
//   },
//   {
//     "value": "Tonga",
//     "label": "Tonga"
//   },
//   {
//     "value": "Trinidad and Tobago",
//     "label": "Trinidad and Tobago"
//   },
//   {
//     "value": "Tunisia",
//     "label": "Tunisia"
//   },
//   {
//     "value": "Turkey",
//     "label": "Turkey"
//   },
//   {
//     "value": "Turkmenistan",
//     "label": "Turkmenistan"
//   },
//   {
//     "value": "Tuvalu",
//     "label": "Tuvalu"
//   },
//   {
//     "value": "Uganda",
//     "label": "Uganda"
//   },
//   {
//     "value": "Ukraine",
//     "label": "Ukraine"
//   },
//   {
//     "value": "United Arab Emirates",
//     "label": "United Arab Emirates"
//   },
//   {
//     "value": "Uruguay",
//     "label": "Uruguay"
//   },
//   {
//     "value": "Uzbekistan",
//     "label": "Uzbekistan"
//   },
//   {
//     "value": "Vanuatu",
//     "label": "Vanuatu"
//   },
//   {
//     "value": "Vatican City",
//     "label": "Vatican City"
//   },
//   {
//     "value": "Venezuela",
//     "label": "Venezuela"
//   },
//   {
//     "value": "Vietnam",
//     "label": "Vietnam"
//   },
//   {
//     "value": "Wallis and Futuna",
//     "label": "Wallis and Futuna"
//   },
//   {
//     "value": "Western Sahara",
//     "label": "Western Sahara"
//   },
//   {
//     "value": "Yemen",
//     "label": "Yemen"
//   },
//   {
//     "value": "Zambia",
//     "label": "Zambia"
//   },
//   {
//     "value": "Zimbabwe",
//     "label": "Zimbabwe"
//   }
// ]

// const countryValues = [
//   {
//     "value": "United States",
//     "label": "United States"
//   },
//   {
//     "value": "Canada",
//     "label": "Canada"
//   },
//   {
//     "value": "United Kingdom",
//     "label": "United Kingdom"
//   },
//   {
//     "value": "Australia",
//     "label": "Australia"
//   },
//   {
//     "value": "Ireland",
//     "label": "Ireland"
//   },
//   {
//     "value": "Portugal",
//     "label": "Portugal"
//   },
//   {
//     "value": "Gibraltar",
//     "label": "Gibraltar"
//   },
//   {
//     "value": "India",
//     "label": "India"
//   },
//   {
//     "value": "Italy",
//     "label": "Italy"
//   },
//   {
//     "value": "New Zealand",
//     "label": "New Zealand"
//   },
//   {
//     "value": "Germany",
//     "label": "Germany"
//   },
//   {
//     "value": "South Africa",
//     "label": "South Africa"
//   },
//   {
//     "value": "Pakistan",
//     "label": "Pakistan"
//   },
//   {
//     "value": "Malaysia",
//     "label": "Malaysia"
//   },
//   {
//     "value": "Spain",
//     "label": "Spain"
//   },
//   {
//     "value": "Philippines",
//     "label": "Philippines"
//   },
//   {
//     "value": "Zimbabwe",
//     "label": "Zimbabwe"
//   },
//   {
//     "value": "Switzerland",
//     "label": "Switzerland"
//   },
//   {
//     "value": "Argentina",
//     "label": "Argentina"
//   },
//   {
//     "value": "Mexico",
//     "label": "Mexico"
//   },
//   {
//     "value": "Guatemala",
//     "label": "Guatemala"
//   },
//   {
//     "value": "Bolivia",
//     "label": "Bolivia"
//   },
//   {
//     "value": "Austria",
//     "label": "Austria"
//   },
//   {
//     "value": "Brazil",
//     "label": "Brazil"
//   },
//   {
//     "value": "Netherlands",
//     "label": "Netherlands"
//   },
//   {
//     "value": "Belgium",
//     "label": "Belgium"
//   },
//   {
//     "value": "Sri Lanka",
//     "label": "Sri Lanka"
//   },
//   {
//     "value": "Malta",
//     "label": "Malta"
//   },
//   {
//     "value": "Guyana",
//     "label": "Guyana"
//   },
//   {
//     "value": "Costa Rica",
//     "label": "Costa Rica"
//   },
//   {
//     "value": "France",
//     "label": "France"
//   },
//   {
//     "value": "Slovenia",
//     "label": "Slovenia"
//   },
//   {
//     "value": "Japan",
//     "label": "Japan"
//   },
//   {
//     "value": "Jamaica",
//     "label": "Jamaica"
//   },
//   {
//     "value": "Grenada",
//     "label": "Grenada"
//   },
//   {
//     "value": "Chile",
//     "label": "Chile"
//   },
//   {
//     "value": "Colombia",
//     "label": "Colombia"
//   },
//   {
//     "value": "Poland",
//     "label": "Poland"
//   },
//   {
//     "value": "Belarus",
//     "label": "Belarus"
//   },
//   {
//     "value": "Ukraine",
//     "label": "Ukraine"
//   },
//   {
//     "value": "Czech Republic",
//     "label": "Czech Republic"
//   },
//   {
//     "value": "Taiwan",
//     "label": "Taiwan"
//   },
//   {
//     "value": "Venezuela",
//     "label": "Venezuela"
//   },
//   {
//     "value": "Peru",
//     "label": "Peru"
//   },
//   {
//     "value": "Channel Islands",
//     "label": "Channel Islands"
//   },
//   {
//     "value": "Kenya",
//     "label": "Kenya"
//   },
//   {
//     "value": "Denmark",
//     "label": "Denmark"
//   },
//   {
//     "value": "Eswatini",
//     "label": "Eswatini"
//   },
//   {
//     "value": "Thailand",
//     "label": "Thailand"
//   },
//   {
//     "value": "Saint Kitts and Nevis",
//     "label": "Saint Kitts and Nevis"
//   },
//   {
//     "value": "Latvia",
//     "label": "Latvia"
//   },
//   {
//     "value": "Nigeria",
//     "label": "Nigeria"
//   },
//   {
//     "value": "Nicaragua",
//     "label": "Nicaragua"
//   },
//   {
//     "value": "Antigua and Barbuda",
//     "label": "Antigua and Barbuda"
//   },
//   {
//     "value": "Lithuania",
//     "label": "Lithuania"
//   },
//   {
//     "value": "Ghana",
//     "label": "Ghana"
//   },
//   {
//     "value": "Belize",
//     "label": "Belize"
//   },
//   {
//     "value": "Slovakia",
//     "label": "Slovakia"
//   }
// ]
export { countryValues, data };

const countryValues = ["Canada", "United States", "United Kingdom", "Australia", "Ireland", "Portugal", "Gibraltar", "India", "Italy", "Germany", "New Zealand", "South Africa", "Netherlands", "Malaysia", "Spain", "Chile", "Colombia", "Argentina", "Mexico", "Guatemala", "Bolivia", "Peru", "Austria", "Switzerland", "Thailand", "Saint Kitts and Nevis", "Philippines", "Zimbabwe", "France", "Latvia", "Nigeria", "Belgium", "Sri Lanka", "Malta", "Brazil", "Nicaragua", "Slovenia", "Vatican City", "Venezuela", "Guyana", "Costa Rica", "Grenada", "Japan", "Lithuania", "Ghana", "Belize", "Poland", "Belarus", "Slovakia", "Czech Republic", "Taiwan", "Ukraine", "Channel Islands", "Denmark"];

const data = [
  { Country: "Canada", Parish: "St. Thomas More", Diocese: "Christ the King - Dartmouth" },
  { Country: "Canada", Parish: "St. Thomas More", Diocese: "Christ the King - Dartmouth" },
  { Country: "United States", Parish: "St Patrick Parish-ONeill", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Catholic Parishes in Partnership of Nebraska", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Sacred Heart Parish Norfolk", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St Gerald, Ralston Nebraska", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St Wenceslaus Parish", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Divine Mercy, Schuyler", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Wenceslaus Parish Verdigre", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Margaret Mary - Omaha", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Catholic Parishes in Partnership", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Sacred Heart", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Christ the King Omaha", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Bernadette", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Cecilia", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Patrick O'Neill NE", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Vincent de Paul", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Wenceslaus", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Robert Bellarmine", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Gerald", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Mary's - Bellevue", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Thomas More", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Catholic Parishes of Western Holt and Boyd Counties", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. John Vianney", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Charles Borromeo", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Mary's", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Patrick's Elkhorn", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Philip Neri-Blessed Sacrament", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St. Patrick, Gretna NE", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "St Gerald, Ralston", Diocese: "Archdiocese of Omaha" },
  { Country: "United States", Parish: "Divine Mercy Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Mary Mother of Mercy", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Josaphat", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Katherine Drexel (Stony Grove Grouping)", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Mother Theodore Guerin", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Raphael the Archangel Catholic Church - Illinois", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Vincent De Paul", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St William", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Teresa of Avila Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of the Lakes", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Young Adult Engagement Team", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Patrick's Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Ss. Faith, Hope & Charity", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Prince of Peace", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Paul Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Julie Billiart", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Immaculate Conception (Talcott)", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Immaculate Conception and St Joseph", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Nicholas of Tolentine", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Sacred Heart and St. Philip", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Saint Clement Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Clement", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary - Illinois", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Bartholomew Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of Ransom, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Queen of All Nations Parish, Chicago, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Vincent Ferrer", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Soul City Church", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. James Highwood", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "The Church of the Holy Spirit", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Church of the Holy Spirit", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Good Shepherd Catholic Church", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Holy Child Jesus Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Holy Family Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Mary Seat of Wisdom", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "National Shrine of St. Maximilian Kolbe", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Most Blessed Trinity Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of the Ridge", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Saint Benedict Parish, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Saint Paul the Apostle", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Sheil Catholic Center", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Catherine of Alexandria", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Cecilia Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Damian", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Edward", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Elizabeth Seton", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Gall", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Hubert", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. James", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. John the Evangelist", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Juliana", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Julie Billart", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary Evanston", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Nobert Church", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Paul", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Paul of the Cross", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Raymond", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Stephen Deacon & Martyr", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Theresa", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Theresa Parish (Palantine)", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Andrew", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Colette", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Church of St. Mary", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Holy Name Cathedral", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Incarnation Catholic Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Mision San Juan Diego", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of Perpetual Help", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady at St. Germaine", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of Humility", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of Sorrows", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Archdiocesean Pastoral Council", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Sacred Heart & St. Eulalia", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Saint Anne Catholic Community", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary of the Annunciation", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Joseph & Francis Xavier Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Joseph Catholic Church, Libertyville", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Alphonsus Liguori", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Anne, Hazel Crest", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Catherine of Siena-St. Lucy", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Christopher", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Denis Church", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Edna", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Elizabeth", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Francis De Sales", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Francis Xavier Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Paul VI", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. John Berchmans", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. John Brebeuf", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary, Buffalo Grove", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary of the Angels", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary of the Woods", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary of Vernon", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Paul of the Cross", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Richard", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Gilbert", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. James Highwood", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Michael the Archangel", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Padre Puo", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Thomas Becket", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Polonia Ministry", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "SS Genevieve and Stanislaus Bishop and Martyr", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Mary, Mother of Divine Grace, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Holy Guardian Angels, La Grange Park", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Paul VI, North Riverside", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Holy Virgin Martyrs: St. Beatrice & St. Maria Goretti", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Paul VI, Riverside", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Edward, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Little Flower Parish, Waukegan", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Mother of the Americas, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary and St. Stephen Protomartyr Des Plaines IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Assumption Catholic Church, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Norbert and Our Lady of the Brook Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Agnes of Bohemia", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Mother Teresa of Calcutta Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Santa Maria del Popolo", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary of the Lake and Our Lady of Lourdes Parish, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Elizabeth of the Trinity, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of Mercy, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Francis Xavier, Lagrange, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Blessed Maria Gabriella", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "SALESIAN PARISHES OF ST. JAMES & ST. JOHN BOSCO", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Catherine Laboure", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Blessed Sacrament", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Queen of Apostles, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "San Jose Sanchez del Rio", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Queen Of All Saints Basilica", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Alphonsus Parish, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Old St Mary's, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Lawrence O'Toole Campus, Matteson", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Parish of Ss Joseph, Anne, & Emeric", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Marcelline, Schaumburg", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Josephine Bakhita, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Paul the Apostle, Gurnee, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel, Melrose Park, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Alphonsus and St. Patrick Parish, Lemont, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Two Holy Martyrs, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Faustina Kowalska, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Holy Family Parish, Inverness, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Anne, Barrington, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Rita of Cascia, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Queen of Martyrs and St Bernadette", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Sts. Martha and Isaac Jogues Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Patricia Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Blessed Martyrs of Chimbote Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Mary of Czestochowa", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Saint Mary of the Lake and Our Lady of Lourdes Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mary Frances of the Five Wounds", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Zachary Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Emily Catholic Church", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Albert the Great, Burbank, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Pius and St. Leonard Parish, Berwyn, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. John Brebeuf Household", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Bede and & St. Denis Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Jesus, Bread of Life Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Cletus Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of the Wayside", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of the Wayside", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Sacred Heart Parish, Palos Hills", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Gerald, Oak Lawn, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Bruno Catholic Church", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Padre Pio", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St John XXIII", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Luke and St. Bernardine Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "All Saints", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. John Henry Newman", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Mother Theodore Guerin Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Christ the King, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Barnabas, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St John Fisher & St Cajetan, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St Giles & St Catherine-St Lucy, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Ascenion and St Edmund", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of Hope, Chicago", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St.. Luke and St. Bernardine Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Ascension and St. Edmund Parish", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "All Saints", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Christ Our Hope Parish, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Brigid Parish, Beach Park, IL", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "St. Padre Pio", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Mary Queen of the Apostles", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St Elizabeth of Hungary & St Isadore parishes", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Holy Redeemer - Immaculate Conception Collaborative", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Michael the Archangel", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Our Lady of Fatima", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Blessed Trinity", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Catholic Parishes of Arlington", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Holy Family Duxbury", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Our Lady of Fatima", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Sacred Heart", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Saint Blaise Parish", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Saint Matthias", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Anselm", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Eulalia", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St Patirck's", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Divine Mercy Shrine", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Catholic Tri-Parishes of Brockton", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Saint Michael Parish", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Host Catholic", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Columbkille Parish", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Thomas the Apostle", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Nashoba Valley Catholic Collaborative", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Matthew the Evangeslist", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Theresa of Avila", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Lexington Catholic Community", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. John Chrysostom", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St. Raphael", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Christ the King, Worcester MA", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Saint Mary of the Assumption, Brookline", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St Ann by the Sea", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Catholic Parishes of the Blue Hills", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "St Julia Parish, Weston & Lincoln", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "The Holy Rood Collaborative", Diocese: "Archdiocese of Boston" },
  { Country: "United States", Parish: "Christ the King - Mich", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St James, Cornelius & Cyprian", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St Thomas the Apostle", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St Robert Belaramine, Flushing MI USA", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Catholic Community of Flint", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St.Thomas the Apostle", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "The Catholic Community of St, James, Cornelius & Cyprian", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Church of the Resurrection", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Good Shepherd Catholic Church", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Holy Spirit", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Immaculate Heart of Mary", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Mission Pathways", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Mary", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Mary Catholic Church", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Patrick Brighton MI.", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Rita", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Joseph Melkite", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Holy Redeemer", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. John the Evangelist Fenton", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Therese of Lisieux", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. John Catholic Church", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "East Lansing Catholics", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "Saint Mary Magdalen, Brighton", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Martha, Okemos", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Pius X, Flint", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Eilzabeth/St. Dominic", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Mary Student Parish", Diocese: "Diocese of Lansing" },
  { Country: "United States", Parish: "St. Mary, Pinckney MI", Diocese: "Diocese of Lansing" },
  { Country: "Canada", Parish: "St. Ignatius of Loyola - Montreal", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Unity Pastoral du Nouveau Rosement - Paroisse St Boneventure", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Luke Catholic Community - Dollard-des-Ormeaux", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Edward the Confessor", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St-Jean Bosco", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Thomas à Becket Church", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Jesus Light of the World", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Monica / OEPS", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "La Fabrique de la Paroisse de Saint Bonaventure", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Holy Name of Jesus Parish, Laval Qc, Canada", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St John Fisher, Pointe-Claire, QC, Canada", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Mission Marie-Auxiliatrice, Montreal, Canada", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Thomas More & St. Willibrord, Verdun, Quebec", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Our Lady of the Annunciation Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Corpus Christi Parish - Senneville", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Paroisse Consolata", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Our Lady of the Philippines/FCMM", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Holy Cross Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Holy Name of Jesus Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Paroisse de l’Immaculée-Conception", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Eglise de La Présentation-de-la-Sainte-Vierge", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Mission Maria Ausiliatrice", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Our Lady of Deliverance", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Our Lady of Mount Carmel Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Church of Our Lady of Pompei", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Sainte-Anne-de-Bellevue Church", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Eglise Saint-Jean Baptiste", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Paroisse Saint-Bonaventure", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Brendan's Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Eglise Saint Elzear", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Gabriel's Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Paroisse Saint-Germain", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Mission Saint-Irenee-de-Lyon", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Aloysius Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Saint Augustine of Canterbury Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Saint Edmund of Canterbury", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Francis Of Assisi Church", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. John Brebeuf Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Malachy's Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Saint Patrick's Basilica - Montreal", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Thomas More Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. Veronica's Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St Augustine of Canterbury Parish, Montreal", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "St. John Brebeuf Parish, LaSalle", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Madre Dei Cristiani Catholic Church, Lasalle", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Transfiguration of Our Lord Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Our Lady of Fatima Parish", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Sanctuaire de la Miséricorde", Diocese: "Archdiocese of Montreal" },
  { Country: "Canada", Parish: "Paroisse Purification de la B.V.M.", Diocese: "Archdiocese of Montreal" },
  { Country: "United Kingdom", Parish: "St John Bosco and St Bernadette Erskine", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Charles Borromeo, Paisley", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Fergus", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St James, Renfrew", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Port Glasgow", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Joseph and St Bridget, Clarkston", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Joseph, Greenock", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Laurence's, Greenock", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Mary's, Greenock", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Margaret's, Johnstone", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Barrhead", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Conval's, Linwood", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Joseph’s & St Patrick’s, Wemyss Bay", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Aidan's Johnstone", Diocese: "Diocese of Paisley" },
  { Country: "United Kingdom", Parish: "St Peter's", Diocese: "Diocese of Paisley" },
  { Country: "Australia", Parish: "Mary Immaculate", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St John the Evangelist - NSW", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Mary MacKillop", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Michael's Parish, Nowra", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Our Lady of Mount Carmel Parish Varroville", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Our Lady of Mount Carmel Parish Varroville", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Mary's & St Francis of Assisi, Warrawong, NSW, Australia", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Immaculate Conception, Unanderra", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Port Kembla - St Patrick’s Catholic Parish", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "West Wollongong - St Therese Catholic Parish", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Lumen Christi Catholic Parishes, Wollongong", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Mary MacKillop, Oran Park", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Paul's, Camden", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Ss Peter and Paul Catholic Parish, Kiama", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Parish of St Michael, Thirroul and Wombarra", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St John the Evangelist, Campbelltown", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Thomas More, Ruse", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Paul's, Albion Park", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "All Saints, Shellharbour City", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Corpus Christi, Mittagong - Bowral", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St John the Evangelist, Dapto", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Holy Family Catholic parish, Ingleburn", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Michael's, Lane Cove", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Joseph's Parish, Bulli", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Anthony's Parish, Tahmoor", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Columbkille’s, Corrimal", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Francis Xavier, Ballina", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Mary Star of the Sea, Milton", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Mary's, Berkeley", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Paul's, Moss Vale", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "St Francis of Assisi Catholic Parish", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Our Lady of Mount Carmel Catholic Parish", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Holy Family Catholic Parish Ingleburn", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Holy Cross Catholic Parish Helensburgh", Diocese: "Diocese of Wollongong" },
  { Country: "Australia", Parish: "Our Lady Help of Christians Rosemeadow", Diocese: "Diocese of Wollongong" },
  { Country: "United States", Parish: "St. John Paul II", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "St. John Paul II, Sellersburg, Indiana", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "Saints Peter and Paul", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "St. Jude", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "St. Susanna", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "St. Therese Little Flower", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "St. Charles Borromeo", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "Our Lady of Perpetual Help, New Albany", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "Our Lady of the Greenwood", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "St Anthony of Padua - Indianapolis", Diocese: "Archdiocese of Indianapolis" },
  { Country: "United States", Parish: "St Simon The Apostle Catholic", Diocese: "Archdiocese of Indianapolis" },
  { Country: "Australia", Parish: "Our Lady of the Southern Cross", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Emmanuel Community", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St. Mary's Coomera", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Noosa District Parish, Queensland", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Our Lady of the Rosary, Shelly Beach, Australia", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St James Parish Coorparoo, Queensland, Australia", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Cannon Hill - St Oliver Plunkett", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "New Farm - Holy Spirit", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Caloundra Catholic Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Noosa District Catholic Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Albany Creek - All Saints", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Holy Cross, Wooloowin", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Coolangatta Tugun Catholic Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Corinda Graceville Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Dutton Park West End - St Ita's", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Evangelisation Brisbane", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Ingham Region Catholic Parishes", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Ipswich Catholic Community", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Living Waters Parish, Caboolture", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Stella Maris, Maroochydore", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Mother of Mercy Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Our Lady of Lourdes, Sunnybank", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St. Paul's, Woodridge", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Rita's, Victoria Point", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Thomas, Camp Hill", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Upper Mt Gravatt Wishart Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Grovely-Samford & Mitchelton Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Jubilee Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Our Lady of Mount Carmel, Coorparoo", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Petrie Catholic Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Mary's, South Brisbane", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St James, Coorparoo", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Benedict's, North Lakes", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St John’s Wood/The Gap", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Kangaroo Point Parish, East Brisbane", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Sebastian’s, Yeronga\xa0", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Our Lady of Fatima, Acacia Ridge", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Guardian Angels, Wynnum", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Holy Family, Indooroopilly", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Annerley Ekibin Parish, Brisbane Oratory", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Christ the King Deception Bay", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St. Agatha's, Clayfield, QLD", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Sacred Heart Booval", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Stafford Catholic Parish-Our Lady Queen of Apostles", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Luke's Buranda", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Our Lady of the Valley, Laidley", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Therese & Anthony Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Therese & St Anthony, Kedron", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Kedron Brook Catholic Community", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Regents Park Catholic Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Patrick's Parish Beenleigh", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Sacred Heart Catholic Parish", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Sebastian's Parish Yeronga", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "Our Lady Queen of Apostles Brisbane", Diocese: "Archdiocese of Brisbane" },
  { Country: "Australia", Parish: "St Peter's Rochedale", Diocese: "Archdiocese of Brisbane" },
  { Country: "United States", Parish: "Saint Dominic Parish", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "St. Mary’s Parish, Florida", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "St. John the Evangelist, Panama City, USA", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "Catheral of the Sacred Heart", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "Nativity of Our Lord", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "Saint Mary Catholic Church", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "St. Rita", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "Nativity of the Blessed Virgin Mary", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "St Paul - Pensacola", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "Good Shepherd Catholic Church, Tallahassee", Diocese: "Diocese of Pensacola-Tallahassee" },
  { Country: "United States", Parish: "St Francis of Assisi Burien, WA", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Mary, Queen of Peace", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Saint Michael", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Holy Family Catholic Church", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Holy Redeemer", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Immaculate Heart of Mary", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Saint Michael Parish", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Saint Stephen the Martyr", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. John Mary Vianney Catholic Church", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Madeleine Sophie", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. John Mission", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Joseph Parish", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Immaculate Heart of Mary", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Mary Catholic Church", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Skagit Valley Catholic Churches", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Bernadette", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Charles Borromeo", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Patrick, Tacoma", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Pius X", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Bridget, Seattle", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "Blessed Sacrament, Seattle", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. James Cathedral, Seattle", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "St. Anne Catholic Church, Seattle, WA", Diocese: "Archdiocese of Seattle" },
  { Country: "United States", Parish: "CLALLAM CATHOLIC", Diocese: "Archdiocese of Seattle" },
  { Country: "Ireland", Parish: "Bohermeen", Diocese: "Diocese of Meath" },
  { Country: "Ireland", Parish: "Dunshaughlin & Culmullen Parish", Diocese: "Diocese of Meath" },
  { Country: "Ireland", Parish: "Clonmellon & Killallon Parish", Diocese: "Diocese of Meath" },
  { Country: "Ireland", Parish: "Delvin Parish", Diocese: "Diocese of Meath" },
  { Country: "Ireland", Parish: "Ashbourne-Donaghmore Parish", Diocese: "Diocese of Meath" },
  { Country: "Ireland", Parish: "Star of the Sea, Mornington", Diocese: "Diocese of Meath" },
  { Country: "United Kingdom", Parish: "St Mary's Cathedral - Aberdeen", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "St Columba's, Banchory, Aberdeenshire, Scotland UK", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Torry", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "St Mary of the Assumption Cathedral", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "St Aidan's, Aviemore", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "St Margaret and the Sacred Heart Church, Lerwick", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "Immaculate Conception Pastoral Area", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "Our Lady & St John the Baptist, Ellon", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "St Peter's, Aberdeen", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "St Margaret and the Sacred Heart Catholic Church, Shetland", Diocese: "Diocese of Aberdeen" },
  { Country: "United Kingdom", Parish: "St. Peter & the Winchester Martyrs", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St James & St William of York, Reading", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Immaculate Conception and St Joseph, Christchurch", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Edmunds and St Josephs, Southampton", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Joseph and St Margaret, Clitherow Bracknell", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Michael and All Angels, Leigh Park", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Annunciation Netley Abbey, Southampton", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Avon Stour Pastoral Area", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Corpus Christi and St Joseph, North End", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Corpus Christi, Wokenham", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "English Martyrs Reading", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "English Martyrs, Didcot", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Fordingbridge Parish,  (St Mary's)", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Holy Family, Millbrook, Southampton", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady and St John, Stubbington", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady and the Saints of Guernsey, Guernsey (Channel Islands)", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady of Sorrows, Fordingbridge", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Peter Waterlooville", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Fareham and Portchester", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Hook", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Boniface, Southampton", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Colmans with St.Pauls", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Edmund (Our Lady and St Edmund) Abingdon", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Edward and St Mark, Windsor", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St John's Cathedral", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Joseph, Basingstoke", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Joseph, Christchurch, Dorset", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Joseph, Grayshott", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Josephs, Christchurch and Holy Redeemer Highcliffe", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Joseph's, Maidenhead", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Mary, Gosport, Hampshire", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Mary's, Portchester", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Michael Tadley and St Oswald Burghfield Common", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Swithun Well Parish", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Thomas More, Iford", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Thomas of Catebury and St Saviours, Totland Bay", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Woolhampton (Douai Abbey) parish", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Aldershot Catholic Community", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady & St Dominic", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Parish of the Cove", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Southampton City Catholics", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "The Catholic Church in Jersey", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Holy Trinity and Our Lady", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Holy Ghost & St Bede, Basingstoke", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Andover", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Catholic Parish of Havant & Emsworth", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Joan of Arc, Farnham", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Thomas More Parish Twyford", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St. Edmund Campion, Maidenhead", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "The Annunciation & Edmund Campion", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "South Wight Catholic Parsh", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Joseph's, Tileurst", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady Help of Christians, Farnborough", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Immaculate Conception, Southampton", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Christ the King and St Colman", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "New Forest Catholic Churches", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady Queen of Peace", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Bournemouth Oratory (Sacred Heart)", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady of the Assumption and St Edward the Confessor", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Parish of Newbury", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes and St Swithun", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Theresa of the child Jesus, Totton", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "St Mary's Alton", Diocese: "Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Ringwood and Fordingbridge", Diocese: "Diocese of Portsmouth" },
  { Country: "Canada", Parish: "St Anthony of Padua - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Christ the Redeemer Parish, West Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Patrick's Parish, Maple Ridge", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Augustine's Catholic Church", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "All Saints, Coquitlam", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St Joseph's, Port Moody, BC, Canada", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Corpus Christi, Vancouver, BC, Canada", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St Anthony's, West Vancouver, Canada", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Holy Trinity", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Sacred Heart Parish, Delta", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Joseph's Parish, Port Moody", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Star of the Sea Parish", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Holy Trinity Parish - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Church of Christ the King - Burnaby", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Sacred Heart Parish - Delta", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Joseph's Parish - Port Moody", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Our Lady of the Assumption - Port Coquitlam", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Edmunds Parish - North Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Sts. Cyril and Methodius Slovak Parish", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Nicholas Parish", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Guardian Angels Parish - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Holy Cross Parish - Burnaby", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Holy Rosary Cathedral", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Holy Trinity Parish - North Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Immaculate Conception Parish - Delta", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Our Lady of Fatima Portuguese Parish", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Our Lady of Good Counsel - Surrey", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Our Lady of Lourdes Parish - Coquitlam", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Our Lady of Sorrows Parish - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Jude Parish", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Patrick's Parish - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Anthony's Parish - West Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Clare of Assisi Parish - Coquitlam", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Francis de Sales Parish - Burnaby", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. James Parish - Abbotsford", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Joseph the Worker Parish - Richmond", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Luke's Parish - Maple Ridge", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Mark's Parish - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Mary's Parish - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Matthew's Parish - Surrey", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Monica Parish - Richmond", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Theresa's Parish - Burnaby", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Joseph's Parish - Langley", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Star fo the Sea, Whiterock BC", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Star of the Sea, Surrey", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Blessed Sacrament Parish, Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Holy Name of Jesus Parish, Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Pius X, North Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Ss. Peter and Paul Parish - Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Holy Spirit, New Westminster", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St Ann's, Abbotsford", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Joseph, Mission BC", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Our Lady of Mercy Catholic Church, Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Our Lady of Perpetual Help, Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "Precious Blood Parish, Surrey", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Francis Xavier", Diocese: "Archdiocese of Vancouver" },
  { Country: "Canada", Parish: "St. Mary's Catholic Parish, Vancouver", Diocese: "Archdiocese of Vancouver" },
  { Country: "United States", Parish: "St Paul Catholic Church, Flowood", Diocese: "Diocese of Jackson" },
  { Country: "United States", Parish: "Saint Paul Catholic Church", Diocese: "Diocese of Jackson" },
  { Country: "United States", Parish: "St. John the Evangelist Catholic Church", Diocese: "Diocese of Jackson" },
  { Country: "United States", Parish: "Holy Savior Catholic Church", Diocese: "Diocese of Jackson" },
  { Country: "United States", Parish: "St. Jude Catholic Church", Diocese: "Diocese of Jackson" },
  { Country: "United States", Parish: "St Raphael the Archangel Catholic Church - North Car", Diocese: "Diocese of Raleigh" },
  { Country: "United States", Parish: "Catholic Community of St. Francis of Assisi", Diocese: "Diocese of Raleigh" },
  { Country: "United States", Parish: "St. Charles Borromeo", Diocese: "Diocese of Raleigh" },
  { Country: "United States", Parish: "St. Mary Magdalene", Diocese: "Diocese of Raleigh" },
  { Country: "United States", Parish: "St. Thomas More", Diocese: "Diocese of Raleigh" },
  { Country: "United States", Parish: "Saint Eugene, Wendell", Diocese: "Diocese of Raleigh" },
  { Country: "Canada", Parish: "St. Isidore, Ontario", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St Patrick Fallowfield", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Francis de Sales", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Maurice Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Peter's Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Mary's Parish - Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Holy Cross Church", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Annunciation of the Lord Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Blessed Sacrament Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Canadian Martyrs Roman Catholic Church", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Notre-Dame-de-Lorette", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Saint Gabriel, Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Saint Joseph's Parish, Lancaster", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St Pius X Long Sault Ontario", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Andrew's", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Bernard's Finch", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "ST. Michael's Parish, Corkery", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Ste-Euphemie", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St George's, Ottawa, ON, Canada", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "North Glengarry Parishes", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse St-Joseph d’Orléans", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse St-Joseph d’Orléans", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Jude's Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Saint Frere Andre Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Divine Infant", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Holy Redeemer - Kanata", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Queen of the Most Holy Rosary Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Notre Dame De Lourdes Parish - Vanier", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Our Lady of the Angels Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Our Lady of Grace/St. Pius X Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Our Lady of the Miraculous Metal", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Our Lady of the Visitation", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse St-Remi", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse St-Mathieu", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse Sacré-Coeur - Bourget", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse Saint-Jacques d'Embrun", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Parish of St-Pierre-Apôtre", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Anthony of Padua Parish - Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Basil's Parish - Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Clement's Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Columban's Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. James Church - Maxville", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. John the Apostle Church - Neapen", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Margaret of Scotland Church", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Monica Parish - Nepean", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Saint Patrick Basilica - Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Andrew's Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Anthony Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Francis de Sales Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse Saint-Francois d'Assise", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Joseph's Parish - Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Peter's Parish - Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Theresa Church", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St Gregory of Zannaince Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Assumption House of Formation", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Paroisse Sainte Marie des Peuples", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. John the Apostle", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Francis de Sales, Smith Falls", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Holy Spirit, Stittsville", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Leonard's Parish, Manotick", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Ignatius the Martyr Parish", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Notre-Dame Cathedral Basilica", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "Saint-Jean-Baptiste d'Ottawa", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "Canada", Parish: "St. Finnan Basilica", Diocese: "Archdiocese of Ottawa-Cornwall" },
  { Country: "United States", Parish: "St Patrick's Parish, Yorkville", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Out Lady od Mercy, Aurora, IL", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Isidore", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Joan of Arc", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Michael Church", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Patrick's Yorkville", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Raymond", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Walter Catholic Church", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Mary Immaculate", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Cathedral of St Raymond", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Christ the Servant", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Holy Spirit Catholic Community", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Maternity of the Blessed Virgin Mary", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Our Lady of Mt Carmel", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Resurrection Parish", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Saints Peter and Paul Parish", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Alexander Parish", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Andrew the Apostle", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Anne Religious Education", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Charles Borromeo", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Jude", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Margaret Mary", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Mary Mokena", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Thomas the Apostle, Naperville, IL", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Notre Dame, Clarendon Hills", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Holy Trinity, Westmount", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Our Lady of Mercy, Aurora", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Alexis, Bensenville", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Holy Family, Shorewood", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Notre Dame, Clarendon Hills", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "St. Mary Parish", Diocese: "Diocese of Joliet" },
  { Country: "United States", Parish: "Visitation Parish, Elmhurst, IL", Diocese: "Diocese of Joliet" },
  { Country: "United Kingdom", Parish: "St Elizabeth of Portugal", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Wimbledon", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of Gillingham", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Holy Cross, Catford", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Holy Ghost Balham", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Holy Innocents, Orpington", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady and St Peter, Wimbledon Common", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of the Rosary, Blackfen", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of the Sacred Heart, Herne Bay", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady Star of the Sea, Greenwich", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Barnabas, Goudhurst", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Sacred Heart of Jesus, Sittingbourne", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Andrew's Thornton Heath", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Andrew's, Tenterden", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Anselm, Tooting Bec, London", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, Edgeware", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Bede, Clapham Park", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Chad, South Norwood", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Columba's, Selsdon, Croydon", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Edmund of Canterbury, Beckenham, Kent", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Elphages, Wallington", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Francis, Maidstone", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Gregory's Earlsfield", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Westerham", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Dormansland", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Joseph and St Swithun, Bromley", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Joseph, New Malden, Kent", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Mary Magdalen, Brockley", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Mary's (Our Lady of Reparation), West Croydon", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Patrick, Chislehurst", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Patrick, Waterloo", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Saviours, Lewisham", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Teresa of Avila, Ashford", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Teresa of the Child Jesus, Morden", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Thomas Aquinas Church Richmond-Ham", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Virgin Mother of Good Counsel, Hythe, Kent, UK", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Holy Family, Kent, UK", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Thomas of Canterbury, Canterbury UK", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Corpus Christi, Brixton Hills", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Holy Cross, Carshalton", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of Good Counsel", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of La Salette & St Joseph", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of the Crays", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Battersea", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St James the Great, Southwark", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Holy Trinity, Clapham (C of E)", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of the Assumption, Links Road", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Corpus Christi, Tonbridge", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Ss Ethelbert & Gertrude, Ramsgate", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Anslem's, Dartford", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of Sorrows, Peckham", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Parish of the Good Shepherd, Dover", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Peter and the Guardian Angels", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Holy Family, Sanderstead", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Mary of the Crays", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Alban the Martyr, South Norwood", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St. James the Great, Petts Wood", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Saint Matthias, Worcester Park, Surrey", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Aidan’s, Coulsdon", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St John Fisher, Rochester", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Cecilia's, North Cheam", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady & St. Joseph, Folkestone", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St George's Cathedral, Southwark", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "The Virgin Mother of Good Counsel", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of Pity and Saint Simon Stock", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady Help of Christians", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady Immaculate", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Peter's", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Lawrence of Canterbury", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Augustine's Church", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Dunstan's Church", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Joseph's Church", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Boniface RC Church", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Thomas More, Dulwich", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Thomas More", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Lawrence", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Mary's Monastery, Clapham", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Catherine of Siena, Chessington and Hook", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Joseph, Roehampton", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of Loreto & St Winefride", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Gertrude’s, South Croydon", Diocese: "Archdiocese of Southwark" },
  { Country: "United Kingdom", Parish: "Our Lady of Walsingham (POLOW), Royal Docks", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Assumption of Our Lady, Maldon Essex", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Brightlingsea with Wivenhoe Parish", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "The Most Holy Redeemer, Essex, UK", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Ongar and Doddinghurst Parish (St Margaret of Scotland)", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady of Compassion Upton Park", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes, Wansted", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady of the Rosary and St Patrick, Walthamstow", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady Queen of Peace, Braintree", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Southend-on-Sea and St John Fisher, Prittlewell", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, Forest Gate", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Cuthbert, Burnham-on-Crouch", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Francis, Stratford", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St George and the English Martyrs. Shoeburyness", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Helens and St Mary Cathedral", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St John Payne, Greenstead, Colchester", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Joseph, Upminster", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Margaret, Canning Town", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Teresa of Lisieux, Luxden & St John the Baptist, Shrub End", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Vincent, Becontree", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady & All Saints, Basildon, UK", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady of Light and St Osyth", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Joseph's, Leyton", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Thomas of Canterbury, Brentwood", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady of Good Counsel", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes and St Joseph", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "The Holy Family & All Saints, Witham", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St James The Less and St Helen", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St. Edward the Confessor, Romford", Diocese: "Diocese of Brentwood" },
  { Country: "United Kingdom", Parish: "St Anne Line, South Woodford", Diocese: "Diocese of Brentwood" },
  { Country: "United States", Parish: "St John the Evangelist - Indiana", Diocese: "Diocese of Gary" },
  { Country: "United States", Parish: "Queen of All Saints Parish", Diocese: "Diocese of Gary" },
  { Country: "United Kingdom", Parish: "Mission Hub", Diocese: "Anglican Diocese of Sheffield" },
  { Country: "United Kingdom", Parish: "St Matthew’s Carver Street", Diocese: "Anglican Diocese of Sheffield" },
  { Country: "United States", Parish: "Notre Dame of Mount Carmel", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Corpus Christi, Chatham, US", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Corpus Christi Parish", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Notre Dame of Mt Carmel", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Saint Mary's Help of Christian Church", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St. Patrick Parish", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Saint Peter the Apostle", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St. Mary, Hudson", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Our Lady of Good Counsel", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Resurrection Parish", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "Saint Kateri Parish", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St. Paul's Inside the Walls", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St. Rose of Lima", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St Vincent Martyr, Madison NJ", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St. Brendan and St. George, Clifton NJ", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St. Francis of Assisi, Haskell", Diocese: "Diocese of Paterson" },
  { Country: "United States", Parish: "St Kateri Tekawitha", Diocese: "Diocese of Paterson" },
  { Country: "Canada", Parish: "St. Agnes Parish", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Dominic's Church", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Patrick's Cathedral", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "Corpus Christi Parish", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "Holy Family Catholic Church", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "Kitchitwa Kateri Anamewgamik Church", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "Our Lady of Loretto", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Andrew R.C. Church", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Elizabeth Catholic Church", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St Anthony’s Parish", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Peter's Catholic Church", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. John the Apostle", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Patrick’s Roman Catholic Church", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Dominic Church, Thunder Bay", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St. Mary's, Fort Frances", Diocese: "Diocese of Thunder Bay" },
  { Country: "Canada", Parish: "St Joseph's Parish, Grimsby", Diocese: "Diocese of St. Catharines" },
  { Country: "Canada", Parish: "St. Michael - Fort Erie", Diocese: "Diocese of St. Catharines" },
  { Country: "Canada", Parish: "Our Lady of the Scapular, Niagara Falls, Canada", Diocese: "Diocese of St. Catharines" },
  { Country: "Canada", Parish: "St. Joseph Parish - Grimsby", Diocese: "Diocese of St. Catharines" },
  { Country: "Canada", Parish: "St. Martin of Tours, Smithville", Diocese: "Diocese of St. Catharines" },
  { Country: "Canada", Parish: "St. Helen, Beamsville", Diocese: "Diocese of St. Catharines" },
  { Country: "Canada", Parish: "St. Julia, St. Catharines", Diocese: "Diocese of St. Catharines" },
  { Country: "United States", Parish: "St. Ann Catholic Parish", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St Gabriel the Archangel, McKinney, Texas", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Holy Spirit Catholic Church", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Prince of Peace", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Saint Ann - Coppell", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Saint Anthony", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Francis", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Mary Immaculate", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Our Lady of Angels", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Monica", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Paul the Apostle", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Pius X", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St Joseph, Richardson, Texas", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Monica", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Paul the Apostle", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Pius X", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Holy Family of Nazareth, Irving, TX", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St Martin of Tours, Forney TX", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Mary Catholic Parish, Sherman TX", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "Sacred Heart, Rowlett", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Michael the Archangel, McKinney", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Mark the Evangelist, Plano TX", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Joseph Catholic Church-Richardson", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Elizabeth", Diocese: "Diocese of Dallas" },
  { Country: "United States", Parish: "St. Patrick - Dallas", Diocese: "Diocese of Dallas" },
  { Country: "Ireland", Parish: "St Joseph and St Benildus, and St Mary's", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "Ireland", Parish: "St. Mary's, Clonmel", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "Ireland", Parish: "Most Holy Trinity Within, Waterford", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "Ireland", Parish: "St. Nicholas & St. Patrick, Carrick on Suir", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "Ireland", Parish: "St. Saviour's, Waterford", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "Ireland", Parish: "Ardfinnan, Ballybacon, Grange, & Newcastle, Fourmilewater", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "Ireland", Parish: "Parish of Portlaw & Ballyduff", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "Ireland", Parish: "St. Oliver Plunkett's Parish", Diocese: "Diocese of Waterford and Lismore" },
  { Country: "United States", Parish: "Prince of Peace Catholic Community", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St Anthony of Padua - The Woodlands", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Sts Simon & Jude", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Christ the Redeemer - Houston", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Our Lady of Perpetual Help, Houston", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Catholic Charismatic Center, Houston, United States", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Our Lady of Lourdes Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Cyril of Alexandria", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Ignatius", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Mary Cathedral Basilica", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Mary of the Miraculous Medal", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Simon & Jude Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Theresa Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Vincent de Paul", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Holy Family Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Holy Family Parish", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Angela Merici Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Edward", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Faustina", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Francis de Sales", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. John Vianney", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Laurence Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Sts. Martha, Mary, & Lazarus, Friends of Jesus", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Michael", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Angela Merici Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Edward", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Francis de Sales", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. John Vianney", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Laurence Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Sts. Martha, Mary, & Lazarus, Friends of Jesus", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St. Michael", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Co-Cathedral of the Sacred Heart", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St Thomas More, Houston", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "St Francis Cabrini Catholic Church", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Queen of Peace, Houston, Texas", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "United States", Parish: "Good Shepherd Catholic Community", Diocese: "Archdiocese of Galveston-Houston" },
  { Country: "Canada", Parish: "Holy Family Parish - London", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Catholic Family of Parishes in Norfolk", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Our Lady of Sorrows, Aylmer, ON", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "St. Justin's Parish, London, Ontario", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Immaculate Heart of Mary", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Ste Anne, Good Shepherd, St. Jerome, Windsor ON, Canada", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Lake Huron Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Amherstburg-Harrow Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Our Lady of the Assumption", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Central Essex Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Chatham Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Christ the King University Parish", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Elgin Roman Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Erie Shores South Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Holy Cross Parish - London", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Kent Lambton Roman Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Mary Immaculate Parish - London", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "North Central Windsor Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Perth-Huron Crossroads Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Sacred Heart Parish - Ingersoll", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "St. Micheal & Sacred Heart Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Sarnia Bluewater Roman Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Corpus Christi/South Windsor Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "St. George Parish", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "St. John the Divine Parish", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "St. Peter's Cathedral Basilica", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "South Kent Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "The LaSalle River Canard Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "West Middlesex Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "Central Essex County Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "St Patrick, Lucan ON", Diocese: "Diocese of London, Ontario" },
  { Country: "Canada", Parish: "East Windsor Catholic Family of Parishes", Diocese: "Diocese of London, Ontario" },
  { Country: "United States", Parish: "All Saints", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "Christ the King Burlington", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "Corpus Christi Parish St. Johnsbury", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "Nativity BVM- St Louis Parish", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "St. Mark", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "St. Monica - Barre", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "Holy Family Parish", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "Our Lady of Seven Dolors", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "Sacred Heart St. Francis de Sales", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "St. Anne/St. Raphael", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "St. John the Evangelist", Diocese: "Diocese of Burlington" },
  { Country: "United States", Parish: "Essex Catholic Community", Diocese: "Diocese of Burlington" },
  { Country: "Australia", Parish: "St Declans", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Ryde Gladesville Catholic Parish", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Ryde Gladesville Catholic Parish", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Felix, Felixstowe", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St James, St Bedes & St Benedicts, Sydney Australia", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Regina Coeli, Sydney, Australia", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "All Hallows Parish, Five Dock", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Catholic Community of City South", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of Fatima Parish, Kingsgrove", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Luke the Evangelist Parish, Revesby", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady Star Of the Sea, Miranda", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Kevin's Parish, Eastwood", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of Perpetual Succour, Erskineville", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St John Bosco, Engadine", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Sacred Heart, Darlinghurst", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "All Saints, Liverpool", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Holy Spirit, North Ryde", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Mater Dei Blakehurst & St Raphael Hurstville South", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of Fatima, Caringbah", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of Fatima, Peakhurst", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of the Rosary, Fairfield", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of the Rosary, Kensington", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Joseph, Belmore", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Mark, Drummoyne", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Mary & St Joseph, Maroubra Bay-Beach", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Canice, Elizabeth Bay", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Fiacre, Leichhardt", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Joachim, Lidcombe", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Joan of Arc, Haberfield", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St John the Baptist, Bonnyrigg", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Joseph, Neutral Bay", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Martha's, Strathfield", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Mary's, Concord", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Mary Queen of Heaven, Georges Hall", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Michael's, Meadowbank", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Therese, Denistone", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St\xa0Joseph’s Parish, Como-Oyster Bay", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Lifesource Christian Church", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Australian Catholic Chinese Community", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Immaculate Heart of Mary Sefton", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Brigid's Parish, Marrickville", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Sydney City South, NSW", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Patrick's, Sutherland, NSW", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "All Saints Liverpool and St Therese Sadleir Miller", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Brendan's and St Michael's Parishes", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Felix De Valois Bankstown", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Catholic Parishes of Bondi", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Columba & the Holy Souls, Leichhardt North", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Ambrose", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Holy Family Catholic Parish Menai", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Catherine Labouré", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Saint Joseph’s Parish, Newtown", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Patrick Parish, Kogarah", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Brigid's, Coogee", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Mary's Cathedral, Sydney", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St. Jerome, Punchbowl", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Anthony's Austral", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of Fatima - Kingsgrove", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Dominic's Parish, Flemington", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Mary help of Christians Sawtell", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Canice", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Mary & St Joseph - Maroubra Bay/Beach", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Joseph’s in Oatley", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Agnes Sydney", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Our Lady of the Southern Cross", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Parish of St Felix de Valois, Bankstown", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "St Joseph Parish, Riverwood", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Sacred Heart Parish, Cabramatta", Diocese: "Archdiocese of Sydney" },
  { Country: "Australia", Parish: "Holy Family Catholic Menai", Diocese: "Archdiocese of Sydney" },
  { Country: "Canada", Parish: "Holy Trinity Pastoral Unit", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Christ the King - Dartmouth", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Saint Benedict Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Saint John the Apostle", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Corpus Christi Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Stella Maris, Halifax, Canada", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Immaculate Conception - Truro", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Saint Ignatius of Loyola Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Divine Mercy Parish - Dartmouth", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Good Shepherd Parish - Halifax", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Our Lady of Guadalupe Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "St. John of the Cross Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Saint Francis and Saint Claire of Assisi Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Holy Apostles Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "St. Mary's Cathedral Basilica", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "All Saints - Amherst", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "Paroisse Notre Dame d'Acadie", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "St. Andre Bessette Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "Canada", Parish: "St. Ambrose Parish", Diocese: "Archdiocese of Halifax-Yarmouth" },
  { Country: "United States", Parish: "St. Francis de Sales", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Joseph - Dover", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Seton Parish", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Our Lady of Perpetual Help", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Catharine", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Church of the Holy Trinity", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Our Lady of Peace", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. John Neumann, Sunbury, OH", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Brigid of Kildare", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Patrick", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Francis de Sales, Newark OH", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. John XXIII", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Perry County Consortium of Catholic Parishes", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Sacred Heart, Coshocton", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Blessed Sacrament Parish", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Cecilia Parish, Columbus", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Matthew the Apostle, Gahanna, OH", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Matthew the Apostle, Gahanna", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "St. Francis de Sales, Newark OH", Diocese: "Diocese of Columbus" },
  { Country: "United States", Parish: "Jackson-Vinton Catholic", Diocese: "Diocese of Columbus" },
  { Country: "United Kingdom", Parish: "St. Bonaventure", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Holy Rood, Swindon", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Immaculate Conception, Devizes", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes, Newent, Gloucestershire", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Salisbury Catholic Churches", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Aldhelm's, Malmesbury", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Bernadette and  St Dunstan, Bristol and Keynsham", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Michael, Shepton Mallet and St Mary, Glastonbury", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Osmunds Church,  Salisbury", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Catholic Churches in the Forest of Dean", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Our Lady of the Immaculate Conception, Devizies & St Joseph,Lavington", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Francis, Nailsea and St Joseph, Portishead", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Mary's, Bath", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Patricks, Corsham", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Benedict's, Radstock", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Sacred Hearts of Jesus and Mary, Cheltenham", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Immaculate conception, Clevedon", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Lawrence and St Paul, Chipping Sodbury and Yate", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Gregory the Great, Cheltenham", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Holy Ghost, Yeovil", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Corpus Christi, Weston-super-Mare", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St George's, Warminster", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Our Lady of Perpetual Succour, Churchdown\xa0", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Nicholas of Tolentino, Bristol", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Augustine’s, Downend", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Taunton Vale Catholics", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Thomas of Canterbury with St Mary's Cricklade", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "St Joseph", Diocese: "Diocese of Clifton" },
  { Country: "United Kingdom", Parish: "Camberley and Bagshot", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Holy Name, Esher", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Paul, Haywards Heath", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Joseph and St John the Baptist, East Brighton", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Sunningdale", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes Thames Ditton", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Edmund, Godalming", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady and the Saints of Sussex", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Christ the Prince of Peace, Weybridge, Surrey", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Effingham and Fetcham Parish (Our Lady of Sorrows)", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady Help of Christians, West Byfleet", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes and St Patrick, Rottingdean", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady of Ransom, St Gregory and St Agnes, Eastbourne", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady of Sorrows, Bognor Regis", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady of the Rosary (St Mary Magdalene), Bexhill-on-Sea", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Oxted and Warlingham, Warlingham", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Hove", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Newhaven", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Anne's, Chertsey", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Clements, Ewell, Surrey", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Cuthberts, Egham", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Dunstans, Woking", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Horsham", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Joseph, Dorking, Surrey", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Mary's, Crowborough", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Michael's Ashtead", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Richard's, Slindon part of Bognor Regis with Slindon parish", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Worth Abbey", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Corpus Christi, Henfield", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Christ the King, Hand-in-Cross", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Parish of the Nativity of the Lord", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Oscar Romero Catholic Highschool", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Catholic Parish of Guildford", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, Rye", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Parish of the Good Shepherd", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Peter's, Hove", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "The Catholic parish of Haslemere, Hindhead & Chiddingfold", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Pancras, Lewes", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Arundel Cathedral, Our Lady and St Philip Howard", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Parishes of Petworth & Midhurst", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Holy Angels, Ash", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Barnabas, Molesey and All Saints, Hersham", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Our Lady of England, Storrington", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Thomas of Canterbury, Mayfield", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Erconwald's, Walton-on-Thames", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Wadhurst", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Michael's, Worthing", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Mary's, Preston Park", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Crawley", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Frimley", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Mary Star of the Sea, Hastings", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Josepph's Epsom", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Cranleigh and Bramley", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Mary Star of the Sea, Hastings", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "St Wilfrid and St Edward The Confessor", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Seaford, Peacehaven and Newhaven", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "United Kingdom", Parish: "Parish of The Good Shepherd, St Leonards-on-Sea", Diocese: "Diocese of Arundel & Brighton" },
  { Country: "Portugal", Parish: "Palhais/Santo António", Diocese: "Diocese of Setúbal" },
  { Country: "Portugal", Parish: "Quinta do Anjo, Setibal, Portugal", Diocese: "Diocese of Setúbal" },
  { Country: "Portugal", Parish: "Santa Margarida do Lavradio, Setúbal, Portugal", Diocese: "Diocese of Setúbal" },
  { Country: "Portugal", Parish: "São Julião, Nossa Senhora da Anunciada and Santa Maria da Graça", Diocese: "Diocese of Setúbal" },
  { Country: "Portugal", Parish: "Paróquia da Moita", Diocese: "Diocese of Setúbal" },
  { Country: "Portugal", Parish: "Our Lady of Grace", Diocese: "Diocese of Setúbal" },
  { Country: "Portugal", Parish: "Catholic Parish of Castello", Diocese: "Diocese of Setúbal" },
  { Country: "United States", Parish: "St Mary of the Assumption", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. Alexis", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "Mary Queen of Peace", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "Saint John & Paul Parish", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. Teresa of Kolkata", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. Joan of Arc &St. Louise de Marrilac", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. Ursula & St. Mary's of the Assumption", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. John XXlll", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. James, Washington PA", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St. Paul Cathedral, Pittsburgh", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St Francis of Assisi, Cabot", Diocese: "Diocese of Pittsburgh" },
  { Country: "United States", Parish: "St Louis Catholic Church", Diocese: "Diocese of Memphis" },
  { Country: "United States", Parish: "St. Philip the Apostle", Diocese: "Diocese of Memphis" },
  { Country: "United States", Parish: "St. Mary's", Diocese: "Diocese of Memphis" },
  { Country: "United States", Parish: "St. Ann Church", Diocese: "Diocese of Memphis" },
  { Country: "United States", Parish: "St. Ann Church", Diocese: "Diocese of Memphis" },
  { Country: "United States", Parish: "Our Lady of Perpetual Help, Collierville", Diocese: "Diocese of Memphis" },
  { Country: "Canada", Parish: "St. Mark's", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our lady of Peace", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St John the Baptist, Woodstock NB Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Mother of Mercy, Florenceville, New Brunswick", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St Croix Parish, St Stephen, New Brunswick", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St Michael the Archangel, Miramichi, NB, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Holy Redeemer, Saint John, NB, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our Lady of Lourdes, Miramichi, NB, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our Lady of Fatima, Fredericton, NB, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Good Shepherd, Miramichi, NB, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. François de Sales, Saint John, NB, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Sainte-Anne-des-Pays-Bas, Fredericton, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St Mary Magdalene, Fredericton, NB, Canada", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Cathedral of the Immaculate Conception", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Church of the Good Shepherd", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. John the Baptish Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. John the Baptist Parish - Woodstock", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Stella Maris Church - Blacks Harbour", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. Pius X Catholic Church of Holy Redeemer Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Holy Trinity Church - Saint John", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Holy Spirit Parish - Saint John", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our Lady of Fatima Catholic Church - Fredericton", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our Lady of Good Counsel - Saint John", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our Lady of Perpetual Help - Rothesay", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Saint Marguerite Bourgeoys", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. Joachim and St. Ann's Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. Theresa of Calcutta Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. John Paul II Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Saint Kateri Tekakwitha Catholic Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. Dunstan's Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. Croix Parish - Charlotte County", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Blessed Trinity Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. Stephen", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our Lady of Hope Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "Our Lady of Rivers Parish", Diocese: "Diocese of Saint John" },
  { Country: "Canada", Parish: "St. Barbara's Parish", Diocese: "Diocese of Saint John" },
  { Country: "Gibraltar", Parish: "St Joseph's", Diocese: "Diocese of Gibraltar" },
  { Country: "Gibraltar", Parish: "Cathedral of Saint Mary the Crowned", Diocese: "Diocese of Gibraltar" },
  { Country: "United States", Parish: "St. Peter Catholic Church", Diocese: "Diocese of Palm Beach" },
  { Country: "United States", Parish: "St. Ignatius of Loyola", Diocese: "Diocese of Palm Beach" },
  { Country: "United States", Parish: "Good Shepherd Catholic Community", Diocese: "Diocese of Fort Worth" },
  { Country: "United States", Parish: "Good Shepherd Catholic Community", Diocese: "Diocese of Fort Worth" },
  { Country: "United States", Parish: "Saint Andrew Catholic", Diocese: "Diocese of Fort Worth" },
  { Country: "United States", Parish: "Saint Patrick Cathedral", Diocese: "Diocese of Fort Worth" },
  { Country: "United States", Parish: "St. Vincent de Paul", Diocese: "Diocese of Fort Worth" },
  { Country: "United States", Parish: "St. Philip the Apostle", Diocese: "Diocese of Fort Worth" },
  { Country: "United States", Parish: "St Elizabeth Ann Seton - Fort Worth", Diocese: "Diocese of Fort Worth" },
  { Country: "Australia", Parish: "The Lakes Catholic Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Catholic Parish of Lower North Shore, Sydney", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Chatswood", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Lakes Parish NSW Australia", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Corpus Christi, St Ives, NSW, Australia", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St. Benedict's, Arcadia Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Holy Name Parish, Wahroonga", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Our Lady Dolours, Chatswood", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St Agatha's Parish, Pennant Hills", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Holy Cross, Kincumber", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Parish of Lindfield - Killara", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Pymble Catholic Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Catholic Community of North Harbour", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Epping Carlingford Catholic Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Catholic Parish of Frenchs Forest", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St Patrick's, Gosford", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Hornsby Cathedral Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Our Lady of the Rosary, Wyoming", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Our Lady Star of the Sea, Terrigal", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Parish of Pittwater", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St John the Baptist, Woy Woy", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Warringah Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Our Lady of Perpetual Succour, Toukley-Lake Munmorah", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St Mary of the Cross MacKillop, Warnerville", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St Agatha's, Pennant Hills", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Manly Freshwater Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Hornsby Cathedral Parish BB", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Wyong Catholic Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Our Lady of Dolours", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Chatswood Parish", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "Catholic Parish of Epping Carlingford", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St Mary's Toukley", Diocese: "Diocese of Broken Bay" },
  { Country: "Australia", Parish: "St Patrick's", Diocese: "Diocese of Broken Bay" },
  { Country: "United States", Parish: "Divine Mercy Parish - Hamden", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Prince of Peace, Woodbury, Connecticut, USA", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. John Fisher", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Prince of Peace Parish", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St.Elizabeth of the Trinity Parish", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Thomas", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Cathedral of Saint Joseph", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Margaret", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Dominic", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Our Lady of the Assumption", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Paul VI Parish", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Mary, Simsbury", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Thomas & St. Timothy, West Hartford", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Most Holy Trinity, Wallingford, CT", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Precious Blood Parish, Milford, CT", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Saint Damien Molokai - Windsor", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Sacred Heart Church", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Saint Josephine Bakhita Parish - Rocky Hill", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Saint Gianna Beretta Molla - West Hartford", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Our Lady of Victory Parish - West Haven", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Christ the King - Wethersfield", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Our Lady Queen of the Apostles - Derby", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Mary Parish - New Haven", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "SS. Isidore and Maria Parish - Glastonbury", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Junipero Serra Parish", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Our Lady of Hope Parish - Harwington", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. John XXIII Parish, West Hartford, CT", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "St. Francis of Assisi - CT", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church - Suffield", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Holy Disciples Parish at St. John the Evangelist Church", Diocese: "Archdiocese of Hartford" },
  { Country: "United States", Parish: "Holy Family Parish - Latrobe", Diocese: "Diocese of Greensburg" },
  { Country: "United States", Parish: "Saint Vincent Basilica Parish", Diocese: "Diocese of Greensburg" },
  { Country: "United States", Parish: "St. Barbara", Diocese: "Diocese of Greensburg" },
  { Country: "United States", Parish: "St. Agnes", Diocese: "Diocese of Greensburg" },
  { Country: "United States", Parish: "St. Bernard of Clairvaux, Indiania, PA", Diocese: "Diocese of Greensburg" },
  { Country: "United Kingdom", Parish: "Our Lady & St Joseph Kingsland", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady Help of Christians, Rickmansworth, Chess Valley", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Mary immaculate and St Gregory church, Barnet", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes, Harpenden", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Christ the King, Cockfosters", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Corpus Christi, Tring", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Hemel East Catholic Parish, Hemel Hempstead", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Holy Cross, Parson's Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady and St Vincent, Potters Bar, Herfordshire", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady Immaculate and St Andrew, Hitchen", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of Grace and St Edward, Chiswick", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes and St Michael, Uxbridge", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of Willesden, Harlesden", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Hemel East Catholic Parish", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady, St John's Wood", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Sacred Heart of Jesus and St Joseph, Ware", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Augustine, Hoddesdon", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Bernadette, Hillingdon", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Ignatius, Stamford Hill, London", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St James, Twickenham", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St John Fisher, N Harrow", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Angel", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Luke, Middlesex", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Margaret of Scotland", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Mary Moorfields, City of London", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Mary of the Angels, Bayswater", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Patrick, Soho", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Patrick, Wapping", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Paul, Wood Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Theodore of Canterbury, Hampton", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Thomas of Catebury and English Martyrs, Hertfordshire", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Thomas, London of Cantebury", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Vincent de Paul, Osterley", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Capellania Catolica Espanola", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Church of Most Sacred Heart, Ruislip", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Church of the Holy Apostles, Pimlico", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of Fatima, White City", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Parish of Harrow South and Northolt", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Saint John Vianney, West Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Ss Albans & Stephens Catholic Church", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Anselm's, Southall", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Edward the Confessor, Golders Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Joseph, St Neots & St Hugh, Buckden", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Peter-in-Chains, Stroud Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Edmund of Canterbury, Whitton", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Parishes of Borehamwood", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of the Visitation, Greenford", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Bartholomew, St Alban's South", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Berkhamsted & Corpus Christi, Tring", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Catholic Churches of Welwyn Garden City", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Catholic Parish of Stonebridge", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of Hal Camden Town, London", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of Victories, Kensington", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "The Catholic Parish of Bishop's Stortford  including Much Hadham and Sawbridgeworth", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Farm Street Church of the Immaculate Conception", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Holy Trinity, Brook Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Holy Rood, Watford", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Sebastian and St Pancras, Kingsbury Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Church of St Mary Magdalen,\xa0Willesden Green", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Church of St Jude, Clapton Park", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St. Agnes, Crincklewood", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady, St Mary of Walsingham", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Thomas More, Wheathampstead", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Mary’s, East Finchley", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Most Holy Redeemer & St Thomas More", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Francis de Sales, Hampton Hill", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Our Lady of the Holy Souls, Kensal New Town", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Hemel West", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Church of Our Lady of the Rosary and St Dominic, Haverstock Hill", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Thomas More, Eastcote", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Holy Ghost & St. Stephen, Shepherd's Bush", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "RC Parish of Hendon", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "RC Parish of Stoke Newington", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "RC Parish of Acton", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "RC Parish of Stevenage", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Joseph's Wealdstone and Harrow Weald", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Pius X", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Westminster Cathedral", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Etheldreda's Church", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Romanian Chaplaincy", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Hackney", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Romanian RC Chaplaincy", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "Parish of Copenhagen Street", Diocese: "Diocese of Westminster" },
  { Country: "United Kingdom", Parish: "St Francis Xavier, Falkirk", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Mary Magdalene, Glenrothes, Fife,", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Our Lady and St Andrew's, Galashiels, Scotland", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes and St Bernadette, Larbert", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Peter, Aberdeen", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "South Edinburgh Cluster", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St John's and St Mary Magdalene's, Portabello Edinburgh East", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Joseph Peebles and Innerleithen", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Mary's, Stirling", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Ninian and Triduana, Edinburgh", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Patrick, Edinburgh", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Catherine of Alexandria", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Livingston & East Calder Catholic Parishes", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Our Lady of the Waves", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Our Lady Mother of the Church, Edinburgh", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Catherine's, Gracemount", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St. Matthew's Parish, Rosewell, Midlothian", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Gabriel, Prestonpans", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Mary's Parish, Stirling", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Our Lady of Loretto and St Michael", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "Jesuit Church of the Sacred Heart, Edinburgh", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "United Kingdom", Parish: "St Matthews Rosewell, Midlothian", Diocese: "Archdiocese of St Andrews & Edinburgh" },
  { Country: "India", Parish: "St Dominic Savio", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "St. Francis de Sales, Bombay", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "St. Ignatius Church, Mumbai", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "Our Lady of Health, Mumbai", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "Our Lady of Assumption, Mumbai", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "Christ the King Church, Maharashtra", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "Divya Kripa Church", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "Our Lady of Immaculate Conception Church", Diocese: "Archdiocese of Bombay" },
  { Country: "India", Parish: "St. Francis Xavier Church, Dabul", Diocese: "Archdiocese of Bombay" },
  { Country: "United Kingdom", Parish: "St James and All Souls, Salford", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Christ the Redeemer, Bolton", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Our Lady and St Joseph, Heywood, Manchester", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Our Lady of Perpetual Succour, Moss Side, Manchester", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Our Lady of the Valley, Clitheroe", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Bernard's, Burnage, Manchester", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Mary, Littlesborough and St Patrick, Rochdale", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Mary, Denton", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Cuthbert, Bolton, North West, UK", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St John Fisher, Bolton, NW, UK", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Schoenstatt Family England", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Cathedral", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Michael's and St Bernadette's, Whitefield", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Parish of the Holy Spirit, Manchester", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Vincent de Paul, Bolton", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St John Southworth", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St. Ambrose", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Parish of The Good Samaritan", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Corpus Christi, Bolton", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Parish of the Holy Family", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St John Henry Newman Parish", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Stafford Churches", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Holy Family", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "The Parish of St John Paul II", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Holy Trinity", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Christ the King", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Vincent and St John the Baptist", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "Holy Cross and St. Helen", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Anne's and St Joseph's, Accrington", Diocese: "Diocese of Salford" },
  { Country: "United Kingdom", Parish: "St Joseph's, Lancaster", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Christ the King, Milnthorpe", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Our Lady and St Hubert's Catholic Church, Blackburn, Lancaster", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes Arnside", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St Andrew and Blessed George Haydock, Cottam, Preston", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St Bernadette's Lancaster", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St Catherine and St Wulstan, Penrith", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St Mary, Duntocher and St Joseph, Faifley", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St Wulstan and St Edmunds, Fleetwood", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Sacred Heart of Jesus, Carlisle", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St Mary of Furness, Ulverston", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Blackpool", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, Preston", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Poulton-le-Fylde", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "The Catholic Parish of Our Lady of Perpetual Help, Carlisle", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Christ The Good Shepherd, Workington", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Holy Family Parish, Freckleton and Warton", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "St John the Evangelist with the English Martyrs", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Lancaster Cathedral", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Parish of St John Henry Newman", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Kent Estuary Catholic Churches", Diocese: "Diocese of Lancaster" },
  { Country: "United Kingdom", Parish: "Saint Maximilian Kolbe, Runcorn", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Blessed John Henry Newman, Latchford, Warrington", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Divine Saviour Parish, Runcorn", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Holy Family Kirby Wirral", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Our Lady and St Christopher, Romiley, Stockport", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Our Lady and The Apostles, Stockport", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Shrewsbury Cathedral", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Albans, Macclesfield", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Ann's, Cheadle Hulme", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Augustine, Divine Saviour, Runcorn", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Columba and St Teresa, Chester", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Hugh and St John, West Timperley", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St John Vianney, Northwich, Cheshire", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Joseph, Birkenhead, Wirral", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Joseph, Sale", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Joseph's, Stockport", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Joseph's, Winsford", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Luke, Frodsham, Cheshire", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Mary's, Middlewich", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Peter, Hazel Grove Stockport", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Vincent De Paul, Bramhall, Stockport", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Winefrides, (St Teresa and St Winefrieds) Bradford", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Winefrides, Lymm, Warrington, Cheshire", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Holy Cross, our Lady & St Paul, Birkenhead, UK", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Peter's, Shrewsbury", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Winefride's, Neston", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Wythenshawe Catholic Church", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Christ Church, Heald Green", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Holy Apostles' and Martyrs, Wallasey", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Whaley Bridge", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Saint Bonaventure’s Missionary Area – St Hilda & St Aidan", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Parish of St Maximilian Kolbe", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "Ss Catherine and Martina Church", Diocese: "Diocese of Shrewsbury" },
  { Country: "United Kingdom", Parish: "St Patrick's", Diocese: "Diocese of Shrewsbury" },
  { Country: "Italy", Parish: "S. Maria di Galatea - Mortora", Diocese: "Sorrento-Castellammare di Stabia" },
  { Country: "Italy", Parish: "Santa Maria di Casarlano, Sorrento", Diocese: "Sorrento-Castellammare di Stabia" },
  { Country: "Italy", Parish: "Parrocchia Santi Prisco e Agnello", Diocese: "Sorrento-Castellammare di Stabia" },
  { Country: "Italy", Parish: "Nostra Signora di Lourdes Sorrento", Diocese: "Sorrento-Castellammare di Stabia" },
  { Country: "Italy", Parish: "Sant'Antonino Abate", Diocese: "Sorrento-Castellammare di Stabia" },
  { Country: "Italy", Parish: "Santo Spirito", Diocese: "Sorrento-Castellammare di Stabia" },
  { Country: "United States", Parish: "The Franciscan Church of the Assumption", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "Divine Mercy - New York", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "Our Lady of Hope", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "Our Lady of Sorrows - New York", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "St. Joseph - St. Stephen", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "Cathedral of the Immaculate Conception", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "St. Joseph & St. Patrick Church", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "St. Mary", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "Holy Family", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "Diocese of Syracuse - Office of Evangelization", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "St. Bernard's Church", Diocese: "Diocese of Syracuse" },
  { Country: "United States", Parish: "Holy Trinity & St. Stephen", Diocese: "Diocese of Syracuse" },
  { Country: "United Kingdom", Parish: "Blessed Sacrament, Exeter", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Our Lady and St Benedict, Marnhull Dorset", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "All Saints, Our Lady and St Patrick's, Teignmouth, Devon", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Helston Church in Falmouth RC Parish", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "The Most Precious Blood, Sidmouth", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Our Lady Star of the Sea, Weymouth Dorset", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Therese, Paignton", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Exeter", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, West Moors", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Benedict's Gillingham", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Catherine's Wimborne", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Cuthbert Mayne, Launceston", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Mary and St Geroge, Totnes", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Mary's, Axminster", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Michael", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Michael and St Barnabas, Sparwood", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Peter's and St Thomas More", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Exeter University Catholic Chaplaincy", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Holy Family, Kinson", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Holy Trintiy, Dorchester", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Our Lady Help of Christians & St Denis", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Pai", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Mary's Immaculate, Falmouth", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Mary's and St Petroc, Bodmin", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Our Lady of the Portal and St Piran", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Boniface, Crediton", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St James and St Boniface, Mid Devon", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Joseph, Newton Abbot", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "St Mary's & St Anthony of Padua Parishes, Poole", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Our Lady of the Assumption and St. Mary Magdalene, Tavistock", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Holy Family, Exmouth & Budleigh Salterton.", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Church of the Assumption, Torquay", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Bridport Parish", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Most Holy Trinity Church", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Kingsbridge and Salcombe", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Parishes of St Joseph & St Walburga and Our Lady of Fatima, Poole", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Holy Family, Honiton", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Buckfast Abbey", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "Holy Cross, Yelverton", Diocese: "Diocese of Plymouth" },
  { Country: "United Kingdom", Parish: "The Most Holy Saviour, Lynton", Diocese: "Diocese of Plymouth" },
  { Country: "Canada", Parish: "St. James Anglican Church, Kentville, Nova Scotia", Diocese: "Anglican Diocese of Nova Scotia and PEI" },
  { Country: "Canada", Parish: "St. Francis by the Lakes, Lower Sackville", Diocese: "Anglican Diocese of Nova Scotia and PEI" },
  { Country: "Canada", Parish: "Anglican Parish of St. Timothy and St. Paul", Diocese: "Anglican Diocese of Nova Scotia and PEI" },
  { Country: "Canada", Parish: "St. Paul's Anglican Church", Diocese: "Anglican Diocese of Nova Scotia and PEI" },
  { Country: "Canada", Parish: "St. John's Anglican Church - Parish of Horton", Diocese: "Anglican Diocese of Nova Scotia and PEI" },
  { Country: "Australia", Parish: "Catholic Parish of Whyalla", Diocese: "Diocese of Port Pirie" },
  { Country: "Australia", Parish: "St Marks Cathedral Parish", Diocese: "Diocese of Port Pirie" },
  { Country: "Australia", Parish: "All Saints Catholic Parish PORT AUGUSTA", Diocese: "Diocese of Port Pirie" },
  { Country: "Australia", Parish: "Booleroo Centre, Pekina", Diocese: "Diocese of Port Pirie" },
  { Country: "Australia", Parish: "Port Pirie Parish", Diocese: "Diocese of Port Pirie" },
  { Country: "Australia", Parish: "Whyalla Parish", Diocese: "Diocese of Port Pirie" },
  { Country: "Australia", Parish: "Renmark Parish", Diocese: "Diocese of Port Pirie" },
  { Country: "Australia", Parish: "Cummins parish", Diocese: "Diocese of Port Pirie" },
  { Country: "United States", Parish: "St. Vincent de Paul Catholic Church", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "Blessed Sacrament", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "Church of the Holy Spirit", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "St. Patrick Catholic Church", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "St. Thomas Aquinas", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "Church of the Magdalen, Wichita", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "St John the Evangelist's, Clonmel", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "Church of the Resurrection, Bel Aire", Diocese: "Diocese of Wichita" },
  { Country: "United States", Parish: "St. Paul, Lyons", Diocese: "Diocese of Wichita" },
  { Country: "Canada", Parish: "St. Joseph's Catholic Church", Diocese: "Corpus Christi Parish" },
  { Country: "Canada", Parish: "St. Joseph's Church", Diocese: "Corpus Christi Parish" },
  { Country: "Canada", Parish: "St Joseph's Kentville, NS", Diocese: "Corpus Christi Parish" },
  { Country: "Canada", Parish: "Valley Catholic Parishes", Diocese: "Corpus Christi Parish" },
  { Country: "Germany", Parish: "Jagstzell/St. Vitus", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Heilig Kreuz Deggingen", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Donau-Riß", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Magstadt - Maichingen", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Pfarrkirche St. Ulrich und Magnus", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "St. Vitus, Hayingen", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Oberes Kochertal", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "SE Oberes Filstal", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Unteres Brenztal", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Abba", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Katholische Kirche Tübingen", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Unteres Filstal", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Home Base Stuttgart", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "SE Westliches Schussental", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Argental", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Künzelsau", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Schemmerhofen", Diocese: "Diocese of Rottenburg–Stuttgart" },
  { Country: "New Zealand", Parish: "St Mary's Bay", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Mark’s Catholic Parish, Auckland, NZ", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Patrick's, Pukekohe", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Joseph's, Kaitaia", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Mary of the Immaculate Conception, Avondale", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Hibiscus Coast Parish", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Holy Cross, Papatoetoe", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Our Lady of Fatima, Meadowbank", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Sacred Heart, Dargaville", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Dominic’s, Blockhouse Bay", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Joseph's, Takapuna", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Joseph's, Orakei", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Mary's Northcote", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Cathedral of St Patrick and St Joseph", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Our Lady Star of the Sea, Howick", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Our Lady of Fatima Parish Meadowbank", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Holy Cross Parish Papatoetoe", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Joseph & St Joachim, Otahuhu", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Holy Family Catholic Church, Kerikeri", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Mary's, Papakura", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Saint Joseph's Catholic Church, Takapuna", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Pontifical Missions Societies, NZ", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Panguru & Pawarenga", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Pius X Catholic Parish, Glen Innes", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Immaculate Conception, Ellerslie", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Maria Assumpta Catholic Church", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St. Joseph, Grey Lynn", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Peter's, Waiheke Island", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St. Luke's, Flat Bush", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Thomas More, Glenfield", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Our Lady of Lourdes, Glen Eden", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "East Coast Bays Church", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Hibiscus Coast Parish", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Anne's Parish, Manurewa", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Ponsonby Herne Bay Parish", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Our Lady of the Sacred Heart, Epsom", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Anthony's, Waiuku", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Joseph and St Patrick's, Helensville", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Christ the King, Auckland", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Francis of Assisi, Thames", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Holy Name, Warkworth", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "The Good Shepherd, Balmoral", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "St Mary's, Mount Albert, Auckland", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Mother of Perpetual Help, Glendowie", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Christ the King Owairaka", Diocese: "Diocese of Auckland" },
  { Country: "New Zealand", Parish: "Holy Family, Te Atatū Peninsula", Diocese: "Diocese of Auckland" },
  { Country: "United States", Parish: "San Francisco Solano", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Saint Joachim, Costa Mesa, California, USA", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Holy Cross Melkite, Placentia, California, USA", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "San Francisco Solano", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Christ Cathedral", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Holy Family Catholic Church", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Our Lady of Fatima Catholic Church", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Our Lady of the Pillar", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Saint Vincent de Paul", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Santiago de Compostela", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Anne Seal Beach", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Anthony Mary Claret", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Hedwig", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Pius V", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St Edward the Confessor", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Holy Family Orange", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Korean Martrys Catholic Center", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Saint Justin Martyr Church", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Vincent de Paul", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "San Antonio de Paul", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Kilian Catholic Church", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Martin de Porres", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Pius V", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "The Orange Catholic Foundation", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Our Lady Queen of Angels, Newport Beach", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. John Neumann, Irvine", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Saint Thomas More, Irvine", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "Holy Trinity - Ladera", Diocese: "Diocese of Orange" },
  { Country: "United States", Parish: "St. Lawrence Catholic Church", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Michael - Gainesville, GA", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Prince of Peace", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Basilica of the Sacred Heart of Jesus", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Catholic Church of St. Ann", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Catholic Shrine of the Immaculate Conception", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Holy Spirit Catholic Church", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Holy Trinity Catholic Church", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Mary Our Queen", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Anthony of Padua, Atlanta", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Clare of Assisi", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. John Neumann", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Lawrence", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Thomas Aquinas", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. John Vianney", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Peter Chanel", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Cathedral of Christ the King", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Catholic Church of Saint Gabriel", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Chancery", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Holy Family", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Our Lady of Vietnam", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Brigid", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Sts. Peter & Paul Catholic Church", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Catholic Church of the Transfiguration", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Our Lady of the Assumption, Brookhaven", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Christ our King and Savior", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St Mary's, Rome GA", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Pius X, Conyers", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Augustine of Hippo, Covington", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Saint Philip Benizi, Jonesboro", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St. Monica, Duluth", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "Saint Jude", Diocese: "Archdiocese of Atlanta" },
  { Country: "United States", Parish: "St John the Evangelist - Maryland", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Holy Family Parish - Middleton", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Agnes & St. William of York", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Church of the Nativity", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "HOLY FAMILY CATHOLIC COMMUNITY", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Joseph Parish, Cockeysville", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St Patrick's Church - Baltimore", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Catholic Community of St. Francis Xavier", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Joseph-on-Carrollton Manor", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Mark, Fallston", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Patrick", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Church of the Nativity", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. John the Evangelist, Hydes, MD", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Patrick, Havre de Grace", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Joseph Monastery", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Peter the Apostle, Libertytown, Md", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Cathedral of Mary Our Queen", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Christ the King", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Church of the Resurrection", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Paul Catholic Church", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Stephen", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St. Andrew by the Bay", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Sacred Heart of Mary", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St Margaret Parish", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "St Clare Catholic Parish - Maryland", Diocese: "Archdiocese of Baltimore" },
  { Country: "United States", Parish: "Catholic Community of Ascension and St. Augustine", Diocese: "Archdiocese of Baltimore" },
  { Country: "Canada", Parish: "Saint-Eugene Parish", Diocese: "Archdiocese of Saint Boniface" },
  { Country: "Canada", Parish: "St. Joseph the Worker Parish - Winnipeg", Diocese: "Archdiocese of Saint Boniface" },
  { Country: "Canada", Parish: "St. Timothy Church- Winnipeg", Diocese: "Archdiocese of Saint Boniface" },
  { Country: "Canada", Parish: "St. Alphonsus Parish", Diocese: "Archdiocese of Saint Boniface" },
  { Country: "Canada", Parish: "St. Emile Roman Catholic Church", Diocese: "Archdiocese of Saint Boniface" },
  { Country: "Germany", Parish: "Gebetstätte\t Marienfried", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Heilig\tGeist-Zwölf\t Aposte", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Italienische\t Mission", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Kath. Jugendstelle Weißenhorn", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Klinikseelsorge\t Memmingen", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinsch aft Grünten", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinsch aft Vöhringen", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "St. Alexander und Theodor, Ottobeuren", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "St. Anton Kempten", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarrei Christkönig", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarriengemeinschaft St Ulrich und Afra", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "St. Martin Bernried", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Grünten", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreingemeinschaft Vöhringen", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Schwabmünchen", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Randelsried: St. Peter and Paul", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Bad Wörishofen", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Pfronten-Nesselwang", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Nördlingen", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Kloster Wettenhausen", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Weißensberg", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "PG Türkheim, Bayern", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarrei St. Michael - Mitten in Mering", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Christkönig/St. Franziskus Augsburg", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Erzabtei St. Ottilien", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "St. Martin & St. Andreas Biburg", Diocese: "Diözese Augsburg" },
  { Country: "Germany", Parish: "Kath. Stadtpfarrei St. Maria, Hilfe der Christen", Diocese: "Diözese Augsburg" },
  { Country: "Ireland", Parish: "St. Mary's Cathedral, Sligo", Diocese: "Elphin Diocese Ireland" },
  { Country: "Ireland", Parish: "Sacred Heart Roscommon", Diocese: "Elphin Diocese Ireland" },
  { Country: "Germany", Parish: "Pfarrei St. Nikolaus und St. Josef Bensberg und Moitzfeld", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft\tWuppertaler\t Westen", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Pfarreiengemeinsch aft Meckenheim", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Wuppertaler Westen", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft\tMeckenheim", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Pfarrgemeinden Herz Jesu und St. Mauritius, Köln", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Lambertus Mettmann", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Sendungsraum Neuss", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Basilica of St. Quirinus", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Mauritius und Herz Jesu Köln", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Katholischer Seelsorgebereich Sankt Augustin", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Katholische Kiche in BiOs", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Seelsorgebereich Stadt Bedburg", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Seelsorgebereich Euskirchen Erftmühlenbach\xa0", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Suitbertus, Remscheid", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "KHG Köln", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Kirchengemeindeverband Ruppichteroth", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Kirche im Veedel", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Sankt Remigius Opladen", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Seelsorgebereich Leverkusen Südost", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Düsseldorfer Rheinbogen", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Josef und Martin - Langenfeld", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Johannes der Täufer - Solingen", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "PG Unter- und Oberbilk, Friedrichstadt und Eller-West", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "PV Neunkirchen-Seelscheid", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "PV Overath", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Sendungsraum Bonner Innenstadt", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Seelsorgebereich Lindlar", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Sendungsraum Engelskirchen und Oberberg Mitte", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Laurentius Gladbach", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Altenberger Dom", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St.Thomas Indische Syro-Malankarische Katholische Gemeinde", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Pfarrverband Solingen MiNor", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Bonaventura und Hl Kreuz - Remscheid", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Margareta Düsseldorf", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Pfarrverband Geistingen Hennef Rott", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Hl. Johannes XXIII Köln", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Katholische Kirche im Seelsorgebereich Barmen-Nordost in Wuppertal", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "Kirchengemeindeverband Barmen-Nordost in Wuppertal", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "St. Laurentius Wuppertal", Diocese: "Erzdiözese Köln" },
  { Country: "Germany", Parish: "SE Bad Münstereifel", Diocese: "Erzdiözese Köln" },
  { Country: "United States", Parish: "St. Robert Bellarmine, El Campo, Texas, USA", Diocese: "Diocese of Victoria" },
  { Country: "United States", Parish: "Our Lady of Sorrows, Victoria, Texas", Diocese: "Diocese of Victoria" },
  { Country: "United States", Parish: "Our Lady of Sorrows", Diocese: "Diocese of Victoria" },
  { Country: "United States", Parish: "St. Robert Bellarmine", Diocese: "Diocese of Victoria" },
  { Country: "United States", Parish: "St. Mary/ Chancery", Diocese: "Diocese of Victoria" },
  { Country: "Australia", Parish: "Southern Suburbs Pastoral District", Diocese: "Anglican Diocese of The Murray" },
  { Country: "Australia", Parish: "Western Fleurieu Pastoral District", Diocese: "Anglican Diocese of The Murray" },
  { Country: "Australia", Parish: "Mount Barker Parish", Diocese: "Anglican Diocese of The Murray" },
  { Country: "Australia", Parish: "Murraylands Pastoral District", Diocese: "Anglican Diocese of The Murray" },
  { Country: "Australia", Parish: "Onkaparinga Valley Parish", Diocese: "Anglican Diocese of The Murray" },
  { Country: "Australia", Parish: "St Hilary’s Anglican Church", Diocese: "Anglican Diocese of The Murray" },
  { Country: "Australia", Parish: "South Coast Pastoral District", Diocese: "Anglican Diocese of The Murray" },
  { Country: "Australia", Parish: "Kingston-Robe Pastoral District", Diocese: "Anglican Diocese of The Murray" },
  { Country: "United Kingdom", Parish: "Our Lady of the Angels and St David's, Cwmbran", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "All Saints, Newport", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Most Holy Trinity, Ledbury", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Our Lady and St Illtyd, Llantwit Major", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Our Lady and St Michael Church", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Rhondda Valley, Tonypandy & Ynyshir (St Gabriel and St Raphael)", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Alban, St Joseph, St Peter, Cardiff", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Brigid", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St David Lewis and St Francis Xavier Parish, Usk", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St David's Cathedral", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Dyfrig's Treforest", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Ethelbert's Leominster", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Francis Xavier, Hereford", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Helens Caerphilly", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Helen's, Barry", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Mary's, Bridgend", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Mary's, Merthyr Tydfil", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Peter's Roath Cardiff", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Philip Evans, Llanedeyrn, Cardiff", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Teilo's with Our Lady of Lourdes, Whitchurch, Cardiff", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Julian's, Newport", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Our Lady & St Patrick", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Parish of Merthyr Tydfil", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Catholic Parishes of Monmouth & Ross on Wye", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Illtyd, Merthyr Tydfil", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "West Vales Catholics", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "Parishes of Chepstow and Caldicot", Diocese: "Archdiocese of Cardiff" },
  { Country: "United Kingdom", Parish: "St Basil and St Gwladys", Diocese: "Archdiocese of Cardiff" },
  { Country: "Australia", Parish: "Our Lady of the Sacred Heart Parish", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "Para Hills Modbury Catholic Parish", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "Hectorville Catholic Parish", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "Emmaus Catholic Parish", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "Greenacres and Walkerville Catholic Parish", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "St Pius X Church, Adelaide", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "St David", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "St David's Church - Tea Tree Gully", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "Cathedral parish Adelaide", Diocese: "Archdiocese of Adelaide" },
  { Country: "Australia", Parish: "St Patrick's Basilica Fremantle", Diocese: "Archdiocese of Adelaide" },
  { Country: "South Africa", Parish: "Parish of St Joseph", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Sacred Heart - Durban", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St. Vincent's, Pelham", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Chatsworth Catholic Parish, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Holy Trinity, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "All Saints Catholic Church Ballito, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Our Lady of Fatima, Durban North", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Immaculate Conception, Pinetown", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Our Lady of Lourdes, Westville, South Africa", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Our lady of the Assumption.", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St. Francis Xavier, Bluff.", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St Leo, Emolweni", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Holy Family Parish, Durban", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St Helen, Edwaleni", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Good Shepherd Parish Phoenix", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Our Lady of Mercy, Kloof", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Holy Rosary (Stanger), Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Immaculate Conception, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Blessed Sacrament, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Growing The Church, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St. Helen (Highway), Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Emmanuel Cathedral, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Ikhwezi Lokusa, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Holy Family Newlands East, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Nazareth House, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St Dominic's Hillcrest", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St Peter's Parish Point, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Christ the King, Wentworth, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St. Anne's Sydenham, Durban, SA", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "Saint Anne's, Shakaskraal", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St Joseph Mt Edngecombe", Diocese: "Archdiocese of Durban" },
  { Country: "South Africa", Parish: "St. Peter's Point Parish", Diocese: "Archdiocese of Durban" },
  { Country: "Australia", Parish: "Mersey Leven Catholic Parish", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "St Mary's Cathedral Parish Hobart Tasmania", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "Holy Spirit Parish, Sandy Bay, Australia", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "St Therese Hobart", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "Kingston-Channel Parish", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "St. Mary's Cathedral-Hobart", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "Launceston Parish", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "St Marys Parish, TAS", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "West Tamar Parish", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "St Threrese of Lisieux, Moonah Lutana", Diocese: "Archdiocese of Hobart" },
  { Country: "Australia", Parish: "Glenorchy Parish", Diocese: "Archdiocese of Hobart" },
  { Country: "Canada", Parish: "Our Lady of Perpetual Help Sherwood Park", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Good Shepherd Parish", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Andrew", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Anthony's Lloydminster", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Francis Xavier Parish", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Blessed Sacrament Parish - Wainwright", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Holy Name Parish - Vermilion", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Holy Trinity Catholic Church - Spruce Grove", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Our Lady of Peace Parish - Innisfail", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Sacred Heart Parish - Red Deer", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Sacred Heart Parish - Wetaskiwin", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Anthony Parish - Drayton Valley", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Charles Parish - Edmonton", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Alphonsus & St. Clare Catholic Parishes", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Joseph Basilica", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Stephen Parish - Lacombe", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Our Lady of the Foothills", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Albert's Parish", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Stephen's, Olds", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Holy Family, St. Albert", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "Our Lady of the Assumption, Sylvan Lake", Diocese: "Archdiocese of Edmonton" },
  { Country: "Canada", Parish: "St. Matthew Catholic Church - Edmonton", Diocese: "Archdiocese of Edmonton" },
  { Country: "United States", Parish: "Church of the Epiphany", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Epiphany Church", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Holy Cross Church - New York", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Church of the Epiphany", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Church of Regina Coeli", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Vincent Ferrer & St. Catherine of Siena", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Andrew", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Patrick's Old Cathedral", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Sts Peter & Paul and St Ursula", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Teresa", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Patricks", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Archdiocese of New York Adult faith formation office", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Columba", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Lawrence O'Toole", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St. Paul the Apostle", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Church of Saint Agnes, New York", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "Good Shepherd, NYC", Diocese: "Archdiocese of New York" },
  { Country: "United States", Parish: "St Anthony - Yonkers", Diocese: "Archdiocese of New York" },
  { Country: "Canada", Parish: "St. John The Baptist Parish", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "Holy Family Parish", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "Resurrection Parish", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "Christ the King Parish - Regina", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "Christ the King Parish - Shaunavon", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "St. Augustine Parish - Wilcox", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "St. Cecilia Parish", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "St. Martin de Porres Parish", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "First Nation and Tri-Parish", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "St. John the Baptist, Estevan", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "Holy Child, Regina", Diocese: "Archdiocese of Regina" },
  { Country: "Canada", Parish: "St. Gerard", Diocese: "Archdiocese of Regina" },
  { Country: "Australia", Parish: "St Mary and St Joseph", Diocese: "Diocese of Bathurst" },
  { Country: "Australia", Parish: "St Mary & Joseph, Orange", Diocese: "Diocese of Bathurst" },
  { Country: "Australia", Parish: "St James’ Parish, Blayney", Diocese: "Diocese of Bathurst" },
  { Country: "Australia", Parish: "St Brigid Dubbo", Diocese: "Diocese of Bathurst" },
  { Country: "Australia", Parish: "St Raphael’s Parish Cowra", Diocese: "Diocese of Bathurst" },
  { Country: "Australia", Parish: "Cathedral Parish of St Michael and St John, Bathurst", Diocese: "Diocese of Bathurst" },
  { Country: "Canada", Parish: "St. Mary's Cathedral - Ontario", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St. Paul the Apostle Parish", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St. Mary's Cathedral - Kingston", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "Paroisse St. Francois d'Assise", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St. Mark & Sacred Heart Parishes", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "Parishes and Missions of Sacred Heart and St. Bridget", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St. John the Apostle Church - Kingston", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St. Joseph's Church - Douro", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St. Peter's Parish - Trenton", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St. John the Baptist Parish", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "St Mary’s Carleton Place", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "Holy Family, Kingston", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "Sacred Heart of Jesus Parish - Marmora", Diocese: "Archdiocese of Kingston" },
  { Country: "Canada", Parish: "Holy Martyrs of Japan (Bradford, ON)", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Margaret Mary Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Mark's Parish - Ontario", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Patrick's", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Holy Family Parish - Whitby", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Merciful Redeemer Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Our Lady of Lourdes Parish, Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Monica's Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St Timothy's Parish, North York", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Our Lady of Lourdes Parish - Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St Timothy's Parish - North York", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Guardian Angels Church - Orillia", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Marguerite d'Youville Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Parroisse Assomption de Notre Dame", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Guardian Angels Parish - Brampton", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Holy Rosary Church", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Holy Spirit Parish - Barrie", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Nativity of our Lord Church", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Our Lady Queen of the World Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Precious Blood Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Paroisse St. Louis De France", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Andre Bessette Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Basil's Catholic Parish at St. Michael's College", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St Charles Borromeo Church", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Eugene de Mazenod Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. John the Baptist Church - Burlington", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Leonard's Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Mark's Church - Stouffville", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Mary Star of the Sea Church", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Mary's Parish - Barrie", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Patrick's Church - Brampton", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Saint Patrick's Parish - Mississauga", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Philip Neri Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Rose of Lima", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Anselm's Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Gregory's Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Mary's Church - Brampton", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Maximilian Kolbe Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. John Chrysostom Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St Barnabas, Scarborough", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Our Lady of Sorrows, Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Gregory the Great Parish, Oshawa", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Elizabeth of Hungary Parish, Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. James", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Blessed Frédéric Ozanam Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Gertrude", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Joseph the Worker Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Bernadette", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Luke's, Thornhill", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Maria Goretti, Scarborough", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Isaac Jogues, Pickering", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Holy Family, Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Jerome, Brampton", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Isaac Jogues Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Ann's Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Nativity of Our Lord, Etobicoke", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Mary Star of the Sea, Etobicoke", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Mary Star of the Sea, Mississauga", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "Our Lady of the Assumption Parish, Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Leo the Great, Arch of Toronto", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "York University Catholic Chaplaincy", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Joseph the Worker", Diocese: "Archdiocese of Toronto" },
  { Country: "Canada", Parish: "St. Catherine of Siena Parish", Diocese: "Archdiocese of Toronto" },
  { Country: "United Kingdom", Parish: "St Peter's Cathedral Parish - Belfast", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Antrim Parish (St Comgalls), Northern ireland", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Drumbo and Carryduff, Belfast", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Dunsford and Ardglass Parish  (St Nicholas)", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Belfast", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "St Patrick's, Downpatrick, UK", Diocese: "Diocese of Down and Connor" },
  { Country: "Ireland", Parish: "St Patrick's, Belfast, Ireland", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Holy Rosary, Belfast", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Newry Catheral Parish", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Loughshore Parishes", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "St MacNissi's, Randelstown", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "St Colmcilles, Belfast", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Holy Rosary, Belfast", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Holy Family, Belfast", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Portstewart Parish", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "St Macartan's Church", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Parish of Antrim", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Parish of Portstewart", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Parish of Carrickfergus", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Glenavy and Killead Parish", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Portaferry Parish", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Dromintee & Jnesborough Parish, in the Archdiocese of Armagh", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Duneane (Moneyglass and Toome)", Diocese: "Diocese of Down and Connor" },
  { Country: "United Kingdom", Parish: "Saint Charles Borromeo Parish", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Our Lady and St Peter, Bridlington", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Our Lady of Perpetual Help, Market Weighton", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St Bernadette's, Nunthorpe", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St Peter, Bridlington", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St Paulinus, Guisborough, UK", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Scarborough Catholic Parishes", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "All Saints, Thirsk", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Our Lady & St Benedict, Ampleforth", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St George's, York", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St Joseph, Stokesley", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Our Lady of Perpetual Help, Middlesbrough", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Saint Joseph and Saint Francis Xavier, Richmond", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Parish of Blessed Nicholas Postgate", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St Margaret Clitherow and St Paulinus", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St Mary's Cathedral", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "The Oratory", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Alphonsus", Diocese: "Diocese of Middlesbrough" },
  { Country: "United Kingdom", Parish: "St Edmund & St Frideswide, Oxford", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph, Stoke-on-Trent", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Ark of the Covenant, Coventry", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Catholic Leamington, Leamington Spa", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Christ the King, Coventry", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Immaculate Conception, Bicester", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Newman House, Edgbaston", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Oldbury, St Francis Xavier and Blackheath, English Martyrs", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady and St Brigid, Northfield, Birmingham", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady and St Rose of Lima, Weolely Castle", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Grace Parish Partnership - Packmoor", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes, Hednesford, Cannock", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of the Angels, Nuneaton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of the Sacred Heart, Bedworth", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady, Lilngton, Leamington, Warwickshire", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart and Holy Souls (Acocks Green Parish)", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Coventry West Mids", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Rugby", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Tunstall", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Ambrose Barlow, Hall Green, Birmingham", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Annes and St Patricks", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Catherine of Siena, Birmingham", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Chad's (Birmingham Cathedral), Sedgley", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John the Baptist with Sacred Heart, Tamworth", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Alton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Longridge", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John Vianney, Mount Nod, Coventry", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph and St Etheldreda, Rugeley", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph and St Helen, Kings Norton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph's, Malvern", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph, Thame", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph's, Wolverhampton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary and St Modwen, Burton-upon-Trent", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Michael and Holy Angels, West Bromwich", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Nicholas, Boldmere, Sutton Coldfield", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Peter, Bromsgrove", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Teresa of the Child Jesus, Trent Vale", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Banbury Catholic Churches", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Christ the King, Kingstanding", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Greyfrairs, Oxford", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Holy Cross and St Francis", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Holy Trinity and Sacred Heart, Sutton Coldfield", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Immaculate Conception and St Dominic, Stone", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Kenelm Youth Trust", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady & St. Wulstan, Southam", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of the Assumption, Coventry", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Precious Blood and All Souls, Coventry", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart & St Teresa, Coleshill", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Catherine of Alexandria, Droitwich", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Ashton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Hanley, Stoke on Trent", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, Wolverhampton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Austin's, Stafford", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Benedict's, Atherstone", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Dustan and St Jude's, Kings Heath", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Elizabeth's, Coventry", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Francis, Handsworth", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St George & St Teresa, Dorridge", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Giles Church, Cheadle", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John and St Martin", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph, Burntwood", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph, Darlaston", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Margaret Mary's, Perry Common", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Marie's, Rugby", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary & St Thomas More, Cannock", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary's, Harvington", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary's, Uttoxeter", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Michael & James, Haunton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Patricks, Strafford", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Thomas More, Coventry", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Thomas More, Sheldon", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "The Most Holy Sacrament & St Osburg", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John Henry Newman Cluster", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "The Oratory, Birmingham", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady and All Saints", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady & St Joseph, Alcester", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady & St Kenelm, Halesowen", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Good Counsel & St Gregory the Great, Bearwood", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes, Yardley Wood", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Augustine of England", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Anne's, Wappenbury", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Catholic Worcester", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph's, Whitnash", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Gregory the Great", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Saviour, Broadway", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Anne's, Stafford", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Immaculate Conception and St Egwin", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph's, Carterton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Charles Borromeo, Hampton-on-the-Hill", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Peter, Bartley Green", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Perpetual Succour, Rednal", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, Headington, Oxford", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Philip Neri Catholic Church, Smethwick", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary, Norton-le-Moors", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Holy Trinity, Newcastle-under-Lyme & Sacred Heart, Silverdale", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "PARISH OF BLESSED CARLO ACUTIS", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Henley-on-Thames", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary the Mount, Walsall", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St. Mary of the Angels, Aldridge", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Peter’s Church Eynsham", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady and St Hugh, Witney", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of the Angels & St Peter in Chains, Hartshill, Stoke-on-Trent", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Gregory’s, Longton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Anne’s, Streetly", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady of the Wayside, Shirley", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Saint Mary’s, Harborne", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Olton Friary", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Catholic Parishes of St Christopher & St Thomas of Canterbury", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart and Holy Souls, Tipton", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Catholic Brailes", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Great Haywood", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "All Saints-St Mary, Newport", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St George's, Worcester", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Dominic's Parish", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Holy Trinity Parish", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Parish of SS Mary & John", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Birinus", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John Henry Newman Parish", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph's", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Margaret Mary", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "The Catholic Church of Corpus Christi", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Corpus Christi RC", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "English Martyrs", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph's, Our Blessed Lady and St Alphonsus", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Michael's, Birmingham", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "RAF Coningsby", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Joseph's Malvern", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Oxford", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St Mary's, Studley", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "St John Fisher, West Heath", Diocese: "Archdiocese of Birmingham" },
  { Country: "United Kingdom", Parish: "Our Lady Star of the Sea, Lowestoft", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Oswald, Peterborough", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Etheldreda's, Ely", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St John the Baptist Cathedral Norwich", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Laurence, Cambridge", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Mary Magdalen Ipswich", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "Catholic Parish of King's Lynn", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Benet's, Beccles", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Luke, Peterborough", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua, Fakenham", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "Our Lady of Good Counsel and St Peter, March", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "Our Lady of The Assumption and The English Martyrs", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Pancras, Ipswich", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Mary's, Ipswich", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Mark's, Ipswich and Holy Family, Brantham", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "Our Lady and St Joseph", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "The Sacred Heart and Saint Margaret Mary", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Dominic's Parish", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "St Felix", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "the Mother of Good Counsel Church, (Clare Priory)", Diocese: "Diocese of East Anglia" },
  { Country: "United Kingdom", Parish: "Blessed English Martyrs, Askern, South Yorkshire", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "Holy Rood, Barnsley", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "Immaculate Conception Rotherham", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "Our Lady Queen of Peace, Chesterfield", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Hillsborough", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Joseph and St Teresa' RC woodlands Doncaster", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Mary Magdalene, Maltby", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Marys Immaculate Conception, Herringthorpe, Rotherham", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Vincent's, Mission Hub", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Wilfrid's and Mother of God, Sheffield", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St William, Sheffield", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Joseph & St Charles Borromeo", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Joseph, Dinnington & Blessed William Richardson, Kiveton Park", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Thomas of Canterbury, Kirk Sandall", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Marie Cathedral", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St William of York", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Josephs, Wath upon Dearne and St Josephs Rawmarsh", Diocese: "Diocese of Hallam" },
  { Country: "United Kingdom", Parish: "St Teresa of the Infant Jesus, Heaton", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Durham Martyrs Parish", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "English Martyrs and Saints Peter and Paul, Stockton-on-Tees", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Holy Family (and St John Vianney), Hartlepool", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Our Lady and St Cuthbert, Prudhoe, Northumberland", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Aidan, Ashington", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Bede's Bedlington", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Cuthbert, North Kenton", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Dominic's Priory, Newcastle", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Joseph, Gatehead", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Patrick, Durham", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Catholic Churches in Washington and Penshaw", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Our Lady & St Wilfred & St Cuthbert, Blyth", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Our Lady of Mercy, Sunderland", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Ovingham Partnership", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "South Durham Catholic Parishes", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Anne, Winlaton", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Bede & St Cuthbert", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Charles, Tudhoe", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Cuthbert's, Durham", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Mary's, Hexham", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Paul's Alnwick", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Robert of Newminster, Morpeth", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Cuthbert's, Newcastle upon Tyne", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St John XXlll", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Joseph, Birtley", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St John Vianney, Newcastle upon Tyne", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Jarrow Cluster of Catholic Churches", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Augustine, Darlington", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Thomas Aquinas, Darlington", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St John Fisher, Sedgefield", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Our Lady Immaculate and St Cuthbert, St Joseph Tow Law, St Thomas Wolsingham", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Holy Family Hartlepool", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Paul's, Cramlington", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Joseph's & St Anne's Catholic Community", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "The Holy Name", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Our Lady and St Joseph", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Elizabeth's Parish", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Cuthbert's", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Joseph's Stanley", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Ovingham Parish Partnership", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Mary Magdalen and St Michael", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Peters RC Parish", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Anthony of Padua", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St. Mary and St. Wilfred, Bishop Aukland", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "St Benedict Partnership of Parishes", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Our Lady and St Cuthbert, Berwick", Diocese: "Diocese of Hexham & Newcastle" },
  { Country: "United Kingdom", Parish: "Aire Valley Parish", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "John Henry Newman", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Immaculate Heart of Mary Parish, Leeds", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Mary Mother of God Parish (St Teresa and St Winefried), Bradford", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Mother of Unfailing Help, (Leeds Cathedral)  Leeds", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Bernard, Halifax", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Ignatius, Ossett", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St John, Wakefield", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Bingley", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Mary & St Michael, Settle", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Our Lady of Kirkstall", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Columba's & St Malachy's", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Parish of St. Peter & St. Paul, Wakefield", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Parish of St Blaise", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Peter & St Paul, Wakefield", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "SS Peter and Paul, Yeadon", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Joseph's, Brighouse and St Partick's, Elland", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Joseph's, Pudsey", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Austin and English Martyrs", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Wilfrid's", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "The Priory Benefice", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Mary's, Knaresborough", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Immaculate Heart of Mary, Huddersfield", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St. Joseph’s, Harrogate", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St. Aelred of Rievaulx, Harrogate", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Our Lady Immaculate & St. Robert, Harrogate", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "St Stephen, Skipton", Diocese: "Diocese of Leeds" },
  { Country: "United Kingdom", Parish: "Christ the King Parish, (St Paschal Baylon) Liverpool", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Holy Cross, St Helens", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Our Lady's Gateacre (Our Lady of Assumption)", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Ribble Valley Parish (St Peter, Stonyhurst and St Josephs, Hurst Green)", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Anne's and Bernard's , Liverpool, Merseyside", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Peter and Paul, Kirkby", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Vincent de Paul Liverpool", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Our Lady of the Assumption, Liverpool, UK", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Holy Family, Southport", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Elizabeth's, Scarisbrick", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Saint Catherine of Alexandria", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St John Stone and Sacred Heart, Ainsdale", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Holy Rosary, Aintree Village", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Holy Family, Cronton", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Joseph's, Anderton", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Dominic, Liverpool", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Hindley Catholic Churches", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Joseph’s, Anderton", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Our Lady of The Annunciation, Bishop Eaton", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Richard of Chichester, Atherton", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Teresa of the Child Jesus, Norris Green", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Alban", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Mary's", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Kirkdale", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Agnes and St Aidan, Liverpool", Diocese: "Archdiocese of Liverpool" },
  { Country: "United Kingdom", Parish: "Mumbles (Our Lady Star of the Sea), Swansea", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Morriston, Swansea", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "St David and St Patrick, Haverfordwest", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "Blessed Sacrament, Swansea", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "St Mary's RC Church, Rhyl", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "Parish of the Welsh Martyrs", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "Shrine of Our Lady of the Taper", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "Burry Port, Kidwelly & Pontyberem", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "Holy Cross, Swansea", Diocese: "Diocese of Menevia" },
  { Country: "United Kingdom", Parish: "Holy Cross (St Philip and St James) Bedford", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Holy Family and St John's Luton", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Immaculate Heart of Mary, Great Missenden", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Our Lady of Peace, Burnham, Slough", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Sacred Heart and Our Lady, Aston-le-walls", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Sacred Heart, Flitwick, Bedfordshire", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Aidan, Little chalfont, Bucks", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Andrew and Our Lady of Peace, Cippenham, Burnham", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Augustine's, High Wycombe", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Barnabas Cluster of Parishes Milton Keynes", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Columba, Chesham", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Gregory the Great, Northampton, Northamptonshire", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St John Henry Newman Parish, High Wycombe", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Joseph’s Parish", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Peter the Apostle, Rushden", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Thomas Aquinas and All Saints, Bletchley", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Catholic Parish of Datchet & Eton", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Christ the King Bedford", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "North Bedford Catholic Community", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Our Lady of Charity & St Augustine's, Daventry", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Peter and St Paul Church", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Thomas More, Twochester", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Peter's, Biggleswade", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Mary's, Dunstable", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Sacred Heart and Our Lady of Perpetual Succour", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Holy Child and St Joseph's, Bedford", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Cathedral of Our Lady Immaculate & St Thomas of Canterbury", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Our Lady of Walsingham, Corby", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Saint Teresa’s Beaconsfield", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Holy Redeemer, Slough", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Holy Family, Slough", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Our Lady of Perpetual Succour, Chesham Bois", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Teresa of the Child Jesus, Princes Risborough", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Sacred Heart Leighton Buzzard", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Francis de Sales and St Mary Magdalene", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Joseph and St Clare, Aylesbury", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Peter's, Marlow", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Our Lady Help of Christians, Luton", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Sacred Heart of Jesus, Luton", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Our Lady Help of Christians & St Lawrence", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Martin de Porres, Luton", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "The Parish of St Edward the Confessor", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Parish of St Joseph the Worker", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Catholic Church of Our Lady of the Sacred Heart", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "Nazareth House Northampton", Diocese: "Diocese of Northampton" },
  { Country: "United Kingdom", Parish: "St Bernadette's, Scunthorpe, UK", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Mary Crowned Parish and All Saints Partish", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "English Martyrs", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Melbourne & Castle Donington", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady and St Thomas of Hereford, Ilkeston", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Assumption, Beeston", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Lincoln, Lincoln UK", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Perpetual Succour, Bulwell", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Victories, Southwell, and Saint Anthony, Calverton", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart and St Margaret Mary, Leicester", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Alban's, Derby", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Anne's, Buxton", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Augustine Webster RC Parish", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Barnabas Cathedral, Nottingham", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Charles Borromeo Hadfield", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Melton Mowbray", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Stapleford", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Mary, Derby", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Mary's, Brigg", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Mary's, Wrawby", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Philip Neri, Mansfield", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Thomas More Wollaton, St Hugh, Bilborough, St Teresa, Aspley", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Wilfrids of York, Coalvile", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Catholic Community of East Nottingham", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady & St Patrick in the Meadow", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Parish of the Most Holy and Undivided Trinity", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Hugh's Church, Lincoln", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St. Patrick, St. George's & St. Joseph, Nottingham", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "The Good Shepherd", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Francis of Assisi, Long Eaton", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Mary's, Derby", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Catholic Parish of Belper and Duffield and Ripley", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "All Saints and St Mary Crowned, Glossop", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Good Shepherd, Nottingham", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Patrick's, Leicester", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St. Mary's and St. John Bosco", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart of Jesus, Loughborough", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St. Peters & Blessed Sacrament West Leicester", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Winefride's, Shepshed", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "The Catholic Community of East Nottingham", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Holy Trinity Holbeach", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of the Angels", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Sacred Heart and Saint Gilbert", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Saint John Fisher and Saint Thomas More", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Saint Joseph's Parish", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Saint Joseph the Worker", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Victories & St. Alphonsus\xa0", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Victories", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Mary and St Augustine Webster", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Joseph's Parish", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Parish of Saint George and All Soldier Saints", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Mother of God Church, Leicester", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Joseph's, Oakham", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "BOURNE AND DEEPINGS CATHOLIC PARISH", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "Our Lady of Lourdes, Mickleover", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "The Annunciation, Rushcliffe", Diocese: "Diocese of Nottingham" },
  { Country: "United Kingdom", Parish: "St Anthony, Headington", Diocese: "Diocese of Wrexham" },
  { Country: "United Kingdom", Parish: "St David, Pantasaph Fransican Friary", Diocese: "Diocese of Wrexham" },
  { Country: "United Kingdom", Parish: "St Winefred's, Holywell", Diocese: "Diocese of Wrexham" },
  { Country: "United Kingdom", Parish: "Most Holy Family, Llandudno Junction", Diocese: "Diocese of Wrexham" },
  { Country: "United Kingdom", Parish: "Church of Our Lady & St Cynfil", Diocese: "Diocese of Wrexham" },
  { Country: "United Kingdom", Parish: "Church of Our Lady of the Assumption", Diocese: "Diocese of Wrexham" },
  { Country: "United Kingdom", Parish: "St Richard Gwyn Communities", Diocese: "Diocese of Wrexham" },
  { Country: "United Kingdom", Parish: "The Visitiation, Taynuilt", Diocese: "Diocese of Argyll and the Isles" },
  { Country: "United Kingdom", Parish: "Glencoe & Mingarry Parishes", Diocese: "Diocese of Argyll and the Isles" },
  { Country: "United Kingdom", Parish: "Christ the King, Glasgow", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "Immaculate Conception, Maryhill, Glasgow", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "St Andrew, Bearsden", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "St Joachim's, Carmyle, Glasgow", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "St Joseph's, Tollcross, Glasgow", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "St Margaret's and Our Holy Redeemer Parish, Clydebank", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "St Mary of the Assumption", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "St Thomas the Apostle, Riddrie", Diocese: "Archdiocese of Glasgow" },
  { Country: "United Kingdom", Parish: "St Marys Lanark, Scotland", Diocese: "Diocese of Motherwell" },
  { Country: "United Kingdom", Parish: "St Aloysius, Chapel Hall", Diocese: "Diocese of Motherwell" },
  { Country: "United Kingdom", Parish: "St. Athanasius, Carluke", Diocese: "Diocese of Motherwell" },
  { Country: "United Kingdom", Parish: "St.Ninian's & St. Cuthbert's, Hamilton", Diocese: "Diocese of Motherwell" },
  { Country: "United Kingdom", Parish: "Our Lady & St Joseph's, Glenboig", Diocese: "Diocese of Motherwell" },
  { Country: "United Kingdom", Parish: "St John the Baptist, Uddingston", Diocese: "Diocese of Motherwell" },
  { Country: "United Kingdom", Parish: "St Joseph's, Galloway", Diocese: "Diocese of Galloway" },
  { Country: "United Kingdom", Parish: "St Mary's, Irvine", Diocese: "Diocese of Galloway" },
  { Country: "United Kingdom", Parish: "Saint Peter's", Diocese: "Diocese of Galloway" },
  { Country: "United Kingdom", Parish: "St Joseph's Covenant, Lawside", Diocese: "Diocese of Dunkeld" },
  { Country: "United Kingdom", Parish: "St Peter and St Paul, Dundee", Diocese: "Diocese of Dunkeld" },
  { Country: "United Kingdom", Parish: "St. Andrew's Cathedral, Dundee", Diocese: "Diocese of Dunkeld" },
  { Country: "United Kingdom", Parish: "St Mary’s Lochee, St Columba’s and St Leonard & St Fergus’", Diocese: "Diocese of Dunkeld" },
  { Country: "United Kingdom", Parish: "St Mary's Monastery, Kinnoull", Diocese: "Diocese of Dunkeld" },
  { Country: "United Kingdom", Parish: "Enniskillen Parish (St Michaels) Northern Ireland", Diocese: "Diocese of Clogher" },
  { Country: "United Kingdom", Parish: "Parish of Culmaine", Diocese: "Diocese of Clogher" },
  { Country: "Australia", Parish: "Nazareth Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Lady's Assumption Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Catherine of Siena", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Peter's Catholic Church", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Patrick's, Lilydale, VIC, Australia", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy Family Parish, Maidstone Australia", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Lady of Fatima, Rosebud, Melbourne, Australia", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St John's Catholic Parish, Melbourne, Australia", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary of the Cross, Mordialloc and Aspendale", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St. Mary of the Cross, Aspendale and Mordialloc", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Catholic Chinese Community of Melbourne", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Hawthorn Catholic Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Lady of The Assumption, Cheltenham", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Ss Peter & Paul's, South Melbourne", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Simon the Apostle, Rowville", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Andrew's Parish, Clayton South", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Benedict's, Burwood", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Gerard's, Dandenong North", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Celtic Parishes - St Kevin's and St Patrick's, Ormond and Murrumbeena", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Luke Parish, Wantirna", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy Family, Maidstone", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Mentone - Parkdale East Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Parishes of Camberwell, Balwyn & Deepdene", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Lady of The Nativity, Essendon West", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Christopher's, Syndal", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Roch's, Glen Iris", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Francis Xavier, Box Hill", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St John the Evangelist, Mitcham", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Macartan's, Mornington", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Bayside Catholic Mission", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Saint Mary Star of the Sea, West Melbourne", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary's, St Kilda East", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary of the Angels Basilica Parish, Geelong", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Paul's, Coburg", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Brunswick and Moreland Mission", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Peter's, Keilor East", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Lady of Perpetual Help, Dromana", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Lady of the Pines, Donvale", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Anne's, Seaford", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St. Carthage's, Parkville", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St. Kevin's, Hampton Park", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Peter Apostle Mission Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Bridget's, Greythron", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy Trinity Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Joseph, Malvern", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary's Star of the Sea Sorrento", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St John Vianney's Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St John Vianney, Mulgrave", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St. Catherine of Siena Parish, Caroline Springs", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Gregory Parish, Doncaster", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Martin De Porres", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Southern Community Church of Christ", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy Trinity, Altona Meadows", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St. Bernadette's Parish, Sunshine North", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St James the Apostle, Hoppers Crossing North", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Saint John Vianney - Mulgrave", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary's, Castlemaine", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary's Parish, Greenborough", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Parish of St Gerard & St Anne, Park Orchards & Warrandyte", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Bernard's Parish, Bacchus Marsh", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Parish of South Yarra", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Peter's parish Clayton", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Benedict's Parish, Burwood", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Holy Redeemer", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy Trinity Catholic Parish, Altona", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy Family Doveton", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy Spirit parish Ringwood", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary's Star of the Sea Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Anthony of Padua, Melton South", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Our Lady of Fatima Parish, Rosebud", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Anthony of Hungary", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Gabriel", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Stephen of Hungary", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Anthony", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Resurrection", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Francis Xavier Parish Box Hill", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Joseph's Chelsea", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Mary's Malvern East", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Clare, Box Hill North", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Church of the Good Shepherd, Gladstone Park", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Thomas the Apostle, Greensborough North", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Andrews Werribee", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Holy spirit Manifold", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Francis of Assisi Mill Park", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Bernard's Claremont", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "All Saints Catholic Parish", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Michael's, North Melbourne", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Peter Chanel, Deer Park", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "Parish of Leongatha", Diocese: "Archdiocese of Melbourne" },
  { Country: "Australia", Parish: "St Joseph the Worker Reservoir North", Diocese: "Archdiocese of Melbourne" },
  { Country: "Netherlands", Parish: "St Augustine, Breda, Netherlands", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "The Good Shepherd (De Goede Herder), Tilburg, Netherlands", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "Holy Family, Breda, Netherlands", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "St. Anna, Steenbergen, Netherlands", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "Sint Elisabethparochie (Saint Elisabeth Parish), Raamsdonksveer, Netherlands", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "Catharinaparochie, Oosterhout", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "Emmausparochie Tilburg Reeshof", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "Parochie Breda Centrum", Diocese: "Diocese of Breda" },
  { Country: "Netherlands", Parish: "Heilige Maria Parochie", Diocese: "Diocese of Breda" },
  { Country: "Australia", Parish: "Innisfail Parish", Diocese: "Diocese of Cairns" },
  { Country: "Australia", Parish: "St Michael's, Gordonvale", Diocese: "Diocese of Cairns" },
  { Country: "Australia", Parish: "St Therese, Edmonton", Diocese: "Diocese of Cairns" },
  { Country: "Australia", Parish: "St Anthony's, Dimbulah", Diocese: "Diocese of Cairns" },
  { Country: "Australia", Parish: "St Mary's Church, Cooktown", Diocese: "Diocese of Cairns" },
  { Country: "Australia", Parish: "Holy Family Church - Rockhampton", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "Rockhampton – Rockonia", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "Capricorn Coast Parish", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "Catholic Parish of Bundaberg", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "St Joseph's, Mackay", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "St Joseph's Cathedral, Rockhampton", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "St Mary's, Clermont", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "St Patrick's, Emerald", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "Valleys Region Parish", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "St. Patrick's Parish, Rockhampton", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "Our Lady Star of the Sea - Gladstone Parishes", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "Capricorn Coast Catholic Parish", Diocese: "Diocese of Rockhampton" },
  { Country: "Australia", Parish: "Holy Spirit Seminary", Diocese: "Diocese of Toowoomba" },
  { Country: "Australia", Parish: "Our Lady Help of Christians, Chinchilla", Diocese: "Diocese of Toowoomba" },
  { Country: "Australia", Parish: "Cathedral of St Patrick", Diocese: "Diocese of Toowoomba" },
  { Country: "Australia", Parish: "City Church", Diocese: "Diocese of Toowoomba" },
  { Country: "Australia", Parish: "Holy Spirit, Cranbrook", Diocese: "Diocese of Townsville" },
  { Country: "Australia", Parish: "Our Lady of Lourdes Parish - Collinsville", Diocese: "Diocese of Townsville" },
  { Country: "Australia", Parish: "Holy Family Parish North East Mallee, Australia", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Warrnambool", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Northeast Mallee", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "St Patrick's Cathedral, Ballarat Central", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Ballarat East", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Mildura", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Colac", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Bungaree", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Redan", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of North Ballarat", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Robinvale", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "Parish of Warrnambool West & Dennington", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "The Parish of Wimmera-Mallee", Diocese: "Diocese of Ballarat" },
  { Country: "Australia", Parish: "St. Mary's Maffra", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Michael's, Berwick, Victoria, Australia", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Parish of Bairnsdale", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Our Lady Help of Christians, Narre Warren", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Thomas the Apostle, Clyde North", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Mel's (Christ the King), Shepparton South", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Parish of Wonthaggi", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Michael's, Traralgon", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Parish of Moe & Newborough", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Parishes of Iona-Maryknoll and Koo Wee Rup", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Saint Mary's Cathedral, Sale", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Saint Agatha, Cranbourne", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Mary's Cathedral", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Patrick's Parish Pakenham", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Parish of Morwell", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Brendan's Parish, Lakes Entrance", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "Parish of Heyfield", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Joseph's Warragul", Diocese: "Diocese of Sale" },
  { Country: "Australia", Parish: "St Mary's Parish Echuca, Victoria, Australia", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Liborius & St Mary's Inglewood, Australia", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Yarrawonga Parish", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Euroa Parish", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Parish of Cobram", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Mary's Parish, Echuca", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Sacred Heart Cathedral, Bendigo", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Mary's, Dookie", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Threrese's, Bendigo (Kennington)", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Brendan's, Shepparton", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Liborius, Eaglehawk", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Mary Help of Christians, Heathcote", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Wodonga Catholic Parish", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Kilians Bendigo", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Mary's, Rushworth", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "KYABRAM Catholic parish", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Kerang, Cohuna and Pyramid Hill Parishes", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Augustine's Kyabram", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Mary’s Parish", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "St Mary’s Myrtleford", Diocese: "Diocese of Sandhurst" },
  { Country: "Australia", Parish: "Our Lady of Lourdes Rockingham", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Santa Clara Parish, Bentley", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St. Teresa of Calcutta, Baldivis", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Infant Jesus, Morley", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "All Saints, Greenwood", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Holy Rosary, Nedlands", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Immaculate Heart of Mary, Scarborough", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Peter the Apostle, Inglewood", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Jerome, Munster", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Anne's, Bedmont", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Brigid's, Midland", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Mary MacKillop, Ballajura", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Immaculate Heart of Mary Scarborough", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Patrick's York", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "The Basilica of St Patrick Perth", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Mary's Cathedral-Perth", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Vincent's in Kwinana", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Sared Heart Mundaring", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Divine Mercy Lower Chittering", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Francis Xavier Parish Armadale", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Bernadette", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Augustine Parish, Rivervale", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Immaculate Heart of Mary Parish, Scarborough", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "Holy Trinity Embleton", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Emilie de Vialar", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Mary's Parish in Guildford", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Benedict's Applecross", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St Joseph Syro Malabar Parish", Diocese: "Archdiocese of Perth" },
  { Country: "Australia", Parish: "St. Patrick's Cathedral, Bunbury", Diocese: "Diocese of Bunbury" },
  { Country: "Australia", Parish: "Our Lady's Assumption, Mandurah", Diocese: "Diocese of Bunbury" },
  { Country: "Australia", Parish: "St Patrick's, Katanning", Diocese: "Diocese of Bunbury" },
  { Country: "Australia", Parish: "Holy Family Parish Bunbury", Diocese: "Diocese of Bunbury" },
  { Country: "Australia", Parish: "Bluff Point Parish", Diocese: "Diocese of Geraldton" },
  { Country: "Australia", Parish: "St Gregory's Parish, Queanbeyan", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Blackfriars Parish, Watson", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Canberra Central Parish", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Holy Spirit, Gungahlin", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Christopher's Cathedral Parish", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Joseph's, O'Connor", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Sacred Heart, Moruya", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Augustine's, Yass", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Patrick's Parish, Bega", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Benedict's Canberra", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Brigid'sDickson", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Immaculate Conception Parish Tumut", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Parish of the Transfiguration", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Corpus Christi Tuggeranong", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Benedict's Parish, Narrabundah", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "Mary Help of Christians Parish, South Woden.", Diocese: "Archdiocese of Canberra and Goulburn" },
  { Country: "Australia", Parish: "St Francis Xavier Parish, Moree", Diocese: "Diocese of Armidale" },
  { Country: "Australia", Parish: "St Joseph's, Gunnedah", Diocese: "Diocese of Armidale" },
  { Country: "Australia", Parish: "Mary & Joseph’s Cathedral Parish", Diocese: "Diocese of Armidale" },
  { Country: "Australia", Parish: "St Nicholas, Tamworth", Diocese: "Diocese of Armidale" },
  { Country: "Australia", Parish: "Sacred Heart Boggabri", Diocese: "Diocese of Armidale" },
  { Country: "Australia", Parish: "St Agnes Catholic Church Community", Diocese: "Diocese of Lismore" },
  { Country: "Australia", Parish: "St Mary's, Casino", Diocese: "Diocese of Lismore" },
  { Country: "Australia", Parish: "Mary Help of Christians, Sawtell", Diocese: "Diocese of Lismore" },
  { Country: "Australia", Parish: "Macleay Valley Catholic Parish", Diocese: "Diocese of Lismore" },
  { Country: "Australia", Parish: "Our Lady of Lourdes Catholic Parish, Wauchope", Diocese: "Diocese of Lismore" },
  { Country: "Australia", Parish: "St Augustines Parish, Coffs Harbour", Diocese: "Diocese of Lismore" },
  { Country: "Australia", Parish: "Mary Mother of the Church, Macquarie Fields Australia", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "St Joseph's Toronto", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "St. Joseph, Toronto Parish", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "St Patrick's, Lochinvar", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "St James, Muswellbrook", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "Maitland Parish", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "St Stephen's, Tea Gardens", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "Wallsend-Shortland Parish", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "Mackillop Charlestown", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "All Saints Catholic Parish Blackbutt South", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "Boolaroo, Warners Bay and Booragul Catholic Parishes", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "Myall Coast Catholic Parish", Diocese: "Diocese of Maitland-Newcastle" },
  { Country: "Australia", Parish: "Christ the Redeemer Maronite Catholic Parish of The Hills, Dural, NSW", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St Patrick's Cathedral Parish, Parramatta", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Kenthurst – St Madeleine Sophie Barat Parish", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Our Lady Queen of Peace\xa0Church, Gerystanes", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Sacred Heart, Blackheath", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St. Luke's Catholic Community", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St Patrick's, Guildford", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St Bernadette's, Castle Hill", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Our Lady of the Way, Emu Plains", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St Monica's (Chinese Chaplaincy), North Parramatta", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St Matthew's, Windsor", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Parish of Richmond", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Holy Spirit Parish, St. Clair", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Parish of Baulkham Hills", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Mary, Queen of the Family, Blacktown", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "Holy Name of Mary, Rydalmere", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St Michael's Parish, Baulkham Hills", Diocese: "Diocese of Parramatta" },
  { Country: "Australia", Parish: "St. Michael's, Wagga Wagga Cathedral", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Albury - Lavington Parish", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "St Mary's, The Rock", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "St Patrick's Wagga Wagga", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Sacred Heart Wagga Wagga", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Narrandera Parish", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Holy Trinity West Wagga Parish", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Ganmain parish", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Mulwala + Berrigan Parishes", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Finley Parish and Tocumwal Parish", Diocese: "Diocese of Wagga Wagga" },
  { Country: "Australia", Parish: "Holy Family Parish, Parkes", Diocese: "Diocese of Wilcannia-Forbes" },
  { Country: "Australia", Parish: "Holy Spirit Parish, Casuarina", Diocese: "Diocese of Darwin" },
  { Country: "Australia", Parish: "Christ the King Church, Tennant Creek", Diocese: "Diocese of Darwin" },
  { Country: "Australia", Parish: "St Joseph Melkite Church", Diocese: "Eparchy of St Michael, Archangel (Melkite)" },
  { Country: "New Zealand", Parish: "Tongariro Catholic", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "St Columba's Frankton, Hamilton, New Zealand", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "Cathedral of the Blessed Virgin Mary", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "Parish of the Holy Family", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "All Saints by the Sea", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "St Mary of the Cross MacKillop, Rotorua", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "Parish of the Holy Cross, Hamilton", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "St Patrick's, Putaruru", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "St Matthew, Silverdale", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "St Patrick's, Te Awamutu", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "St Thomas Aquinas, Tauranga", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "St Thomas More, Mount Maunganui", Diocese: "Diocese of Hamilton" },
  { Country: "New Zealand", Parish: "Catholic Parish of New Plymouth", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Catholic Parish of Hastings", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Hawera Parish", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Cathedral of the Holy Spirit, Palmerston North", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Our Lady of Lourdes & Sacred Heart Rongotea", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "North Taranaki Pastoral Area", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "St. Mary's Catholic Church, Palmerston North", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "St Mary’s, Foxton", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Waitara and Inglewood", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Catholic Parish of Whanganui", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "St Brigid's, Pahiatua", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Catholic Parish of Napier", Diocese: "Diocese of Palmerston North" },
  { Country: "New Zealand", Parish: "Cathedral of the Sacred Heart", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Catholic Parish of Wellington South", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "St Theresa's, Plimmerton", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Catholic Parish of Te Awakairangi", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Holy Family Parish, Nelson - Stoke", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "St Mary of the Angels, Wellington", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "St Joseph's, Levin/Taitoko", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Catholic Parish of Otari", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Catholic Enquiry Centre", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Our Lady of Kapiti", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Star of the Sea Parish, Marlborough", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Catholic Parish of Otari", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "Wairarapa Catholic Parish", Diocese: "Archdiocese of Wellington" },
  { Country: "New Zealand", Parish: "St Peter Chanel, Christchurch, South Island, NZ", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "The Catholic Parish of Christchurch South, NZ", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Catholic Cathedral Parish, Christchurch, NZ", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Catholic Parish of Christchurch North, NZ", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Christchurch West, NZ", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Mid-Canterbury Parish", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "St Francis of Assisi, Mairehau", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "St Joseph's, Papanui", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "St Theresa's, Riccarton", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Stella Maris Parish, Christchurch", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Selwyn Parish", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "St Patrick and All Saints Parish, Mackenzie", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "St Patrick, Greymouth", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Mid Canterbury – St Augustine of Canterbury Parish", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Greymouth – St Patrick's Parish", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "St Mary's, Hokitika", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "Our Lady of the Plains Catholic Parish - Selwyn", Diocese: "Diocese of Christchurch" },
  { Country: "New Zealand", Parish: "St Mary's Mosgiel and St Peter Chanel Green Island", Diocese: "Diocese of Dunedin" },
  { Country: "New Zealand", Parish: "Sacred Heart Church, Invercargill", Diocese: "Diocese of Dunedin" },
  { Country: "New Zealand", Parish: "Holy Family, Wanaka/Cromwell", Diocese: "Diocese of Dunedin" },
  { Country: "New Zealand", Parish: "St Patrick's Basillica, Mercy Parish", Diocese: "Diocese of Dunedin" },
  { Country: "New Zealand", Parish: "South Otago, Dunedin", Diocese: "Diocese of Dunedin" },
  { Country: "United States", Parish: "St. Joseph, Hayward Wisconsin", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "Holy Rosary, Medford Wisconsin", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "Cathedral of Christ the King", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "Good Shepherd", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "Nativity of Our Lord", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "Saint Patrick Parish", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. Ann's", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. Mary's Immaculate Conception", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. Francis de Sales Parish Cluster", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. Francis Xavier", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. Mary's", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Diocese of Superior" },
  { Country: "United States", Parish: "St. Therese of Lisieux Catholic Church", Diocese: "Diocese of Superior" },
  { Country: "Malaysia", Parish: "Cathedral of the Holy Spirit, Greenlane, Penang, Malaysia", Diocese: "Diocese of Penang" },
  { Country: "Malaysia", Parish: "Church of Our Lady of Fatima of the Holy Rosary, Kota Bahru", Diocese: "Diocese of Penang" },
  { Country: "Malaysia", Parish: "Nativity of the Blessed Virgin Mary, Penang", Diocese: "Diocese of Penang" },
  { Country: "Canada", Parish: "St Joseph's Vanderhoof, B.C.", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "Immaculate Heart of Mary, Burns Lake, BC, Canada", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "Saint Joseph's Catholic Church, Vanderhoof BC", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "St. Joseph, Smithers BC", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "Christ our Saviour Parish", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "Sacred Heart Parish - Terrace", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "St. Andrew's Parish - Fraser Lake", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "Notre Dame Parish - Dawson Creek", Diocese: "Diocese of Prince George" },
  { Country: "Canada", Parish: "Christ the King, Kitimat", Diocese: "Diocese of Prince George" },
  { Country: "United States", Parish: "St, Aloyius Gonzaga", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Bartholomew", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Albert the Great", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Marion Catholic Community", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Patrick, Troy OH", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Francis of Assisi, Centerville OH", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Cathedral Basilica of Saint Peter in Chains", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Annunciation of the Blessed Virgin Mary, Cincinatti", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Preble Country Catholic Region", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Emmanuel Catholic Church, Dayton", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Augustine, Wilmington", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Our Lady of the Visitation, Cincinnati", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Gertrude, Cincinnati", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Our Lady of Sorrows, Monroe, OH", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Patrick - Bellefontaine", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Luke - Beavercreek", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Henry Cluster - Ohio", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St Francis de Sales - Lebanon", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Our Lady of Sorrows", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Our Lady of Light / St Francis/Incarnation", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Our Lady of Light Family of Parishes", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Benedict the Moor Parish", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Archdiocese of Cincinnati" },
  { Country: "United States", Parish: "Saint Mary Church, Uxbridge, MA, USA", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Mary, Uxbridge", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Mary's Jefferson", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Patrick, Rutland", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Divine Mercy Parish, MA", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Anthony of Padua, Dudley", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Sacred Heart of Jesus Parish, Webster, MA", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel and Our Lady of Loreto Parish", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Mary's-Shrewsbury", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Our Lady of Hope Parish", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Rose of Lima, Northborough", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "The Catholic Community of Sacred Heart", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Saint Peter Parish", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Roch Church", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Saint Joseph Church, Charlton", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Our Lady of Providence Parish", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "Our Lady of Czestochowa, Worcester, MA", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St. Joseph, Fitchburg MA", Diocese: "Diocese of Worcester" },
  { Country: "United States", Parish: "St John Vianney, San Jose, California, USA", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St Joseph Cathedral Basilica, San Jose, California", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St. Joseph Cathedral Basilica", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "Most Holy Trinity", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "Saint Anthony", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St. Martin of Tours Parish", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St. Christopher Catholic Church", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St. Simon", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St. Leo the Great", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St. Joseph of Cupertino, California", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "Queen of Apostles, San Jose, California", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St. Nicholas & St. William Parish, Los Altos", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St Mary, Gilroy, California", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "Chinese Catholic Mission, San Jose", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "Saint Lawrence the Martyr Catholic Parish", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "Christ the King Church", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St Frances Cabrini Parish", Diocese: "Diocese of San Jose" },
  { Country: "United States", Parish: "St Elizabeth of Portugal - Milpitas", Diocese: "Diocese of San Jose" },
  { Country: "Canada", Parish: "Christ the Redeemer", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "St Margarets Parish, Arisaig, Antigonish, NS", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "St. Ninian Parish", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "Our Lady of Lourdes - Stellarton", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "St. Anne Parish", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "St. Joseph Parish - Port Hawkesbury", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "Stella Maris Parish", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "St. Joseph Parish, North Sydney", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "East Richmond County Catholic Parishes", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "Saint Peter's, Port Hood", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "TRI-PARISH GROUPING", Diocese: "Diocese of Antigonish" },
  { Country: "Canada", Parish: "St. Joseph Parish", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "Sacred Heart Church", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Joseph Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. James Parish - Okotoks", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "All Saints Parish - Lethbridge", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "Sacred Heart Church - Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Albert the Great Parish", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Ambrose & St. Catherine's Twinned Parishes", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Bernard's/Our Lady of the Assumption Parish", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Luke's Parish - Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Mary's Parish - Cochrane", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Patrick Parish - Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St Peter's, Calgary, Alberta", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Agnes, Carstairs", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "Holy Spirit, Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Mary's Cathedral, Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "Saint Gabriel the Archangel, Chestermere", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "Our Lady of Fatima, Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Mary’s Cathedral, Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. Mary's Parish Brooks", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "St. James, Calgary", Diocese: "Diocese of Calgary" },
  { Country: "Canada", Parish: "Good Shepherd Pastoral Unit", Diocese: "Diocese of Charlottetown" },
  { Country: "Canada", Parish: "St. Benedict's Pastoral Unit", Diocese: "Diocese of Charlottetown" },
  { Country: "Canada", Parish: "Holy Redeemer", Diocese: "Diocese of Charlottetown" },
  { Country: "Canada", Parish: "St. Anthony's Parish", Diocese: "Diocese of Charlottetown" },
  { Country: "Canada", Parish: "Holy family Pastoral Unit", Diocese: "Diocese of Charlottetown" },
  { Country: "Canada", Parish: "St. John Paul II Pastoral Unit", Diocese: "Diocese of Charlottetown" },
  { Country: "Canada", Parish: "Sacred Heart, Alberton", Diocese: "Diocese of Charlottetown" },
  { Country: "Canada", Parish: "Holy Redeemer Parish", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Holy Redeemer Parish - Corner Brook", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Stephenville Crossing Assumption Parish", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Cathedral of the Most Holy Redeemer and of the Immaculate Conception", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Our Lady of Grace - Bird Cove", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Our Lady of Labrador Parish", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Our Lady of Lourdes Parish", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Basilica of Our Lady of Perpetual Help", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Sacred Heart Parish - Curling", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "St. Ann's Parish - Codroy Valley", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "St. Stephen's Parish - Stephenville", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "Holy Rosary Parish - Pasadena", Diocese: "Diocese of Corner Brook and Labrador" },
  { Country: "Canada", Parish: "St. Aloysuis Gonzaga & St. Columban Mission", Diocese: "Archdiocese of Gatineau" },
  { Country: "Canada", Parish: "Assumption Parish - Avondale", Diocese: "Diocese of Grand Falls" },
  { Country: "Canada", Parish: "St. Mary's Church - Beaverlodge", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Joseph, Grande Prairie", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St Paul, High Prairie", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Mary's, Beaverlodge", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Paul, High Prairie", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Joseph, Grande Prairie", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Bernadette, Eleske", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Immaculate Heart of Mary, Hines", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Joseph, John D'Or Prairie", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. James the Major – Manning", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Immaculate Conception, Sexsmith", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Sacred Heart. Cadotte Lake", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Francois Xavier, Eaglesham", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Theresa of the Child Jesus, Whitelaw", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Sacre Coeur, Donnelly", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Holy Rosary, Crooked Creek", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Our Lady of Peace, Peace River", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. John Bosco, Gift Lake", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Guy, Guy", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Patrick, Wanham", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Ste. Anne, Joussard", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. Martin, Desmarais/Wabasca", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Our Lady of Good Counsel, High Level", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "St. James the Major, Smith", Diocese: "Archdiocese of Grouard-McLennan" },
  { Country: "Canada", Parish: "Canadian Martyrs Parish / McMaster University Chaplaincy", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St Mary of the Visitaton, Cambridge, Ontario", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St. Michael's Parish, Waterloo, ON", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St. Pius X, Brantford ON", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Annunciation of our Lord Parish - Hamilton", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Most Blessed Sacrament Parish", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Christ's Church Cathedral", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Holy Cross Croatian Parish", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Holy Rosary Parish - Milton", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Immaculate Heart Of Mary Parish - Stoney Creek", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Our Lady of the Assumption Parish - Stoney Creek", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Regina Mundi Parish", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St. Augustine's Parish", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St. Mary's Parish - Hamilton", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St. Mark's Parish - Kitchener", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "Holy Family, Hanover", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St. Aloysius, Kitchener", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St. Andrew's, Oakville", Diocese: "Diocese of Hamilton" },
  { Country: "Canada", Parish: "St-Charles-Borromeo Cathedral, Joliette", Diocese: "Diocese of Joliette" },
  { Country: "Canada", Parish: "Our Lady of Perpetual Help Parish - Kamloops", Diocese: "Diocese of Kamloops" },
  { Country: "Canada", Parish: "Our Lady of the Mountains, Whistler", Diocese: "Diocese of Kamloops" },
  { Country: "Canada", Parish: "St. Augustines Parish Moncton", Diocese: "Archdiocese of Moncton" },
  { Country: "Canada", Parish: "Saint-Esprit Pastoral Unit", Diocese: "Archdiocese of Moncton" },
  { Country: "Canada", Parish: "St. Bernard's", Diocese: "Archdiocese of Moncton" },
  { Country: "Canada", Parish: "Cathedral of Mary Immaculate", Diocese: "Diocese of Nelson" },
  { Country: "Canada", Parish: "St. Mary & Christ the Servant Parishes", Diocese: "Diocese of Nelson" },
  { Country: "Canada", Parish: "St. John Vianney, Penticton, BC", Diocese: "Diocese of Nelson" },
  { Country: "Canada", Parish: "Holy Trinity, Trail BC", Diocese: "Diocese of Nelson" },
  { Country: "Canada", Parish: "Parishes of Astorville, Bonfield, and Corbeil", Diocese: "Diocese of Pembroke" },
  { Country: "Canada", Parish: "Holy Canadian Martyrs, Combermere", Diocese: "Diocese of Pembroke" },
  { Country: "Canada", Parish: "St. Hedwig's, Barry's Bay", Diocese: "Diocese of Pembroke" },
  { Country: "Canada", Parish: "St. Francis Xavier, Renfrew Ontario", Diocese: "Diocese of Pembroke" },
  { Country: "Canada", Parish: "St. Anne Parish, Mattawa", Diocese: "Diocese of Pembroke" },
  { Country: "Canada", Parish: "Sacred Heart of Jesus Parish - Peterborough", Diocese: "Diocese of Peterborough" },
  { Country: "Canada", Parish: "St. Francis of Assisi Parish - Newcastle", Diocese: "Diocese of Peterborough" },
  { Country: "Canada", Parish: "St. Paul and Our Lady of Mount Carmel Parish", Diocese: "Diocese of Peterborough" },
  { Country: "Canada", Parish: "Cathedral of St. Peter-In-Chains", Diocese: "Diocese of Peterborough" },
  { Country: "Canada", Parish: "St. Paul the Apostle Church", Diocese: "Diocese of Peterborough" },
  { Country: "Canada", Parish: "St. Alphonsus Liguori, Peterborough", Diocese: "Diocese of Peterborough" },
  { Country: "Canada", Parish: "St. Vital Church", Diocese: "Diocese of Prince Albert" },
  { Country: "Canada", Parish: "Saint-Thomas-d'Aquin, Québec", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Paroisse Bienheureuse Mère Saint-Louis", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Bellechasse-Etchemins Mission", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Paroisse Notre Dame de l'Annonciation", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Paroisse Saint-Thomas-d'Aquin", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Paroisse Sainte-Kateri Tekakwitha", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Saint Sauveur Parish", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Saint-Jean-Baptiste Catholic Parish", Diocese: "Archdiocese of Québec" },
  { Country: "Canada", Parish: "Paroisse Bienheureuse-Élisabeth-Turgeon", Diocese: "Archdiocese of Rimouski" },
  { Country: "Canada", Parish: "Paroisse Sainte-Trinite", Diocese: "Diocese of Rouyn-Noranda" },
  { Country: "Canada", Parish: "Notre-Dame-de-Lorette", Diocese: "Archdiocese of Saint-Boniface" },
  { Country: "Canada", Parish: "Parish of St. Bernadette", Diocese: "Archdiocese of Saint-Boniface" },
  { Country: "Canada", Parish: "Mary, Mother of the Church, Winnipeg", Diocese: "Archdiocese of Saint-Boniface" },
  { Country: "Canada", Parish: "Paroisse Saints-Martyrs-Canadiens", Diocese: "Archdiocese of Saint-Boniface" },
  { Country: "Canada", Parish: "Paroisse Saint-Joseph, Saint-Joseph, MB", Diocese: "Archdiocese of Saint-Boniface" },
  { Country: "Canada", Parish: "Paroisse Ile-des-Chênes - Notre-Dame-de-la-Miséricorde", Diocese: "Archdiocese of Saint-Boniface" },
  { Country: "Canada", Parish: "Paroisse Précieux-Sang, Saint-Hyacinthe, Qc, Canada", Diocese: "Diocese of Saint-Hyacinthe" },
  { Country: "Canada", Parish: "Living Church", Diocese: "Diocese of Saint-Hyacinthe" },
  { Country: "Canada", Parish: "Our Lady of Good Counsel Parish - Otterburn Park", Diocese: "Diocese of Saint-Hyacinthe" },
  { Country: "Canada", Parish: "Saint John Paul II Pastoral Unit", Diocese: "Diocese of Saint-Jean-Longueuil" },
  { Country: "Canada", Parish: "Pariosse Saint-Jean XXIII", Diocese: "Diocese of Saint-Jean-Longueuil" },
  { Country: "Canada", Parish: "Holy Family Parish - Deux-Montagnes", Diocese: "Diocese of Saint-Jérôme" },
  { Country: "Canada", Parish: "Paroisse Sainte-Marie-du-Lac", Diocese: "Diocese of Saint-Jérôme" },
  { Country: "Canada", Parish: "Pariosse Ste-Therese-d'Avila", Diocese: "Diocese of Saint-Jérôme" },
  { Country: "Canada", Parish: "St. Peter's, Quebec", Diocese: "Diocese of Saint-Jérôme" },
  { Country: "Canada", Parish: "Saint-Antoine Parish, Quebec", Diocese: "Diocese of Saint-Jérôme" },
  { Country: "Canada", Parish: "St. John Paul II, Quebec", Diocese: "Diocese of Saint-Jérôme" },
  { Country: "Canada", Parish: "Saint-Louis-de-France, QC", Diocese: "Diocese of Saint-Jérôme" },
  { Country: "Canada", Parish: "St. Joseph Parish, Whitecourt", Diocese: "Diocese of Saint-Paul" },
  { Country: "Canada", Parish: "Immaculate Heart of Mary Parish", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "Catheral of the Holy Family", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "Holy Spirit Parish - Saskatoon", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "Our Lady of Lourdes Parish - Saskatoon", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "Sacred Heart of Jesus Parish - Montmartre", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "Saint Anne's Parish - Saskatoon", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Augustine Parish - Humboldt", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Francis Xavier Parish", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Joseph Parish - Kindersley", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Mary's Parish - Macklin", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Michael Parish - Saskatoon", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Paul's Co-Cathedral", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Peter's Parish - Muenster", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Peter the Apostle Parish - Saskatoon", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Philip Neri Parish - Saskatoon", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Philippe Neri Parish - Vonda", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. James Parish - Wilkie", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St Theresa @ Immaculate Heart of Mary", Diocese: "Diocese of Saskatoon" },
  { Country: "Canada", Parish: "St. Veronica's", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "Holy Name of Jesus Church - North Bay", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "Our Lady of Good Counsel Parish - Sault Ste-Marie", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "Our Lady of Hope Church", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "Pro-Cathedral of the Assumption", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "North Shore Catholic Parishes", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "St. Peter the Apostle Church - North Bay", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "St. Monica, Wawa", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "Paroisse Sainte Marie du Sault", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "St. Gregory's Church", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "St Vincent de Paul Parish in North Bay", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "Christ the King - Sudbury", Diocese: "Diocese of Sault Ste-Marie" },
  { Country: "Canada", Parish: "Shrine of the Sacred-Heart of Beauvoir", Diocese: "Archdiocese of Sherbrooke" },
  { Country: "Canada", Parish: "St Jean Bosco", Diocese: "Archdiocese of Sherbrooke" },
  { Country: "Canada", Parish: "St-Patrice Magog, PQ", Diocese: "Archdiocese of Sherbrooke" },
  { Country: "Canada", Parish: "Shrine of the Sacred-Heart of Beauvoir", Diocese: "Archdiocese of Sherbrooke" },
  { Country: "Canada", Parish: "Église Notre-Dame de Protection", Diocese: "Archdiocese of Sherbrooke" },
  { Country: "Canada", Parish: "Unité Pastorale de la Croix Glorieuse", Diocese: "Archdiocese of Sherbrooke" },
  { Country: "Canada", Parish: "Basilica Cathedral St. John the Baptist", Diocese: "Archdiocese of St. John’s" },
  { Country: "Canada", Parish: "Sacred Heart Parish - St. Bride's", Diocese: "Archdiocese of St. John’s" },
  { Country: "Canada", Parish: "Sacred Heart Parish -  Marystown", Diocese: "Archdiocese of St. John’s" },
  { Country: "Canada", Parish: "St. Kevin's & St. Joseph's Parishes", Diocese: "Archdiocese of St. John’s" },
  { Country: "Canada", Parish: "St. Pius X, St. John's NFLD", Diocese: "Archdiocese of St. John’s" },
  { Country: "Canada", Parish: "Holy Name of Jesus Parish", Diocese: "Diocese of Timmins" },
  { Country: "Canada", Parish: "Sacred Heart of Jesus Parish - Timmins", Diocese: "Diocese of Timmins" },
  { Country: "Canada", Parish: "Our Lady of Perpetual Help Parish", Diocese: "Diocese of Valleyfield" },
  { Country: "Canada", Parish: "Paroisse Catholique Sainte-Marguerite-d'Youville", Diocese: "Diocese of Valleyfield" },
  { Country: "Canada", Parish: "Saint Patrick of the Island", Diocese: "Diocese of Valleyfield" },
  { Country: "Canada", Parish: "St. Thomas Aquinas, Hudson", Diocese: "Diocese of Valleyfield" },
  { Country: "Canada", Parish: "Paroisses de la Région de Châteauguay", Diocese: "Diocese of Valleyfield" },
  { Country: "Canada", Parish: "Saint-Laurent et Saint-Viateur", Diocese: "Diocese of Valleyfield" },
  { Country: "Canada", Parish: "St. Peter's Church - Nanaimo", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "Christ the King - Courtenay", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "Church of the Ascension - Parksville", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "Our Lady of Grace Parish", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "Sacred Heart Catholic Church - Victoria", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "St. Andrew's Cathedral", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "St. Patrick's Parish - Victoria", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "Trinity Catholic Church - Nanaimo", Diocese: "Diocese of Victoria" },
  { Country: "Canada", Parish: "St. Augustine of Canterbury Church", Diocese: "Archdiocese of Winnipeg" },
  { Country: "Canada", Parish: "St. Charles Church", Diocese: "Archdiocese of Winnipeg" },
  { Country: "Canada", Parish: "St. Ignatius of Loyola Church - Winnipeg", Diocese: "Archdiocese of Winnipeg" },
  { Country: "Canada", Parish: "St. Patrick's Church - Winnipeg", Diocese: "Archdiocese of Winnipeg" },
  { Country: "Canada", Parish: "St. Nicholas Tavelich Parish, Winnipeg", Diocese: "Archdiocese of Winnipeg" },
  { Country: "Ireland", Parish: "Kilkerley & Knockbridge, Dundalk, Ireland", Diocese: "Archdiocese of Armagh" },
  { Country: "Ireland", Parish: "Termonfechin Parish", Diocese: "Archdiocese of Armagh" },
  { Country: "Ireland", Parish: "St. Patrick's Cathedral", Diocese: "Archdiocese of Armagh" },
  { Country: "United States", Parish: "Saint Mary of the Bay, Warren, Rhode Island, USA", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "Christ the King", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Mary of the Bay", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "Ss. Peter and Paul", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Paul Cranston", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Thomas", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Mary's Newport", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Jude's Church", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "Sts. Rose & Clement", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Sebastian, RI", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Mary's Newport", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Jude's Church", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "Sts. Rose & Clement", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Sebastion", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "St. Francis of Assisi Wakefield RI", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "Mary Mother of Mankind", Diocese: "Diocese of Providence" },
  { Country: "United States", Parish: "Lumen Christi Parish, Mequon, Wisconsin, USA", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St Charles", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Basilica of St. Josaphat", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Catholic Community of Waukesha", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Holy Apostles Catholic Parish", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. John the Evangelist", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. Roman", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Sheboygan South Catholic Parishes", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Christ the King Catholic Parish", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Nativity of Our Lord", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "Ss Paul & Bruno Catholic Parishes", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. Charles Parish and School", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. James", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. John the Baptist Catholic Church & School", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. Mary of the Hill", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St. Paul Catholic Church", Diocese: "Archdiocese of Milwaukee" },
  { Country: "United States", Parish: "St.\xa0Elizabeth Ann Seton, New Berlin", Diocese: "Archdiocese of Milwaukee" },
  { Country: "Ireland", Parish: "Parish of Kilmacrennan, Donegal, Ireland", Diocese: "Diocese of Raphoe" },
  { Country: "Ireland", Parish: "Parish of Carrigart", Diocese: "Diocese of Raphoe" },
  { Country: "Ireland", Parish: "Kilmacrenan Parish", Diocese: "Diocese of Raphoe" },
  { Country: "Ireland", Parish: "Stranorlar Parish", Diocese: "Diocese of Raphoe" },
  { Country: "Ireland", Parish: "Parish of Newtowncunningham", Diocese: "Diocese of Raphoe" },
  { Country: "Ireland", Parish: "St Mary Pope's Quay, Cork, Ireland", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Clonakilty Parish, Cork Ireland", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Barryroe Parish, Parochial House, Lislevane, Bandon, Co.Cork", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Cathedral of St Mary & St Anne, Blackpool/The Glen/Ballyvolane and Sunday's Well", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Most Precious Blood, Clogheen", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Church of the Real Presence, Curraheen", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Carrigaline Parish", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Ballincollig Parish", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Parish of Douglas & Rochestown", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Sacred Heart Church, Cork", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Ballinlough Parish", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Bantry Parish", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Kinsale Parish", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Harbour Parishes", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Christ the King, Turner's Cross", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Holy Family, Caheragh", Diocese: "Diocese of Cork and Ross" },
  { Country: "Ireland", Parish: "Greystones & Kilquade Parishes", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Beaumont, Dublin Ireland", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St. Mark's, Springfield, St Aidan's, Brookfield and St Thomas the Apostle, Jobstown", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Our Lady of Good Counsel, Johnstown Killiney", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Holy Cross, Dundrum", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Ascension of the Lord, Balally", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Blessington Parish", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St. Brigid's, Cabinteely", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Divine Mercy, Lucan South Parish", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Donabate, Portrane & Balheary Parish", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St. Micheal's, Dun Laoghaire", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Immaculate Heart of Mary Parish, Enniskerry", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Pastoral Area of Garristown, Naul & Rolestown", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "North Wall Parish", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Sacred Heart Church, Donnybroook", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St. Mary's, Lucan", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St. Peter's Parish, Bray", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St. Teresa's, Charendon Street", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Arklow Parish", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Combined Catholic Parishes of Clondalkin", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St. Martin de Porres, Tallaght", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Our Lady Queen of Peace, Bray", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Church of St Ciarán, Hartstown", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Our Lady of Victories, Sallynoggin and Glenageary", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Christ the King Cabra", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St Vincent de Paul, Marino", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Our Lady of Good Counsel, Ballyboden", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St John the Evangelist, Ballinteer", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Parish of St Thomas the Apostle, Laurel Lodge/Carpenterstown", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Our Lady Mother of the Church, Castlerock", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Ballybrack-Killiney Parish", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Sacred Heart of Jesus, Huntstown", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "St Sylvester's Parish Malahide", Diocese: "Archdiocese of Dublin" },
  { Country: "Ireland", Parish: "Howth Parish - Church of the Assumption", Diocese: "Archdiocese of Dublin" },
  { Country: "Portugal", Parish: "Senhora da Conceição, Aveiro Portugal", Diocese: "Diocese of Aveiro" },
  { Country: "Portugal", Parish: "Fonte de Angeão", Diocese: "Diocese of Aveiro" },
  { Country: "Portugal", Parish: "Marinha Grande, Portugal", Diocese: "Diocese of Leiria-Fátima" },
  { Country: "Portugal", Parish: "Alqueidão da Serra", Diocese: "Diocese of Leiria-Fátima" },
  { Country: "Portugal", Parish: "Vermoil and Meirinhas", Diocese: "Diocese of Leiria-Fátima" },
  { Country: "Australia", Parish: "Belmont North Redhead, NSW Australia", Diocese: "Anglican Diocese Of Newcastle" },
  { Country: "Australia", Parish: "East Lake Macquarie Anglican", Diocese: "Anglican Diocese Of Newcastle" },
  { Country: "Portugal", Parish: "São Victor, Braga Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Nossa Senhora da Oliveira, Braga, Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Nossa Senhora da Conceição, Braga, Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Ferreiró, Outeiro Maior and Parada, Porto, Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Santa Eulália de Fafe e Santa Comba de Fornelos, Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "São Vicente, Braga, Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "São Miguel de Gualtar / Este São Mamede, Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Santa Maria, Nossa Senhora da Expectação, Santa Cecilia, Portugal", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Arcos, Escudeiros, Esporoes, Lamas, Morreira, Penso, Trandeiras", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Fafe, Santa Eulália", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Ferreiró Outeriro Parada", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Divino Salvador, Joane", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Santa Eufémia Church of Prazins", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Idreja de Priscos", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Vila Nova de Famalicão, Santo Adrião", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "São Tiago de Esporões", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Paróquia de Brufe (São Martinho)", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Paróquia São João Baptista de Vila do Conde", Diocese: "Archdiocese of Braga" },
  { Country: "Portugal", Parish: "Paróquia de Santa Maria de Arnoso", Diocese: "Archdiocese of Braga" },
  { Country: "Spain", Parish: "Sant Pere, Tarragona, Spain", Diocese: "Diocese of Tortosa" },
  { Country: "Portugal", Parish: "S. Jose and S. Jean Baptiste, Coimbra, Portugal", Diocese: "Diocese of Coimbra" },
  { Country: "Portugal", Parish: "Paróquia de São José", Diocese: "Diocese of Coimbra" },
  { Country: "Portugal", Parish: "São Pedro de Alva", Diocese: "Diocese of Coimbra" },
  { Country: "United States", Parish: "Cathedral of the Epiphany", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "All Saints Parish", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Holy Name Parish", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Holy Mary Cluster", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Resurrection of Our Lord", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Pilgrim Cluster", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "St. Mary's", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Good Shepherd", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Holy Trinity Parish", Diocese: "Diocese of Sioux City" },
  { Country: "United States", Parish: "Mater Dei Parish", Diocese: "Diocese of Sioux City" },
  { Country: "United Kingdom", Parish: "Ardstraw West & Castlederg, Derry, N. Ireland, UK", Diocese: "Diocese of Derry" },
  { Country: "Ireland", Parish: "Moville, County Donegal, Ireland", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Parish of Cumber Upper Learmount", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Parish of Ballinascreen", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Dungiven Parish", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Bellaghy-Ballyscullion Parish", Diocese: "Diocese of Derry" },
  { Country: "Ireland", Parish: "Parish of Buncrana", Diocese: "Diocese of Derry" },
  { Country: "Ireland", Parish: "Iskaheen Parish", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Steeltown Parish", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Holy Family, Derry", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Cappagh Parish", Diocese: "Diocese of Derry" },
  { Country: "Ireland", Parish: "Milan Parish", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Culmore Parish", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Parishes of Glendermott and Strathfoyle", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "Three Patrons Parish", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "DRUMRAGH PARISH", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "The Parish of Dunboe, Macosquin & Aghadowey", Diocese: "Diocese of Derry" },
  { Country: "United Kingdom", Parish: "St Eugene's Cathedral", Diocese: "Diocese of Derry" },
  { Country: "Portugal", Parish: "Alcanede, Portugal", Diocese: "Diocese de Santarém" },
  { Country: "Portugal", Parish: "Alcanede", Diocese: "Diocese de Santarém" },
  { Country: "Portugal", Parish: "Santarém", Diocese: "Diocese de Santarém" },
  { Country: "Portugal", Parish: "Fazendas de Almeirim", Diocese: "Diocese de Santarém" },
  { Country: "Netherlands", Parish: "St. Joris parish, Eindhoven", Diocese: "Den Bosch" },
  { Country: "Portugal", Parish: "Silves, Algarve, Portugal", Diocese: "Diocese of Faro" },
  { Country: "Portugal", Parish: "Monchique, Algarve, Portugal", Diocese: "Diocese of Faro" },
  { Country: "United States", Parish: "St. Francis of Assisi, Bend, Oregon, USA", Diocese: "Diocese of Baker" },
  { Country: "United States", Parish: "St John the Baptist, Savage, Min, USA", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Church of St. Joseph", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Cathedral of St. Paul", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. Albert the Great", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Church of St. Henry", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Church of St. Mark", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. Odilia Church", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Holy Family", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Mary, Mother of the Church", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Saint Bonaventure", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. Hubert", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. John Neumann", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. Joseph Catholic Church", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. Mary of the Lake", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St Anne, Le Sueur", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Church of the Immaculate Conception,", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "St. Boniface Catholic Church", Diocese: "Archdiocese of St Paul & Minneapolis" },
  { Country: "United States", Parish: "Holy Cross Catholic Church", Diocese: "Diocese of Orlando" },
  { Country: "United States", Parish: "Holy Redeemer Catholic Church", Diocese: "Diocese of Orlando" },
  { Country: "United States", Parish: "Nativity Catholic Church", Diocese: "Diocese of Orlando" },
  { Country: "United States", Parish: "Queen of Peace", Diocese: "Diocese of Orlando" },
  { Country: "United States", Parish: "St. Theresa", Diocese: "Diocese of Orlando" },
  { Country: "United States", Parish: "St. Frances Xavier Cabrini, Lake Nona", Diocese: "Diocese of Orlando" },
  { Country: "United States", Parish: "St. Thomas Aquinas, St Cloud, FL", Diocese: "Diocese of Orlando" },
  { Country: "United States", Parish: "St Pius by", Diocese: "Diocese of Owensboro" },
  { Country: "United States", Parish: "St. Mary of the Woods", Diocese: "Diocese of Owensboro" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Diocese of Owensboro" },
  { Country: "United States", Parish: "St. Francis de Sales", Diocese: "Diocese of Owensboro" },
  { Country: "United States", Parish: "Sts. Joseph & Paul Catholic Church", Diocese: "Diocese of Owensboro" },
  { Country: "United States", Parish: "St. Stephen Cathedral", Diocese: "Diocese of Owensboro" },
  { Country: "United States", Parish: "St. Peter of Alcantara", Diocese: "Diocese of Owensboro" },
  { Country: "United States", Parish: "Holy Trinity/Historic Saint Patrick", Diocese: "Diocese of Peoria" },
  { Country: "United States", Parish: "Epiphany Parish, Normal IL", Diocese: "Diocese of Peoria" },
  { Country: "United States", Parish: "Holy Trinity and Historic St. Patrick", Diocese: "Diocese of Peoria" },
  { Country: "United States", Parish: "St. Matthew's", Diocese: "Diocese of Peoria" },
  { Country: "United States", Parish: "Holy Cross, Mendota, Illinois", Diocese: "Diocese of Peoria" },
  { Country: "United States", Parish: "St. Ann Catholic Church, Peoria, IL", Diocese: "Diocese of Peoria" },
  { Country: "United States", Parish: "St. Patrick of Merna", Diocese: "Diocese of Peoria" },
  { Country: "United States", Parish: "Corpus Christi Catholic Church", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "Our Lady of Joy", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "Our Lady of Perpetual Help", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Thomas the Apostle", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Joan of Arc", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Francis Xavier", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Gabriel Catholic Church", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Bernadette Parish Scottsdale", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Thomas More Parish Glendale", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Patrick Catholic Community, Scottsdale", Diocese: "Diocese of Phoenix" },
  { Country: "United States", Parish: "St. Michael Catholic Church", Diocese: "Archdiocese of Anchorage-Juneau" },
  { Country: "United States", Parish: "Immaculate Heart of Mary", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "Light of the World", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "Spirit of Christ", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "St. Louis Louisville", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "St. Augustine", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "St. Catherine of Siena, Denver", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "Risen Christ Catholic Church, Denver", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "st pius x parish", Diocese: "Archdiocese of Denver" },
  { Country: "United States", Parish: "Church of the Holy Family", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "National Shrine of the Little Flower", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Guardian Angels Catholic Church", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St Raymond-OLGC Catholic Church", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St. Christopher", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St. Scholastica", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St. John Neumann", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Christ the Redeemer", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Church of the Transfiguration", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Immaculate Conception", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Our Lady of Victory", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Sacred Heart Church", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Ste. Anne", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St. John the Evangelist", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St. Mary Queen of Creation", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St. Fabian, Farmington Hills", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "St Lucy", Diocese: "Archdiocese of Detroit" },
  { Country: "United States", Parish: "Epiphany Parish", Diocese: "Archdiocese of Dubuque" },
  { Country: "United States", Parish: "Notre Dame Parish", Diocese: "Archdiocese of Dubuque" },
  { Country: "United States", Parish: "Cathedral of St. Raphael & St. Patrick", Diocese: "Archdiocese of Dubuque" },
  { Country: "United States", Parish: "St Joseph the Worker, Dubuque", Diocese: "Archdiocese of Dubuque" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton, Hiawatha", Diocese: "Archdiocese of Dubuque" },
  { Country: "United States", Parish: "Church of the Ascension", Diocese: "Archdiocese of Kansas City in Kansas" },
  { Country: "United States", Parish: "Curé of Ars Catholic Church", Diocese: "Archdiocese of Kansas City in Kansas" },
  { Country: "United States", Parish: "Church of the Nativity", Diocese: "Archdiocese of Kansas City in Kansas" },
  { Country: "United States", Parish: "Holy Trinity Catholic Parish", Diocese: "Archdiocese of Kansas City in Kansas" },
  { Country: "United States", Parish: "Prince of Peace Catholic Communtiy", Diocese: "Archdiocese of Kansas City in Kansas" },
  { Country: "United States", Parish: "Our Lady of Grace", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "Our Lady of the Assumption Catholic Church", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Bede the Venerable", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Dominic", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Vincent de Paul", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Ferdinand", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Rosa of Lima", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "Our Lady of Guadalupe Hermosa Beach", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "Our Lady of Lourdes, Northridge", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "Our Saviour", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. John Eudes", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Philip the Apostle", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Denis Church", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Peter Claver, Simi Valley", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St John Fisher, Rancho Palos Verdes", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St Louise De Marillac, Covina", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St. Cornelius, Long Beach", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St Maximilian Kolbe, Westlake Village", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "St Jude the Apostle", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "Blessed Sacrament Hollywood", Diocese: "Archdiocese of Los Angeles" },
  { Country: "United States", Parish: "Catholic Church of the Incarnation", Diocese: "Archdiocese of Louisville" },
  { Country: "United States", Parish: "Saint Christopher", Diocese: "Archdiocese of Louisville" },
  { Country: "United States", Parish: "Catholic Church of the Incarnation", Diocese: "Archdiocese of Louisville" },
  { Country: "United States", Parish: "St. Edward Catholic Church, Jeffersontown, KY", Diocese: "Archdiocese of Louisville" },
  { Country: "United States", Parish: "Blessed Trinity Church, Miami", Diocese: "Archdiocese of Miami" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Archdiocese of Miami" },
  { Country: "United States", Parish: "Mary Help of Christians", Diocese: "Archdiocese of Miami" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Archdiocese of Miami" },
  { Country: "United States", Parish: "St. Isaac Jogues", Diocese: "Archdiocese of Miami" },
  { Country: "United States", Parish: "St. Pius X Catholic Church", Diocese: "Archdiocese of Miami" },
  { Country: "United States", Parish: "St John Neumann", Diocese: "Archdiocese of Miami" },
  { Country: "United States", Parish: "St Catherine of Siena, Mobile, Alabama, USA", Diocese: "Archdiocese of Mobile" },
  { Country: "United States", Parish: "St. John the Evangelist", Diocese: "Archdiocese of Mobile" },
  { Country: "United States", Parish: "St. Columba Parish", Diocese: "Archdiocese of Mobile" },
  { Country: "United States", Parish: "City of St. Jude Parish", Diocese: "Archdiocese of Mobile" },
  { Country: "United States", Parish: "St. Patrick's Parish, Robertsdale", Diocese: "Archdiocese of Mobile" },
  { Country: "United States", Parish: "Holy Name of Jesus Church", Diocese: "Archdiocese of New Orleans" },
  { Country: "United States", Parish: "St. Mary Magdalen", Diocese: "Archdiocese of New Orleans" },
  { Country: "United States", Parish: "St Teresa of Avila", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Community of God's Love", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Parish Community of Saint Helen", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Christ of the Annuciation", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Church of the Little Flower", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Holy Trinity", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Our Lady of Sorrows", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "St. Aloysius Church", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel, Orange NJ", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Blessed Sacrament-St Charles Borromeo", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "St Pius X, Old Tappan NJ", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Immaculate Heart of Mary, Scotch Plains", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "St Joseph's, East Rutherford", Diocese: "Archdiocese of Newark" },
  { Country: "United States", Parish: "Church of the Epiphany - Oklahoma", Diocese: "Archdiocese of Oklahoma City" },
  { Country: "United States", Parish: "Church of the Epiphany", Diocese: "Archdiocese of Oklahoma City" },
  { Country: "United States", Parish: "Catholic Pastoral Centre", Diocese: "Archdiocese of Oklahoma City" },
  { Country: "United States", Parish: "St. Andrew the Apostle", Diocese: "Archdiocese of Oklahoma City" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Archdiocese of Oklahoma City" },
  { Country: "United States", Parish: "Holy Spirit, Mustang", Diocese: "Archdiocese of Oklahoma City" },
  { Country: "United States", Parish: "St. Anastasia", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Old St. Joseph's", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Corpus Christi Catholic Church", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Our Mother of Consolation Parish", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "St. Patrick", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Mary Mother of the Redeemer", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Our Lady of Guadalupe", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Our Lady of Peace", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Queen of Peace", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "St. Denis", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "St. Laurence", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "St. Thomas of Villanova", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Visitation of the Blessed Virgin Mary, Norristown", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "Ss. Peter and Paul, West Chester", Diocese: "Archdiocese of Philadelphia" },
  { Country: "United States", Parish: "St. Pius X", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "Holy Redeemer Catholic Church", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St. Cecilia Church", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St. John the Apostle", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St. Michael the Archangel", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St. Anthony, Tiguard OR", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "Holy Trinity Beaverton OR", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St. Matthew, Hillsboro", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St Monica, Coos Bay", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St Helen, Sweet Home", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St Paul, Silverton", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St Mary Catholic Church - Oregon", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "St Ann Catholic Church - Oregon", Diocese: "Archdiocese of Portland in Oregon" },
  { Country: "United States", Parish: "Holy Trinity", Diocese: "Archdiocese of San Antonio" },
  { Country: "United States", Parish: "St. Lawrence", Diocese: "Archdiocese of San Antonio" },
  { Country: "United States", Parish: "Notre Dame", Diocese: "Archdiocese of San Antonio" },
  { Country: "United States", Parish: "Church of the Epiphany", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St Anthony", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Agnes", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Matthias", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Anthony", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Pius Church", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Raymond's", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "Saint Bartholomew Parish Community", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Finn Barr, San Francisco", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St Paul of the Shipwreck", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Stephen", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "St. Raphael Parish", Diocese: "Archdiocese of San Francisco" },
  { Country: "United States", Parish: "San Clemente, Los Lunas", Diocese: "Archdiocese of Santa Fe" },
  { Country: "United States", Parish: "St. Anthony's, Columbus", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Pax Christi", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "St. Gerard Catholic Church", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Church of Corpus Christi", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Church of St. Edward", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Church of the Immaculate Conception", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "St. Peter Kirkwood", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Shrine of St. Joseph", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "St. Margaret Mary Alacoque", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Assumption of the Blessed Virgin Mary", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "St. Justin Martyr", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "St. Alban Roe", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "St. Patrick Church", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Saint Joseph Parish, Manchester, USA", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "Annunciation, Webster Groves", Diocese: "Archdiocese of St. Louis" },
  { Country: "United States", Parish: "St. Jane Frances de Chantal", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "Shrine of the Immaculate Conception", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "Church of the Incarnation", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "St. Pius X Church", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "St. Andrew Apostle", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "Our Lady of Mercy", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "St. Mary's of Piscataway", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "Saint Gabriel Parish", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "St. Michael Parish, Brandywine, MD", Diocese: "Archdiocese of Washington" },
  { Country: "United States", Parish: "Christ Church Albany", Diocese: "Diocese of Albany" },
  { Country: "United States", Parish: "St. Ambrose", Diocese: "Diocese of Albany" },
  { Country: "United States", Parish: "St. Paul the Apostle", Diocese: "Diocese of Albany" },
  { Country: "United States", Parish: "St. Joseph's Church, Coopersburg", Diocese: "Diocese of Allentown" },
  { Country: "United States", Parish: "Our Mother of Sorrows", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Holy Spirit Parish", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "St. John the Evangelist, Altoona", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "St. Peter's", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Our Lady of Victory", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "St. Michael the Archangel", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "St. Thérèse Of The Child Jesus, Altoona", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "St. Michael the Archangel, Hollidaysburg", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Holy Family Parish, Portage", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Church of the Good Shepherd", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Saints Peter and Paul Parish, Philipsburg", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Saint Patrick Parish, Johnstown", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Saint John the Evangelist Parish, Bellefonte", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Saint Francis of Assisi Parish, Johnstown", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Saint John the Baptist Parish, New Baltimore", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Saint John the Evangelist Parish, Lakemont", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "Saint John Vianney Parish, Mundy's Corner", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "St. Benedict Church", Diocese: "Diocese of Altoona-Johnstown" },
  { Country: "United States", Parish: "St. Thomas the Apostle Parish", Diocese: "Diocese of Amarillo" },
  { Country: "United States", Parish: "Holy Trinity Catholic Church", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "Our Lady of Hope", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Philip the Apostle", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. John Neumann", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. John the Beloved", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Mark Catholic Church", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "Corpus Christi, South Riding", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St Agnes, Arlington", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "Holy Spirit, Annandale", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Catherine of Siena, Great Falls", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "Nativity Catholic Church", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Louis Parish", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Mary of Sorrows Catholic Church", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Joseph Catholic Church", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. Isidore the Farmer", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "Sacred Heart of Jesus Catholic Church", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church", Diocese: "Diocese of Arlington" },
  { Country: "United States", Parish: "St. William - Round Rock, TX", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "Saint Jose", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "Saint Mary Cathedral", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Mary's of the Visitation", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. John Neumann", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Luke Catholic Parish", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Patrick Church", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Patrick Church", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Luke UMC", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Luke, Temple, Texas", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Joseph Catholic Church", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Theresa, Austin", Diocese: "Diocese of Austin" },
  { Country: "United States", Parish: "St. Thomas More", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "St. Jude the Apostle", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "St James, LA", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "Sacred Heart of Jesus, Baton Rouge", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "Church of the Immaculate Conception", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "St. Theresa of Avila", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "Our Lady of Prompt Succor, White Castle", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "St Theresa of Avila", Diocese: "Diocese of Baton Rouge" },
  { Country: "United States", Parish: "Our Lady of Victory", Diocese: "Diocese of Beaumont" },
  { Country: "United States", Parish: "St. Elizabeth", Diocese: "Diocese of Beaumont" },
  { Country: "United States", Parish: "St. Elizabeth", Diocese: "Diocese of Beaumont" },
  { Country: "United States", Parish: "Immaculate Conception Parish", Diocese: "Diocese of Belleville" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Belleville" },
  { Country: "United States", Parish: "St Bonifae Parish - Indiana", Diocese: "Diocese of Belleville" },
  { Country: "United States", Parish: "Our Mother of Sorrows", Diocese: "Diocese of Biloxi" },
  { Country: "United States", Parish: "St. James Catholic Church, Gulfport, MS", Diocese: "Diocese of Biloxi" },
  { Country: "United States", Parish: "Cathedral of Saint Paul", Diocese: "Diocese of Birmingham" },
  { Country: "United States", Parish: "Our Lady of the Valley, Fort Payne", Diocese: "Diocese of Birmingham" },
  { Country: "United States", Parish: "Corpus Christi", Diocese: "Diocese of Bismarck" },
  { Country: "United States", Parish: "St. John the Baptist and St. Mary of the Assumption", Diocese: "Diocese of Bismarck" },
  { Country: "United States", Parish: "Our Lady of the Rosary", Diocese: "Diocese of Boise" },
  { Country: "United States", Parish: "Holy Apostles, Meridian", Diocese: "Diocese of Boise" },
  { Country: "United States", Parish: "St. Catherine of Siena", Diocese: "Diocese of Bridgeport" },
  { Country: "United States", Parish: "St. Andrew", Diocese: "Diocese of Bridgeport" },
  { Country: "United States", Parish: "St. Mary, Ridgefield", Diocese: "Diocese of Bridgeport" },
  { Country: "United States", Parish: "Saint Mary R.C. Church", Diocese: "Diocese of Bridgeport" },
  { Country: "United States", Parish: "Our Lady of the Presentation-Mercy", Diocese: "Diocese of Brooklyn" },
  { Country: "United States", Parish: "Saint Thomas the Apostle", Diocese: "Diocese of Brooklyn" },
  { Country: "United States", Parish: "St. Nicholas of Tolentine", Diocese: "Diocese of Brooklyn" },
  { Country: "United States", Parish: "St Thomas More", Diocese: "Diocese of Brooklyn" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Diocese of Brooklyn" },
  { Country: "United States", Parish: "St. Robert Bellarmine", Diocese: "Diocese of Brooklyn" },
  { Country: "United States", Parish: "Most Holy Trinity - St. Mary", Diocese: "Diocese of Brooklyn" },
  { Country: "United States", Parish: "The Lord of Divine Mercy, Brownsville, TX", Diocese: "Diocese of Brownsville" },
  { Country: "United States", Parish: "Our Lady of the Blessed Sacrament", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Catholic Community of Christ Our Light", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Christ the Redeemer", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Christ of the Incarnation", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Our Lady of Guadalupe", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Our Lady of Peace", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Our Lady of the Lakes", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Saint Charles Borromeo", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Saint Damien", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Saints Peter and Paul Parish", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "St. Bridget University Parish", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "St. Joseph's", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "St. Mary's", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "St. Vincent de Paul", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "St Mary of Mt Carmel", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Holy Eucharist, Cherry Hill", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "Infant Jesus Parish", Diocese: "Diocese of Camden" },
  { Country: "United States", Parish: "St Mary Magdalene Catholic Church", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "Saint Mary Magdalene Catholic Church", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Mary Magdelene", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Mary of the Assumption", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Philip Neri", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Peter's", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Gregory the Great", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "Catholic Community Carolina Forest", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Mary of the Assumption", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Philip Neri", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Peter's", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Gregory the Great", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "Catholic Community Carolina Forest", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St Peter’s, Beaufort", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "Catholic Community at Carolina Forest", Diocese: "Diocese of Charleston" },
  { Country: "United States", Parish: "St. Gabriel Church", Diocese: "Diocese of Charlotte" },
  { Country: "United States", Parish: "St. Luke, Mint Hill", Diocese: "Diocese of Charlotte" },
  { Country: "United States", Parish: "St Paul's Newman Center", Diocese: "Diocese of Cheyenne" },
  { Country: "United States", Parish: "Holy Martyrs", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Clarence Parish", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Hiliary", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Paul Parish", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Gabriel", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Andrew Abbey", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Helen", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Edward Parish", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Francis Xavier", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Peter Church, North Ridgeville", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "Divine Word, Kirtland", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "Church of the Assumption, Broadview Heights", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "St. Catharine, Columbus", Diocese: "Diocese of Cleveland" },
  { Country: "United States", Parish: "Saint Benedict Parish - Colorado", Diocese: "Diocese of Colorado Springs" },
  { Country: "United States", Parish: "Catherine of Siena Institute", Diocese: "Diocese of Colorado Springs" },
  { Country: "United States", Parish: "Our Lady of the Pines Catholic Community", Diocese: "Diocese of Colorado Springs" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Diocese of Colorado Springs" },
  { Country: "United States", Parish: "St. Joseph Catholic Church", Diocese: "Diocese of Colorado Springs" },
  { Country: "United States", Parish: "St. Thomas More", Diocese: "Diocese of Corpus Christi" },
  { Country: "United States", Parish: "Our Lady of Refuge", Diocese: "Diocese of Corpus Christi" },
  { Country: "United States", Parish: "Society of Our Lady of the Most Holy Trinity (SOLT)", Diocese: "Diocese of Corpus Christi" },
  { Country: "United States", Parish: "Most Precious Blood, Corpus Christi", Diocese: "Diocese of Corpus Christi" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Covington" },
  { Country: "United States", Parish: "Cathedral Basilica of the Assumption", Diocese: "Diocese of Covington" },
  { Country: "United States", Parish: "Holy Cross, Covington", Diocese: "Diocese of Covington" },
  { Country: "United States", Parish: "Christ the King, Dallas", Diocese: "Diocese of Covington" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Diocese of Davenport" },
  { Country: "United States", Parish: "Sts. Mary and Mathias, Muscatine IA", Diocese: "Diocese of Davenport" },
  { Country: "United States", Parish: "St. Patrick", Diocese: "Diocese of Davenport" },
  { Country: "United States", Parish: "Sacred Heart", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Christ the King Des Moines", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Our Lady's Immaculate Heart", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Saint Boniface", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Saint Francis of Assisi", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Ss. John and Paul", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "St. Pius X", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "St. Theresa of the Child Jesus", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "St. Boniface", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "St. Raphael", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Assumption of the Blessed Virgin Mary", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Basilica of Saint John", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "St. Ambrose Cathedral", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "St. Joseph Parish, Des Moines", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Corpus Christi, Council Bluffs", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Holy Rosary Parish, Glenwood Iowa", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "St Mary of Nazareth, Des Moines", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Holy Trinity Parish - Des Moines", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Holy Spirit Parish", Diocese: "Diocese of Des Moines" },
  { Country: "United States", Parish: "Blessed Sacrament", Diocese: "Diocese of Duluth" },
  { Country: "United States", Parish: "St. Agnes & Sacred Heart", Diocese: "Diocese of Duluth" },
  { Country: "United States", Parish: "St. Benedict", Diocese: "Diocese of Duluth" },
  { Country: "United States", Parish: "Santa Rosa de Lima Catholic Church", Diocese: "Diocese of El Paso" },
  { Country: "United States", Parish: "Sacred Heart", Diocese: "Diocese of Erie" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Erie" },
  { Country: "United States", Parish: "St. Bartholomew", Diocese: "Diocese of Erie" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Diocese of Evansville" },
  { Country: "United States", Parish: "Holy Rosary, Evansville", Diocese: "Diocese of Evansville" },
  { Country: "United States", Parish: "Saints Peter and Paul, Haubstadt, IN", Diocese: "Diocese of Evansville" },
  { Country: "United States", Parish: "Good Shepherd Parish", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "St. Joseph-St. Therese Church", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Saint Mary of the Assumption", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Catholic Community of New Bedford North", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Christ the King", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "St. Mary's Norton", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Espirito Santo, Fall River", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Holy Redeemer Fall River", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Taunton Catholic North", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "St. John of God", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "St. Dominic Parish", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Holy Trinity Parish", Diocese: "Diocese of Fall River" },
  { Country: "United States", Parish: "Holy Spirit Catholic Church", Diocese: "Diocese of Fargo" },
  { Country: "United States", Parish: "St. John's Catholic Church", Diocese: "Diocese of Fort Wayne-South Bend" },
  { Country: "United States", Parish: "St. Matthew Cathedral", Diocese: "Diocese of Fort Wayne-South Bend" },
  { Country: "United States", Parish: "St Thérèse Little Flower, South Bend", Diocese: "Diocese of Fort Wayne-South Bend" },
  { Country: "United States", Parish: "St Vincent de Paul", Diocese: "Diocese of Fort Wayne-South Bend" },
  { Country: "United States", Parish: "Holy Family Kingsburg", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "Our Lady of Perpetual Help", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "Our Lady of Victory", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "St. John's Cathedral", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "Saint Clemente Mission Parish", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "St. Ann, Riverdale", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "St. Mary of the Miraculous Medal", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "St. Philip the Apostle", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "St. Anthony of Padua", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "St Joseph's, Mariposa", Diocese: "Diocese of Fresno" },
  { Country: "United States", Parish: "Christ the King, Acme, MI", Diocese: "Diocese of Gaylord" },
  { Country: "United States", Parish: "Sts. Peter & Paul", Diocese: "Diocese of Grand Island" },
  { Country: "United States", Parish: "St. Mary's Cathedral, Grand Island", Diocese: "Diocese of Grand Island" },
  { Country: "United States", Parish: "St. Leo's, Grand Island", Diocese: "Diocese of Grand Island" },
  { Country: "United States", Parish: "All Saints Catholic Church", Diocese: "Diocese of Grand Rapids" },
  { Country: "United States", Parish: "Our Lady of Consolation", Diocese: "Diocese of Grand Rapids" },
  { Country: "United States", Parish: "St. Francis de Sales", Diocese: "Diocese of Grand Rapids" },
  { Country: "United States", Parish: "St. Patrick - St. Anthony", Diocese: "Diocese of Grand Rapids" },
  { Country: "United States", Parish: "St. John Vianney", Diocese: "Diocese of Grand Rapids" },
  { Country: "United States", Parish: "St. Paul the Apostle Catholic Church", Diocese: "Diocese of Grand Rapids" },
  { Country: "United States", Parish: "Immaculate Conception Church, Wolf Point,MT", Diocese: "Diocese of Great Falls-Billings" },
  { Country: "United States", Parish: "Holy Family Parish", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Kaukauna Catholic Parishes", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Resurrection Catholic Parish", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St Mary of the Angels", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Margaret Mary", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Most Blessed Sacrament", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Ss Peter & Paul", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Bernard", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Edward the Confessor", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Ss Mary & John the Baptist", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Wenceslaus", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Bridget Catholic Community", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Clare Parish", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Mary Catholic Community", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Thomas More", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Stella Maris, Egg Harbor", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St Philip the Apostle, Green Bay", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Our Lady of Lourdes Parish Family", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Mary Luxemburg", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Prince of Peace Catholic Community, Green Bay", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Joseph Parish, Wautoma", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "St. Mary & SS. Joseph & Edward Parishes", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Church of St. Mary Magdalene, Waupaca, WI", Diocese: "Diocese of Green Bay" },
  { Country: "United States", Parish: "Saint Benedict the Abbot", Diocese: "Diocese of Harrisburg" },
  { Country: "United States", Parish: "St. Paul the Apostle", Diocese: "Diocese of Harrisburg" },
  { Country: "United States", Parish: "Sacred Heart of Jesus", Diocese: "Diocese of Harrisburg" },
  { Country: "United States", Parish: "St. Francis Xavier, Gettysburg", Diocese: "Diocese of Harrisburg" },
  { Country: "United States", Parish: "Corpus Christi", Diocese: "Diocese of Harrisburg" },
  { Country: "United States", Parish: "Christ the King Church", Diocese: "Diocese of Helena" },
  { Country: "United States", Parish: "St. Thomas", Diocese: "Diocese of Helena" },
  { Country: "United States", Parish: "Sts Peter and Paul", Diocese: "Diocese of Honolulu" },
  { Country: "United States", Parish: "Saint Theresa Church, Kihei", Diocese: "Diocese of Honolulu" },
  { Country: "United States", Parish: "St. Peter", Diocese: "Diocese of Jefferson City" },
  { Country: "United States", Parish: "St. Pius X, Moberly", Diocese: "Diocese of Jefferson City" },
  { Country: "United States", Parish: "St. Mary's Catholic Church", Diocese: "Diocese of Kalamazoo" },
  { Country: "United States", Parish: "St. Mary's Assumption, Bronson, MI", Diocese: "Diocese of Kalamazoo" },
  { Country: "United States", Parish: "St. Joseph Catholic Church", Diocese: "Diocese of Kalamazoo" },
  { Country: "United States", Parish: "Cathedral of the Most Sacred Heart of Jesus, Knoxville", Diocese: "Diocese of Knoxville" },
  { Country: "United States", Parish: "St Dominic Parish, Kingsport, TN", Diocese: "Diocese of Knoxville" },
  { Country: "United States", Parish: "St. Bronislava Catholic Church", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Saint Patrick", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Saint Bronislava", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "St. Adalbert & St. Mary-Torun", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "St. Mary Parish", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "St. Mary's Catholic Church", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Holy Name of Jesus Parish", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Holy Spirit Parish", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "St. Mary & St. Bartholomew", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "ST. RAYMOND OF PEÑAFORT PARISH", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Our Lady of the Falls, Chippewa Falls", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Immaculate Conception-St Joseph Fairview", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Christ the King Parish, Spencer", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Mary, Mother of the Church Parish, La Crosse", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Sacred Heart Parish, Cashton", Diocese: "Diocese of La Crosse" },
  { Country: "United States", Parish: "Church of the Blessed Sacrament", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "St. Thomas Aquinas", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "St. Patrick Parish", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "St. Thomas Aquinas", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "St. Alphonsus Liguori", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "St. Louis de Montfort", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "The Anderson-Alexandria Pastorate", Diocese: "Diocese of Lafayette in Indiana" },
  { Country: "United States", Parish: "Sacred Heart of Jesus (Broussard, LA)", Diocese: "Diocese of Lafayette in Louisiana" },
  { Country: "United States", Parish: "St. Pius X", Diocese: "Diocese of Lafayette in Louisiana" },
  { Country: "United States", Parish: "Our Lady Queen of All Saints", Diocese: "Diocese of Lafayette in Louisiana" },
  { Country: "United States", Parish: "Our Lady Queen of Angels", Diocese: "Diocese of Lafayette in Louisiana" },
  { Country: "United States", Parish: "St. Bernard, Breaux Bridge", Diocese: "Diocese of Lafayette in Louisiana" },
  { Country: "United States", Parish: "St. Joseph Apache Mission", Diocese: "Diocese of Las Cruces" },
  { Country: "United States", Parish: "Sacred Heart", Diocese: "Diocese of Lexington" },
  { Country: "United States", Parish: "St. Mark", Diocese: "Diocese of Lexington" },
  { Country: "United States", Parish: "Cathedral of Christ the King", Diocese: "Diocese of Lexington" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton - Lexington", Diocese: "Diocese of Lexington" },
  { Country: "United States", Parish: "Saint Peter's and Saint Joseph's", Diocese: "Diocese of Lincoln" },
  { Country: "United States", Parish: "St. Patrick's Lincoln", Diocese: "Diocese of Lincoln" },
  { Country: "United Kingdom", Parish: "All Saints, Monks Road", Diocese: "Diocese of Lincoln" },
  { Country: "United States", Parish: "Christ the King", Diocese: "Diocese of Little Rock" },
  { Country: "United States", Parish: "Our Lady of the Lakes", Diocese: "Diocese of Little Rock" },
  { Country: "United States", Parish: "St. Joseph Church", Diocese: "Diocese of Little Rock" },
  { Country: "United States", Parish: "Immaculate Heart of Mary Church, North Little Rock", Diocese: "Diocese of Little Rock" },
  { Country: "United States", Parish: "St. John Neumann Lubbock", Diocese: "Diocese of Lubbock" },
  { Country: "United States", Parish: "St. Gabriel in Lake Mills, Wisconsin", Diocese: "Diocese of Madison" },
  { Country: "United States", Parish: "St. Thomas Aquinas - Wisconsin", Diocese: "Diocese of Madison" },
  { Country: "United States", Parish: "St. Christopher Parish", Diocese: "Diocese of Madison" },
  { Country: "United States", Parish: "Good Shepherd Catholic Church", Diocese: "Diocese of Madison" },
  { Country: "United States", Parish: "Sacred Heart Catholic Church", Diocese: "Diocese of Madison" },
  { Country: "United States", Parish: "St. Cecilia", Diocese: "Diocese of Madison" },
  { Country: "United States", Parish: "St. Thomas Aquinas, Madison", Diocese: "Diocese of Madison" },
  { Country: "United States", Parish: "Christ the King - New Hamp", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "Blessed Trinity Cluster", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "Immaculate Conception", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "Divine Mercy Parish", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St. Andre Bessette", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St. Katharine Drexel", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St. Kathryn's", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St. Michael", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St. Mary, Rochester, NH", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "Our Lady of the Holy Rosary & St. Leo", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "Sacred Heart Church", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St. Mary & St. Peter Church", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "Ste. Marie Parish", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St Theresa and St Mary", Diocese: "Diocese of Manchester" },
  { Country: "United States", Parish: "St Christopher - Marquette", Diocese: "Diocese of Marquette" },
  { Country: "United States", Parish: "Our Lady of Mount Virgin", Diocese: "Diocese of Metuchen" },
  { Country: "United States", Parish: "Blessed Sacrament", Diocese: "Diocese of Metuchen" },
  { Country: "United States", Parish: "St. Bernard of Clairvaux, Bridgewater", Diocese: "Diocese of Metuchen" },
  { Country: "United States", Parish: "Corpus Christi, South River", Diocese: "Diocese of Metuchen" },
  { Country: "United States", Parish: "St Mary - Stony Hill", Diocese: "Diocese of Metuchen" },
  { Country: "United States", Parish: "Mary, Mother of God, Hillsborough", Diocese: "Diocese of Metuchen" },
  { Country: "United States", Parish: "Mission San Luis Obispo", Diocese: "Diocese of Monterey" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Diocese of Nashville" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Diocese of Nashville" },
  { Country: "United States", Parish: "Church of the Nativity, Thompsons Station", Diocese: "Diocese of Nashville" },
  { Country: "United States", Parish: "Church of St. Peter", Diocese: "Diocese of New Ulm" },
  { Country: "United States", Parish: "Holy Redeemer. Marshall", Diocese: "Diocese of New Ulm" },
  { Country: "United States", Parish: "St. Therese of Lisieux", Diocese: "Diocese of Norwich" },
  { Country: "United States", Parish: "Cathedral of Christ the Light", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Joan of Arc", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. John Vianney", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Joseph Basilica", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Mary", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Mary of the Immaculate Conception", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "Holy Spirit Catholic Church", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Patrick Oakland", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Anne Catholic Church", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Bonaventure Catholic Community", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Catherine of Siena, Martinez, CA", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St Patrick, West Oakland", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "Church of The Assumption \u200bOf The Blessed Virgin Mary, San Leandro", Diocese: "Diocese of Oakland" },
  { Country: "United States", Parish: "St. Peter's Parish", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Philip Minh", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Anthony's", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Peter's Parish", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Patrick's", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Peter Massena", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Mary's & St. John's", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Agnes, Lake Placid", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Peter's Church - Hammond, NY", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "St. Peter's Parish - Massena, NY", Diocese: "Diocese of Ogdensburg" },
  { Country: "United States", Parish: "Corpus Christi Parish", Diocese: "Diocese of Portland in Maine" },
  { Country: "United States", Parish: "Our Lady of the Lake", Diocese: "Diocese of Portland in Maine" },
  { Country: "United States", Parish: "St. Paul the Apostle", Diocese: "Diocese of Portland in Maine" },
  { Country: "United States", Parish: "Prince of Peace, Lewiston", Diocese: "Diocese of Portland in Maine" },
  { Country: "United States", Parish: "Christ the King", Diocese: "Diocese of Pueblo" },
  { Country: "United States", Parish: "Saint Benedict, Florence, CO", Diocese: "Diocese of Pueblo" },
  { Country: "United States", Parish: "St. Joseph Pueblo", Diocese: "Diocese of Pueblo" },
  { Country: "United States", Parish: "St Joseph, Capulin", Diocese: "Diocese of Pueblo" },
  { Country: "United States", Parish: "Sangre de Cristo", Diocese: "Diocese of Pueblo" },
  { Country: "United States", Parish: "St. Joseph, Grand Junction", Diocese: "Diocese of Pueblo" },
  { Country: "United States", Parish: "St. Mary Catholic Church, Montrose", Diocese: "Diocese of Pueblo" },
  { Country: "United States", Parish: "Cathedral of Our Lady of Perpetual Help", Diocese: "Diocese of Rapid City" },
  { Country: "United States", Parish: "Blessed Sacrament Church", Diocese: "Diocese of Rapid City" },
  { Country: "United States", Parish: "St. Joseph, Spearfish", Diocese: "Diocese of Rapid City" },
  { Country: "United States", Parish: "Blessed Sacrament Church", Diocese: "Diocese of Rapid City" },
  { Country: "United States", Parish: "St. Joseph, Spearfish", Diocese: "Diocese of Rapid City" },
  { Country: "United States", Parish: "Central Black Hills Catholic Parishes", Diocese: "Diocese of Rapid City" },
  { Country: "United States", Parish: "St. Gall", Diocese: "Diocese of Reno" },
  { Country: "United States", Parish: "Cathedral of the Sacred Heart", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Our Lady of Nazareth", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Saint Benedict Catholic Church", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Catholic Church of St. Mark", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Holy Spirit", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Our Lady of Mount Carmel", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Saint Bede Catholic Church", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Cluster Parishes of Portsmouth & Chesapeake", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "St. Gregory the Great, Virginia Beach", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Saint Kateri Tekakwitha, Tabb", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Saint Michael, Glen Allen", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Church of the Ascension, Virginia Beach", Diocese: "Diocese of Richmond" },
  { Country: "United States", Parish: "Saint Kateri", Diocese: "Diocese of Rochester" },
  { Country: "United States", Parish: "Southeast Rochester Catholic Community", Diocese: "Diocese of Rochester" },
  { Country: "United States", Parish: "St. Frances Xavier Cabrini Parish", Diocese: "Diocese of Rochester" },
  { Country: "United States", Parish: "St. Jerome", Diocese: "Diocese of Rochester" },
  { Country: "United States", Parish: "St. Rita", Diocese: "Diocese of Rochester" },
  { Country: "United States", Parish: "St. Louis Church", Diocese: "Diocese of Rochester" },
  { Country: "United States", Parish: "St. Martin de Porres", Diocese: "Diocese of Rochester" },
  { Country: "United States", Parish: "St. Mary", Diocese: "Diocese of Rockford" },
  { Country: "United States", Parish: "St. John Neumann", Diocese: "Diocese of Rockford" },
  { Country: "United States", Parish: "St. Rita", Diocese: "Diocese of Rockford" },
  { Country: "United States", Parish: "St. Patrick Parish", Diocese: "Diocese of Rockford" },
  { Country: "United States", Parish: "St. Thomas More, Elgin", Diocese: "Diocese of Rockford" },
  { Country: "United States", Parish: "Assumption of the Blessed Virgin Mary", Diocese: "Diocese of Rockville Centre" },
  { Country: "United States", Parish: "St. Agnes Cathedral", Diocese: "Diocese of Rockville Centre" },
  { Country: "United States", Parish: "St. Anne's", Diocese: "Diocese of Rockville Centre" },
  { Country: "United States", Parish: "St. James Church", Diocese: "Diocese of Rockville Centre" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Rockville Centre" },
  { Country: "United States", Parish: "Our Lady of Loreto", Diocese: "Diocese of Rockville Centre" },
  { Country: "United States", Parish: "Holy Trinity Parish", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "St. Clare", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "St. Joseph", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "Good Shepherd", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "St. Joseph Marello", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "Th Catholic Foundation", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "Ss. Peter & Paul Church, Rocklin", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "Holy Spirit, Fairfield", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "St John the Baptist, Chico", Diocese: "Diocese of Sacramento" },
  { Country: "United States", Parish: "Holy Family", Diocese: "Diocese of Saginaw" },
  { Country: "United States", Parish: "Blessed Sacrament", Diocese: "Diocese of Saginaw" },
  { Country: "United States", Parish: "St. Agnes Church", Diocese: "Diocese of Saginaw" },
  { Country: "United States", Parish: "Our Lady of Grace", Diocese: "Diocese of Saginaw" },
  { Country: "United States", Parish: "St. Elizabeth of Hungary, Reese", Diocese: "Diocese of Saginaw" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Diocese of Salina" },
  { Country: "United States", Parish: "Christ Prince of Peace Catholic Community", Diocese: "Diocese of Salt Lake City" },
  { Country: "United States", Parish: "St. Joseph the Worker", Diocese: "Diocese of Salt Lake City" },
  { Country: "United States", Parish: "St Ann Parish", Diocese: "Diocese of Salt Lake City" },
  { Country: "United States", Parish: "St. Vincent Pallotti", Diocese: "Diocese of San Angelo" },
  { Country: "United States", Parish: "Our Lady of Guadalupe", Diocese: "Diocese of San Angelo" },
  { Country: "United States", Parish: "St Joseph, San Angelo", Diocese: "Diocese of San Angelo" },
  { Country: "United States", Parish: "St. Mel", Diocese: "Diocese of San Bernardino" },
  { Country: "United States", Parish: "St. Francis of Assisi", Diocese: "Diocese of San Bernardino" },
  { Country: "United States", Parish: "St. Martha Catholic Church", Diocese: "Diocese of San Bernardino" },
  { Country: "United States", Parish: "Our Mother of Confidence", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "St Mary Magdalene - San Diego", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "Sacred Heart OB Parish", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "Saint Brigid", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "St. Rita", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "Immaculate Heart of Mary", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "St. Gabriel Catholic Church", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "Church of St. Luke", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "St. James - St. Leo Catholic Community", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "Holy Trinity, El Cajon", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "St. Patrick Catholic Community, Carlsbad", Diocese: "Diocese of San Diego" },
  { Country: "United States", Parish: "Queen of Peace", Diocese: "Diocese of Santa Rosa" },
  { Country: "United States", Parish: "St. Apollinaris, Napa", Diocese: "Diocese of Santa Rosa" },
  { Country: "United States", Parish: "Cathedral of St John", Diocese: "Diocese of Savannah" },
  { Country: "United States", Parish: "St. John Neumann Parish", Diocese: "Diocese of Scranton" },
  { Country: "United States", Parish: "Church of St. John", Diocese: "Diocese of Scranton" },
  { Country: "United States", Parish: "Our Lady Queen of Peace, Brodheadsville", Diocese: "Diocese of Scranton" },
  { Country: "United States", Parish: "St. Jude Catholic Church", Diocese: "Diocese of Shreveport" },
  { Country: "United States", Parish: "Cathedral of St. Joseph", Diocese: "Diocese of Sioux Falls" },
  { Country: "United States", Parish: "St. Michael", Diocese: "Diocese of Sioux Falls" },
  { Country: "United States", Parish: "St. Michael", Diocese: "Diocese of Sioux Falls" },
  { Country: "United States", Parish: "St. James Parish, Chamberlain, SD", Diocese: "Diocese of Sioux Falls" },
  { Country: "United States", Parish: "Immaculate Conception, Watertown", Diocese: "Diocese of Sioux Falls" },
  { Country: "United States", Parish: "Ss Simon and Jude", Diocese: "Diocese of Springfield in Illinois" },
  { Country: "United States", Parish: "Cathedral of the Immaculate Conception", Diocese: "Diocese of Springfield in Illinois" },
  { Country: "United States", Parish: "Christ the King", Diocese: "Diocese of Springfield in Illinois" },
  { Country: "United States", Parish: "Jersey County Catholic Churches", Diocese: "Diocese of Springfield in Illinois" },
  { Country: "United States", Parish: "St. Michael the Archangel", Diocese: "Diocese of Springfield in Massachusetts" },
  { Country: "United States", Parish: "Holy Cross, Springfield", Diocese: "Diocese of Springfield in Massachusetts" },
  { Country: "United States", Parish: "St. Vincent de Paul", Diocese: "Diocese of Springfield-Cape Girardeau" },
  { Country: "United States", Parish: "Cathedral Basilica of St. Augustine", Diocese: "Diocese of St. Augustine" },
  { Country: "United States", Parish: "Saint Patrick Gainesville", Diocese: "Diocese of St. Augustine" },
  { Country: "United States", Parish: "Christ the King", Diocese: "Diocese of St. Augustine" },
  { Country: "United States", Parish: "Epiphany Catholic Church", Diocese: "Diocese of St. Augustine" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton, Palm Coast", Diocese: "Diocese of St. Augustine" },
  { Country: "United States", Parish: "Centered on Christ Catholic Community", Diocese: "Diocese of St. Cloud" },
  { Country: "United States", Parish: "St. Mary's Cathedral", Diocese: "Diocese of St. Cloud" },
  { Country: "United States", Parish: "Mary of the Immaculate Conception", Diocese: "Diocese of St. Cloud" },
  { Country: "United States", Parish: "Mary Queen of Peace", Diocese: "Diocese of St. Cloud" },
  { Country: "United States", Parish: "Parishes on the Prairie Area Catholic Community", Diocese: "Diocese of St. Cloud" },
  { Country: "United States", Parish: "Shepherd of the Hills", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Sacred Heart/Pinellas Park", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Ignatius of Antioch", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Patricks", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Our Lady of Lourdes", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Timothy", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Jerome", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St James the Apostle, Port Richey", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Catherine of Siena, Clearwater", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Mark the Evangelist, Tampa", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Holy Martyrs of Vietnam, FL", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. John Vianney, St. Pete Beach, FL", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Blessed Sacrament Catholic Church, Seminole, FL", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Nativity Catholic Church", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Peter the Apostle, Trinity FL", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Raphael Catholic Church, St. Petersburg, FL", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St Joseph Catholic Church - Tampa", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "St. Patrick's Tampa", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Holy Family Parish, St. Petersburg", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Resurrection Catholic Church", Diocese: "Diocese of St. Petersburg" },
  { Country: "United States", Parish: "Holy Family", Diocese: "Diocese of Steubenville" },
  { Country: "United States", Parish: "Triumph of the Cross, Steubenville", Diocese: "Diocese of Steubenville" },
  { Country: "United States", Parish: "St . Anthonys", Diocese: "Diocese of Stockton" },
  { Country: "United States", Parish: "St. Anthony of Padua Catholic Parish", Diocese: "Diocese of Stockton" },
  { Country: "United States", Parish: "St. Stanislaus", Diocese: "Diocese of Stockton" },
  { Country: "United States", Parish: "St. Mary Catholic Community", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "St. John the Baptist", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "St. Joseph & St. Ann's", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "Christ the King, Toledo", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "Saint John XXIII, Perrysburg", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "Sacred Heart, Fremont, OH", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "St. Nicholas & Holy Family Parishes, OH", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "Little Flower, Toledo, OH", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "Epiphany of the Lord Parish, Toledo, OH", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "St. Joseph Parish, Toledo, OH", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "St. Mary Parish, Wakeman, OH", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "St. Thomas More University Parish", Diocese: "Diocese of Toledo" },
  { Country: "United States", Parish: "St. Benedict", Diocese: "Diocese of Trenton" },
  { Country: "United States", Parish: "Saint Maximilian Kolbe Church", Diocese: "Diocese of Trenton" },
  { Country: "United States", Parish: "Incarnation-St. James", Diocese: "Diocese of Trenton" },
  { Country: "United States", Parish: "St. Mark the Evangelist", Diocese: "Diocese of Tucson" },
  { Country: "United States", Parish: "San Martin de Porres", Diocese: "Diocese of Tucson" },
  { Country: "United States", Parish: "Santa Catalina Catholic Church", Diocese: "Diocese of Tucson" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton", Diocese: "Diocese of Tucson" },
  { Country: "United States", Parish: "St. Thomas the Apostle, Tucson, AZ", Diocese: "Diocese of Tucson" },
  { Country: "United States", Parish: "Corpus Christi, Tucson", Diocese: "Diocese of Tucson" },
  { Country: "United States", Parish: "St. Francis Xavier Catholic Church", Diocese: "Diocese of Tulsa" },
  { Country: "United States", Parish: "St. John", Diocese: "Diocese of Tulsa" },
  { Country: "United States", Parish: "Cathedral of the Immaculate Conception", Diocese: "Diocese of Tyler" },
  { Country: "United States", Parish: "St. Joseph the Worker", Diocese: "Diocese of Tyler" },
  { Country: "United States", Parish: "St. Joseph the Worker", Diocese: "Diocese of Tyler" },
  { Country: "United States", Parish: "Ave Maria Catholic Church", Diocese: "Diocese of Venice" },
  { Country: "United States", Parish: "St. Katherine Drexel", Diocese: "Diocese of Venice" },
  { Country: "United States", Parish: "Our Lady of Light, Fort Myers", Diocese: "Diocese of Venice" },
  { Country: "United States", Parish: "St. Mary", Diocese: "Diocese of Wilmington" },
  { Country: "United States", Parish: "St. John the Beloved", Diocese: "Diocese of Wilmington" },
  { Country: "United States", Parish: "St. Mary Magdalen", Diocese: "Diocese of Wilmington" },
  { Country: "United States", Parish: "St. Augustine", Diocese: "Diocese of Winona-Rochester" },
  { Country: "United States", Parish: "Our Lady of Mt Carmel", Diocese: "Diocese of Winona-Rochester" },
  { Country: "United States", Parish: "St Bernard and St Bridget Catholic Churches", Diocese: "Diocese of Winona-Rochester" },
  { Country: "United States", Parish: "Church of the Resurrection, Rochester", Diocese: "Diocese of Winona-Rochester" },
  { Country: "United States", Parish: "St. Pius X, Rochester", Diocese: "Diocese of Winona-Rochester" },
  { Country: "United States", Parish: "St. Joseph Catholic Church", Diocese: "Diocese of Yakima" },
  { Country: "United States", Parish: "Our Lady of the Assumption", Diocese: "Diocese of Yakima" },
  { Country: "United States", Parish: "Ss Philip and James", Diocese: "Diocese of Youngstown" },
  { Country: "United States", Parish: "St. Joan of Arc", Diocese: "Diocese of Youngstown" },
  { Country: "United States", Parish: "St. Patrick", Diocese: "Diocese of Youngstown" },
  { Country: "United States", Parish: "Christ the Servant Parish, Canton", Diocese: "Diocese of Youngstown" },
  { Country: "United States", Parish: "St. John Chrysostom Byzantine Catholic Church, Columbus", Diocese: "Eparchy of Parma" },
  { Country: "Australia", Parish: "St Joseph's Maronite Catholic Parish", Diocese: "Maronite Eparchy of Our Lady of Lebanon" },
  { Country: "South Africa", Parish: "Emmaus- St. John Paul II, Bloemfontein, SA", Diocese: "Archdiocese of Bloemfontein" },
  { Country: "South Africa", Parish: "St Anthony", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "St John's Catholic Church, Cape Town, SA", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "Saints Simon and Jude Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "Saint Philip Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "St. Lawrence Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "Paarl Catholic Church - St. Augustine", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "Our Lady of Fatima Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "Fish Hoek Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "St Martin de Porres Roman Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "Constantia Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "St. Mary's Cathedral", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "Our Lady of the Assumption", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "St Michael's", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "St Paul's Catholic Church", Diocese: "Archdiocese of Cape Town" },
  { Country: "South Africa", Parish: "St John the Apostle, Florida Park, Johannesburg, Gauteng, SA", Diocese: "Diocese of Johannesburg" },
  { Country: "South Africa", Parish: "Church of the Resurrection, Bryanston", Diocese: "Diocese of Johannesburg" },
  { Country: "South Africa", Parish: "St Andrew's, Johannesburg, SA", Diocese: "Diocese of Johannesburg" },
  { Country: "Chile", Parish: "Parroquia de San Jose Benito Cottolengo, Santiago, Chile", Diocese: "Arquidiocesis de Santiago" },
  { Country: "Colombia", Parish: "Parroquia de San Pascual Bailon, Bogota, Colombia", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Colombia", Parish: "Medalla Milagrosa", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Colombia", Parish: "Iglesia Santa Maria Del Cedro", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Colombia", Parish: "Parroquia Virgen De La Medalla Milagrosa", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Colombia", Parish: "Parroquia Santa Matilde", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Colombia", Parish: "Parroquia Cristo Misionero", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Colombia", Parish: "Iglesia Divino Niño Jesús de Praga", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Colombia", Parish: "San Ramón Nonato", Diocese: "Arquidiocesis de Bogota" },
  { Country: "Argentina", Parish: "Vicaria Santa Teresita, Cordoba, Argentina", Diocese: "Arquidiocesis de Cordoba" },
  { Country: "Mexico", Parish: "Parroquia Francesa, Mexico City", Diocese: "Arquidiocesis de la Ciudad de Mexico" },
  { Country: "Mexico", Parish: "Parroquia Santa Margarita María Alacoque", Diocese: "Arquidiocesis de la Ciudad de Mexico" },
  { Country: "Mexico", Parish: "Parroquia del Purísimo Corazón de María", Diocese: "Arquidiocesis de la Ciudad de Mexico" },
  { Country: "Mexico", Parish: "Parroquia Santo Domingo de Guzman", Diocese: "Arquidiocesis de la Ciudad de Mexico" },
  { Country: "Mexico", Parish: "Parroquia de Nuestra Señora de Guadalupe Emperatriz de América", Diocese: "Arquidiocesis de la Ciudad de Mexico" },
  { Country: "Mexico", Parish: "Parroquia del Apóstol San Simón Ticumán", Diocese: "Arquidiocesis de la Ciudad de Mexico" },
  { Country: "Mexico", Parish: "Parroquia de Santa Lucia Azcapotzalco", Diocese: "Arquidiocesis de la Ciudad de Mexico" },
  { Country: "Guatemala", Parish: "Parroquia de Santa Catarina de Alejandria, Catarina, Guatemala", Diocese: "Diocesis de San Marcos" },
  { Country: "Guatemala", Parish: "Inmaculada Concepcion de Maria", Diocese: "Diocesis de San Marcos" },
  { Country: "Bolivia", Parish: "Parroquia de Jesus Obrero, El Alto, Bolivia", Diocese: "Diocesis de El Alto" },
  { Country: "Bolivia", Parish: "Parroquia - El Alto", Diocese: "Diocesis de El Alto" },
  { Country: "Peru", Parish: "Jesús Obrero", Diocese: "Diocesis de El Alto" },
  { Country: "South Africa", Parish: "St Francis Xavier, Zeerust, NW Province SA", Diocese: "Diocese of Rustenburg" },
  { Country: "Germany", Parish: "Pfarrverband Gräfelfing", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband\t Vaterstetten", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband\tSt.\t Thomas\tund\tSt", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Solidarpfarreien Scheyern, Niederscheyern, Gerolsbach", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Christus Erlöser Neuperlach", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrkirche Heilige Familie", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Gemeinde St. Andreas Eching", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Stadtkirche Germering", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrei St. Joseph, Kirchseeon", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Erdinger Moos", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Heilig Geist, Munich", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband St. Thomas und St. Lorenz", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband am Luitpoldpark", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "St. Johannes der Täufer", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Obersüßbach-Neuhausen-Weihmichl", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband München West", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Allach-Untermenzing", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Stephanskirchen", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Anger-Aufham-Piding", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Pacem", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband München-Westend", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Laim", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "PV Schweitenkirchen", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Taufkirchen", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Vier Heilige Riem Trudering", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "St. Sebastian Gilching", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Kichanschöring", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Rottenbuch", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Pfarrverband Raubling", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Erzbischöfliches Pater-Rupert-Mayer-Schulzentrum", Diocese: "Erzdiözese München-Freising" },
  { Country: "Germany", Parish: "Römisch Katholische Kirchengemeinde Karlsruhe Durlach Bergdörfer", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Katholische Seelsorgeeinheit Mannheim Johannes XXIII", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeinheit Lauda-Königshofen", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Haslach", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Kloster Wittichen", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Pfarrei Konstanz-Petershausen", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Pfarrei Konstanz Altstadt", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheiten an Wolf und Kinzig", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Aachtal", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit - Villingen-Schwenningen", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Wallfahrtskircheund\xa0Kloster\xa0Waghäusel", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Überlingen", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Dekanatsverband Tauberbischofsheim", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Heitersheim", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit An der Eschach", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Kath. Seelsorgeeinheit Mannheim Süd", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Kath. Kirchengemeinde Bruchsal-Michaelsberg", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Seelsorgeeinheit Achern", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Römisch-Katholische Kirchengemeinde Leimen-Nußloch-Sandhausen", Diocese: "Erzdiözese Freiburg" },
  { Country: "Germany", Parish: "Römisch Katholische  Kirchengemeinde Achern", Diocese: "Erzdiözese Freiburg" },
  { Country: "Canada", Parish: "Saint John XXIII", Diocese: "Divine Mercy Parish - Dartmouth" },
  { Country: "Canada", Parish: "St. Thomas Aquinas Canadian Martyrs", Diocese: "Good Shepherd Parish - Halifax" },
  { Country: "Canada", Parish: "Saint Peter Church", Diocese: "Our Lady of Guadalupe Parish" },
  { Country: "Canada", Parish: "St Peter, Dartmouth, NS", Diocese: "Our Lady of Guadalupe Parish" },
  { Country: "Canada", Parish: "St. Patrick's", Diocese: "Saint Francis and Saint Claire of Assisi Parish" },
  { Country: "Canada", Parish: "Saint Theresa Church - Halifax", Diocese: "Saint Francis and Saint Claire of Assisi Parish" },
  { Country: "Canada", Parish: "Our Lady of Mount Carmel Shad Bay", Diocese: "Holy Apostles Parish" },
  { Country: "Canada", Parish: "Holy Family Church", Diocese: "All Saints - Amherst" },
  { Country: "Canada", Parish: "St John the Evangelist - Parish of Newcastle-Nelson-Hardwicke", Diocese: "Anglican Diocese of Fredericton" },
  { Country: "Canada", Parish: "St Mark, Sussex Corner - Parishes of Waterford and St. Mark", Diocese: "Anglican Diocese of Fredericton" },
  { Country: "Canada", Parish: "St John the Evangelist - Parish of Douglas & Nasjwaaksis", Diocese: "Anglican Diocese of Fredericton" },
  { Country: "Canada", Parish: "Anglican Parish of Gagetown", Diocese: "Anglican Diocese of Fredericton" },
  { Country: "Canada", Parish: "St. Paul's Bloor Street", Diocese: "Anglican Diocese of Toronto" },
  { Country: "United States", Parish: "St. Mary/ Chancery", Diocese: "Diocese of Victoria" },
  { Country: "Germany", Parish: "Apostelhaus Ratingen", Diocese: "Legionaries of Christ" },
  { Country: "Australia", Parish: "Ministry District of Yorke Peninsuala", Diocese: "Anglican Diocese of Willochra" },
  { Country: "Germany", Parish: "Heilig Kreuz Erlangen", Diocese: "Erzdiözese Bamberg" },
  { Country: "Germany", Parish: "Kloster Mariahilfberg", Diocese: "Erzdiözese Bamberg" },
  { Country: "Germany", Parish: "Seelsorgebereich St. Bonifaz - St. Wolfgang - Heilig Kreuz Nürnberg", Diocese: "Erzdiözese Bamberg" },
  { Country: "Germany", Parish: "Pfarrei Mariä Opferung Poxdorf", Diocese: "Erzdiözese Bamberg" },
  { Country: "Germany", Parish: "Hl.\tDreifaltigkeit,\t Brandenburg", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "Pfarrei St. Wilhelm", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "St. Bonifatius Bad Belzig", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "Pfarrei Heilige Familie in Berlin-Lichterfelde", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "Katholische Kirche in Wittstock, Pritzwalk und Meyenburg", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "Kath. Pfarrei St Matthias Schoneberg", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "Pfarrei Herz Jesu", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "Pfarrei St. Elisabeth Berlin", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "St. Bernhard Stralsund-Rügen-Demmin", Diocese: "Erzdiözese Berlin" },
  { Country: "Germany", Parish: "St.\tMarien,\t Hamburg", Diocese: "Erzdiözese Hamburg" },
  { Country: "Germany", Parish: "Pfarrgemeinde Sankt Sophien", Diocese: "Erzdiözese Hamburg" },
  { Country: "Germany", Parish: "Katholische Pfarrei St. Ansverus", Diocese: "Erzdiözese Hamburg" },
  { Country: "Germany", Parish: "Gemeinde St. Michael, Schwarzenbek", Diocese: "Erzdiözese Hamburg" },
  { Country: "Germany", Parish: "Pfarrei Heilige Elisabeth", Diocese: "Erzdiözese Hamburg" },
  { Country: "Germany", Parish: "Friedenskirche Hamburg-Jenfeld", Diocese: "Erzdiözese Hamburg" },
  { Country: "Germany", Parish: "Pfarrei Hl. Elisabeth", Diocese: "Erzdiözese Hamburg" },
  { Country: "Germany", Parish: "Pastoralverbund\t Wanne", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pastoraler Raum Wanne-Eickel", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pastoraler Raum Brakeler Land", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pastoralverbund Paderborn Nord-Ost-West", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "PV Elsen-Wewer-Borchen", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "PR Brakeler Land", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "PV Büren", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pastoraler Raum Kirchhundem", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "St. Johannes Baptist, Attendorn", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pastoraler Raum Lippe-Detmold", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Dompfarrei Minden", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "St. Dionysius, Herne", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pastoralverbund Delbrück-Hövelhof", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pastoraler Raum Soest", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "PV Nördliches Siegerland", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "St. Clara Phoenixsee", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pfarrei St. Nicolai", Diocese: "Erzdiözese Paderborn" },
  { Country: "Germany", Parish: "Pfarrei St. Marien Wassenberg", Diocese: "Diözese Aachen" },
  { Country: "Germany", Parish: "St Peter und St Paul Eschweiler", Diocese: "Diözese Aachen" },
  { Country: "Germany", Parish: "St. Servatius, Selfkant", Diocese: "Diözese Aachen" },
  { Country: "Germany", Parish: "Gemeinschart der Gemeinden (GdG) Huckelhoven", Diocese: "Diözese Aachen" },
  { Country: "Germany", Parish: "PMK - Polnische Missionsgemeinde Mönchengladbach", Diocese: "Diözese Aachen" },
  { Country: "Germany", Parish: "Parroquia de Lengua Espanola Aachen", Diocese: "Diözese Aachen" },
  { Country: "Germany", Parish: "Heiliger Apostel Matthias GdG Blankenheim/Dahlem", Diocese: "Diözese Aachen" },
  { Country: "Germany", Parish: "St. Laurentius, Leipzig", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "San Petrus Strehlen", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "St. Bonifatius Leipzig-Süd", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "Kathedrale Sanctissimae Trinitatis", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "Parish of St. Elisabeth Dresden", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "Katholische Dompfarrei St. Petri", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "Kath. Pfarrei Heilige Mutter Teresa", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "Herz Jesu Plauen", Diocese: "Diözese Dresden–Meißen" },
  { Country: "Germany", Parish: "Communio Ingolstadt West", Diocese: "Diözese Eichstätt" },
  { Country: "Germany", Parish: "Hofkirche Neumarkt", Diocese: "Diözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrverband Nürnberg-Südwest/Stein", Diocese: "Diözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrverband Plankstetten-Oening-Kevenhüll", Diocese: "Diözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrverband Burgheide", Diocese: "Diözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrei Gunzenhausen", Diocese: "Diözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrei St. Laurentius Erfurt", Diocese: "Diözese Erfurt" },
  { Country: "Germany", Parish: "St. Urbanus Gelsenkirchen", Diocese: "Diözese Essen" },
  { Country: "Germany", Parish: "Parish of St. Medardus", Diocese: "Diözese Essen" },
  { Country: "Germany", Parish: "Pfarrei St. Joseph - Bottrop", Diocese: "Diözese Essen" },
  { Country: "Germany", Parish: "Pfarrei St. Michael Werdohl-Neuenrade", Diocese: "Diözese Essen" },
  { Country: "Germany", Parish: "Katholische Kirche in Gelnhausen, Grundau und Linsengericht", Diocese: "Diözese Fulda" },
  { Country: "Germany", Parish: "Kath. Pfarramt Mariä Himmelfahrt", Diocese: "Diözese Fulda" },
  { Country: "Germany", Parish: "Katholische Kirche Petersberg", Diocese: "Diözese Fulda" },
  { Country: "Germany", Parish: "Pfarrei St Klara und Franziskus - Hanau", Diocese: "Diözese Fulda" },
  { Country: "Germany", Parish: "OMI-Jugendkloster", Diocese: "Diözese Fulda" },
  { Country: "Germany", Parish: "Zisterzienserpriorat Neuzelle", Diocese: "Diözese Görlitz" },
  { Country: "Germany", Parish: "Pfarrei Heilige Familie Lübbenau", Diocese: "Diözese Görlitz" },
  { Country: "Germany", Parish: "Kath. Pfarrgemeinde Hl. Engel, Hannover", Diocese: "Diözese Hildesheim" },
  { Country: "Germany", Parish: "St. Michael Göttingen", Diocese: "Diözese Hildesheim" },
  { Country: "Germany", Parish: "St. Barbara Lindhorst", Diocese: "Diözese Hildesheim" },
  { Country: "Germany", Parish: "St. Joseph, Holzminden", Diocese: "Diözese Hildesheim" },
  { Country: "Germany", Parish: "St. Martinus Hildesheim", Diocese: "Diözese Hildesheim" },
  { Country: "Germany", Parish: "Kath. Kirchengemeinde Oberes Schlüchttal", Diocese: "Diözese Limburg" },
  { Country: "Germany", Parish: "PG Fremdingen", Diocese: "Diözese Limburg" },
  { Country: "Germany", Parish: "Pfarrei St. Petrus Canisius, Mainz", Diocese: "Diözese Mainz" },
  { Country: "Germany", Parish: "Maria Himmelskron", Diocese: "Diözese Mainz" },
  { Country: "Germany", Parish: "Pfarrgruppe Wickstadt / Dorn-Assenheim", Diocese: "Diözese Mainz" },
  { Country: "Germany", Parish: "Pastoralraum Otzberger Land", Diocese: "Diözese Mainz" },
  { Country: "Germany", Parish: "Pfarrgruppe Bingen", Diocese: "Diözese Mainz" },
  { Country: "Germany", Parish: "Pfarrgruppe Oppenheim", Diocese: "Diözese Mainz" },
  { Country: "Germany", Parish: "Wallfahrtskirche Kevelaer", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St. Antonius", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St. Katharina von Siena Lindern", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "Emmanuel House Münster", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "Kirchengemeinde Heilig Geist Kalkar", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St. Antonius Abbas Kranenburg", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St. Peter und Paul Kranenburg", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "Sankt Marien Kevelaer", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St. Ludgeri", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St. Peter und Paul – Halverde-Schale", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St. Martinus Gocherland", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "KSHG Münster", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "St Dionysius Rheine", Diocese: "Diözese Münster" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Obergrafschaft", Diocese: "Diözese Osnabrück" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Rütenbrock", Diocese: "Diözese Osnabrück" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Herzlake und Dohren", Diocese: "Diözese Osnabrück" },
  { Country: "Germany", Parish: "St. Raphael Bremen", Diocese: "Diözese Osnabrück" },
  { Country: "Germany", Parish: "ApostelHaus Alzgern", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Pfarrverband Straßkirchen", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Pfarrverband Unterneukirchen", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Pfarrverband Hengersberg", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Pfarrverband Zwiesel Ludwigsthal", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Kloster St. Magdalena - Altötting", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Pfarrverband Passau St. Anton", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Pfarrverband Waldkirchen", Diocese: "Diözese Passau" },
  { Country: "Germany", Parish: "Hl Dreifaltigkeit, Regensburg", Diocese: "Diözese Regensburg" },
  { Country: "Germany", Parish: "Propstei St. Michael, Paring", Diocese: "Diözese Regensburg" },
  { Country: "Germany", Parish: "Herz Marien, Regensburg", Diocese: "Diözese Regensburg" },
  { Country: "Germany", Parish: "Pfarrei Waldsassen", Diocese: "Diözese Regensburg" },
  { Country: "Germany", Parish: "Pfarrei Maria Schutz, Kaiserslautern", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Maria Rosenberg", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Pfarrei Hl. Michael, Deidesheim", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Pfarrei Hl. Martin, St. Ingbert", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Pfarrei Heilige Veronika Ensheim", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Pfarrei Heiliger Remigius Kusel", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Pfarrei Mandelbachtal", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Hl. Christophorus", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Hl. Edith Stein Schifferstadt", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Kath. Pfarrei Heiliger Petrus, Dahner Felsenland", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Pfarrei Heiliger Franz Xaver Lauterecken", Diocese: "Diözese Speyer" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft  Saarburg", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "PG Altenkessel-Klarenthal", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: 'PG Saarlouis "Links der Saar"', Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "St. Josef, Saarbruecken", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Kath. Pfarramt Spiesen-Elversberg", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Sankt Laurentiuskirche, Ahrweiler", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Rupertsberg", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Oberthal-Namborn", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Pfrarreiengemeinschaft Quierschied", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Pastoraler Raum Sinzig", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Pfarrei Sankt Odilia Dillingen", Diocese: "Diözese Trier" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Besengau /Bastheim", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "St.\tMaria\t Magdalena,\t Münnerstadt", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft St. Nikolaus Süd-Spessart", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "Pfarrreiengemeinschaft 12 Apostel am Tor zum Spessart", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Aidhausen Riedbach", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "St. Nikolaus - Haibach", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "Stadtpfarrei Heilig Geist Schweinfurt", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "Pastoraler Raum Münnerstadt", Diocese: "Diözese Würzburg" },
  { Country: "Germany", Parish: "Pfarreiengemeinschaft Hl. Franziskus im Maintal", Diocese: "Diözese Würzburg" },
  { Country: "Ireland", Parish: "Ferbane Parish", Diocese: "Diocese of Ardagh & Clonmacnois" },
  { Country: "Ireland", Parish: "Longford Parish", Diocese: "Diocese of Ardagh & Clonmacnois" },
  { Country: "Ireland", Parish: "Grenagh Parish", Diocese: "Diocese of Cloyne" },
  { Country: "Ireland", Parish: "Killeagh Inch Parish", Diocese: "Diocese of Cloyne" },
  { Country: "Ireland", Parish: "Cobh Parish", Diocese: "Diocese of Cloyne" },
  { Country: "Ireland", Parish: "Glanworth, Ballindangan and Curraghagalla Parish", Diocese: "Diocese of Cloyne" },
  { Country: "United Kingdom", Parish: "Parish of Seapatrick", Diocese: "Diocese of Dromore" },
  { Country: "United Kingdom", Parish: "Kilbroney Parish", Diocese: "Diocese of Dromore" },
  { Country: "United Kingdom", Parish: "Moyraverty Parish", Diocese: "Diocese of Dromore" },
  { Country: "Ireland", Parish: "Newry Catherdral Parish", Diocese: "Diocese of Dromore" },
  { Country: "Ireland", Parish: "Parish of Clarinbridge", Diocese: "Diocese of Galway, Kilmacduagh & Kilfenora" },
  { Country: "Ireland", Parish: "St. John's, Tralee", Diocese: "Diocese of Kerry" },
  { Country: "Ireland", Parish: "Glenflesk", Diocese: "Diocese of Kerry" },
  { Country: "Ireland", Parish: "Killeigh Parish", Diocese: "Diocese of Kildare & Leighlin" },
  { Country: "Ireland", Parish: "Caragh Parish - Newbridge, Caragh, Prosperous", Diocese: "Diocese of Kildare & Leighlin" },
  { Country: "Ireland", Parish: "New-Market-on-Fergus Parish", Diocese: "Diocese of Killaloe" },
  { Country: "Ireland", Parish: "Parish of Urney and Annagelliff", Diocese: "Diocese of Kilmore" },
  { Country: "United Kingdom", Parish: "Killann Parish", Diocese: "Diocese of Kilmore" },
  { Country: "Ireland", Parish: "Kilmallock Ballingaddy, Limerick", Diocese: "Diocese of Limerick" },
  { Country: "Austria", Parish: "Pfarren Pinzgau-Quellen", Diocese: "Erzdiözese Salzburg" },
  { Country: "Austria", Parish: "Pfarrverband Kirchbichl, Bad Häring und Schwoich", Diocese: "Erzdiözese Salzburg" },
  { Country: "Austria", Parish: "Home Church Salzburg", Diocese: "Erzdiözese Salzburg" },
  { Country: "Austria", Parish: "Pfarre Salzburg St. Martin", Diocese: "Erzdiözese Salzburg" },
  { Country: "Austria", Parish: "St Johann in Tirol", Diocese: "Erzdiözese Salzburg" },
  { Country: "Austria", Parish: "Pfarrverband Aigen-Elsbethen", Diocese: "Erzdiözese Salzburg" },
  { Country: "Austria", Parish: "Stadtpfarre Salzburg Maxglan", Diocese: "Erzdiözese Salzburg" },
  { Country: "Austria", Parish: "Zentrum Johannes Paul ll", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Dornbach Parish", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Krumbach Parish", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarre Lichtental", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarre Maria Rotunda", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Stift Heiligenkreuz", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "St. Rochus Wien", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarre Gaaden bei Mödling", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarre Baden St. Stephan", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Gemeinde Startup", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Gemeinde Lichtenegg", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarre Brunn am Gebirge", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Johannesgemeinschaft Marchegg", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Polnische Evangelikale Gemeinde", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarre Inzersdorf-Neustift", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarrverband Raxgebiet", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "PFARRE MARIA LANZENDORF", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Mutter Gottes Kirche", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "Pfarrverband Pulkautal", Diocese: "Erzdiözese Wien" },
  { Country: "Austria", Parish: "St. Margaretha-Traiskirchen", Diocese: "Erzdiözese Wien" },
  { Country: "Germany", Parish: "Hl. Drei Könige, Hittisau", Diocese: "Diözese Feldkirch" },
  { Country: "Austria", Parish: "Pfarre Tosters", Diocese: "Diözese Feldkirch" },
  { Country: "Austria", Parish: "Pfarrkirche St. Georg Lauterach", Diocese: "Diözese Feldkirch" },
  { Country: "Austria", Parish: "Ebenthal in Kärnten", Diocese: "Diözese Gurk" },
  { Country: "Germany", Parish: "Pfarre St. Valentin-Landschach", Diocese: "Diözese Gurk" },
  { Country: "Austria", Parish: "Holy Trinity/Gray", Diocese: "Diözese Gurk" },
  { Country: "Austria", Parish: "Franziskanerkloster Schwaz", Diocese: "Diözese Innsbruck" },
  { Country: "Germany", Parish: "Pfarre Asten", Diocese: "Diözese Linz" },
  { Country: "Germany", Parish: "Pfarre Enns", Diocese: "Diözese Linz" },
  { Country: "Germany", Parish: "Pfarre Mondsee", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Waldneukirchen", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Rohr im Kremstal", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Mondsee", Diocese: "Diözese Linz" },
  { Country: "Germany", Parish: "Enns St. Marien", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "St. Georgen am Ybbsfelde", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Schönau im Mühlkreis", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Schwertberg", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Windhaag bei Perg", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Königswiesen", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Riedau", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Maria Schmolln", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "St. Georgen im Attergau", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Münzbach", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Windhaag bei Perg", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Pierbach", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Katholische Kirche in Oberosterreich", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Church in Schönau", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Church of Pierbach", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Lohnsburg", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Aspach", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Stift Schlägl", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "Pfarre Ottnang am Hausruck", Diocese: "Diözese Linz" },
  { Country: "Austria", Parish: "St. Valentin", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarramt Wieselburg", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "PVB Südliches Waldviertel", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "PVB Im Horner Becken", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "PVB Kasten - Stössing - Michelbach", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "PVB Melk - St. Koloman", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "PVB Zeillern Oed", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarren Großschönau & Wurmbrand", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Maria Lourdes", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Lengenfeld", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Asperhofen", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarren St. Veit a.d. Gölsen und Schwarzenbach", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Pottenbrunn", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Steinakirchen/Forst", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Allhartsberg", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Wieselburg", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Stift Altenburg", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre Tulln Sankt Stephan", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarre St. Leonhard am Forst", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Pfarrverband Herz Jesu im Waldviertel", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Neuhofen Mariä Himmelfahrt", Diocese: "Diözese Sankt Pölten" },
  { Country: "Austria", Parish: "Hl. Dreifaltigkeit im Gölsental", Diocese: "Diözese Sankt Pölten" },
  { Country: "Switzerland", Parish: "Oasis Zug", Diocese: "Diözese Basel" },
  { Country: "Switzerland", Parish: "St. Leodegar Luzern", Diocese: "Diözese Basel" },
  { Country: "Switzerland", Parish: "Pfarrei St. Peter und Paul Ramsen", Diocese: "Diözese Basel" },
  { Country: "Switzerland", Parish: "Pfarrei St. Felix und Regula Thalwil", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "St. Anton Ibach", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "St. Agatha & St. Joseph Dietikon", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "Pfarrei St. Urban", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "Herz Jesu Oerlikon", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "Pfarramt Rüschlikon St. Nikolaus von Myra", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "Kath. Pfarrei St. Michael Altendorf", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "Pfarrei Flims & Trin - Graubünden", Diocese: "Diözese Chur" },
  { Country: "Switzerland", Parish: "Pfarrei Beckenried - Schweiz", Diocese: "Diözese Chur" },
  { Country: "Italy", Parish: "Due parrocchie: Spirito Santo e Virgo Potens", Diocese: "Diözese Lausanne, Genf und Fribourg" },
  { Country: "Switzerland", Parish: "Église Saint-Robert", Diocese: "Diözese Lausanne, Genf und Fribourg" },
  { Country: "Switzerland", Parish: "Pfarrei Wünnewil-Flamatt", Diocese: "Diözese Lausanne, Genf und Fribourg" },
  { Country: "Switzerland", Parish: "Pfarrei Heitenried", Diocese: "Diözese Lausanne, Genf und Fribourg" },
  { Country: "Switzerland", Parish: "Seelsorgeeinheit Blattenberg", Diocese: "Diözese St. Gallen" },
  { Country: "Switzerland", Parish: "Missionshaus Untere Waid - Mörschwihl", Diocese: "Diözese St. Gallen" },
  { Country: "Switzerland", Parish: "Pfarrei Glis-Gamsen-Brigerbad", Diocese: "Diocese of Sion" },
  { Country: "Switzerland", Parish: "Pfarreien Brigerberg-Simplon", Diocese: "Diocese of Sion" },
  { Country: "Switzerland", Parish: "Herz-Jesu Pfarrei Brig", Diocese: "Diocese of Sion" },
  { Country: "Italy", Parish: "Natività San Giovanni Battista in Lonato del Garda", Diocese: "Diocese of Verona" },
  { Country: "Italy", Parish: ". Domus Pacis (centro diocesano di pastorale della bassa veronese)", Diocese: "Diocese of Verona" },
  { Country: "Italy", Parish: "Unità pastorale di Caprino, Lubiara, Pazzon e Pesina", Diocese: "Diocese of Verona" },
  { Country: "Italy", Parish: "Santa Maria Regina, Verona", Diocese: "Diocese of Verona" },
  { Country: "Italy", Parish: "Parrocchia di San Pietro in Cattedra", Diocese: "Diocese of Verona" },
  { Country: "Italy", Parish: "San Giovanni Lupatoto", Diocese: "Diocese of Verona" },
  { Country: "Italy", Parish: "Parrocchia di Domegliara, Volargne, Ponton, Ceraino", Diocese: "Diocese of Verona" },
  { Country: "South Africa", Parish: "Anglican Parish of Germiston", Diocese: "Anglican Diocese of the Highveld" },
  { Country: "Argentina", Parish: "Santos Joquin Y Ana", Diocese: "Diocese of Azul" },
  { Country: "Malaysia", Parish: "Church of Christ the King", Diocese: "Malacca Johore Diocese" },
  { Country: "Malaysia", Parish: "St. Henry Church, Batu Panat", Diocese: "Malacca Johore Diocese" },
  { Country: "Malaysia", Parish: "Church of Our Lady of Guadalupe, Krubong", Diocese: "Malacca Johore Diocese" },
  { Country: "Malaysia", Parish: "Church of the Immaculate Conception, Johor", Diocese: "Malacca Johore Diocese" },
  { Country: "Portugal", Parish: "Lanheses (Parish Grouping)", Diocese: "Viana do Castelo" },
  { Country: "Thailand", Parish: "St. Raphael Cathedral", Diocese: "Surat Thani Diocese" },
  { Country: "Saint Kitts and Nevis", Parish: "St. Theresa, Charlestown", Diocese: "Diocese of Saint John's - Basseterre" },
  { Country: "Philippines", Parish: "Sacred Heart of Jesus Sta. Mesa, Manila", Diocese: "Archdiocese of Manila" },
  { Country: "Philippines", Parish: "St. Peter the Apostle", Diocese: "Diocese of Daet" },
  { Country: "Zimbabwe", Parish: "St. Peter Canisius, Harare", Diocese: "Archdiocese of Harare" },
  { Country: "Ireland", Parish: "St. Patrick's, Greystones", Diocese: "Church of Ireland Diocese of Dublin and Glendalough" },
  { Country: "France", Parish: "St. Vincent de Paul, Nice", Diocese: "Diocese of Nice" },
  { Country: "Zimbabwe", Parish: "St. Mary's Cathedral Basilica Parish, Bulawayo", Diocese: "Archdiocese of Bulawayo" },
  { Country: "Latvia", Parish: "St. Mary Magdalene, Riga", Diocese: "Archdiocese of Riga" },
  { Country: "Nigeria", Parish: "Catholic Church of the Presentation, Ikeja", Diocese: "Catholic Archdiocese of Lagos" },
  { Country: "Mexico", Parish: "Parroquia El Santísimo Sacramento, Culiacán", Diocese: "Diócesis de Culiacán" },
  { Country: "Mexico", Parish: "Divina Providencia, los Mochis", Diocese: "Diócesis de Culiacán" },
  { Country: "Mexico", Parish: "Divina Providencia, los Mochis", Diocese: "Diócesis de Culiacán" },
  { Country: "Malaysia", Parish: "Church of St. Jude Thaddeus, Selangor", Diocese: "Archdiocese of Kuala Lumpur" },
  { Country: "Malaysia", Parish: "Sts Peter and Paul, Banting", Diocese: "Archdiocese of Kuala Lumpur" },
  { Country: "Malaysia", Parish: "Church of St. Thomas More, Subang Jaya", Diocese: "Archdiocese of Kuala Lumpur" },
  { Country: "Netherlands", Parish: "Seminary/ Marian Shrine Our Lady in Distress, Heiloo, Amsterdam", Diocese: "Diocese of Haarlem–Amsterdam" },
  { Country: "Netherlands", Parish: "Church of Our Lady", Diocese: "Diocese of Haarlem–Amsterdam" },
  { Country: "Netherlands", Parish: "Haarlem en Boaz", Diocese: "Diocese of Haarlem–Amsterdam" },
  { Country: "Belgium", Parish: "Kerk Goddelijk Kind jezus, Antwerp", Diocese: "Diocese of Antwerp" },
  { Country: "Sri Lanka", Parish: "Church of Our Lady of Fatima, Columbo", Diocese: "Archdiocese of Columbo" },
  { Country: "Malta", Parish: "John Paul II Family Institute, Gozo", Diocese: "Diocese of Gozo" },
  { Country: "Netherlands", Parish: "St. Catharina, Eindhoven", Diocese: "Diocese of Roermond" },
  { Country: "Netherlands", Parish: "RK Stadsparochie Marcellinus & Petrus Geleen", Diocese: "Diocese of Roermond" },
  { Country: "Netherlands", Parish: "Parochiefederatie Born", Diocese: "Diocese of Roermond" },
  { Country: "Netherlands", Parish: "St. Christoffel Kathedraal", Diocese: "Diocese of Roermond" },
  { Country: "Argentina", Parish: "Parroquia Nuestra Señora del Rosario, Paraná", Diocese: "Archdiocese of Paraná" },
  { Country: "India", Parish: "Don Bosco Parish, Jhabua", Diocese: "Diocese of Jhabua" },
  { Country: "Colombia", Parish: "Parroquía Jesús de la Misericordia", Diocese: "Arquidiocesis de Villavicencio" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora do Carmo", Diocese: "Arquidiocese de Londrina" },
  { Country: "France", Parish: 'Saint Vincent-de-Paul "les Réformés", Marseille', Diocese: "Archdiocese of Marseille" },
  { Country: "Italy", Parish: "The Chaplaincy For Migrants, Caserta, Italy", Diocese: "Diocese of Aversa" },
  { Country: "Italy", Parish: "Parrocchia ss. annunziata e s. giuseppe", Diocese: "Diocese of Aversa" },
  { Country: "Italy", Parish: "San Mauro Vescovo, San mauro Pascoli", Diocese: "Rimini Diocese" },
  { Country: "Italy", Parish: "Immacolata e san Michele, Volla", Diocese: "Ardiocese of NAPOLI" },
  { Country: "Italy", Parish: "s. Maria Apparente", Diocese: "Ardiocese of NAPOLI" },
  { Country: "Italy", Parish: "San Siro di Struppa, Genova", Diocese: "Archdiocese of Genova" },
  { Country: "Italy", Parish: "Nostra Signora di Loreto, Genova", Diocese: "Archdiocese of Genova" },
  { Country: "Italy", Parish: "Nativita di gesu, Genova", Diocese: "Archdiocese of Genova" },
  { Country: "Italy", Parish: "Santa Maria Assunta di Sestri Ponente", Diocese: "Archdiocese of Genova" },
  { Country: "Italy", Parish: "San Giacomo Maggiore Parish", Diocese: "Archdiocese of Genova" },
  { Country: "Italy", Parish: "St. Anne of Teglia", Diocese: "Archdiocese of Genova" },
  { Country: "Italy", Parish: "S. Pietro Apostolo", Diocese: "Archdiocese of Ferrara-Comacchio" },
  { Country: "Mexico", Parish: "Parroquia Universitaria San Juan Bosco, Monterrey", Diocese: "Archdiocese of Monterrey" },
  { Country: "Mexico", Parish: "Santa Clara de Asís", Diocese: "Archdiocese of Monterrey" },
  { Country: "Mexico", Parish: "Nuestra Señora de Fatima", Diocese: "Archdiocese of Monterrey" },
  { Country: "Mexico", Parish: "Parroquia Santa María de Guadalupe SN", Diocese: "Archdiocese of Monterrey" },
  { Country: "Mexico", Parish: "Nuestra Madre Santísima de la Luz", Diocese: "Archdiocese of Monterrey" },
  { Country: "Argentina", Parish: "Santísima Trinidad, Avellaneda", Diocese: "Avellaneda-Lanus Diocese" },
  { Country: "Colombia", Parish: "Parroquia Nuestra Señora del Perpetuo Socorro", Diocese: "Sonsón Rionegro" },
  { Country: "Colombia", Parish: "El camino de Emaús", Diocese: "Sonsón Rionegro" },
  { Country: "Mexico", Parish: "Parroquia San Chárbel", Diocese: "Diocese of Veracruz" },
  { Country: "Nicaragua", Parish: "Parroquia San Pedro Apostol", Diocese: "Diócesis de Juigalpa" },
  { Country: "Nicaragua", Parish: "Parroquia de Santa Ana, Santo Tomás, Nicaragua", Diocese: "Diócesis de Juigalpa" },
  { Country: "Nicaragua", Parish: "Parroquia de Santiago Apóstol, Santo Tomás, Nicaragua", Diocese: "Diócesis de Juigalpa" },
  { Country: "Slovenia", Parish: "Bakovci, Murska Sobota", Diocese: "Diocese of Murska Sobota" },
  { Country: "Slovenia", Parish: "Slovenj Gradec, Slovenia", Diocese: "Archdiocese of Maribor" },
  { Country: "Slovenia", Parish: "Pastoralna zveza župnij Slovenj Gradec", Diocese: "Archdiocese of Maribor" },
  { Country: "Slovenia", Parish: "Lovrenc na Pohorju", Diocese: "Archdiocese of Maribor" },
  { Country: "Slovenia", Parish: "Muta Church, dedicated to Saint Margaret", Diocese: "Archdiocese of Maribor" },
  { Country: "Italy", Parish: "Santa Rosalia, Palermo", Diocese: "Archdiocese of Palermo" },
  { Country: "Italy", Parish: "San Vincenzo de Paoli", Diocese: "Archdiocese of Palermo" },
  { Country: "Italy", Parish: "San Severo in Savio", Diocese: "Archdiocese of Ravenna-Cervia" },
  { Country: "Italy", Parish: "San Vincenzo e Beata vergine delle Grazie", Diocese: "Diocese of Como" },
  { Country: "Italy", Parish: "S. Pietro apostolo, Salerno", Diocese: "Diocese of Nola" },
  { Country: "Italy", Parish: "San Pietro Cicciano", Diocese: "Diocese of Nola" },
  { Country: "Italy", Parish: "San Michele Arcangelo", Diocese: "Diocese of Latina - Terracina - Sezze - Priverno" },
  { Country: "Italy", Parish: "San Ludovico d’Angió", Diocese: "Archidiocese of Napoli" },
  { Country: "Italy", Parish: "Parrocchia SS. Pietro E Paolo", Diocese: "Archidiocese of Napoli" },
  { Country: "Italy", Parish: "S. Cuore di Gesú", Diocese: "Archidiocese of Napoli" },
  { Country: "Italy", Parish: "Sacro Cuore di Gesú", Diocese: "Archidiocese of Napoli" },
  { Country: "Italy", Parish: "Santa Maria delle Grazie e San Gennaro in Trecase", Diocese: "Archidiocese of Napoli" },
  { Country: "Italy", Parish: "Parrocchia Immacolata a Pizzofalcone", Diocese: "Archidiocese of Napoli" },
  { Country: "Italy", Parish: "S. Maria Apprente", Diocese: "Archidiocese of Napoli" },
  { Country: "Italy", Parish: "Santa Maria dell'Olivo, Roma", Diocese: "Diocese of Rome" },
  { Country: "Vatican City", Parish: "Sant'Anna dei Palafrenieri", Diocese: "Diocese of Rome" },
  { Country: "Germany", Parish: "Päpstliches Institut Santa Maria dell'Anima", Diocese: "Diocese of Rome" },
  { Country: "Italy", Parish: "Collegio del Verbo Divino", Diocese: "Diocese of Rome" },
  { Country: "Italy", Parish: "San Cleto", Diocese: "Diocese of Rome" },
  { Country: "United Kingdom", Parish: "Holy Trinity South Woodford (C of E)", Diocese: "Anglican Diocese of Chelmsford" },
  { Country: "United Kingdom", Parish: "St James Clacton-on-Sea with St Christopher's, Jaywick (C of E)", Diocese: "Anglican Diocese of Chelmsford" },
  { Country: "United Kingdom", Parish: "St Edward the Confessor, Romford", Diocese: "Anglican Diocese of Chelmsford" },
  { Country: "United Kingdom", Parish: "St. James the Great, East Hill, Colchester", Diocese: "Anglican Diocese of Chelmsford" },
  { Country: "United Kingdom", Parish: "Ingrave, St Nicholas and St Stephen’s, Brentwood", Diocese: "Anglican Diocese of Chelmsford" },
  { Country: "Portugal", Parish: "Nova Oeiras, Lisboa, Portugal", Diocese: "Archdiocese of Lisboa" },
  { Country: "Portugal", Parish: "Unidade Pastoral de Sintra", Diocese: "Archdiocese of Lisboa" },
  { Country: "Portugal", Parish: "Our Lady of Martyrs Catholic Church", Diocese: "Archdiocese of Lisboa" },
  { Country: "Portugal", Parish: "Nossa Senhora da Conceição de Olivais Sul", Diocese: "Archdiocese of Lisboa" },
  { Country: "Portugal", Parish: "Lumiar", Diocese: "Archdiocese of Lisboa" },
  { Country: "Portugal", Parish: "São Julião da Barra", Diocese: "Archdiocese of Lisboa" },
  { Country: "Italy", Parish: "Unità Pastorale Trasfigurazione del Signore", Diocese: "Diocese of Brescia" },
  { Country: "Italy", Parish: "Parrocchia Di S. Stefano", Diocese: "Diocese of Brescia" },
  { Country: "Italy", Parish: "San Matteo Apostolo in Agerola", Diocese: "Archdiocese of Amalfi - Cava de' Tirreni" },
  { Country: "Italy", Parish: "Chiesa di Santa Maria del Lacco", Diocese: "Archdiocese of Amalfi - Cava de' Tirreni" },
  { Country: "Italy", Parish: "Seelsorgeeinheit Tramin", Diocese: "Diocese of Bozen-Brixen" },
  { Country: "Italy", Parish: "Laces-Tarres, Chiesa Parrocchiale", Diocese: "Diocese of Bozen-Brixen" },
  { Country: "Italy", Parish: "Pfarre Schlanders", Diocese: "Diocese of Bozen-Brixen" },
  { Country: "Italy", Parish: "Madonna delle Grazie, Modica", Diocese: "Diocese of Noto" },
  { Country: "Italy", Parish: "San Giuseppe, Jesi", Diocese: "Diocese of Jesi" },
  { Country: "Portugal", Parish: "Campo De Besteiros & Santiago De Besteiros", Diocese: "Diocese of Viseu" },
  { Country: "Thailand", Parish: "Xavier Learning Community", Diocese: "Chiang Rai Diocese" },
  { Country: "Portugal", Parish: "União de Freguesias Lordelo do Ouro e Massarelos", Diocese: "Diocese of Porto" },
  { Country: "Portugal", Parish: "S.Bartolomeu de Arouca", Diocese: "Diocese of Porto" },
  { Country: "Italy", Parish: "Urinando", Diocese: "Diocese of Bergamo" },
  { Country: "Mexico", Parish: "Inmaculado Corazón de María. Col. Guerrero", Diocese: "Arquidiócesis Primada de México" },
  { Country: "Mexico", Parish: "Nuestra Señora del Perpetuo Socorro, Torre Blanca", Diocese: "Arquidiócesis Primada de México" },
  { Country: "Mexico", Parish: "P. Resurrección del Señor", Diocese: "Arquidiócesis Primada de México" },
  { Country: "Mexico", Parish: "P. La ascensión del Señor Coyoacan", Diocese: "Arquidiócesis Primada de México" },
  { Country: "Mexico", Parish: "P. Francesa", Diocese: "Arquidiócesis Primada de México" },
  { Country: "Netherlands", Parish: "Parochie Sint Jan de Doper", Diocese: "Bisdom Rotterdam (Roman Catholic Diocese of Rotterdam)" },
  { Country: "Colombia", Parish: "Parroquia de Santiago Apostol", Diocese: "Cali" },
  { Country: "Venezuela", Parish: "San José obrero-el Pao", Diocese: "Ciudad Guayana" },
  { Country: "Guyana", Parish: "Parroquia Cristo Rey", Diocese: "Ciudad Guayana" },
  { Country: "Costa Rica", Parish: "San Pedro Apóstol", Diocese: "Diocese of Buenos Aires" },
  { Country: "Argentina", Parish: "Parroquia Nuestra Señora de Luján", Diocese: "Diocese of Buenos Aires" },
  { Country: "Argentina", Parish: "Parroquia Tránsito de la Santísima Virgen", Diocese: "Diocese of Buenos Aires" },
  { Country: "Argentina", Parish: "Parroquia Nuestra Señora del Perpetuo Socorro", Diocese: "Diocese of Buenos Aires" },
  { Country: "Argentina", Parish: "Parroquia Sagrada Eucaristía", Diocese: "Diocese of Buenos Aires" },
  { Country: "Mexico", Parish: "Señor de la Misericordia", Diocese: "Arquidiócesis de Chihuahua" },
  { Country: "Mexico", Parish: "Parroquia de Santo Niño de Jesús de Praga", Diocese: "Arquidiócesis de Chihuahua" },
  { Country: "Mexico", Parish: "Parroquia Jesus Buen Pastor", Diocese: "Arquidiócesis de Chihuahua" },
  { Country: "Mexico", Parish: "Iglesia Nuestra Señora de Fátima", Diocese: "Arquidiócesis de Chihuahua" },
  { Country: "Mexico", Parish: "Parroquia Nuestra Señora Refugio De Pecadores", Diocese: "Arquidiócesis de Chihuahua" },
  { Country: "Switzerland", Parish: "Lugano Besso, chiesa di San Nicolao", Diocese: "Lugano / Svizzera" },
  { Country: "Italy", Parish: "Maria santissima della Cava", Diocese: "Diocesi di Mazara del Vallo" },
  { Country: "Canada", Parish: "St. Charbel's Maronite Catholic Church", Diocese: "Eparchy of Saint Maron, Canada" },
  { Country: "Canada", Parish: "Our Lady of Lebanon Meronite Catholic Church", Diocese: "Eparchy of Saint Maron, Canada" },
  { Country: "Italy", Parish: "Sacro Cuore Immacolato di Maria", Diocese: "Terni Narin Amelia" },
  { Country: "India", Parish: "The Church of Immaculate Conception", Diocese: "Archdiocese of Delhi" },
  { Country: "India", Parish: "St. Sebastian's Church, East Delhi", Diocese: "Archdiocese of Delhi" },
  { Country: "United Kingdom", Parish: "St Richards Three Bridges", Diocese: "Anglican Diocese of Chichester" },
  { Country: "United Kingdom", Parish: "St. Helen's & St. Richard's, Hangleton (C of E)", Diocese: "Anglican Diocese of Chichester" },
  { Country: "United Kingdom", Parish: "Parish of Chidham (C of E)", Diocese: "Anglican Diocese of Chichester" },
  { Country: "United Kingdom", Parish: "All Saints, Hove", Diocese: "Anglican Diocese of Chichester" },
  { Country: "Ireland", Parish: "St. Mary's Dominican Parish", Diocese: "Diocese of Galway" },
  { Country: "Slovenia", Parish: "Zupnija Celje - Sveti Duh", Diocese: "Skofija Celje" },
  { Country: "Slovenia", Parish: "Polzela", Diocese: "Skofija Celje" },
  { Country: "United Kingdom", Parish: "Christ Church Lancaster (C of E)", Diocese: "Anglican Diocese of Blackburn" },
  { Country: "United Kingdom", Parish: "St Aidan & St Leonard Churches (C of E)", Diocese: "Anglican Diocese of Blackburn" },
  { Country: "United Kingdom", Parish: "St Georges, Preston (C of E)", Diocese: "Anglican Diocese of Blackburn" },
  { Country: "United Kingdom", Parish: "St Leonard's Balderstone", Diocese: "Anglican Diocese of Blackburn" },
  { Country: "United Kingdom", Parish: "St Peter & St Paul, Rishton", Diocese: "Anglican Diocese of Blackburn" },
  { Country: "United Kingdom", Parish: "Bro Ardudwy", Diocese: "Anglican Diocese of Bangor" },
  { Country: "United Kingdom", Parish: "Bro Ystumanner Ministry Area", Diocese: "Anglican Diocese of Bangor" },
  { Country: "Canada", Parish: "St. Paul's Anglican Church, Kingston", Diocese: "Anglican Diocese of Ontario" },
  { Country: "United Kingdom", Parish: "Holy Trinity Brompton (C of E)", Diocese: "Anglican Diocese of London" },
  { Country: "United Kingdom", Parish: "St Peter's, Bethnal Green, (C of E)", Diocese: "Anglican Diocese of London" },
  { Country: "United Kingdom", Parish: "St Eth's, Fulham (C of E)", Diocese: "Anglican Diocese of London" },
  { Country: "United Kingdom", Parish: "St. Thomas, Kensal Town", Diocese: "Anglican Diocese of London" },
  { Country: "United Kingdom", Parish: "St Matthew's Brixton", Diocese: "Anglican Diocese of London" },
  { Country: "United Kingdom", Parish: "St Peters", Diocese: "Anglican Diocese of London" },
  { Country: "United Kingdom", Parish: "St Marks", Diocese: "Anglican Diocese of London" },
  { Country: "United Kingdom", Parish: "St Peter's Church in Notting Hill", Diocese: "Anglican Diocese of London" },
  { Country: "Mexico", Parish: "San Juan Bosco", Diocese: "Nuevo Laredo" },
  { Country: "United Kingdom", Parish: "St Thomas, Stopsley, Luton", Diocese: "Anglican Diocese of St Albans" },
  { Country: "United Kingdom", Parish: "St Mary's Luton (C of E)", Diocese: "Anglican Diocese of St Albans" },
  { Country: "United Kingdom", Parish: "St Martin's", Diocese: "Anglican Diocese of St Albans" },
  { Country: "Italy", Parish: "St. Daniel the Prophet, POVEGLIANO", Diocese: "Diocese of Treviso" },
  { Country: "Philippines", Parish: "Our Lady of Purification Parish", Diocese: "Archdiocese of Lingayen-Dagupan" },
  { Country: "New Zealand", Parish: "Holy Trinity, Wainuiomata", Diocese: "Anglican Diocese of Wellington" },
  { Country: "Canada", Parish: "8Wing Chapel", Diocese: "Roman Catholic Military Ordinariate\xa0of Canada" },
  { Country: "Grenada", Parish: "Christ the King", Diocese: "Diocese of St. George's in Grenada" },
  { Country: "Argentina", Parish: "Santuario de Fatima", Diocese: "Archdiocese of Córdoba" },
  { Country: "Argentina", Parish: "Parroquia San Jerónimo", Diocese: "Archdiocese of Santa Fe de la Vera Cruz" },
  { Country: "Argentina", Parish: "Parroquia Nuestra Señora de la Asunción", Diocese: "Archdiocese of Resistencia" },
  { Country: "Argentina", Parish: "Iglesia Santo Cristo", Diocese: "Diocese of Avellaneda–Lanús" },
  { Country: "Argentina", Parish: "Parroquia Santa Isabel de Hungría", Diocese: "Diocese of Avellaneda–Lanús" },
  { Country: "Argentina", Parish: "Parroquia Nuestra Señora De Itati Goya", Diocese: "Diocese of Goya" },
  { Country: "Argentina", Parish: "Parroquia Virgen de las Flores", Diocese: "Diocese of Moron" },
  { Country: "Argentina", Parish: "Parroquia Cristo Rey Oberá", Diocese: "Diocese of Oberá" },
  { Country: "Argentina", Parish: "Parroquia Cristo Rey San Francisco", Diocese: "Diocese of San Francisco, Argentina" },
  { Country: "Argentina", Parish: "Iglesia María Auxiliadora", Diocese: "Diocese of San Luis" },
  { Country: "Argentina", Parish: "Parroquia San José", Diocese: "Diocese of San Martín" },
  { Country: "Argentina", Parish: "Parroquia Niño Jesús de Praga", Diocese: "Diocese of San Isidro" },
  { Country: "Argentina", Parish: "Parroquia Nuestra Señora De La Unidad", Diocese: "Diocese of San Isidro" },
  { Country: "Bolivia", Parish: "Parroquia Nuestra Señora de Fátima", Diocese: "Archdiocese of La Paz" },
  { Country: "Brazil", Parish: "Paróquia São Maximiliano Kolbe", Diocese: "Diocese of Mogi das Cruzes" },
  { Country: "Brazil", Parish: "PARÓQUIA SÃO PEDRO APÓSTOLO", Diocese: "Diocese of Mogi das Cruzes" },
  { Country: "Brazil", Parish: "Paróquia São Judas Tadeu - Suzano", Diocese: "Diocese of Mogi das Cruzes" },
  { Country: "Brazil", Parish: "Paróquia de São José", Diocese: "Archdiocese of Campinas" },
  { Country: "Brazil", Parish: "Igreja Matriz, Nossa Sra. da Glória", Diocese: "Diocese of Cruzeiro do Sul" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora da Visitação", Diocese: "Diocese of Caraguatatuba" },
  { Country: "Brazil", Parish: "Paróquia São João Batista", Diocese: "Diocese of Caraguatatuba" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora de Fátima do Campolim", Diocese: "Archdiocese of Sorocaba" },
  { Country: "Brazil", Parish: "St. John the Baptist Cathedral, Barreiras", Diocese: "Diocese de Barreiras" },
  { Country: "Brazil", Parish: "Paróquia São José - Bragança Paulista", Diocese: "Diocese de Bragança Paulista" },
  { Country: "Brazil", Parish: "Igreja Matriz Paróquia Divino Espírito Santo", Diocese: "Diocese of Cachoeiro de Itapemirim" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora da Penha", Diocese: "Diocese of Cachoeiro de Itapemirim" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora das Graças", Diocese: "Diocese of Limeira" },
  { Country: "Brazil", Parish: "Comunidade Católica Ruah Adonai", Diocese: "Diocese de Campo Limpo" },
  { Country: "Brazil", Parish: "Paroquial Nossa Senhora do Pilar", Diocese: "Diocese de Duque de Caxias e Sao Joa" },
  { Country: "Brazil", Parish: "Paróquia de São José do Carpina", Diocese: "Diocese de Nazaré" },
  { Country: "Brazil", Parish: "Paroquia Sao Domingos", Diocese: "Diocese de Osasco" },
  { Country: "Brazil", Parish: "Our Lady of Fatima", Diocese: "Diocese de Osasco" },
  { Country: "Brazil", Parish: "Paróquia São Paulo Apóstolo", Diocese: "Diocese de Santos" },
  { Country: "Brazil", Parish: "Paróquia Santo Antônio de Hidrolândia", Diocese: "Archidiocèse de Goiânia" },
  { Country: "Brazil", Parish: "Paróquia Santa Terezinha do Menino Jesus", Diocese: "Diocese de Guaxupé" },
  { Country: "Brazil", Parish: "Paróquia São José", Diocese: "Diocese de Guaxupé" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora Mãe de Deus", Diocese: "Diocese de Ipameri" },
  { Country: "Brazil", Parish: "Paróquia Santa Teresinha do Menino Jesus", Diocese: "Diocese de Jundiaí" },
  { Country: "Brazil", Parish: "PARÓQUIA SÃO JOÃO BATISTA", Diocese: "Diocese de Leopoldina" },
  { Country: "Brazil", Parish: "Paróquia São José", Diocese: "Diocese de Leopoldina" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora Rosa Mística", Diocese: "\xa0Centenario Archdiocese of Maceió" },
  { Country: "Brazil", Parish: "Capela Rainha dos Apóstolos", Diocese: "Archdiocese of Londrina" },
  { Country: "Brazil", Parish: "Catedral Basílica Menor Nossa Senhora da Glória", Diocese: "Archdiocese de Maringá" },
  { Country: "Brazil", Parish: "Paróquia de São José", Diocese: "Diocese of Penedo" },
  { Country: "Brazil", Parish: "Igreja São Benedito", Diocese: "Arquidiocese de Ribeirão Preto" },
  { Country: "Brazil", Parish: "Basílica Santuário de São Sebastião", Diocese: "Archdiocese of São Sebastião do Rio de Janeiro" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora da Vitória", Diocese: "Archdiocese of São Sebastião do Rio de Janeiro" },
  { Country: "Brazil", Parish: "Paróquia São Francisco de Paula", Diocese: "Archdiocese of São Sebastião do Rio de Janeiro" },
  { Country: "Brazil", Parish: "Paróquia Santa Mônica", Diocese: "Archdiocese of São Sebastião do Rio de Janeiro" },
  { Country: "Brazil", Parish: "Santuário São Judas Tadeu", Diocese: "Archdiocese of São Sebastião do Rio de Janeiro" },
  { Country: "Brazil", Parish: "Paróquia São João Maria Vianney", Diocese: "Diocese of Santo Amaro" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora Guadalupe", Diocese: "Diocese de São Carlos" },
  { Country: "Brazil", Parish: "Paróquia São Domingos", Diocese: "Diocese of São João da Boa Vista" },
  { Country: "Brazil", Parish: "Igreja da Conceição", Diocese: "Diocese of São João del Rei" },
  { Country: "Brazil", Parish: "Paróquia de Santo Antônio", Diocese: "Diocese of São José dos Pinhais" },
  { Country: "Brazil", Parish: "Paróquia de São Pedro", Diocese: "Archdiocese of São Salvador da Bahia" },
  { Country: "Brazil", Parish: "PARÓQUIA CRISTO REI - CATEDRAL", Diocese: "Diocese de Toledo" },
  { Country: "Brazil", Parish: "Paróquia Nossa Senhora de Fátima", Diocese: "Diocese de Umuarama" },
  { Country: "Colombia", Parish: "Parroquia San Jose", Diocese: "Archdiocese of Cali" },
  { Country: "Colombia", Parish: "Parroquia Santo Tomas de Aquino", Diocese: "Arquidiocesis de Medellin" },
  { Country: "Colombia", Parish: "Parroquia Espiritu Santo", Diocese: "Diócesis de Ocaña" },
  { Country: "Colombia", Parish: "Parroquia San Antonio de Padua", Diocese: "Diocese of Socorro y San Gil" },
  { Country: "Colombia", Parish: "Templo parroquia San Juan Bautista", Diocese: "Diocese of Tumaco" },
  { Country: "United States", Parish: "Church of the Ascension, Saratoga CA", Diocese: "Archdiocese of San José" },
  { Country: "Costa Rica", Parish: "Parroquia San Francisco de Asís, Tepeyac", Diocese: "Archdiocese of San José" },
  { Country: "Italy", Parish: "Santuario Maria SS. della Cava", Diocese: "Diocesi\xa0di\xa0Mazara del Vallo" },
  { Country: "Italy", Parish: "Basílica de Sant'Eustorgio", Diocese: "Arcidiocesi di Milano" },
  { Country: "Japan", Parish: "St. Igntius Church, Tokyo", Diocese: "Catholic Archdiocese of Tokyo" },
  { Country: "Italy", Parish: "Chiesa della Sacra Famiglia", Diocese: "Diocesi di Prato" },
  { Country: "Malaysia", Parish: "Blessed Sacrament Church, Kuching", Diocese: "Diocese of Kuching" },
  { Country: "Mexico", Parish: "Nuestra Señora de Guadalupe Río de Luz", Diocese: "Diocese of Ecatepec" },
  { Country: "Mexico", Parish: "Jesús de Nazareth", Diocese: "Diócesis de Saltillo" },
  { Country: "Mexico", Parish: "Parroquia de Nuestra Señora de Lourdes", Diocese: "Diócesis de Tampico" },
  { Country: "Mexico", Parish: "Iglesia San José", Diocese: "Diocese of Torreón" },
  { Country: "Netherlands", Parish: "Parochie Heilige Maria", Diocese: "Dioecesis Buscoducensis" },
  { Country: "Netherlands", Parish: "Hl. Titus Brandsmaparochie", Diocese: "Archdiocese of Utrecht" },
  { Country: "Brazil", Parish: "Paróquia São Francisco De Assis - Lins", Diocese: "Diocese of Lins" },
  { Country: "Brazil", Parish: "Santuário Diocesano Nossa Senhora de Fátima", Diocese: "Diocese of Lins" },
  { Country: "Brazil", Parish: "Igreja de São João Batista", Diocese: "Archdiocese of São Luís do Maranhão" },
  { Country: "Lithuania", Parish: "St.Virgin Mary of Consolation Church", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "John Paul II Parish", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Church of Sts. Peter and Paul", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Church of St. Archangel Raphael", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Vilniaus šv. vysk. Stanislovo ir šv. Vladislovo Arkikatedra Bazilika", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Church of Saints Philip and James", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Prisikėlimo", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Vilniaus Visų Šventųjų parapija", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Parudaminio Kristaus Atsimainymo parapija", Diocese: "Archdiocese of Vilnius" },
  { Country: "Lithuania", Parish: "Parudaminio Kristaus Atsimainymo parapija", Diocese: "Archdiocese of Vilnius" },
  { Country: "United States", Parish: "Church of the Ascension, Orlando", Diocese: "Diocese of Central Florida, Anglican" },
  { Country: "Italy", Parish: "Mazzarrà Sant'Andrea", Diocese: "Diocesi di Messina" },
  { Country: "Spain", Parish: "Cristo de la Misericordia", Diocese: "Constitución, 2" },
  { Country: "Spain", Parish: "Ntra Sra de la Asunción", Diocese: "Ciudad Real" },
  { Country: "Spain", Parish: "San Juan Bautista", Diocese: "Ciudad Real" },
  { Country: "Spain", Parish: "Asuncion de Nuestra Señora", Diocese: "Ciudad Real" },
  { Country: "Spain", Parish: "San Carlos Borromeo", Diocese: "Diocese of Getafe" },
  { Country: "Spain", Parish: "San Timoteo", Diocese: "Diocese of Madrid" },
  { Country: "Spain", Parish: "Encarnación del Señor", Diocese: "Diocese of Madrid" },
  { Country: "Spain", Parish: "Santa Ángela de la Cruz", Diocese: "Diocese of Madrid" },
  { Country: "Spain", Parish: "Juan Sao Pablo II", Diocese: "Diocese of Granada" },
  { Country: "Ghana", Parish: "St. Dominic Savio", Diocese: "Diocese of Accra" },
  { Country: "Canada", Parish: "Sts. Vladimir and Olga Ukrainian Catholic Church, Windsor", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "St. Josephs Ukrainian Catholic Church", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "St. Mary's Ukrainian Catholic Church", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "Sts. Cyril and Methodius Ukrainian Catholic Parish", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "St. Mary's Ukrainian Catholic Parish, Sudbury", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "Holy Protection of the Blessed Virgin Mary Ukrainian Catholic Church", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "St. George's Ukrainian Catholic Church", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "Assumption of the Blessed Virgin Mary Ukrainian Catholic Church", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "St. Nicholas Ukrainian Catholic Church, Toronto", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "St. George the Great Martyr Ukrainian Catholic Church", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "Dormition of the Mother of God Ukrainian Catholic Church", Diocese: "The Eparchy of Toronto and Eastern Canada" },
  { Country: "Canada", Parish: "Holy Trinity Ukrainian Catholic Church", Diocese: "Ukrainian Catholic Archeparchy of Winnipeg" },
  { Country: "Canada", Parish: "Holy Eucharist Ukrainian Catholic Church", Diocese: "Ukrainian Catholic Archeparchy of Winnipeg" },
  { Country: "Canada", Parish: "Ukrainian Catholic Rossburn Pastoral District", Diocese: "Ukrainian Catholic Archeparchy of Winnipeg" },
  { Country: "Argentina", Parish: "Santa Rita de Casia", Diocese: "San Justo" },
  { Country: "Mexico", Parish: "La Resurreccion de Jesus", Diocese: "Diocesis de Cuernavaca" },
  { Country: "Argentina", Parish: "San Cayetano Church (Iglesia San Cayetano)", Diocese: "Roman Catholic Archdiocese of Rosario" },
  { Country: "Chile", Parish: "Parroquia San Francisco Javier De Peralillo", Diocese: "Diócesis de Rancagua" },
  { Country: "Mexico", Parish: "La Divina Providencia", Diocese: "Diocese of Azcapotzalco" },
  { Country: "Canada", Parish: "Holy Eucharist Ukrainian Catholic Cathedral", Diocese: "New Westminster Eparchy" },
  { Country: "Canada", Parish: "Dormition Ukrainian Catholic Parish", Diocese: "Ukrainian Catholic Eparchy of Saskatoon" },
  { Country: "Canada", Parish: "St. Basil (Regina) Ukrainian Catholic Church", Diocese: "Ukrainian Catholic Eparchy of Saskatoon" },
  { Country: "Canada", Parish: "St Stephen Protomartyr Ukrainian Catholic Church", Diocese: "Ukrainian Catholic Eparchy of Edmonton" },
  { Country: "Canada", Parish: "The Assumption of the Blessed Virgin Mary Ukrainian Catholic Church", Diocese: "Ukrainian Catholic Eparchy of Edmonton" },
  { Country: "Netherlands", Parish: "Parochiefederatie Gennep", Diocese: "Bisdom Roermond" },
  { Country: "Philippines", Parish: "Sacred Heart Parish", Diocese: "Chancery Diocese of Novaliches" },
  { Country: "Ireland", Parish: "St Patrick's Church Greystones", Diocese: "Church of Ireland Diocese of Dublin and Glendalough" },
  { Country: "Canada", Parish: "St Ambrose Co-Cathedral", Diocese: "St. Ambrose Parish" },
  { Country: "Slovenia", Parish: "Župnija Ljubljana Dravlje", Diocese: "Roman Catholic Archdiocese of Ljubljana" },
  { Country: "Slovenia", Parish: "Župnija Preska - Župnijski urad Preska", Diocese: "Roman Catholic Archdiocese of Ljubljana" },
  { Country: "United Kingdom", Parish: "St Bertelin, Stafford (C of E)", Diocese: "Anglican Diocese of Lichfield" },
  { Country: "United Kingdom", Parish: "St Gabriel's Fullbrook (C of E)", Diocese: "Anglican Diocese of Lichfield" },
  { Country: "Canada", Parish: "New Parish", Diocese: "New Diocese" },
  { Country: "Belize", Parish: "San Pedro Roman Catholic Church", Diocese: "Diocese of Belize City–Belmopan" },
  { Country: "Canada", Parish: "ST. MICHAEL & ALL ANGELS", Diocese: "Anglican Diocese of Western Newfoundland" },
  { Country: "Canada", Parish: "The Ascension", Diocese: "Anglican Diocese of Eastern Newfoundland" },
  { Country: "Mexico", Parish: "Iglesia San Antonio Maria Claret Nuevo Laredo", Diocese: "Roman Catholic Diocese of San Nicolás de los Arroyos" },
  { Country: "Argentina", Parish: "Parroquia María Madre de la Iglesia", Diocese: "Roman Catholic Diocese of San Nicolás de los Arroyos" },
  { Country: "Argentina", Parish: "Maria Madre de la Iglesia", Diocese: "San Nicolas" },
  { Country: "Mexico", Parish: "San Antonio María Claret", Diocese: "Nuevo Laredo" },
  { Country: "Colombia", Parish: "Seminario Menor JPII", Diocese: "San Vicente del Caguán- Caquetá-" },
  { Country: "Argentina", Parish: "San Cayetano", Diocese: "Avellaneda-Lanus" },
  { Country: "Chile", Parish: "San Judas Tadeo Malloa", Diocese: "Rancagua Sexta Region" },
  { Country: "Mexico", Parish: "Sagrada familia", Diocese: "La Paz, Baja California Sur" },
  { Country: "United States", Parish: "Holy Cross-Ss. Constantine & Helen Greek Orthodox Church", Diocese: "Greek Orthodox Metropolis of Atlanta" },
  { Country: "United States", Parish: "Holy Cross - Ss. Constantine & Helen Greek Orthodox Church", Diocese: "Greek Orthodox Metropolis of Atlanta" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Belarus", Parish: "St. Ladislaus", Diocese: "Diocese of Vitebsk" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "no information", Diocese: "no information" },
  { Country: "United Kingdom", Parish: "St James, Hamsterley (C of E)", Diocese: "Anglican Diocese of Durham" },
  { Country: "United Kingdom", Parish: "St Aidan, Sunderland C of E", Diocese: "Anglican Diocese of Durham" },
  { Country: "United Kingdom", Parish: "St Aidan", Diocese: "Anglican Diocese of Durham" },
  { Country: "United Kingdom", Parish: "St Christopher, Allesley Hall and Whoberley (C of E)", Diocese: "Anglican Diocese of Coventry" },
  { Country: "United Kingdom", Parish: "St Margaret's", Diocese: "Anglican Diocese of Coventry" },
  { Country: "United Kingdom", Parish: "Christ Church Toxteth Park", Diocese: "Anglican Diocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St Thomas Eccleston, (C of E)", Diocese: "Anglican Diocese of Liverpool" },
  { Country: "United Kingdom", Parish: "St James' Church, Milton (C of E)", Diocese: "Anglican Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "Holy Trinity & St Columba, Fareham (C of E)", Diocese: "Anglican Diocese of Portsmouth" },
  { Country: "United Kingdom", Parish: "The Trunch Group of Parishes (C of E)", Diocese: "Anglican Diocese of Norwich" },
  { Country: "United Kingdom", Parish: "Old Mills Parishes (C of E)", Diocese: "Anglican Diocese of Worcester" },
  { Country: "United Kingdom", Parish: "St Mary's, Worcester", Diocese: "Anglican Diocese of Worcester" },
  { Country: "Poland", Parish: "St. Jude Thaddeus", Diocese: "Roman Catholic Archdiocese of Gdańsk" },
  { Country: "Poland", Parish: "Exaltation of the Holy Cross", Diocese: "Roman Catholic Archdiocese of Gdańsk" },
  { Country: "Poland", Parish: "Św. Mikołaja", Diocese: "Roman Catholic Archdiocese of Gdańsk" },
  { Country: "Poland", Parish: "Holy Family Church (Kościół pw. Świętej Rodziny w Gdyni)", Diocese: "Roman Catholic Archdiocese of Gdańsk" },
  { Country: "Poland", Parish: "St. Clement Hofbauer", Diocese: "Archdiocese of Warsaw" },
  { Country: "Poland", Parish: "St. Anthony", Diocese: "Archdiocese of Warsaw" },
  { Country: "Poland", Parish: "St. James the Apostle", Diocese: "Archdiocese of Warsaw" },
  { Country: "Poland", Parish: "Parafia Świętego Łukasza Ewangelisty i Świętego Floriana Męczennika", Diocese: "Archdiocese of Łódź" },
  { Country: "Poland", Parish: "Opole Seminary", Diocese: "Diocese of Opole" },
  { Country: "Philippines", Parish: "Saint Joseph Priory - Cebu Faith Hub", Diocese: "Archdiocese of Cebu" },
  { Country: "Poland", Parish: "Parafia Rzymsko-Katolicka pw. Św. Jana XXIII", Diocese: "Archdiocese of Poznań" },
  { Country: "Poland", Parish: "Parafia Rzymskokatolicka pw. św. Łukasza Ewangelisty w Poznaniu", Diocese: "Archdiocese of Poznań" },
  { Country: "Poland", Parish: "Kościół rzymskokatolicki pw. Św. Jana Pawła II", Diocese: "Archdiocese of Poznań" },
  { Country: "Poland", Parish: "Św. Marcina Biskupa", Diocese: "Archdiocese of Poznań" },
  { Country: "Ireland", Parish: "St. Mary's Parish, Enderry", Diocese: "Diocese of Kildare and Leighlin" },
  { Country: "Poland", Parish: "Ducha Świętego w Zambrowie", Diocese: "Diecezja Łomżyńska" },
  { Country: "Slovakia", Parish: "Detvianska Huta", Diocese: "Banská Bystrica" },
  { Country: "United States", Parish: "St Padre Pio", Diocese: "Archdiocese of Chicago" },
  { Country: "United States", Parish: "Our Lady of the Ridge/St Linus", Diocese: "Archdiocese of Chicago" },
  { Country: "United Kingdom", Parish: "Filey Parish Churches (C of E)", Diocese: "Anglican Diocese of York" },
  { Country: "United Kingdom", Parish: "St Leonard & St Jude Scawsby with S. Luke's Scawthorpe", Diocese: "Anglican Diocese of York" },
  { Country: "United States", Parish: "St. Peter's Parish", Diocese: "Diocese of Ogdensburg" },
  { Country: "United Kingdom", Parish: "St Peter and the Holy Apostles Plymouth", Diocese: "Anglican Diocese of Exeter" },
  { Country: "United Kingdom", Parish: "St Mary the Virgin, Churston (C of E)", Diocese: "Anglican Diocese of Exeter" },
  { Country: "Czech Republic", Parish: "Velké Pavlovice", Diocese: "Brno" },
  { Country: "Czech Republic", Parish: "Kunštát an Moravě", Diocese: "Brno" },
  { Country: "Czech Republic", Parish: "Třebíč -zámek", Diocese: "Brno" },
  { Country: "Czech Republic", Parish: "Brno sv. Petr a Pavel - katedrála", Diocese: "Brno" },
  { Country: "United States", Parish: "Queen of Angels, St. Joseph, St. Anne, St. Thomas", Diocese: "Archdiocese of Seattle" },
  { Country: "United Kingdom", Parish: "St John the Evangelist, Dormansland (C of E)", Diocese: "Anglican Diocese of Southwark" },
  { Country: "United Kingdom", Parish: "St Augustine of Canterbury, Alston", Diocese: "Anglican Diocese of Newcastle UK" },
  { Country: "United Kingdom", Parish: "Our Lady of the Assumption", Diocese: "Polska Misja Katolicka - UK" },
  { Country: "United Kingdom", Parish: "Launceston Parish", Diocese: "Anglican Diocese of Truro" },
  { Country: "Ghana", Parish: "Divine Mercy\xa0Chaplaincy", Diocese: "Archdiocese of Kumasi" },
  { Country: "Taiwan", Parish: "Linkou St. Josef Freinademetz", Diocese: "Archdiocese of Taipei" },
  { Country: "Taiwan", Parish: "Zhonghe Mother of God", Diocese: "Archdiocese of Taipei" },
  { Country: "Taiwan", Parish: "Our Lady of Nansongshan", Diocese: "Archdiocese of Taipei" },
  { Country: "Taiwan", Parish: "Our Lady of Assumption", Diocese: "Archdiocese of Taipei" },
  { Country: "Malaysia", Parish: "St Catherine Church Inanam", Diocese: "Archdiocese of Kota Kinabalu" },
  { Country: "United Kingdom", Parish: "St Thomas & All Saints Church, Bedford, Leigh", Diocese: "Anglican Diocese of Manchester" },
  { Country: "United Kingdom", Parish: "St George's Tuffley with St Margaret's Whaddon", Diocese: "Anglican Diocese of Gloucester" },
  { Country: "United Kingdom", Parish: "St Peter's, Drayton (C of E)", Diocese: "Anglican Diocese of Oxford" },
  { Country: "United Kingdom", Parish: "St Michael and All Angels, Uffington (C of E)", Diocese: "Anglican Diocese of Oxford" },
  { Country: "United Kingdom", Parish: "St Mary’s, Charlbury and All Saints, Shorthampton.", Diocese: "Anglican Diocese of Oxford" },
  { Country: "Poland", Parish: "Ducha Świętego", Diocese: "Diocese of Kielce" },
  { Country: "Belarus", Parish: "Immaculate Heart of Mary", Diocese: "Vitebsk" },
  { Country: "Poland", Parish: "Wniebowzięcia NMP i św. Jakuba Starszego", Diocese: "no information" },
  { Country: "Ukraine", Parish: "no information", Diocese: "no information" },
  { Country: "Poland", Parish: "św. Wojciech parish", Diocese: "Zielonogórsko-Gorzowska" },
  { Country: "Taiwan", Parish: "Our lady of assumption", Diocese: "Archdiocese of Taipei" },
  { Country: "Poland", Parish: "św. Marcina Biskupa", Diocese: "Diocese of Koszalińsko-Kołobrzeska" },
  { Country: "Poland", Parish: "Świętych Apostołów Piotra i Pawła", Diocese: "Diocese of Legnica" },
  { Country: "Poland", Parish: "Matki Bożej Nieustającej Pomocy", Diocese: "Diocese of Legnica" },
  { Country: "Venezuela", Parish: "Nuestra Señora del Valle", Diocese: "Diócesis de Margarita" },
  { Country: "Poland", Parish: "Miłosierdzia Bożego", Diocese: "Diocese of Zielona Góra-Gorzów" },
  { Country: "United States", Parish: "St. Elizabeth Ann Seton", Diocese: "Catholic Diocese of Lexington" },
  { Country: "South Africa", Parish: "Holy Cross McKay's Nek", Diocese: "Queenstown" },
  { Country: "Germany", Parish: "St. Sebald, Schwabach", Diocese: "Erzdiözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrverband Buxheim-Eitensheim", Diocese: "Erzdiözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrverband Nürnberg-Südwest/Stein", Diocese: "Erzdiözese Eichstätt" },
  { Country: "Germany", Parish: "Pfarrei Mariä Himmelfahrt Berching", Diocese: "Erzdiözese Eichstätt" },
  { Country: "Colombia", Parish: "Nuestra Señora de las Lajas", Diocese: "Pasto Nariño" },
  { Country: "Mexico", Parish: "San Juan Diego", Diocese: "Diocesis de La Paz" },
  { Country: "Peru", Parish: "La Virgen de Nazaret", Diocese: "Arquidiócesis de Lima" },
  { Country: "South Africa", Parish: "Lenasia Golden Highway", Diocese: "Catholic Archdiocese of Johannesburg" },
  { Country: "South Africa", Parish: "Saint Patrick La Rochelle", Diocese: "Catholic Archdiocese of Johannesburg" },
  { Country: "Italy", Parish: "SS. Nome di Gesù", Diocese: "Conversano - Monopoli" },
  { Country: "Italy", Parish: "SS. Nome di Gesù", Diocese: "Conversano - Monopoli" },
  { Country: "Venezuela", Parish: "Nuestra Señora de la Candelaria", Diocese: "Diócesis de la Guaira" },
  { Country: "South Africa", Parish: "Ikhwezi Lokusa", Diocese: "Archdiocese of durban" },
  { Country: "Spain", Parish: "Parroquia Ntra. Sra. Del Don", Diocese: "Valencia" },
  { Country: "Spain", Parish: "Ntra Sra del Milagro y san Maximiliano M Kolbe", Diocese: "Valencia" },
  { Country: "Spain", Parish: "San Vicente Ferrer", Diocese: "Orihuela-Alicante" },
  { Country: "United Kingdom", Parish: "St Martins", Diocese: "St Albans" },
  { Country: "Nigeria", Parish: "St Theresa of the child Jesus, Totton", Diocese: "Portsmouth Diocese" },
  { Country: "Canada", Parish: "St. John the Baptist", Diocese: "Diocese of St. Paul in Alberta" },
  { Country: "Channel Islands", Parish: "St Mary and the Saints of Guernsey", Diocese: "Portsmouth UK" },
  { Country: "Switzerland", Parish: "Kloster Einsiedeln", Diocese: "Territorialabtei Kloster Einsiedeln" },
  { Country: "Portugal", Parish: "Santarém", Diocese: "Santarém" },
  { Country: "Portugal", Parish: "Nossa Senhora de Fátima - Lajedo (Ponta Delgada)", Diocese: "Angra/Açores" },
  { Country: "Switzerland", Parish: "Basel", Diocese: "Diocese de Basiléia" },
  { Country: "Portugal", Parish: "Santa Luzia", Diocese: "Angra" },
  { Country: "Netherlands", Parish: "Catharina parochie", Diocese: "Archdiocese of Mechelen-Brussels" },
  { Country: "Portugal", Parish: "Guilhufe", Diocese: "Porto" },
  { Country: "Portugal", Parish: "Portel", Diocese: "Évora" },
  { Country: "Denmark", Parish: "Aarhus", Diocese: "København" },
  { Country: "Portugal", Parish: "Penamacor", Diocese: "Diocese of Guarda" },
  { Country: "Philippines", Parish: "San Roque Parish", Diocese: "Diocese of Kalookan" },
  { Country: "Poland", Parish: "pw. św. Maksymiliana Kolbe", Diocese: "Diocese of Tarnowska" },
  { Country: "Poland", Parish: "Św. Krzyża i Matki Bożej Nieustającej Pomocy", Diocese: "Diocese of Tarnowska" },
  { Country: "Poland", Parish: "Św. Józefa i Matki Bożej Fatimskiej", Diocese: "Diocese of Tarnowska" },
  { Country: "Poland", Parish: "MBNP Kamionka Wielka", Diocese: "Diocese of Tarnowska" },
  { Country: "Poland", Parish: "Św. Jadwigi", Diocese: "Diocese of Tarnowska" },
];