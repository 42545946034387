export const DiscoverDutch = (text, type, section) => {
  switch (section) {
    case "discover-heading":
      return " Ontdek de beste volgende stappen voor uw parochie";
      case "discover-info-1":
        return "Parochievernieuwing is niet gemakkelijk, maar u hoeft het niet alleen te doen."
    case "discover-info-2":
      return "Bij Divine Renovation begeleiden we priesters en leiders die hun parochie tot leven willen zien komen door hen te voorzien van de hulpmiddelen en coaching die ze nodig hebben om vernieuwing tot stand te brengen.";
      case "discover-info-3":
        return "Laten we eens dieper in de resultaten duiken en bekijken wat deze inzichten uw parochie kunnen opleveren."
    case "get-in-touch-cta":
      return " Neem Contact Op";
    default:
      return text;
  }
}