import React, { useState, useContext } from 'react'
import { useNavigate, useRoutes } from 'react-router-dom'
import '../../App.scss'

import AuthContext from '../../Store/AuthContext'


const Login = () => {

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [ btnDisabled, setBtnDisabled ] = useState(true)

	const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  const route = useRoutes([
    {
      path: "/",
      element : <Login />  
    }
  ])
  console.log(route)


  const checkBtnDisabled = () => {
    if(email.trim().length > 0 && password.trim().length > 0) {
      return false
      } else {
        return true
      }
  }

  const accountLogin = () => {
    if(emailRegex.test(email)){
      let payload = {
        "email": email,
        "password": password
      }
  
      fetch("https://staging-api.bleat.church/v4/login", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }).then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(data.status == 'success'){
          console.log(data.token);
					const tokenExpiration = new Date(new Date().getTime() + 86400000);
          authCtx.login(
           data.token,
           data.userId,
           data.userFirstName,
           email,
           data.userScope[0],
           tokenExpiration
          )
        }
        navigate(`/dashboard/${data.userId}/overview`, { replace: true })
      })
      .catch((error) => console.error(error));

    }
  }

  return (
    <div className="login-container">
      <h1>Log in to access your dashboard</h1>
      {/* <div className="completion">See how you compare* with similarly-sized American churches</div> */}
      {/* <ol type="1" className="intro -points-list">
          {searchParams.get("leader") ? <>
            <li>1. Take this 3-minute assessment</li>
            <li>2. View your results instantly</li>
            <li>3. Evaluate if you are derailing or on track</li>
          </> : <>
            <li>1. Take this 3-minute assessment</li>
            <li>2. Get your leadership team to take it after you</li>
            <li>3. View everyone's results on 1 dashboard</li>
            <li>4. Evaluate if you are derailing or on track</li>
          </>}
        </ol> */}
      {/* <h2>To begin, tell us how you serve your parish</h2> */}

      <div className="login-inputs">
        <div className="email-input">
          <p>Email ID</p>
          <input type="text" placeholder='E.g. speter@rock.church' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="password-input">
          <p>Password</p>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button className="submit-btn active" disabled={checkBtnDisabled()} onClick={accountLogin}>Log in</button>
      </div>
      {/* </div> */}
      <div className='note'>
        <p>Forgot your password? <span>Click here.</span></p>
      </div>
    </div>
  )
}

export default Login