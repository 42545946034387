export const DashboardAnswersDutch = (text, type, section) => {
  // console.log("text: ",text,"type: ",type,"section: ",section);
  switch (section) {
    case "strongly-agree":
      return "Zeer mee eens";
    case "agree":
      return "Mee eens";
    case "neutral":
      return "Neutraal";
    case "disagree":
      return "Oneens";
    case "strongly-disagree":
      return "Zeer oneens";
    
                  
    default:
      return text;
  }
}
