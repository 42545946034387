import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import DashboardDownArrowRed from "../../Assets/Images/dashboardDownArrowRed.svg";
import useWindowDimensions from '../../Hooks/useWindowDimensions';



//Assets/Images/flags
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const flagImages = importAll(require.context('../../Assets/Images/Bleat-flags', false, /\.(png|jpe?g|svg)$/));


const MobileFlagHeader = () => {
  const { id } = useParams();
  const { windowWidth } = useWindowDimensions();
  const [newData, setNewData] = useState('')




  useEffect(() => {
    fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/parish", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setNewData(data.data);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch:", error);
      });
  }, []);







  return (
    <>
      {windowWidth <= 831 ?
        <div className='header-container'>
          <div className='user-details'>
            <div className='flag'>
              <img src={flagImages[`${newData?.parishDetails?.country}.png`]} className='flag' alt="" />
            </div>
            <div className='names'>
              <h3>{newData?.parishDetails?.diocese}</h3>
              <p>{newData?.parishDetails?.parish}</p>
            </div>
            <div className='dropdown'>
              <img src={DashboardDownArrowRed} alt="" />
            </div>
          </div>
        </div> : null
      }
    </>
  )
}

export default MobileFlagHeader