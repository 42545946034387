export const MilestoneBarDutch = (text, type, section) => {
  switch (section) {
    case "onlookers":
      return "Toeschouwers";
    case "on-the-shore":
      return "Aan wal";
    case "casting-off":
      return "Van wal steken";
    case "raising-the-sails":
      return "Het hijsen van de zeilen";
    case "sailing-the-waves":
      return "De golven bedwingen";
    case "beacon-parish":
      return "baken parochies";
    default:
      return text;
  }
}