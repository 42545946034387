import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { isEmail } from 'validator';

import AssessmentProgressContext from '../../Store/AssessmentProgressContext';
import CircularProgress from '@mui/material/CircularProgress';

import { Button } from '@mui/material';
import ProgressBarSingle from '../../Components/ProgressBarSingle/ProgressBarSingle';

import leadershipIcon from '../../Assets/Images/leadership.svg'
import evangelizationIcon from '../../Assets/Images/evangelization.svg'
import questionMark from '../../Assets/Images/question-mark.svg'
import tick from '../../Assets/Images/thankyou.svg'
import prevArrow from '../../Assets/Images/ArrowBack.svg';
import nextArrow from '../../Assets/Images/NextArrow.svg';
import FirstPauseScreen from '../../Assets/Images/first-pause-screen-img.png'
import SecondPauseScreen from '../../Assets/Images/second-pause-screen-img.png'
import ThirdPauseScreen from '../../Assets/Images/third-pause-screen-img.png'

import DesignationContext from "../../Store/DesignationContext";
import LanguageContext from "../../Store/LanguageContext";
import { translate } from "../../Components/translate/translate";

const ProgressMilestone = () => {

  const assessProgressCtx = useContext(AssessmentProgressContext);
  const langCtx = useContext(LanguageContext)
  let navigate = useNavigate();
  const { id, pageId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const designationCtx = useContext(DesignationContext);
  const [userType, setUserType] = useState(designationCtx.designation);
  

  // Assessment Progress Section Names
  // { title: "Holy Spirit",     status: "finished" },
  // { title: "Evangelization",  status: "active" },
  // { title: "Leadership",      status: "unfinished" }


  useEffect(() => {
    setUserType(designationCtx.designation);
  }, [designationCtx.designation]);

  useEffect(() => {
    if (pageId === "763") {
      assessProgressCtx.updateMainSectionsStatus((prev) => {
        return prev.map(sec => {
          if (sec.title === "Holy Spirit") return { ...sec, status: "finished" };
          if (sec.title === "Evangelization") return { ...sec, status: "active" };
          else return { ...sec, status: "unfinished" };
        })
      })
    }
    if (pageId === "765") {
      assessProgressCtx.updateMainSectionsStatus((prev) => {
        return prev.map(sec => {
          if (sec.title === "Holy Spirit") return { ...sec, status: "finished" };
          if (sec.title === "Evangelization") return { ...sec, status: "finished" };
          if (sec.title === "Leadership") return { ...sec, status: "active" };
          else return { ...sec, status: "unfinished" };
        })
      })

    }
    if (pageId === "766") {
      assessProgressCtx.updateMainSectionsStatus((prev) => {
        return prev.map(sec => {
          if (sec.title === "Holy Spirit") return { ...sec, status: "finished" };
          if (sec.title === "Evangelization") return { ...sec, status: "finished" };
          if (sec.title === "Leadership") return { ...sec, status: "finished" };
          else return { ...sec, status: "unfinished" };
        })
      })
    }

  }, [pageId]);

  useEffect(() => {
    checkFormValid();
  }, [email]);
  const navigateForwardFunc = () => {
    if (pageId === "763") {
      navigate("/assessment/" + id + "/765?" + searchParams, { replace: true });
    }
    if (pageId === "765") {
      navigate("/assessment/" + id + "/766?" + searchParams, { replace: true });
    }
    if (pageId === "766") {
      navigate("/assessment/" + id + "/860?" + searchParams, { replace: true });
    }
    if (pageId === "860") {
      navigate("/results/" + id + "/?" + searchParams, { replace: true });
    }
  }

  const navigateBackwardFunc = () => {
    if (pageId === "763") {
      navigate("/assessment/" + id + "/763?" + searchParams, { replace: true });
    }
    if (pageId === "765") {
      navigate("/assessment/" + id + "/765?" + searchParams, { replace: true });
    }
    if (pageId === "766") {
      navigate("/assessment/" + id + "/766?" + searchParams, { replace: true });
    }
  }

  const submitToApi = () => {

    

    // fetch(process.env.REACT_APP_API_URI + '/v3/user/' + id + '/completed', {
    //   method: 'PUT',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: jsonBody
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   if (data.status === "success") {
    //     localStorage.removeItem("assessmentAnswers");
    //     localStorage.removeItem("additionalAnswers");
    //     navigate("/results/" + id, { replace: true });
    //   } else {
    //     setIsSaving(false);
    //   }
    // }).catch((err) => {
    //   setIsSaving(false);
    //   console.error("Failed to submit user details...", err);
    // })

  }

  const handleSubmit = () => {
    console.log(userType);
    if ( userType !== 'parishioner' ) {
      if ( isEmail(email?.trim()) ) {

        setIsSaving(true);

        fetch(process.env.REACT_APP_API_URI + '/v3/user/' + id + '/completed', {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          return response.json();
        }).then(data => {
          if (data.status === "success") {
            let payload = { hash: id }
            let emailId = 57;
            if(userType == 'leader') {
              emailId = 58;
            }
            fetch(process.env.REACT_APP_API_URI + '/v4/user/assessment/' + emailId + '/acknowledgement/' + email, {
              method: 'POST',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(payload)
            }).then(response => {
              return response.json();
            }).then(data => {
              if (data.status === "success") {
                localStorage.removeItem("assessmentAnswers");
                localStorage.removeItem("additionalAnswers");
                setIsSaving(false);
                navigate("/results/" + id, { replace: true });
              } else {
                setIsSaving(false);
                navigate("/results/" + id, { replace: true });
              }
            }).catch((err) => {
              setIsSaving(false);
              console.error("Failed to submit user details...", err);
            })
          }
        }).catch((err) => {
          setIsSaving(false);
          console.error("Failed to submit user details...", err);
        })
        
      }
      else {
        showErrors()
      }
    } else if ( userType === 'parishioner' ) {
      setIsSaving(true);
      fetch(process.env.REACT_APP_API_URI + '/v3/user/' + id + '/completed', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          localStorage.removeItem("assessmentAnswers");
          localStorage.removeItem("additionalAnswers");
          setIsSaving(false);
          navigate("/results/" + id, { replace: true });
        } else {
          setIsSaving(false);
          navigate("/results/" + id, { replace: true });
        }
      }).catch((err) => {
        setIsSaving(false);
        console.error("Failed to submit user details...", err);
      })
    }
    
  }

  const showErrors = () => {
    if (isEmail(email?.trim())) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  }

  const checkFormValid = () => {
    if (isEmail(email?.trim())) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }

  const showButton = () => {
    if (isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return handleTranslate("Next", "next-cta");
  }

  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "progressMilestonePageDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  if (pageId === "763") {
    return (
      <div className="container progress-milestone">
        <div className="section-progress-top">
          <ProgressBarSingle currentProgress={0}
            totalNumOfQuestions={4} />
        </div>
        <div className="heading">
          {handleTranslate("Well done on completing the first part! Keep going!", "first-pause-screen-heading")}
        </div>
        {/* <div className='section-info'>
          <div className='image-container'>
            <img src={evangelizationIcon} alt="" />
          </div>
          <div className="section-name">EVANGELIZATION</div>
          <div className="questions">
            <img src={questionMark} alt="" />
            <p>15 Questions</p>
          </div>
        </div> */}
        <div className="section-description">{handleTranslate("“As strong as I felt my faith was, the Holy Spirit filled me up even more ... I realized that our God is such an abundant giver. He goes above and beyond because he wants you to draw closer to him. He is pursuing you. He needs you to turn toward him.”", "first-pause-screen-info")}</div>
        <img src={FirstPauseScreen} alt="" className="pause-screen-img" />
        <div className="sub-heading"><span className="line-one">{handleTranslate("- Shana,", "first-pause-screen-footer-name",)}</span><br /><span className="line-two">{handleTranslate("from the Parish of St. Peter and the Winchester Martyrs (England)", "first-pause-screen-footer-address")}</span></div>
        <div className='action-btn'>
          <button onClick={navigateForwardFunc}>{handleTranslate("Continue", "continue-cta")}</button>
        </div>
        {/* <div className="step-buttons">
          <Button variant="prev" className='prev-btn' onClick={navigateBackwardFunc} >
          <img src={prevArrow} alt="previous button" />
          </Button>
        </div> */}
      </div>
    )
  }
  else if (pageId === "765") {
    return (
      <div className="container progress-milestone">
        <div className="section-progress-top">
          <ProgressBarSingle currentProgress={1}
            totalNumOfQuestions={4} />
        </div>
        <div className="heading">
          {handleTranslate("Thank you for your patience. The results will be worth it!", "second-pause-screen-heading")}
        </div>
        {/* <div className='section-info'>
          <div className='image-container'>
          <img src={leadershipIcon} alt="" />
          </div>
          <div className="section-name">Leadership</div>
          <div className="questions">
          <img src={questionMark} alt="" />
            <p>20 Questions</p>
            </div>
          </div> */}
        <div className="section-description">{handleTranslate("“We have discovered we are most efficacious when we foster a certain openness to the Spirit. If we have an hour-and-a-half-long meeting, we might spend at least half an hour in prayer, waiting on the Holy Spirit before we get to business.”", "second-pause-screen-info")}</div>
        <img src={SecondPauseScreen} alt="" className="pause-screen-img" />
        <div className="sub-heading"><span className="line-one">{handleTranslate("- Bishop John Adams of Palmerston North,", "second-pause-screen-footer-name")}</span><br /><span className="line-two">{handleTranslate("former Parish Priest of St. Peter Chanel Parish, New Zealand", "second-pause-screen-footer-address")}</span></div>
        <div className='action-btn'>
          <button onClick={navigateForwardFunc}>{handleTranslate("Continue", "continue-cta")}</button>
        </div>
        {/* <div className="step-buttons">
          <Button variant="prev" className='prev-btn' onClick={navigateBackwardFunc} >
            <img src={prevArrow} alt="previous button" />
          </Button>
        </div> */}
      </div>
    )
  }

  // this page ID does not exist and should be created in backoffice

  else if (pageId === "766") {
    return (
      <div className="container progress-milestone">
        <div className="section-progress-top">
          <ProgressBarSingle currentProgress={2}
            totalNumOfQuestions={4} />
        </div>
        <div className="heading">
          {handleTranslate("You’re almost done!", "third-pause-screen-heading")}
        </div>
        {/* <div className='section-info'>
          <div className='image-container'>
          <img src={leadershipIcon} alt="" />
          </div>
          <div className="section-name">Leadership</div>
          <div className="questions">
          <img src={questionMark} alt="" />
            <p>20 Questions</p>
            </div>
          </div> */}
        <div className="section-description">{handleTranslate("“We have to tell them, respecting the culture in which we find ourselves, which in this world is secular at the moment ... But we’ve still got to sing our song of joy, of hope, and let the Holy Spirit do what the Holy Spirit will do with us, whether we’re in tune or out of tune.”","third-pause-screen-info")}</div>
        <img src={ ThirdPauseScreen } alt="" className="pause-screen-img"/>
        <div className="sub-heading"><span className="line-one">{handleTranslate("- Father Paddy O’Donovan,", "third-pause-screen-footer-name")}</span><br /><span className="line-two">{handleTranslate("Notre Dame of Mt. Carmel Parish ", "third-pause-screen-footer-address")}</span></div>
        <div className='action-btn'>
          <button onClick={navigateForwardFunc}>{handleTranslate("Continue", "continue-cta")}</button>
        </div>
        {/* <div className="step-buttons">
          <Button variant="prev" className='prev-btn' onClick={navigateBackwardFunc} >
            <img src={prevArrow} alt="previous button" />
          </Button>
        </div> */}
      </div>
    )
  } else if (pageId === "860") {
    return (
      <>
        <div className="container milestone-3">
          <div className="progress-milestone">
            <div className="section-progress-top">
              <ProgressBarSingle currentProgress={3}
                totalNumOfQuestions={4} />
            </div>
            <div className='section-info'>
              {/* <div className='image-container'>
                <img src={tick} alt="" />
              </div> */}
              <img src={tick} alt="" />
              <p className="section-name">{handleTranslate("Thank You!", "thank-you")}</p>
            </div>
            <p className="email-request">{handleTranslate("We're grateful for your time and for sharing your perceptions of your parish.", "thank-you-info")}</p>
            {
              (userType === "priest" || userType === "leader") &&
              <>
                <p className="information">{handleTranslate("Please provide your email so we can email you your report.", "provide-your-email")}</p>
                <div className="input">
                  <p className="label">{handleTranslate("Email", "email")}<span>*</span></p>
                  <input type="text" placeholder={handleTranslate("E.g. speter@rock.church", "email-input-placeholder")} onChange={(e) => setEmail(e.target.value)} onFocus={() => setEmailValid(true)} />
                  {!emailValid && <p style={{ color: "crimson", fontWeight: 400, fontSize: "15px" }}>{handleTranslate("Please enter your valid email id", "email-error")}</p>}
                </div>
              </>
            }
            <div className={`action-btn ${!(userType === "priest" || userType === "leader") ? 'extra-margin-btn' : ''}`}>
              <button onClick={handleSubmit}>{showButton()}</button>
            </div>
            {
              (userType === "priest" || userType === "leader") &&
              <p className='content'>{handleTranslate("*We are only collecting this information from priests and leaders.", "thank-you-footer-note-1")}<br />{handleTranslate("This assessment can be taken completely anonymously by other parishioners.", "thank-you-footer-note-2")}</p>
            }
          </div>
          {(userType === "priest" || userType === "leader") && (
            <p className="footer-text">
              {langCtx.language !== "Nederlands"
                ? <>Read our <a href="https://missionalwaypoint.org/privacy-policy/" target="_blank"> privacy policy</a>  to know how we're using your data or write to us at <a href="mailto:support@missionalwaypoint.org">support@missionalwaypoint.org</a> to know more.</>
                : <>In ons privacybeleid staat hoe we omgaan met uw gegevens. Vragen hierover kunt u mailen naar <a href="mailto:bleat@40parables.com">bleat@40parables.com</a></>}
              </p>
          )}
        </div>
        {/* <div className="step-buttons">
              <Button variant="prev" className='prev-btn' onClick={navigateBackwardFunc} >
                <img src={prevArrow} alt="previous button" />
              </Button>
            </div> */}
      </>
    )
  }
  // return (
  //   <div>
  //     <h1>Good job! One down, two more to go!</h1>
  //     <div className="step-buttons" style={{ display: "flex", justifyContent: "space-between" }}>
  //       <button onClick={navigateBackwardFunc}>PREV</button>
  //       <button onClick={navigateForwardFunc}>NEXT</button>
  //     </div>
  //   </div>
  // )
}



export default ProgressMilestone;


