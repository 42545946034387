import React, { useContext } from "react";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import Location from "../Assets/Images/location-blue.png";
import { translate } from "./translate/translate";
import LanguageContext from "../Store/LanguageContext";

function MilestoneBar(props) {

  const { windowWidth } = useWindowDimensions();
  const langCtx = useContext(LanguageContext)

  var extraMultiplyer = 1;
  if(props.from === 'Dashboard'){
    extraMultiplyer = 1;
  } else if (props.from === 'Results'){
    extraMultiplyer = 1.3;
  }

  const dotLine = (times) => {
    return <div style={{marginLeft: (times/8)+"px", height: times*8+"px"}} className="dot-line"></div>
  };

  const handleTranslate = (text, type) => {

    // console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "milestoneBarDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  return (
    <div className="milestone-bar-cover">
      <div className="milestone-bar">
        <div className={`lookers ${ props.location === 0 ? "active" : ""}`}>
          <div className="circle" />
          <p>{handleTranslate("Onlookers", "onlookers")}</p>
          <div className="location">
            <img className="location-img" src={Location} alt="" />
          </div>
          {dotLine((windowWidth / 100) * 1.5 * extraMultiplyer)}
        </div>
        <div className={`on-shore ${ props.location === 1 ? "active" : ""}`}>
          <div className="circle" />
          <p>{handleTranslate("On the Shore", "on-the-shore")}</p>
          <div className="location">
            <img className="location-img" src={Location} alt="" />
          </div>
          {dotLine((windowWidth / 100) * 2.2 * extraMultiplyer)}
        </div>
        <div className="triangle triangle-one"/>
        <div className={`casting-off ${ props.location === 2 ? "active" : ""}`}>
          <div className="circle" />
          <p>{handleTranslate("Casting Off", "casting-off")}</p>
          <div className="location">
            <img className="location-img" src={Location} alt="" />
          </div>
          {dotLine((windowWidth / 100) * 1 * extraMultiplyer)}
        </div>
        <div className="triangle triangle-two"/>
        <div className={`raising-sails ${props.location === 3 ? "active" : ""}`}>
          <div className="circle" />
          <p>{handleTranslate("Raising the Sails", "raising-the-sails")}</p>
          <div className="location">
            <img className="location-img" src={Location} alt="" />
          </div>
          {dotLine((windowWidth / 100) * 2 * extraMultiplyer)}
        </div>
        <div className={`sailing-waves ${ props.location === 4 ? "active" : ""}`}>
          <div className="circle" />
          <p>{handleTranslate("Sailing the Waves", "sailing-the-waves")}</p>
          <div className="location">
            <img className="location-img" src={Location} alt="" />
          </div>
          {dotLine((windowWidth / 100) * 2 * extraMultiplyer)}
        </div>
        <div className={`beacon-parish ${ props.location === 5 ? "active" : ""}`}>
          <div className="circle" />
          <p>{handleTranslate("Beacon Parish", "beacon-parish")}</p>
          <div className="location">
            <img className="location-img" src={Location} alt="" />
          </div>
          {dotLine((windowWidth / 100) * 0.2 * extraMultiplyer)}
        </div>
        <div className="triangle triangle-three"/>
      </div>
    </div>
  );
}

export default MilestoneBar;
