import { useState, useEffect, createContext } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isJsonString } from '../utils/helper';

const AssessmentQuestionContext = createContext({
  questions: {},
  designation: '',
  addQuestions: (addQuestions) => {},
  updateAnswer: (updateAnswer) => {},
  updateTextAnswer: (updateTextAnswer) => {},
  updateDesignation: (updateDesignation) => {},
  resetDesignation: () => {}
});

export function AssessmentQuestionContextProvider(props) {

  const location = useLocation();

  const [questions, setQuestions] = useState([]);
  const [designation, setDesignation] = useState('');

  useEffect(() => {
    if (location.pathname.split("/").includes("review") && isJsonString(localStorage.getItem('assessmentAnswers'))) {
      let localStoredQuestions = JSON.parse(localStorage.getItem("assessmentAnswers"));
      if (localStoredQuestions?.length) {
        setQuestions(localStoredQuestions);
      }
    }
  }, [location]);

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question = questions?.find(function (obj) { return obj.id == questionId; });
    question.answer = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question = questions?.find(function (obj) { return obj.id == questionId; });
    question.answerTxt = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }

  function updateDesignationHandler(newDesignation) {
    setDesignation(newDesignation);
  }

  function resetDesignationHandler() {
    setDesignation('');
  }

  const context = {
    questions: questions,
    designation: designation,
    addQuestions: addQuestionsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler,
    updateDesignation: updateDesignationHandler,
    resetDesignation: resetDesignationHandler
  };

  return <AssessmentQuestionContext.Provider value={context}>
    {props.children}
  </AssessmentQuestionContext.Provider>
}

export default AssessmentQuestionContext;
