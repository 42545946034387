import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, CircularProgress, LinearProgress } from '@mui/material';
import Button from '../../Components/Button';

import Question from '../../Components/Questions';
import ProgressBar from '../../Components/ProgressBar';
import ProgressBarSingle from '../../Components/ProgressBarSingle/ProgressBarSingle.jsx';

import prevArrow from '../../Assets/Images/ArrowBack.svg';
import nextArrow from '../../Assets/Images/NextArrow.svg';
import nextArrowWhite from '../../Assets/Images/next-arrow-white.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';

// import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import AssessmentQuestionContext from '../../Store/AssessmentQuestionContext';
import QuestionsProgressContext from '../../Store/QuestionsProgressContext';
import AssessmentProgressContext from '../../Store/AssessmentProgressContext.jsx';
import DesignationContext from '../../Store/DesignationContext.jsx';
import { isJsonString } from '../../utils/helper.js';


const AssessmentV3 = () => {

  // const assessProgressCtx = useContext(AssessmentProgressContext);
  // console.log("assessProgressCtx", assessProgressCtx);
  const assessCtx = useContext(AssessmentQuestionContext);
  const questionsCtx = useContext(QuestionsProgressContext);
  const designationCtx = useContext(DesignationContext);
  const { windowWidth } = useWindowDimensions();
  let navigate          = useNavigate();
  const { id, pageId }  = useParams();
  
  console.log("pageId", pageId);
  
  const [searchParams, setSearchParams]     = useSearchParams();
  const [isLoading, setIsLoading]           = useState(true);
  const [activeStep, setActiveStep]         = useState(0);
  const [prevValue, setPrevValue]           = useState(0);
  // const [nextValue, setNextValue]           = useState(0);
  const [sections, setSections]             = useState([]);
  const [questions, setQuestions]           = useState([]);
  const [questionCount, setQuestionCount]   = useState(0);
  const [currentHash, setCurrentHash]       = useState(0);
  const [hasAdditional, setHasAdditional]   = useState(false);
  const [apiData, setApiData]               = useState();
  
  // const [nextDisabled, setNextDisabled] = useState(true);
  // console.log("searchParams.get('member')", searchParams.get("member"));

  const assessProgressCtx = useContext(AssessmentProgressContext);

  const questionType = questions?.[activeStep]?.answerType;

  useEffect(() => {
    // document.body.style.backgroundColor = "#CCCC0021";
    
    setIsLoading(true);
    getQuestions();
    localStorage.removeItem("backFromDetailsPage");
    // if(id != null) {      
    //   getAssessment();
    // } else {
    //   getQuestions();
    // }

    if (pageId === "763") {
      assessProgressCtx.updateMainSectionsStatus((prev) => {
        return prev.map(sec => {
          if (sec.title === "Holy Spirit") return { ...sec, status: "active" };
          if (sec.title === "Evangelization") return { ...sec, status: "unfinished" };
          else return { ...sec, status: "unfinished" };
        })
      })
    }
    if (pageId === "765") {
      assessProgressCtx.updateMainSectionsStatus((prev) => {
        return prev.map(sec => {
          if (sec.title === "Holy Spirit") return { ...sec, status: "finished" };
          if (sec.title === "Evangelization") return { ...sec, status: "active" };
          if (sec.title === "Leadership") return { ...sec, status: "unfinished" };
          else return { ...sec, status: "unfinished" };
        })
      })
      
    }
    if (pageId === "766") {
      assessProgressCtx.updateMainSectionsStatus((prev) => {
        return prev.map(sec => {
          if (sec.title === "Holy Spirit") return { ...sec, status: "finished" };
          if (sec.title === "Evangelization") return { ...sec, status: "finished" };
          if (sec.title === "Leadership") return { ...sec, status: "active" };
          else return { ...sec, status: "unfinished" };
        })
      })
    }

  }, [pageId, designationCtx.designation]);


  // useEffect(() => {
  //   getQuestions();
  // }, [pageId]);
  
  useEffect(() => {
    if(localStorage.getItem("backFromAddQPage") === "true" && questions?.length) {
      // console.log("activeStep", activeStep); console.log("questions", questions);
      setActiveStep(questions?.length - 1);
      localStorage.removeItem("backFromAddQPage");
    }
  }, [questions, pageId]);

  function getQuestions() {

    // if(assessCtx.questions?.length > 0) {
    //   console.log("assessCtx.questions", assessCtx.questions);
    //   setQuestions(assessCtx.questions);
    //   setQuestionCount(assessCtx.questions?.length);
    //   setIsLoading(false);

    //   // go to the last additional question if coming back from Details page 
    //   // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
    //   if(localStorage.getItem("backFromDetailsPage") === "true") {
    //     setActiveStep(assessCtx.questions?.length - 1);
    //   } else {
    //     // go to the first unanswered question 
    //     for (let i = 0; i < assessCtx.questions?.length; i++) {
    //       if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
    //         setActiveStep(i);
    //         break;
    //       } else setActiveStep(assessCtx.questions?.length - 1);
    //     }
    //   }
    // }
    // else {

      let clientId = process.env.REACT_APP_CLIENT_ID;
      // fetch(process.env.REACT_APP_API_URI + '/v3/admin/questions/' + clientId, {
      fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status == "success") {
          console.log("getQuestions data", data);
          let questions = data?.data.questions;

          console.log(designationCtx.designation);
          if(designationCtx.designation !== "parishioner") {
            console.log("Are we here");
            let leaderQuestions = [];
            for(let q = 0; q < questions.length; q++ ) {
              if(questions[q].variable != "exp0" && questions[q].variable != "resource0" && questions[q].variable != "vision0") {
                leaderQuestions.push(questions[q]);
              }
            }
            questions = leaderQuestions.sort((a, b) => parseInt(a.orderNo) - parseInt(b.orderNo));
          }
          console.log(questions);
          if(retrieveStoredAnswers()) {
            let answeredQuestions = [];
            let storedQuestions = retrieveStoredAnswers();
            questions?.forEach(q => {
              let question = storedQuestions.find(obj => obj.id == q.id);
              if(question) {
                q.answerTxt = question.answerTxt;
                q.answer = question.answer;
                q.answers = question.answers;
              }
            })
          }
          // setSections(data.sections);
          // setQuestions(data.questions);
          // setQuestionCount(data.questions?.length);
          // assessCtx.addQuestions(questions);
          // if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);

          // go to first unanswered question 
          let goingForward = localStorage.getItem("goingForward") === "true" ? true : false;

          const jumpToUnansweredQuestion = () => {
            let sortedQuestions = data?.data.questions;
            sortedQuestions = sortedQuestions?.sort((a, b) => a.orderNo - b.orderNo);
            for (let i = 0; i < sortedQuestions?.length; i++) {
              if (sortedQuestions?.[i]?.answer == undefined && assessCtx.questions?.[i]?.answer !== 0) {
                setActiveStep(i);
                break;
              } else setActiveStep(sortedQuestions?.length - 1);
            }
          }

          if (goingForward) {
            setActiveStep(0);
            localStorage.removeItem("goingForward");
          }
          else jumpToUnansweredQuestion();

          setSections(data?.data.sections);
          // setQuestionCount(data?.data?.questions?.length);
          setQuestionCount(data?.data?.questionCount);
          setQuestions(questions);
          setApiData(data.data);
          assessCtx.addQuestions(questions);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => {
        console.error("Error while fetching questions...", err);
        setIsLoading(false);
      });
    // }
  }

  // console.log("searchParams", [...searchParams]); console.log("apiData", apiData);

  const currentSectionName = sections?.find(section => section.id === questions?.[activeStep]?.sectionId)?.section;

  console.log("hasAdditional", hasAdditional);


  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined' && isJsonString(localStorage.getItem('assessmentAnswers'))) {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if(savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {
    // if (activeStep < 1) {
    //   // if(searchParams.get("member")) {
    //   if ([...searchParams]?.length) {
    //     // navigate("/start?member=" + searchParams.get("member"), { replace: true });
    //     navigate("/start?" + searchParams, { replace: true });
    //   } else navigate("/start", { replace: true });
    // } else {
    //   setActiveStep(activeStep - 1);
    //   setPrevValue(questions?.[activeStep - 1].answer);
    // }
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      // setPrevValue(questions?.[activeStep + 1].answer);
    } else {
      navigateBackwardFunc();
      // navigate("/?" + searchParams, { replace: true });
      // navigateBackToIntroPages();
    }
  };

  const nextQuestion = () => {

    assessCtx.updateAnswer(questions?.[activeStep].id, questions?.[activeStep].answer);
    // assessCtx.updateTextAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt);
    if (activeStep === questions?.length - 1) {
      setTimeout(() => { completeAssessment(); }, 500);
    } else if(activeStep < questions?.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions?.[activeStep + 1].answer);
      }, 20);
    //   // setSection(questions?.[activeStep + 1].sectionId - sectionIdOffset);
    }
    
    const addQPageId = 659;
    const detailsPageId = 730;
    if (activeStep < questions?.length - 1) {
      if (questions?.[activeStep]?.answer > 0) {
        setActiveStep((prev) => prev + 1);
      }
    } else {
      navigateForwardFunc();
      // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt) {
      // navigateToIntroPages();
      if (questions[activeStep]?.answer > 0) {
        // navigate(`/details/${id}/${detailsPageId}?${searchParams}`, { replace: true });
        // navigate(`/details/${id}`, { replace: true });
        completeAssessment();
        // navigate(`/details/${id}?${searchParams}`, { replace: true });
      }
    }

  };

  const submitAnswer = (answer) => {
    
    let jsonBody = {
      // "id": questions?.[activeStep]?.id,
      "pageId": pageId,
      "answer": answer,
      "answerTxt": null,
      "answerChecked": false,
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/question/' + questions?.[activeStep]?.id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        // save the answer option so you see the selected option when going back & forth between questions 
        
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
        console.error("Failed to send answer details...", data);
 
      }
    }).catch((err) => {
      console.error("Failed to send answer details...", err);

    }).finally(()=>{

    // this should not depend on API response as it needs to happen in the background
    if (activeStep < (questions?.length - 1)) {
        setActiveStep(activeStep + 1);
    } else {
        // navigate(navigatePath, { replace: true });
        // navigateForwardFunc();
        completeAssessment();
    }

    })

  }

  // console.log("activeStep", activeStep);
  // console.log("orderNo", questions?.[activeStep]?.orderNo, typeof questions?.[activeStep]?.orderNo);
  const navigatePath = (pgId) => "/assessment/" + id + "/" + pgId + "/?" + searchParams;
  const navigateForwardFunc = () => {
    if(pageId === "28") {
      navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
    } else if(pageId === "30") {
      navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
    } else if(pageId === "32") {
      navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
    } else if(pageId === "34") {
      navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
    } else if(pageId === "36") {
      navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
    } else if(pageId === "38") {
      navigate("/assessment-additional/" + id + "/39?" + searchParams, { replace: true });
    }

    if (pageId === "763") {
      navigate("/progress-milestone/" + id + "/763?" + searchParams, { replace: true });
    }
    if (pageId === "765") {
      navigate("/progress-milestone/" + id + "/765?" + searchParams, { replace: true });
    }
    if (pageId === "766") {
      navigate("/progress-milestone/" + id + "/766?" + searchParams, { replace: true });
    }
    if (pageId === "860") {
      navigate("/progress-milestone/" + id + "/860?" + searchParams, { replace: true });
    }
  }
  const navigateBackwardFunc = () => {
    let qOrderNum = questions?.[activeStep]?.orderNo;
    console.log("qOrderNum", qOrderNum);
    console.log("pageId", pageId, typeof pageId);
    
    if (pageId === "28") navigate("/first-form/" + id + "?" + searchParams, { replace: true }); 
    else if (pageId === "30") {
      if(qOrderNum == 2) navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(28), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "32") {
      if(qOrderNum == 5) navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(30), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "34") {
      if(qOrderNum == 8) navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(32), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "36") {
      if(qOrderNum == 11) navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(34), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "38") {
      if(qOrderNum == 14) navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(36), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    } else if (pageId =="860") {
      navigate("/progress-milestone/" + id + "/766?" + searchParams, { replace: true })
    } else if (pageId === "766") {
      navigate("/progress-milestone/" + id + "/765?" + searchParams, { replace: true });
    } else if (pageId === "765") {
      navigate("/progress-milestone/" + id + "/763?" + searchParams, { replace: true });
    } else if (pageId === "763") {
      navigate("/before-you-start/" + id + "/763?" + searchParams, { replace: true });
    }
    else {
      navigate("/?" + searchParams, { replace: true });
    }
  }

  const moveToNextQuestion = (answer) => {
    // questions[activeStep].answer = answer;
    setQuestions((prev) => prev.map((q) => {
      if (q.id === questions?.[activeStep]?.id) {
        q.answer = answer;
        if (q.na === "yes" && answer > 0) {
          q.skip = false;
        }
      }
      return q;
    }));
    assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
    // assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);
    if(activeStep < questions?.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    } else {
      // questions?.[activeStep].answer = answer;
      console.log("\n\nSUBMITTING ANSWERS\n\n");
      setTimeout(() => { completeAssessment(); }, 500);
    }

    // setTimeout(() => {
      submitAnswer(answer);
    // }, 250);
    console.log("answer", answer);

  }

  function navigateToIntroPages() {

    let qOrderNo = questions?.[activeStep]?.orderNo;
    if (qOrderNo == 7) {
      navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    } else if (qOrderNo == 14) {
      navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    } else if (qOrderNo == 21) {
      navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    }
  }

  function navigateBackToIntroPages() {
    // let qOrderNo = questions?.[activeStep]?.orderNo;
    // if (qOrderNo == 8) {
    //   navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    // } else if (qOrderNo == 15) {
    //   navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    // } else if (qOrderNo == 22) {
    //   navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    // } else if (qOrderNo == 1) {
    //   navigate("/?" + searchParams, { replace: true });
    // }
    navigate("/before-you-start/" + id + "?" + searchParams, { replace: true });
  }

  const completeAssessment = () => {

    setIsLoading(true);
    
    const jsonBody = { 
      pageId: pageId,
      questions: questions,
    }
    console.log(jsonBody);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      setIsLoading(false);
      if(data.status === "success") {
        console.log("completeAssessment data", data)
        const additionalpageId = 744;
        localStorage.removeItem("assessmentAnswers");
        if (pageId === "763" && questions?.[activeStep]?.answer > 0) {
          navigateForwardFunc();
        }
        else if (pageId === "765" && questions?.[activeStep]?.answer > 0) {
          navigateForwardFunc();
        }
        else if (pageId === "766" && questions?.[activeStep]?.answer > 0) {
          navigateForwardFunc();
        }
        else if (pageId === "860" && questions?.[activeStep]?.answer > 0) {
          navigateForwardFunc();
        }
        // navigate(`/assessment-additional/${id}/${additionalpageId}`, { replace: true });
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to send answer details...", err);
      setIsLoading(false);
    });

    // if ( pageId == 728 ) {
    //   console.log('pageId-----',pageId)
    //   navigate("/intro/" + id + "/734?" + searchParams, { replace: true });
    // }
    // else if ( pageId == 735 ) {
    //   navigate("/intro/" + id + "/736?" + searchParams, { replace: true });
    // }
    // else if ( pageId == 737 ) {
    //   navigate("/intro/" + id + "/738?" + searchParams, { replace: true });
    // }
	}

  const nextDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return { display: "flex" };
    }
    if (questions?.[activeStep]?.answer > 0) {
      return { display: "flex" };
    };
    return { display: "none" };
  }
  // console.log("questions?.[activeStep]", questions?.[activeStep]);
  // console.log("questions?.[activeStep].answer", questions?.[activeStep]?.answer);
  // console.log("questions?.[activeStep]", questions?.[activeStep]?.id);
  // console.log("\n\nquestions?.[activeStep]", activeStep, (questions?.length - 1), "\n\n");
  
  // const nextDisabled = (answer) => {
  //   if(questions?.[activeStep]?.mandatory === "no") {
  //     return {display: "block"};
  //   } else {
  //     // if(questions?.[activeStep]?.answerType == "slider" && questions?.[activeStep]?.answer != 0) {
  //     if(questions?.[activeStep]?.answerType == "slider" && !questions?.[activeStep]?.answer > 0) {
  //       return { display: "block" };
  //     } else if(questions?.[activeStep]?.answerType == "radio" && questions?.[activeStep]?.answer != 0) {
  //       return {display: "block"};
  //     } else if(questions?.[activeStep]?.answerType == "textarea" && questions?.[activeStep]?.answerTxt != "") {
  //       return {display: "block"};
  //     }
  //   } 
  //   return {display: "none"};
  // }

  const updateTextAnswer = (id, textVal) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) q.answerTxt = textVal;
      return q;
    }))
  }

  console.log("questions", questions);

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr?.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  const questionsPerSection = getQuestionsPerSection();
  // const questionsPerSection = [8, 8, 9, 10];
  // console.log("questionsPerSection", questionsPerSection);
  // console.log("currentProgress", activeStep);

  const toggleSkipQuestionCheckbox = (step) => {
    // we are not really skipping the question, we are answering it with answer 0
    // e.preventDefault();
    const currentSkipState = assessCtx.questions?.[step]?.skip || false;
    const updatedSkipState = !currentSkipState;
    setQuestions((prev) => {
      const updatedQuestions = [...prev];
      updatedQuestions[step].skip = updatedSkipState;
      return updatedQuestions;
    })
    assessCtx.skipQuestion(questions?.[step]?.id, updatedSkipState);
    // only skip question if skip is set to true
    if (updatedSkipState) {
      moveToNextQuestion(0);
    }
  };

  const getStepContent = (step) => {
    return (
      <Question question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} updateTextAnswer={updateTextAnswer} />
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress style={{ color: '#792122' }} size={60} /></div>
	}

  const handleDisabled = () => {
    if(assessCtx.questions[activeStep]?.answer === undefined) {
      return true 
    } else {
      return false
    }
  } 

  console.log(handleDisabled())

  return (
    <>
    {/* {windowWidth < 831 ? <>
      <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={questions?.length} />
      <p className="current-section">{currentSectionName}</p>
    </> : ""} */}

    <div className="container assessment">
      <div className="section-progress-top">
          {/* <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} currentSectionName={currentSectionName} /> */}
          {/* <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} 
          totalNumOfQuestions={questionCount} /> */}
          {/*<p className="current-section">{currentSectionName}</p>*/}
      </div>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              { windowWidth > 830 && <div className="spacer"></div>}

              {/* {windowWidth > 830 ?
                <>
                  <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
                  <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={16} />
                </>
                : <>
                  <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} />
                  <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={16} />
                  <p className="current-section">{currentSectionName}</p>
                </>
              } */}
              {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} sections={sections} currentSectionName={currentSectionName} /> */}
              {/* <div className="spacer"></div> */}
              {/* <div className="spacer"></div> */}
              {/* <div className="spacer mobile-spacer"></div> */}
              {/* <div className="spacer mobile-spacer"></div> */}
              
              {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questionCount}</div> */}
              {/*<div className="progress-indicator"><strong>{questions?.[activeStep]?.orderNo}</strong>/{questionCount}</div>*/}

              {getStepContent(activeStep)}
                
                {/* <div className='checkbox-container'>
                  <input type="checkbox" />
                  <p>I don't know.</p>
                  


                </div> */}

            </div>
              <div className="step-buttons">
                {/* {windowWidth > 550 && <Button variant="prev" onClick={prevQuestion} className='prev-btn' >
                  <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
                </Button> }
                {windowWidth <= 550 && <Button variant="prev" onClick={prevQuestion}>
                  <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;
                </Button> }
                {windowWidth > 550 && <Button variant="next" className="next-btn" onClick={nextQuestion} style={nextDisabled()}>
                  <span style={{ borderBottom: "1px solid #6EC5B8" }}>NEXT</span><img style={{ paddingLeft: "8px" }} src={nextArrow} alt="next button" />
                </Button> }
                {windowWidth <= 550 && <Button variant="next" className="next-btn" onClick={nextQuestion} style={nextDisabled()}>
                  <img style={{ paddingLeft: "8px" }} src={nextArrow} alt="next button" />
                </Button> } */}

                <Button variant="prev" onClick={prevQuestion} className='prev-btn' >
                  <img src={prevArrow} alt="previous button" />
                </Button>
                <Button variant="next" className="next-btn" onClick={nextQuestion} style={nextDisabled()} handleDisabled={handleDisabled()} >
                  <img src={nextArrow} alt="next button" />
                </Button>

              </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default AssessmentV3;

