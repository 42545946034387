export const ResultsStewardDutch = (text, type, section) => {
  switch (section) {
    case "result-details-title":
      return "Observaties van uw sterke punten in uw parochie";
    case "result-details-title-2":
      return "Gebieden waarin je misschien wilt groeien";
    case "result-steward-heading":
      return "Volgens jouw resultaten ben je een gastvrouw/heer";
    case "steward-description":
      return "Net als de gastvrouw/heer op een schip, suggereert de manier waarop jij kijkt naar je leiderschapscapaciteiten en je verlangen naar evangelisatie, dat je sterk bent in het verwelkomen van mensen en ervoor zorgen dat ze zich thuis voelen. Dit is cruciaal in een missionaire parochie. Dienen in evangelisatieteams, programma's zoals Alpha, of in het gastvrijheidsteam van de parochie kan goed bij je passen.";
    case "thank-you-heading":
      return "Bedankt voor het invullen van de Missionaire wegwijzer!";
    case "thank-you-description-1":
      return "Uw antwoorden zullen uw parochie helpen om te zien waar ze zich bevindt op haar missionaire reis en de leiders in de parochie helpen om de weg vooruit te ontdekken. Maar parochievernieuwing gebeurt alleen in de kracht van God, en uw voortdurende gebed voor de pastoor en de leiders van de parochie is een van de krachtigste manieren waarop u kunt bijdragen.";
    case "thank-you-description-2":
      return "Parochievernieuwing gebeurt meestal niet van de ene op de andere dag,zijn er zes fases onderscheiden in missionair worden die helpen om vast te stellen waar de parochie zich op deze reis bevindt, welke uitdagingen ze kan verwachten en waar naar uitgekeken kan worden.";
    case "thank-you-description-3":
      return "Veel parochies hebben deze weg al afgelegd en zijn bereid en in staat om u te helpen op uw reis. Het Ecosysteem Missionaire Parochie kan u helpen in contact te komen met andere parochies en kan u materiaal en begeleiding bieden om u op weg te helpen.";
    case "observation-cta":
      return "Observaties van de sterke kanten in jullie parochie";
    case "areas-to-grow-cta":
      return "Gebieden waarin jullie kunnen groeien";
    case "download-results-cta":
      return "Download de resultaten";
    case "feel-the-parish-heading":
      return "Voel je parochie tot leven komen";
    case "feel-the-parish-description":
      return "Hier zijn drie manieren waarop jullie (de resultaten van) de Missionaire wegwijzer optimaal kunnen benutten binnen de parochie.";
    case "pray-heading":
      return "Bid voor je parochie";
    case "grow-heading":
      return " Groei binnen je parochie";
    case "reflect-heading":
      return "Reflecteer op je parochie";
    case "pray-description":
      return "Als je parochie op weg is naar parochievernieuwing, hebben priesters en leiders de kracht en bemoediging nodig die voortkomen uit het bidden van bijvoorbeeld de rozenkrans.";
    case "grow-description":
      return "Vind boeken en bronnen die jullie ondersteunen in het werken aan parochievernieuwing.";
    case "reflect-description":
      return "Denk na over hoe je je parochie kunt helpen om mensen dichter bij Jezus te brengen.";
    case "pray-cta":
      return "bidden";
    case "discover-cta":
      return "Ontdek";
    case "learn-cta":
      return "Leer";
    case "discover-the-transformation":
      return "Ontdek de transformatie van parochies die ‘missionair-zijn’ omarmen";
    case "discover-the-transformation-description":
      return "Hier zijn drie manieren om te leren van, en steun te geven aan evangelisatie buiten jullie parochie";
    case "read-good-news":
      return "Lees goed nieuws";
    case "invest-in-yourself":
      return "Investeer in uwzelf";
    case "support-the-mission":
      return "Steun de missie";
    case "read-good-news-description":
      return "Leiding geven aan verandering kan moeilijk zijn. Maar u bent niet alleen! Ontvang inspirerende verhalen over hoop en vernieuwing in uw inbox.";
    case "invest-in-yourself-description":
      return "Doe mee aan een evenement om van anderen te leren, te groeien in leiderschap en nieuwe hoop te krijgen.";
    case "support-the-mission-description":
      return "Jullie parochie neemt stappen om mensen dichter bij Jezus te brengen. Stelt u zich de vele andere plaatsen voor waar levens kunnen worden getransformeerd. Met uw steun kan dat werkelijkheid worden.";
    case "sign-up-cta":
      return "Aanmelden";
    case "register-cta":
      return "Registreer";
    case "give-cta":
      return "Geef";

    default:
      return text;
  }
}