import React, { useEffect, useState, useRef, useLayoutEffect, useContext, useCallback } from 'react'
import HolySprit from "../../../Assets/Images/holy-spirit.svg";
import Evangelization from "../../../Assets/Images/evangelization.svg";
import leadership from "../../../Assets/Images/leadership.svg";
import leader from "../../../Assets/Images/leader.svg";
import parishioner from "../../../Assets/Images/parishioner.svg";
import Discover from '../../../Components/Discover/Discover';
import { insightsStyle } from '../../../Components/htmlRender/htmlInsights';

import Matrix from '../../../Components/Matrix/Matrix';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import DashboardDownArrowRed from "../../../Assets/Images/dashboardDownArrowRed.svg";
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import MobileFlagHeader from '../../../Components/MobileFlagHeader/MobileFlagHeader';
import DesignationContext from '../../../Store/DesignationContext';
import LanguageContext from '../../../Store/LanguageContext';
import DashboardContext from '../../../Store/DashboardContext';
import { translate } from '../../../Components/translate/translate';
import { html2pdf } from 'html2pdf.js';



function DashboardInsights({ selectedTab, shouldObserve, setRenderInsight }) {
  const { id } = useParams();

  const { windowWidth } = useWindowDimensions();

  const [currTab, setCurrTab] = useState(null);
  const [apiData, setApiData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const [pageData, setPageData] = useState()
  const [excellenceData, setExcellenceData] = useState()
  const [htmlContent, setHtmlContent] = useState('');
  const [open, setOpen] = useState("");

  const componentRef = useRef(null);
  const observerRef = useRef(null);
  const mutationTimeoutRef = useRef(null);
  const designationCtx = useContext(DesignationContext)
  const langCtx = useContext(LanguageContext)
  const dashboardCtx = useContext(DashboardContext);

  useEffect(() => {
    dashboardCtx.setDashboardId(id)
    getParishInsights(id)
  }, [id])

  const finalHTML = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="X-UA-Compatible" content="ie=edge">
      <title>HTML 5 Boilerplate</title>
      ${insightsStyle}
    </head>
    <body>
    ${htmlContent}
    </body>
  </html>
  `

  const onDownloadPDF = () => {
    return new Promise((resolve) => {
      console.log(htmlContent,"ins aman")
      dashboardCtx.updateHtmlString(htmlContent)
      resolve()
    });
  }

  const handleTranslate = useCallback((text, type) => {
    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "dashboardInsightsDutch", type)
      }
      default: {
        return text;
      }
    }
  }, [langCtx.language]);


  // const downloadPdf = async (pdfLink) => {
    
  //   const proxyUrl = pdfLink;
  //   try {
  //     const response = await fetch(proxyUrl);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const blob = await response.blob();
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = 'Results.pdf';
  //     link.click();
  //     URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error('Error fetching PDF:', error);
  //   }
  // };

  const downloadPdfLocal = async (finalHTML) => {
    try {
      await html2pdf()
        .from(finalHTML)
        .set({
          filename: 'Insights.pdf',
          jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' }
        })
        .save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  // Effect to handle selectedTab change
  useEffect(() => {
    setCurrTab(selectedTab)
  }, [selectedTab]);

  // UseLayoutEffect to set up the MutationObserver and ensure it runs synchronously after DOM mutations
  useLayoutEffect(() => {
    if (shouldObserve && componentRef.current) {
      const callback = () => {
        if (mutationTimeoutRef.current) {
          clearTimeout(mutationTimeoutRef.current);
        }

        // Delay the execution to ensure all mutations are settled
        mutationTimeoutRef.current = setTimeout(() => {
          setHtmlContent(componentRef.current.innerHTML);
        }, 50); // You can adjust the timeout value as needed
      };

      observerRef.current = new MutationObserver(callback);

      observerRef.current.observe(componentRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
        if (mutationTimeoutRef.current) {
          clearTimeout(mutationTimeoutRef.current);
        }
      };
    }
  }, [currTab, shouldObserve]);

  // Effect to call onDownloadPDF after htmlContent is updated
  useEffect(() => {
    if (htmlContent && shouldObserve) {
      console.log(htmlContent);
      onDownloadPDF()
        .then(() => {
          if (currTab !== 2) {
            
            setCurrTab(currTab + 1)
          }
          else if(currTab === 2) {
            setRenderInsight(false)
          }
        })
        .catch((error) => {
          console.error('Error downloading PDF:', error);
        });
    }
  }, [htmlContent, shouldObserve]);

  // const data = [[
  //   {
  //     'heading': 'Planning and Priorities',
  //     'questions': [
  //       { 'question': 'Our leaderships has clearly identified steps on how we can fulfill the vision', 'answer': ['Strongly Agree', 'Agree'] },
  //       { 'question': 'Our plans become reality because we  develop systems to get us there', 'answer': ['Agree', 'Strongly Agree'] },
  //       { 'question': 'Putting priorities into actions is one of our strengths', 'answer': ['Strongly Agree', 'Agree'] }]
  //   },
  // ],
  // [
  //   {
  //     'heading': 'Primacy of Programs', 'questions': [
  //       { 'question': 'When planning programs, priority is given to evangelization', 'answer': ['Disagree', 'Strongly Disagree'] },
  //       { 'question': 'Presenting the good news is a priority in the execution of all our programs.', 'answer': ['Strongly Disagree', 'Diagree'] },
  //       { 'question': 'Primacy is given to programs like Alpha, that are intended to articulate the good news of the gospel with people outside the parish', 'answer': ['Strongly Disagree', 'Strongly Disagree'] }]
  //   },

  // ],
  // [
  //   {
  //     'heading': 'Resources Aligned', 'questions': [
  //       { 'question': 'We budget heavily for evangelization and sharing the good news', 'answer': ['Strongly Agree', 'Strongly Disagree'] },
  //       { 'question': 'We have allocated specific human resources whose singular focus is evangelization', 'answer': ['Agree', 'Strongly Disagree'] },
  //       { 'question': 'We allocate building resources for evangelization programs, like Alpha.', 'answer': ['Strongly Agree', 'Disagree'] },
  //       { 'question': 'Our budget is created according to our priorities.', 'answer': ['Agree', 'Disagree'] },]
  //   },

  // ],
  // ]

  const inSnakeCase = (str) => str.replace(/\W+/g, " ").split(/ |\B(?=[A-Z])/).map(word => word.toLowerCase()).join('-');

  const getParishInsights = (id) => {
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + '/v4/user/assessment/' + id + '/parishInsights', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.message === "success") {
        designationCtx.updateDesignation(data?.userInfo[0]?.designation)

        if (data?.areasOfExcellence?.length !== 0) {
          setCurrTab(0)
        }
        else if (data?.areasOfGrowth?.length !== 0) {
          setCurrTab(1)
        }
        else if (data?.areasNeedingGreaterAlignment?.length !== 0) {
          setCurrTab(2)
        }
        else {
          setCurrTab(0)
        }

        const filteredData = filterQuestions(data);

        console.log('data: ', data)

        if (data?.userInfo[0].designation !== 'parishioner') {
          setPageData(filteredData)
          setExcellenceData(filteredData?.areasOfExcellence)
        }
        else {
          setPageData(data)
          setExcellenceData(data?.areasOfExcellence)
        }

        if (currTab === 0) {
          setApiData(filteredData?.areasOfExcellence);
        }
        else if (currTab === 1) {
          setApiData(filteredData?.areasOfGrowth);
        }
        else if (currTab === 2) {
          setApiData(filteredData?.areasNeedingGreaterAlignment);
        }
      } else {
      }
    }).catch((err) => console.error("Failed to fetch insights...", err))
      .finally(() => {
        setIsLoading(false)
      });
  }

  const filterQuestions = (data) => {
    const questionsToRemove = [
      "The Holy Spirit has a profound impact on my life.",
      "I am eager to share my faith with others.",
      "I am eager to share my gifts in service of the parish."
    ];

    const filterQuestionList = (questions) => {
      return questions.filter(questionObj => !questionsToRemove.includes(questionObj.question.trim()));
    };

    const traverseAndFilter = (obj) => {
      if (Array.isArray(obj)) {
        return obj
          .map(item => traverseAndFilter(item))
          .filter(item => item !== null);
      } else if (typeof obj === 'object' && obj !== null) {
        const newObj = {};
        let isEmpty = true;
        for (const key in obj) {
          if (key === 'questions') {
            const filteredQuestions = filterQuestionList(obj[key]);
            if (filteredQuestions.length > 0) {
              newObj[key] = filteredQuestions;
              isEmpty = false;
            }
          } else {
            const filteredItem = traverseAndFilter(obj[key]);
            if (filteredItem !== null) {
              newObj[key] = filteredItem;
              isEmpty = false;
            }
          }
        }
        return isEmpty ? null : newObj;
      }
      return obj;
    };

    return traverseAndFilter(data);
  };

  const getAreasNeedsGreaterAlignmentSections = useCallback(() => {
    return apiData?.map((list, index) => {
      const sections = Object.keys(list);
      return sections.map((section, i) => {
        const questions = list[section];
        // console.log(section, " questions: ", questions)
        return (
          questions.length !== 0 && <Matrix
            key={i}
            title={handleTranslate(section, inSnakeCase(section))}
            // title={section}
            questions={questions[0]?.questions}
            from="insights"
            color="blue"
            data={questions}
            open={open === section ? "collapse" : ""} 
            setOpen={(isOpen) => setOpen(isOpen ? section : "")}
            image={"https://40parables-assets.s3.amazonaws.com/holy-spirit.svg"}
            pageData={pageData}
          />
        );
      });
    })
  }, [apiData, open, pageData, handleTranslate])

  const getAreasOfExcellence = useCallback(() => {
    return apiData?.map((list, index) => {
      const sections = Object.keys(list);
      return sections.map((section, i) => {
        const questions = list[section];
        return (
          questions.length !== 0 && <Matrix
            key={i}
            title={handleTranslate(section, inSnakeCase(section))}
            questions={questions[0]?.questions}
            from="insights"
            color="green1"
            data={questions?.questions}
            open={open === section ? "collapse" : ""} 
            setOpen={(isOpen) => setOpen(isOpen ? section : "")}
            image={"https://40parables-assets.s3.amazonaws.com/evangelization.svg"}
            pageData={pageData}
            section={'areasOfExcellence'}
            currentTab={currTab}
          />
        );
      });
    })
  }, [apiData, open, pageData, handleTranslate]);

  const getAreasOfGrowth = useCallback(() => {
    return apiData?.map((list, index) => {
      const sections = Object.keys(list);
      return sections.map((section, i) => {
        const questions = list[section];
        return (
          questions.length !== 0 && <Matrix
            key={i}
            title={handleTranslate(section, inSnakeCase(section))}
            questions={questions[0]?.questions}
            from="insights"
            color="red"
            data={questions[0]?.questions}
            open={open === section ? "collapse" : ""} 
            setOpen={(isOpen) => setOpen(isOpen ? section : "")}
            image={"https://40parables-assets.s3.amazonaws.com/leadership.svg"}
            section={'areasOfGrowth'}
            pageData={pageData}
          />
        );
      });
    })
  }, [apiData, open, pageData, handleTranslate])

  const moveQuestions = (pageData) => {

    const questionsToMove = [1126, 1102, 1106, 1112];

    const areasOfGrowthToExcellence = (growthArray, excellenceArray) => {
      console.log("moveQuestions growthArray: ", growthArray)

      // let growingLeadership = growthArray[7]['Growing Leadership Skills']
      // let primacyOfPrograms = growthArray['Primacy of Programs']
      // let culturalReienforcement = growthArray['Cultural Reinforcement']
      // let vision = growthArray['Vision']

      const moveToExcellence = (question, section) => {
        const sectionIndex = excellenceArray.findIndex(el => Object.keys(el)[0] === section);

        if (sectionIndex !== -1) {
          const questions = excellenceArray[sectionIndex][section][0].questions;
          if (!questions.includes(question)) {
            questions.push(question);
          }
        } else {
          excellenceArray.push({
            [section]: [{
              questions: [question]
            }]
          });
        }
      };


      growthArray?.forEach((el) => {
        const sections = [
          { section: 'Growing Leadership Skills', questionId: 1126 },
          { section: 'Primacy of Programs', questionId: 1102 },
          { section: 'Cultural Reinforcement', questionId: 1106 },
          { section: 'Vision', questionId: 1112 }
        ];

        sections.forEach(({ section, questionId }) => {
          if (el[section]) {
            const questions = el[section][0]?.questions;

            if (questions) {
              for (let i = questions.length - 1; i >= 0; i--) {
                const question = questions[i];
                const leaderRound = Math.round(question?.leaderAverage)
                const parishionerRound = Math.round(question?.parishionerAverage)
                if (question?.questionId === questionId) {
                  if (leaderRound < 50 && parishionerRound < 50) {
                    moveToExcellence(question, section);
                    questions.splice(i, 1);
                  }
                }
              }

              // Remove the section if it becomes empty
              if (questions.length === 0) {
                delete el[section];
              }
            }
          }
        });
      });




      console.log("moveQuestions excellenceArray: ", excellenceArray)



    }

    const areasOfExcellenceToGrowth = (excellenceArray, growthArray) => {
      console.log('moveQuestions execellencearray ', excellenceArray)

      excellenceArray?.forEach((el) => {
        const sections = [
          { section: 'Growing Leadership Skills', questionId: 1126 },
          { section: 'Primacy of Programs', questionId: 1102 },
          { section: 'Cultural Reinforcement', questionId: 1106 },
          { section: 'Vision', questionId: 1112 }
        ];

        const moveToGrowth = (question, section) => {
          const sectionIndex = growthArray.findIndex(el => Object.keys(el)[0] === section);

          console.log("movetogrowth: ", question)

          if (sectionIndex !== -1) {
            const questions = growthArray[sectionIndex][section][0].questions;
            if (!questions.includes(question)) {
              questions.push(question);
            }
          } else {
            growthArray.push({
              [section]: [{
                questions: [question]
              }]
            });
          }
        };

        sections?.forEach((section, questionId) => {
          console.log('section el', el)
          console.log('section', section)
          console.log('section el[section]', el[section?.section])
          if (el[section?.section]) {
            const questions = el[section?.section][0]?.questions;


            if (questions) {
              for (let i = questions.length - 1; i >= 0; i--) {
                const question = questions[i];
                const roundLeaderAvg = Math.round(question?.leaderAverage)
                const roundParishionerAvg = Math.round(question?.parishionerAverage)
                console.log("roundLeaderAvg ", roundLeaderAvg)
                console.log("roundParishionerAvg ", roundParishionerAvg)
                if (question?.questionId === section?.questionId) {
                  if (roundLeaderAvg >= 50 && roundParishionerAvg >= 50) {
                    moveToGrowth(question, section?.section);
                    questions.splice(i, 1);
                  }
                }
              }

              if (questions.length === 0) {
                delete el[section];
              }
            }
          }
        })

      })

      console.log("moveQuestions growthArray: ", growthArray)

    };


    areasOfGrowthToExcellence(pageData?.areasOfGrowth, pageData?.areasOfExcellence)
    areasOfExcellenceToGrowth(pageData?.areasOfExcellence, pageData?.areasOfGrowth)

  };

  console.log("pagedata ", pageData)


  useEffect(() => {

    console.log('pageData', pageData)
    // console.log("moveQuestions(pageData): ", moveQuestions(pageData)) no need for this function now

    if (currTab === 0) {
      console.log('pageData?.areasOfExcellence', pageData?.areasOfExcellence)
      setApiData(pageData?.areasOfExcellence);
    }
    else if (currTab === 1) {
      console.log('pageData?.areasOfGrowth', pageData?.areasOfGrowth)
      setApiData(pageData?.areasOfGrowth);
    }
    else if (currTab === 2) {
      console.log('pageData?.areasNeedingGreaterAlignment', pageData?.areasNeedingGreaterAlignment)
      setApiData(pageData?.areasNeedingGreaterAlignment);
    }
    else if (currTab === null) {
      setCurrTab(0)
    }
  }, [currTab, pageData])

  useEffect(() => {
    console.log('apiData', apiData)
  }, [apiData])

  console.log("pageData", pageData?.leaderCount)

  const Boxs = useCallback(() => {
    if (currTab === 0) {
      return (
        <div className="box matrix" style={{ borderTopLeftRadius: "0px" }}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress style={{ color: "#84161D" }} size={60} />
            </div>
          ) : (
            <div className="leppa-container">
              {pageData?.areasOfExcellence?.length === 0 || pageData?.leaderCount === 0 || pageData?.parishionerCount <= 10 ? (
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1rem",
                    paddingBottom: "0",
                    margin: "0"
                  }}
                >
                  {handleTranslate("There is no data yet from leaders or parishioners. Share this link with them to take the assessment.", "not-enough-leaders-or-parishioners")}
                </p>
              ) : (
                <>
                  {windowWidth >= 831 && (
                    <div className="head">
                      <div className="row">
                        <div className="h-cell" style={{ width: "65%" }}>
                          <p className="lpp">
                            {handleTranslate("These are areas in which your parish as a whole seems to be doing well.", "areas-of-excellence-info")}
                          </p>
                        </div>
                        <div className="h-cell lepa-container" style={{ width: "16%" }}>
                          <div className="lp-container">
                            <img src={"https://40parables-assets.s3.amazonaws.com/dr-leader.png"} alt="" />
                            <p>
                              {handleTranslate("Leaders", "leaders")} <strong>({pageData?.leaderCount})</strong>
                            </p>
                          </div>
                        </div>
                        <div className="h-cell lepa-container" style={{ width: "16%" }}>
                          <div className="lp-container">
                            <img src={"https://40parables-assets.s3.amazonaws.com/dr-parishnor.png"} alt="" />
                            <p>
                              {handleTranslate("Parishioners", "parishioners")} <strong>({pageData?.parishionerCount})</strong>
                            </p>
                          </div>
                        </div>
                        <div className="h-cell" style={{ width: "5%" }}></div>
                      </div>
                    </div>
                  )}
                  {getAreasOfExcellence()}
                </>
              )}
            </div>
          )}
        </div>
      );
    } else if (currTab === 1) {
      return (
        <div className="box matrix">
          <div className="leppa-container">
            {
              isLoading ? <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30vh",
                  }}
                >
                  <CircularProgress style={{ color: "#84161D" }} size={60} />
                </div>
              </> : <>
                {(pageData?.areasOfGrowth?.length === 0 || pageData?.leaderCount === 0 || pageData?.parishionerCount <= 10 || (pageData?.areasOfGrowth[1]?.Vision?.length == 0 && pageData?.areasOfGrowth[0]["Experience of the Holy Spirit"]?.length == 0)) ? (
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1rem",
                      paddingBottom: "0",
                      margin: "0"
                    }}
                  >
                    {handleTranslate("There is no data yet from leaders or parishioners. Share this link with them to take the assessment.", "not-enough-leaders-or-parishioners")}
                  </p>
                ) : (
                  <>
                    {windowWidth >= 831 && (
                      <div className="head">
                        <div className="row">
                          <div className="h-cell" style={{ width: "65%" }}>
                            <p className="lpp">
                              {handleTranslate("These are areas in which your parish as a whole might hope to improve.", "areas-of-growth-info")}
                            </p>
                          </div>
                          <div className="h-cell lepa-container" style={{ width: "16%" }}>
                            <div className="lp-container">
                              <img src={"https://40parables-assets.s3.amazonaws.com/dr-leader.png"} alt="" />
                              <p>
                                {handleTranslate("Leaders", "leaders")} <strong>({pageData?.leaderCount})</strong>
                              </p>
                            </div>
                          </div>
                          <div className="h-cell lepa-container" style={{ width: "16%" }}>
                            <div className="lp-container">
                              <img src={parishioner} alt="" />
                              <p>
                                {handleTranslate("Parishioners", "parishioners")} <strong>({pageData?.parishionerCount})</strong>
                              </p>
                            </div>
                          </div>
                          <div className="h-cell" style={{ width: "5%" }}></div>
                        </div>
                      </div>
                    )}
                    {getAreasOfGrowth()}
                  </>
                )}
              </>
            }
          </div>
        </div>
      );
    } else {
      return (
        <div className="box matrix">
          <div className="leppa-container">{
            isLoading ? <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30vh",
                }}
              >
                <CircularProgress style={{ color: "#84161D" }} size={60} />
              </div>
            </> : <>
              {pageData?.areasNeedingGreaterAlignment?.length === 0 || pageData?.leaderCount === 0 || pageData?.parishionerCount <= 10 ? (
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1rem",
                    paddingBottom: "0",
                    margin: "0"
                  }}
                >
                  {handleTranslate("There is no data yet from leaders or parishioners. Share this link with them to take the assessment.", "not-enough-leaders-or-parishioners")}
                </p>
              ) : (
                <>
                  {windowWidth >= 831 && (
                    <div className="head">
                      <div className="row">
                        <div className="h-cell" style={{ width: "65%" }}>
                          <p className="lpp">
                            {handleTranslate("These are areas in which your parishioners seem to be having a difference in perceptions.", "areas-needing-greater-alignment-heading")}
                          </p>
                        </div>
                        <div className="h-cell lepa-container" style={{ width: "16%" }}>
                          <div className="lp-container">
                            <img src={"https://40parables-assets.s3.amazonaws.com/dr-leader.png"} alt="" />
                            <p>
                              {handleTranslate("Leaders", "leaders")} <strong>({pageData?.leaderCount})</strong>
                            </p>
                          </div>
                        </div>
                        <div className="h-cell lepa-container" style={{ width: "16%" }}>
                          <div className="lp-container">
                            <img src={parishioner} alt="" />
                            <p>
                              {handleTranslate("Parishioners", "parishioners")} <strong>({pageData?.parishionerCount})</strong>
                            </p>
                          </div>
                        </div>
                        <div className="h-cell" style={{ width: "5%" }}></div>
                      </div>
                    </div>
                  )}
                  {getAreasNeedsGreaterAlignmentSections()}
                </>
              )}
            </>
          }
          </div>
        </div>
      );
    }
  }, [
      currTab, isLoading, pageData, windowWidth, 
      handleTranslate, getAreasOfExcellence, getAreasOfGrowth, getAreasNeedsGreaterAlignmentSections
  ]);

  return (
    <div className='cover'>
      <div className='dashboard-insigts'>
        <div className='tabs'>
          <div className={`tab ${currTab === 0 && 'active' + currTab}`} onClick={() => setCurrTab(0)}>
            <p>{handleTranslate("Areas of excellence", "areas-of-excellence")}</p>
          </div>
          <div className={`tab ${currTab === 1 && 'active' + currTab}`} onClick={() => setCurrTab(1)}>
            <p>{handleTranslate("Areas of growth", "areas-of-growth")}</p>
          </div>
          <div className={`tab ${currTab === 2 && 'active' + currTab}`} onClick={() => setCurrTab(2)}>
            <p>{handleTranslate("Areas needing greater alignment", "areas-needing-greater-alignment")}</p>
          </div>
        </div>
        <div ref={componentRef}>
          {Boxs()}
          <Discover />
        </div>
      </div>
    </div>
  )
}

export default DashboardInsights