import { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { Link, useNavigate, useSearchParams, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

import VideoThumbnail from '../../Assets/Images/video-thumbnail.jpg';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
// import playBtn from '../../Assets/Images/play-btn.png';
// import playBtnHover from '../../Assets/Images/play-btn-hover.png';
import playBtn from '../../Assets/Images/play-btn-triangle.png';
import playBtnHover from '../../Assets/Images/play-btn-triangle.png';
import Clock from '../../Assets/Images/clock.png';
import { CheckBoxRounded, CheckCircleRounded, Close, Pause, PlayArrow } from "@mui/icons-material";
// import { Button } from '@mui/material';
import Button from '../../Components/Button';
import AssessmentQuestionContext from '../../Store/AssessmentQuestionContext';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import '../../App.scss';
import priest from '../../Assets/Images/priest.svg'
import leader from '../../Assets/Images/leader.svg'
import parishioner from '../../Assets/Images/parishioner.svg'
import DesignationContext from '../../Store/DesignationContext';
import { translate } from '../../Components/translate/translate';
import LanguageContext from '../../Store/LanguageContext';

const Start = props => {

  const { windowWidth } = useWindowDimensions();

  // context apis
  const assessCtx = useContext(AssessmentQuestionContext);
  const designationCtx = useContext(DesignationContext);
  const langCtx = useContext(LanguageContext);

  // states
  const [isSaving, setIsSaving] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [isVidPaused, setIsVidPaused] = useState(false);
  const [btnHovered, setBtnHovered] = useState(false);
  // const [startPlaying, setStartPlaying]   = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [playBtnImg, setPlayBtnImg] = useState(playBtn);
  const [userType, setUserType] = useState("");
  const [animateErrorText, setAnimateErrorText] = useState(false);
  const [isCorrectHash, setIsCorrectHash] = useState(false);
  const [parentParish, setParentParish] = useState(0);
  const [parentAssessment, setParentAssessment] = useState(0);
  const [parentUnique, setParentUnique] = useState("");
  const [parentDetails, setParentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const display = searchParams.get("display");
  const navigate = useNavigate();
  const { startHash, type } = useParams();

  useEffect(() => {
    if (startHash) {
      getPriestFromHash(startHash);
    }
    if(userType) {
      localStorage.setItem("userType", userType);
    }
  }, []);

  const getPriestFromHash = (hash) => {
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + '/v4/user/assessment/' + hash, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        console.log(data);

        if (data.status == "success") {
          // fetch(process.env.REACT_APP_API_URI + '/v4/misc/50/parishByName/' + hash, {
          //   method: 'GET',
          //   mode: 'cors',
          //   headers: { 'Content-Type': 'application/json' }
          // }).then(response => {
          //   return response.json();
          // }).then(data => {
          //   if (data.status === "success") {
          //     designationCtx.updatePriestDetails(data?.userDetails)
          //     if(data.status == "success") {

          //       setIsCorrectHash(true);
          //       setParentParish(data.userAssessment);
          //     }
          //   } else {
          //     // setIsSaving(false);
          //     // setOpenSnackbar(true);
          //   }
          // }).catch((err) => console.error("Failed to find the priest...", err));

          designationCtx.updatePriestDetails(data.userDetails);
          designationCtx.updatePriestHash(data.userAssessment.uniqueId);

          setParentUnique(data.userAssessment.uniqueId);
          setParentDetails(data.userDetails);

          setParentParish(data.userAssessment.parentId);
          setParentAssessment(data.userAssessment.id);

          setIsCorrectHash(true);

          console.log(type);

          if (type) {
            if (type === 'l' && data.userDetails !== "" ) {
              setUserType("leader")
              designationCtx.updateDesignation("leader")
              designationCtx.updateQrCode("leader")
              setTimeout(() => {
                createSubAssessmentAndUser("leader", data.userDetails, data.userAssessment.uniqueId);
              }, 500);
            } else if (type === 'p'  && data.userDetails !== "" ) {
              setUserType("parishioner")
              designationCtx.updateDesignation("parishioner")
              designationCtx.updateQrCode("parishioner")
              setTimeout(() => {
                createSubAssessmentAndUser("parishioner", data.userDetails, data.userAssessment.uniqueId);
              }, 500);
            }else {
              designationCtx.resetQrCode()
            }
          }
          if ( type !== 'l' && type !== 'p' ) {
            setIsLoading(false)
          }
        }
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to find the priest...", err));
  }

  const createSubAssessmentAndUser = async (userType, parentDetails, parentUnique) => {
    
    console.log(parentDetails);
    console.log(parentUnique);
    console.log(userType);
    setIsSaving(true);

    const assessmentId = process.env.REACT_APP_CLIENT_ID;

    let parentId = 0;
    let userId = 0;
    let userDetails = {};
    let hasParent = false;
    let pageId = 763;
    let firstPage = "first-form";
    let designation = userType;
    if (startHash && isCorrectHash) {
      hasParent = true;
      parentId = parentAssessment;
      firstPage = "before-you-start";
      pageId = 764
    }

    designationCtx?.updatePriestDetails(parentDetails);
    designationCtx?.updatePriestHash(parentUnique);
    designationCtx?.updateDesignation(userType);

    fetch(process.env.REACT_APP_API_URI + '/v4/user/assessmentAndUser', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ assessmentId, userId, userDetails, firstPage, pageId, designation, hasParent, parentId })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log("data", data);
      if (data.status === "success") {
        // We need to set correct parent Id since the parent ID needs to be the parish
        if (hasParent) {

          fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + data.id + `/updateParent`, {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ parentId: parentParish })
          }).then(response => {
            return response.json();
          }).then(parent => {
            console.log(parent);
            if (parent.status === "success") {
              let url = data.url.replace("http://localhost:3000", "");
              navigate(url + searchParams, { replace: true });
              setIsSaving(false);
            }
          }).catch(error => {
            console.log(error);
          });

        } else {
          let url = data.url.replace("http://localhost:3000", "");
          navigate(url + searchParams, { replace: true });
          setIsSaving(false);
        }

      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }

  const createAssessmentAndUser = async () => {
    
    setIsSaving(true);

    const assessmentId = process.env.REACT_APP_CLIENT_ID;

    let parentId = 0;
    let userId = 0;
    let userDetails = {};
    let hasParent = false;
    let pageId = 763;
    let firstPage = "first-form";
    let designation = userType;
    if (startHash && isCorrectHash) {
      hasParent = true;
      parentId = parentAssessment;
      firstPage = "before-you-start";
      pageId = 764
    }

    designationCtx?.updatePriestDetails(parentDetails);
    designationCtx?.updatePriestHash(parentUnique);
    designationCtx?.updateDesignation(userType);

    fetch(process.env.REACT_APP_API_URI + '/v4/user/assessmentAndUser', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ assessmentId, userId, userDetails, firstPage, pageId, designation, hasParent, parentId })
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log("data", data);
      if (data.status === "success") {
        // We need to set correct parent Id since the parent ID needs to be the parish
        if (hasParent) {

          fetch(process.env.REACT_APP_API_URI + `/v4/user/assessment/` + data.id + `/updateParent`, {
            method: 'PUT',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ parentId: parentParish })
          }).then(response => {
            return response.json();
          }).then(parent => {
            console.log(parent);
            if (parent.status === "success") {
              let url = data.url.replace("http://localhost:3000", "");
              navigate(url + searchParams, { replace: true });
              setIsSaving(false);
            }
          }).catch(error => {
            console.log(error);
          });

        } else {
          let url = data.url.replace("http://localhost:3000", "");
          navigate(url + searchParams, { replace: true });
          setIsSaving(false);
        }

      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }

  const introPageLink = () => {
    
    if (type) {
      if (type === 'l') {
        setUserType("leader")
        designationCtx.updateQrCode("leader")
      }
      else if (type === 'p') {
        setUserType("parishioner")
        designationCtx.updateQrCode("parishioner")
      }
      else {
        designationCtx.resetQrCode()
      }
    }
    else {
      designationCtx.resetQrCode()
    }
    
    if (!userType) {
      setAnimateErrorText(true);
    }
    ( userType && designationCtx?.priestDetails) && createAssessmentAndUser();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const showButton = () => {
    if (isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return handleTranslate("Test and See","cta");
  }

  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "startPageDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  // use effects

  

  // useEffect(() => {
    
  // }, [type, designationCtx?.priestDetails])

  // useEffect(() => {
  //   if (animateErrorText) {
  //     const timeoutId = setTimeout(() => {
  //       setAnimateErrorText(false);
  //     }, 500);

  //     return () => clearTimeout(timeoutId);
  //   }

  //   if (type && userType && designationCtx?.priestDetails !== "") {
  //     introPageLink()
  //   }
  // }, [animateErrorText, userType, introPageLink, designationCtx?.priestDetails]);

  useEffect(() => {
    designationCtx.updateDesignation(userType)
  }, [userType]);

  useEffect(() => {
    designationCtx.updatePriestDetails(parentDetails)
    designationCtx.updatePriestHash(parentUnique)
  }, [parentUnique, parentDetails]);

  // useEffect(() => {
  //   const page = window.location.href;
  //   const urlArray = page.split('/');

  //   if (urlArray.includes('start') && urlArray.includes(startHash) && designationCtx.designation === 'leader') {
  //     // console.log('condition satisfied')
  //     designationCtx.updatePriestHash(startHash);
  //   }
  //   else {
  //     designationCtx.updatePriestHash('');
  //   }

  // }, [window.location])

  return (
    <div className="container start">
      {
        isLoading ? <div className="loading">
          <CircularProgress size={40} />
        </div>
          :
          <div className="intro">
            <h1 className='intro-heading'>{handleTranslate("Is your parish on track with its mission?", "intro-heading")}</h1>
            <p className='intro-para'>{handleTranslate("If you want your parish to live the Great Commission, it needs a clear picture of where it is now to begin to work toward missional flourishing.","intro-para1")}</p>
            <p className='intro-para'>{handleTranslate("Take this 15-minute assessment as an anonymous participant to provide your perception of where your parish is on its missional journey.","intro-para2")}</p>

            <h2 className='user-selection-heading'>{handleTranslate("Let us know how you serve in your parish.","user-selection-heading")}</h2>
            <div className="assessment-categories">
              {!isCorrectHash && <div className="priest category" style={userType === "priest" ? { background: windowWidth <= 550 ? "rgba(151, 169, 124, 0.2)" : "#1B608A33", border: "2px solid rgba(27, 96, 138, 0.90)" } : { border: "2px solid transparent" }} onClick={() => { setUserType("priest") }}>
                <div className="img-container">
                  <img src={priest} alt="priest" />
                </div>
                <p className='category1'>{handleTranslate("Priest","category1")}</p>
              </div>}
              <div className="leader category" style={userType === "leader" ? { background: windowWidth <= 550 ? "rgba(151, 169, 124, 0.2)" : "#1B608A33", border: "2px solid rgba(27, 96, 138, 0.90)" } : { border: "2px solid transparent" }} onClick={() => { setUserType("leader") }}>
                <div className="img-container">
                  <img src={leader} alt="leader" />
                </div>
                <p className='category2'>{handleTranslate("Leader","category2")}</p>
              </div>
              <div className="parishioner category" style={userType === "parishioner" ? { background: windowWidth <= 550 ? "rgba(151, 169, 124, 0.2)" : "#1B608A33", border: "2px solid rgba(27, 96, 138, 0.90)" } : { border: "2px solid transparent" }} onClick={() => { setUserType("parishioner") }}>
                <div className="img-container">
                  <img src={parishioner} alt="parishioner" />
                </div>
                <p className='category3'>{handleTranslate("Parishioner","category3")}</p>
              </div>
            </div>

            <div className="completion" style={searchParams.get("leader") && windowWidth > 830 ? { marginTop: '47px' } : null}>
              <p className={`intro-para choose-user-text ${animateErrorText ? "shake-text" : ""}`}>{handleTranslate("Unsure? Choose Parishioner.","choose-user-text")}</p>
              <button className="cta" onClick={() => introPageLink()}>{showButton()}</button>
              <div className='time-estimate'>
                <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span>{searchParams.get("leader") ? "15 min" : "15 min"}</span>
              </div>
            </div>
          </div>
      }
    </div>
  );
}

export default Start;
