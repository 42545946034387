import { createContext, useState } from 'react';

const LanguageContext = createContext({
  language: 'English',
  setLanguage: (lang) => {}
});

export function LanguageContextProvider(props) {
  const [language, setLanguage] = useState('English');

  function changeLanguageHandler(lang) {
    setLanguage(lang);
  }

  const context = {
    language: language,
    setLanguage: changeLanguageHandler
  };

  return (
    <LanguageContext.Provider value={context}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export default LanguageContext;
