import React, { useState, useContext } from 'react';
import { useParams, useRoutes } from 'react-router-dom';
import Sidebar from '../../../Components/Sidebar/Sidebar';

import pdfImage from '../../../Assets/Images/pdf.svg';
import pngImage from '../../../Assets/Images/png.svg';
import pptImage from '../../../Assets/Images/ppt.svg';
import Discover from '../../../Components/Discover/Discover';
import DashboardOverview from '../Overview/DashboardOverview';
import DashboardInsights from '../Insights/DashboardInsights';
import { CircularProgress } from '@mui/material';
import EnablePopupModal from './EnablePopupModal';
import LanguageContext from '../../../Store/LanguageContext';
import { translate } from '../../../Components/translate/translate';
import DashboardContext from '../../../Store/DashboardContext';
import { dashboardStyle } from '../../../Components/htmlRender/htmlPdf';

const DashboardDownloads = () => {

  const { id } =  useParams();
  const langCtx = useContext(LanguageContext)
  const dashboardCtx=useContext(DashboardContext)
  const [showDownloadButton, setShowDownloadButton] = useState('')

  const [renderOverview, setRenderOverview] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState("")
  const [renderInsight1, setRenderInsight1] = useState(false)

  const [myResults, setMyResults] = useState(false)
  const [parishDashboard, setParishDashbaord] = useState(false)
  const [leadershipDashboard, setLeadershipDashboard] = useState(false)
  const [parishionerDashBoard, setParishionerDashBoard] = useState(false)
  const [insights, setInsights] = useState(false)
  const [downloadAll, setdownloadAll] = useState(false)

  const [openPopup, setOpenPopup] = useState(false);

  const handleOpen = () => setOpenPopup(true);
  const handleClose = () => setOpenPopup(false);

  const onLabelClick = (label) => {
    return new Promise((resolve) => {
      console.log(label);
      setSelectedLabel(label);
      setRenderOverview(true);
      // to check to popup is blocked or not.
      if (isPopupBlocked()) {
        setOpenPopup(true)
        return
      } else {
        setOpenPopup(false)
      }

      const interval = setInterval(() => {
        if (!renderOverview) {
          clearInterval(interval);
          resolve();
        }
      }, 10000); // Adjust the interval time as needed
    });
  }

  const onInsightsClick = () => {
    return new Promise((resolve, reject) => {
      const popupWindows = [];
      for (let i = 0; i < 5; i++) {
        const popupWindow = window.open('', '', 'width=100,height=100,left=24,top=24,scrollbars,resizable');
        popupWindows.push(popupWindow);
      }
      if (isPopupBlockerActivated(popupWindows)) {
        setOpenPopup(true); // Resolve the promise
      } else {
        setOpenPopup(false);
        setSelectedLabel("Insights");
        setRenderInsight1(true); // Resolve the promise
      }
      const interval = setInterval(() => {
        if (!renderOverview) {
          clearInterval(interval);
          resolve();
        }
      }, 50000);
    });
  }


  const isPopupBlocked = () => {
    const checkedCheckboxes = document.querySelectorAll('.checkbox:checked');

    if (checkedCheckboxes?.length > 1) {
      const popUp = window.open('', '', 'width=100,height=100,left=24,top=24,scrollbars,resizable');
      if (!popUp) {
        return true;
      }
      try {
        if (popUp.closed || typeof popUp.closed === 'undefined') {
          return true;
        }
      } catch (error) {
        return true;
      }
      popUp.close();
      return false;

    }
    return false;


  };

  var isPopupBlockerActivated = function(popupWindows) {
    let blocked = false;
    popupWindows.forEach((popupWindow) => {
      if (popupWindow) {
        if (/chrome/.test(navigator.userAgent.toLowerCase())) {
          try {
            popupWindow.focus();
          } catch (e) {
            blocked = true;
          }
        } else {
          popupWindow.onload = function() {
            if (!(popupWindow.innerHeight > 0)) {
              blocked = true;
            }
          };
        }
        popupWindow.close();
      } else {
        blocked = true;
      }
    });
    return blocked;
  };
  function removeConsecutiveDuplicates(input) {
    return input.replace(/(.+)(?=\1)/g, '');
  }
  function extractAndMergeHTML(singleHTMLString) {
    const htmlSections = singleHTMLString.match(/<!DOCTYPE html>[\s\S]*?<html[^>]*>[\s\S]*?<\/html>/gi);
  
    const metaTags = new Set();
    let styleContent = '';
    let bodyContent = '';
    let title = '';
  
    if (htmlSections) {
      htmlSections.forEach(htmlSection => {
        // Extract <meta> tags and avoid duplicates
        const metaMatches = htmlSection.match(/<meta[^>]*>/g);
        if (metaMatches) {
          metaMatches.forEach(meta => metaTags.add(meta.trim())); // Add to Set to avoid duplicates
        }
  
        // Extract <style> content
        const styleMatch = htmlSection.match(/<style[^>]*>([\s\S]*?)<\/style>/i);
        if (styleMatch) {
          styleContent += styleMatch[1].trim() + '\n'; // Append <style> content
        }
  
        // Extract <body> content
        const bodyMatch = htmlSection.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
        if (bodyMatch) {
          bodyContent += bodyMatch[1].trim() + '\n'; // Append <body> content
        }
  
        // Extract the <title> (use the first one)
        const titleMatch = htmlSection.match(/<title[^>]*>([\s\S]*?)<\/title>/i);
        if (titleMatch && !title) {
          title = titleMatch[1].trim(); // Use the title from the first document
        }
      });
    }
  
    // Combine everything into a single HTML document
    return `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          ${[...metaTags].join('\n')}
          <title>${title || 'Merged Document'}</title>
          <style>${styleContent}</style>
        </head>
        <body>
          ${bodyContent}
        </body>
      </html>
    `;
  }
  
  const onDownloadPDF = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/generateReport", {
          method: "POST",
          headers: {
            "Content-Type": "text/html"
          },
          body: 
            extractAndMergeHTML(dashboardCtx.htmlString),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const url = await response.json();
        const pdfUrl = await url.reportURL;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Result.pdf";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log(dashboardCtx.htmlString)
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }



  const callSequentially = async () => {

    if (isPopupBlocked()) {
      setOpenPopup(true)
      return
    } else {
      setOpenPopup(false)
    }

    myResults && await onLabelClick("My Results");
    parishDashboard && await onLabelClick("Parish Dashboard");
    leadershipDashboard && await onLabelClick("Leadership Dashboard");
    parishionerDashBoard && await onLabelClick("Parishioner Dashboard");
    //insights && await onInsightsClick();
    await onDownloadPDF()


  }
  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "dashboardDownloadsDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  const tableData = [
    { label: `${handleTranslate("My Results", "my-results")}` },
    { label: `${handleTranslate("Parish Dashboard", "parish-dashboard")}` },
    { label: `${handleTranslate("Leadership Dashboard", "leadership-dashboard")}` },
    { label: `${handleTranslate("Parishioner Dashboard", "parishioner-dashboard")}` },
    { label: `${handleTranslate("Insights", "insights")}` },

  ]

  const handleCheckboxChange = (e) => {
    if (e.target.value === "My Results") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setMyResults(true)
      }
      else {
        setMyResults(false)
      }
    }
    if (e.target.value === "Parish Dashboard") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setParishDashbaord(true)
      }
      else {
        setParishDashbaord(false)
      }
    }
    if (e.target.value === "Leadership Dashboard") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setLeadershipDashboard(true)
      }
      else {
        setLeadershipDashboard(false)
      }
    }
    if (e.target.value === "Parishioner Dashboard") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setParishionerDashBoard(true)
      }
      else {
        setParishionerDashBoard(false)
      }
    }
    if (e.target.value === "Insights") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        setInsights(true)
      }
      else {
        setInsights(false)
      }
    }
    if (e.target.value === "Download All") {
      if (document.getElementById(e.target.value.replace(/ +/g, '')).checked) {
        const cb = document.querySelectorAll('.checkbox')
        cb.forEach((checkbox) => {
          checkbox.checked = true;
        });
        setMyResults(true)
        setParishDashbaord(true)
        setLeadershipDashboard(true)
        setParishionerDashBoard(true)
        setInsights(true)
        setdownloadAll(true)
      }
      else {
        const cb = document.querySelectorAll('.checkbox')
        cb.forEach((checkbox) => {
          checkbox.checked = false;
        });
        setMyResults(false)
        setParishDashbaord(false)
        setLeadershipDashboard(false)
        setParishionerDashBoard(false)
        setInsights(false)
        setdownloadAll(false)
      }
    }
    const checkedCheckboxes = document.querySelectorAll('.checkbox:checked');
    setShowDownloadButton(checkedCheckboxes.length > 0);
  }



  return (
    <>
      <div className='downloads-container'>
        <div className='downloads-right-container'>

          <h1>{handleTranslate("Download your results and insights in multiple formats to access them anytime.", "downloads-heading-info")}</h1>


          <div className='download-section'>
            <h2>{handleTranslate("Downloads", "downloads")}</h2>

            <table className='download-table'>
              <thead>
                <tr className="header">
                  <th className="center-cell">
                    <input style={{ padding: "10px 5px" }} value={`Download All`} type="checkbox" id={`DownloadAll`} className='checkbox' onChange={handleCheckboxChange} />
                  </th>
                  <th>{handleTranslate('Title', 'title')}</th>
                  <th>&nbsp;</th>
                  <th>{handleTranslate("Download Format", "format")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  tableData?.map((item, index) => (
                    <tr className='content' key={index}>
                      <th className="center-cell">
                        <input value={`${item.label}`} type="checkbox" id={`${item.label.replace(/ +/g, '')}`} className='checkbox' onChange={handleCheckboxChange} />
                      </th>
                      <th>
                        <p>{item?.label} </p>
                      </th>
                      <th>&nbsp;</th>
                      <th>
                        <div className='images-container'>
                          {((renderOverview || renderInsight1) && item.label === selectedLabel) ? <CircularProgress size={30} sx={{ color: "#44546A", display: `${item.label === "Download All" ? "none" : ""}` }} /> : <img style={{ display: `${item.label === "Download All" ? "none" : ""}` }} src={pdfImage} alt='' onClick={() => {
                            if (item?.label !== "Insights" && item?.label !== "Download All") {
                              onLabelClick(item?.label)
                            }
                            else if (item?.label === "Insights") {
                              onInsightsClick()
                            }
                          }} />}

                        </div>
                        {/* <img src={pptImage} alt='' />
                        <img src={pngImage} alt='' /> */}
                        {renderOverview && selectedLabel === item.label && (
                          <div style={{ display: "none" }}>
                            <DashboardOverview
                              selectedLabel={selectedLabel}
                              shouldObserve={true}
                              setRenderOverview={setRenderOverview}
                            />
                          </div>
                        )}
                        {/*
                        <div style={{ display: "none" }}>
                          {[0,1,2].map(i=>{
                            return(renderInsight && selectedLabel === item.label && (<DashboardInsights selectedTab={i} shouldObserve={true} setRenderInsight={setRenderInsight}/>))
                          })}
                        </div>
                        */}
                        {
                          <div style={{ display: "none" }}>
                            {renderInsight1 && selectedLabel === item.label && <DashboardInsights selectedTab={0} shouldObserve={true} setRenderInsight={setRenderInsight1} />}
                          </div>
                        }
                      </th>
                    </tr>

                  ))
                }

              </tbody>
            </table>


          </div>
          <div className='download-btn'>
            {showDownloadButton && (
              <button onClick={callSequentially} id="downloadBtn" >Download</button>
            )}
          </div>

          <Discover />

        </div>

      </div>

      <EnablePopupModal handleOpen={handleOpen} handleClose={handleClose} open={openPopup} />
    </>

  )
}

export default DashboardDownloads