import React from 'react'
import useWindowDimensions from '../Hooks/useWindowDimensions';
import shadowIcon from '../Assets/Images/shadow.svg'
import { CircleTwoTone, WbSunnyOutlined } from "@mui/icons-material";
import { Button } from '@mui/material';

export default function HorizontalBarCharts(props) {

  console.log(props)
  const windowWidth = useWindowDimensions()

  const getProgreebarColor = (index) => {
    // if( index == 0 ) {
    //   return '#6183b4';
    // }
    // else if( index == 1 ) {
    //   return '#a35a53';
    // }
    // else if( index == 2 ) {
    //   return '#a6b56f';
    // }
    // else if( index == 3 ) {
    //   return '#756998';
    // }
    // else{
    //   return '#74a8be';
    // }
    return '#1B608A';
  }

  const getLeftMargin = () => {
    if (windowWidth < 651 && props.score > 97) {
      return { left: 98 + "%" };
    } else if (windowWidth < 651 && props.score < 3) {
      return { left: 2 + "%" };
    } else {
      return { left: Math.round(props.score) + "%" };
    }
    // return { left: Math.round(score) + "%" };
  };

  const getProgressLinearBar = (startIndex, endIndex) => {
     return props.sectionsAndSubScores
       ?.slice(startIndex, endIndex ).map((el, index) => {
      const scoreName = el?.section;
      return <div className="progressbar-container" key={index}>
        <p className="progressbar-score-name">{scoreName}</p>
        <div className='progressbar-items'>  
        <div className="progress-linear-bar">
          <div className="si_ass_result" style={{ position: "relative" }}>
            <div className="progress-linear-opacity-filter" style={{ width: "100%",height:"9px" }}>
              <div className="transparent" style={{ width: `${Math.round(el?.score)}%`, height: "9px", background: getProgreebarColor(index)}}>
              </div>
              <div className="opaque" style={{ width: `${(100 - Math.round(el?.score))}%`, height: "9px", background: `#E8E8E8` }}></div>
            </div>
          </div>
        </div>
        <span className="msi_score" >{Math.round(el?.score)}%</span>
        </div>

        {/* <div className="progressbar-icon-and-section-name">
          { getProgressBarIcon(el) }
        </div> */}

        
      </div>
    })
  }

  const getProgressBarIcon = (item) => {
    if ( item?.score <= 30 ) {
       return <>
        <img src={shadowIcon} alt='img' />
        <div className="section-name">Shadow</div>
       </>
    }
    else if ( item?.score > 30 &&  item?.score <= 75 ){
      return <>
        <CircleTwoTone/>
        <div className="section-name">Middle</div>
      </>
    }
    else {
      return <>
         <WbSunnyOutlined/>
         <div className="section-name">Sunlight</div>
      </>
    }
  }

  return (
    <div className='progress-bar-content-container '>
      <div className='progress-bar-main'>
       <div style={{position:"relative"}}> 
        <div className='tooltip-bottom'>  
      <div className='progressbar-contents'>
          {getProgressLinearBar(0, 3)}

          <div className='content-para'>
            <hr />

          <p>
            Cultivating a biblical understanding and an experiential awareness of the Holy Spirit will help you nurture faith in your parish.
        
            </p>
            <a href='#' >Here are some resources to help your parish grow in the Spirit.</a>
        </div>
      </div>
      </div>
        </div>
        <div style={{ position: "relative" }}> 
        <div className='tooltip-bottom'>

      <div className='progressbar-contents ' >
          {getProgressLinearBar(4, 7)}

          <div className='content-para'>
            <hr />

          <p>
            Equipping your parish with the desire, knowledge, programs, and guidance will help you maximize your Kingdom impact.
        
            </p>
            <a href='#'>
              Here are some resources to help your parish grow in evangelization.

            </a>

      </div>
      </div>
          </div>
        </div>
        <div style={{ position: "relative" }} >
        <div className='tooltip-bottom'>

      <div className='progressbar-contents'>
          {getProgressLinearBar(7, 10)}
          <div className='content-para'>
            <hr />

          <p>
              Building your leadership team in skill, knowledge, and Spirit will help to accomplish the vision and mission of your parish.
          
            </p>
            <a href='#'>
              Here are some resources to help your parish grow in leadership.

            </a> 
        </div>
            </div>
          </div>
      </div>
      </div>
      <div className='action-btn'>

        <Button className='download-result-btn'>Download my results</Button>
      </div>


      
      {/* {getProgressLinearBar()} */}
    
    </div>
  )
}
