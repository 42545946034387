export const PreAssessmentPageDutch = (text, type, section) => {
  switch (section) {
    case "pre-assessment-heading":
      return "Missionaire parochies wereldwijd laten zien dat je missionair bent wanneer je parochie wordt gedreven door de volgende principes:";
    case "power-of-the-holy-spirit":
      return "Kracht van <br/> de Heilige Geest";
    case "primacy-of-evangelization":
      return "Prioriteit geven <br/> aan evangelisatie";
    case "best-of-leadership":
      return " De beste principes <br/> van leiderschap";
    case "questions":
      return "vragen";
    case "next-cta":
      return "Volgende";
    default:
      return text;
  }
}