import React, { useState } from 'react'
import tabTextCircle from '../Assets/Images/result-text-tab-circle.svg'

export default function ResultsText() {
  const [tabName, setTabName] = useState("Mentor")
  const resultPeoplePatternData = [
    { 
      "tabName": "Mentor",
      "desc": "<p>One of your Middle Grounds is the Mentor Pattern. With some concentration and effort, you can coach and develop others.</p><p>Mentors are people who like to come alongside you and develop you to be your best. They hone who you are, fanning the flame of your ability. Mentors build into you and help you realize your potential. They coach and guide you to help you discover your whole self. As trusted allies, they are friendly faces who encourage you to grow, develop and change.</p><p>All these Mentor qualities are within your grasp and will come with moderate effort.</p>"
    },
    { 
      "tabName": "Administrator",
      "desc": "<p>One of your Middle Grounds is the Administrator Pattern. With some concentration and effort, you can be good with tactics, details, and following the plan.</p><p>Administrators help establish the plan and then follow it as it is laid out. They like policy and structure, and are good with details and budgets. Administrators focus on management and tend to be averse to taking risks. They are good with procedures and like to follow the rules.</p><p>All these Administrator qualities are within your grasp and will come with moderate effort.</p>"
    },
    { 
      "tabName": "Catalyst",
      "desc": "<p>One of your Middle Grounds is the Catalyst Pattern. With some concentration and effort, you can be an innovator.</p><p>Catalysts are people who will challenge you, and so take you to new ground. They innovate and experiment in life, motivating you to change. Catalysts seem to light fires under people, spurring them on to unconventional or entirely new methods. They can be revolutionary, stirring up people. As trusted critics, we know their words are speaking truth as they speed up change in our lives.</p><p>All these Catalyst qualities are within your grasp and will come with moderate effort.</p>"
    },
    { 
      "tabName": "Relational",
      "desc": "<p>One of your Middle Grounds is the Relational Pattern. With some concentration and effort, you can be a great team builder.</p><p>Relationals are people who are motivated by harmony and see the team as crucial. They want people to be whole and healthy, and so are good team builders. Relationals highly value grace and mercy, and can be quite loving and warm. People deeply matter to them. Decisions are guided by deep-seated values and personal connections to others.</p><p>All these Relational qualities are within your grasp and will come with moderate effort.</p>"
    },
    { 
      "tabName": "Overseer",
      "desc": "<p>One of your Middle Grounds is the Overseer Pattern. With some concentration and effort, you can focus on strategy.</p><p>Overseers like to see the big picture. They work best with principles and concepts as their guiding force. Overseers prefer to work on strategy, aligning people with their right places to work. They think in terms of the vision and direction of things, tending to look at the horizon rather than at what is immediately in front of them.</p><p>All these Overseer qualities are within your grasp and will come with moderate effort.</p>"
    }
  ];

  const getSelectedData = () => {
    const foundElement = resultPeoplePatternData.find((el) => el.tabName === tabName);
    return foundElement.desc;
  }
  
  return (
    <div>
       <div className="result-people-pattern-tabs-content-container">
          <div className="results-people-pattern-header">
           <p>Click on each tab to learn more about your people pattern</p>
          </div>
          <div className="result-people-pattern-tabs">
            <div className="tabs-container">
                {
                  resultPeoplePatternData?.map((el)=>{
                    return <div className={`tab tab-${el.tabName.toLowerCase()} ${el.tabName === tabName ? 'active' : ''}`} onClick={()=>{setTabName(el.tabName)}}>
                      <p>{el.tabName}</p>
                    </div>
                  })
                }
            </div>
            <div className="tab-text-container">
                <div className="tab-text-header">
                  <h4>{tabName}</h4>
                  <img src={tabTextCircle} alt="" />
                </div>
                <div className="tab-text" dangerouslySetInnerHTML={{ __html: getSelectedData() }}></div>
            </div>
          </div>
        </div>
    </div>
  )
}
