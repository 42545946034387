import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute } from "react-router-dom";
import TagManager from 'react-gtm-module';

import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import Header from './Components/Layout/Header/Header.jsx';
import Footer from './Components/Layout/Footer/Footer.jsx';
import LeftLogo from './Components/Layout/LeftLogo';
import Start from './Pages/Start/Start';
// import Assessment from './Pages/Assessment';
import AssessmentV3 from './Pages/Assessment/Assessment';
import AssessmentAdditional from './Pages/AssessmentAdditional';
import Details from './Pages/Details/Details';
import Results from './Pages/Results';
import Dashboard from './Pages/Dashboard/Dashboard.jsx';
import DashboardOverview from './Pages/Dashboard/Overview/DashboardOverview.jsx';
import Invite from './Pages/Invite/Invite';
import InviteThankYou from './Pages/InviteThankYou';
import PreAdditionalInfo from './Pages/PreAdditionalInfo';
import PreAdditionalQuestions from './Pages/PreAdditionalQuestions';
import PreAssessment from './Pages/PreAssessment/PreAssessment.jsx';
import { AssessmentProgressContextProvider } from './Store/AssessmentProgressContext';
import AssessmentProgressSections from './Components/AssessmentProgressSections';

import { PreAdditionalQuestionContextProvider } from './Store/PreAdditionalQuestionContext';
import { AdditionalQuestionContextProvider } from './Store/AdditionalQuestionContext';
import { AssessmentQuestionContextProvider } from './Store/AssessmentQuestionContext';
import { QuestionsProgressContextProvider } from './Store/QuestionsProgressContext';
import AuthContext from './Store/AuthContext.jsx';
import DonutChart from './Components/DonutChart';
import Intro from './Pages/Intro/Intro.jsx';
import FirstForm from './Pages/FirstForm/FirstForm.jsx';
import Charts from './Pages/Chart.jsx';
import AssessmentAdditionalV3 from './Pages/AssessmentAdditional/AssessmentAdditional';
import MultipleResults from './Pages/MultipleResults.jsx';
import ProgressMilestone from './Pages/ProgressMilestone/ProgressMilestone.jsx';

import './App.scss';
import { InitialTransition } from './Components/Animation/InitialTransition.jsx';
import ResultsSteward from './Pages/Results/ResultsSteward.jsx';
import Login from './Pages/Login/Login.jsx'
import Sidebar from './Components/Sidebar/Sidebar.jsx';
import DashboardDownloads from './Pages/Dashboard/Downloads/DashboardDownloads.jsx';
import DashboardResources from './Pages/Dashboard/Resources/DashboardResources.jsx';
import DashboardCompare from './Pages/Dashboard/Compare/DashboardCompare.jsx';
import DashboardInsights from './Pages/Dashboard/Insights/DashboardInsights.jsx';
import { DesignationContextProvider } from './Store/DesignationContext.jsx';
import { Language } from '@mui/icons-material';
import { LanguageContextProvider } from './Store/LanguageContext.jsx';
import { DashboardContextProvider } from './Store/DashboardContext.jsx';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#c0c000',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#878700',
  },
}));


// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-TJF3SRL5'
}

TagManager.initialize(tagManagerArgs)

function App() {

  const authCtx = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [contentBlocks, setContentBlocks] = React.useState([]);
  const [bodyBlock, setBodyBlock] = React.useState([]);
  const [currentLocation, setCurrentLocation] = useState(window.location.pathname);

  useEffect(() => {
    // getAssessmentContent()
  }, []);

  // const getAssessmentContent = () => {

  //   let url = window.location.href.replace("http://", "");
  //   url = url.replace("https://", "");
  //   let path = url.split("/");

  //   let urlId = "";
  //   if (path[0].includes("localhost")) {
  //     let devPath = path[0].split(":");
  //     urlId = (parseInt(devPath[1]) - 3000).toString();
  //   } else {

  //     if (url.includes("preview=")) {
  //       console.log("We have it");
  //       urlId = url.split("preview=");
  //       urlId = urlId[1].split("&");
  //       urlId = urlId[0].toString();
  //     }
  //     // else urlId = "50";
  //     else urlId = process.env.REACT_APP_CLIENT_ID;
  //   }

  //   console.log(urlId);

  //   // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + urlId + '/byUrl/' + process.env.REACT_APP_URL_FIELD, {

  //   setIsLoading(true);
  //   fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + urlId + '/content', {
  // 		method: 'GET',
  // 		mode: 'cors',
  // 		headers: { 'Content-Type': 'application/json' }
  // 	}).then(response => {
  // 		return response.json();
  // 	}).then(data => {
  //     console.log(data);

  // 		if(data.status == "success") {
  //       if(path[1].includes('start')) {
  //         console.log("Are we here");
  //         setContentBlocks(data.content.filter(obj => obj.page == "start"));
  //       }
  //       setBodyBlock(contentBlocks.filter(obj => obj.pageArea == "background"))
  //       setIsLoading(false);
  //       console.log(contentBlocks);
  //       console.log(bodyBlock);
  // 		} else {
  // 			// setOpenSnackbar(true);
  // 		}
  // 	}).catch((err) => {
  //     console.error("Failed to fetch assessment content...", err);
  //     setIsLoading(false);
  //   });

  // }

  useEffect(() => {
    setCurrentLocation(window.location.pathname)
  }, [authCtx, window.location.pathname])

  // if (isLoading) {
  //   return <div className="loading"><div style={{width: "400px"}}><BorderLinearProgress value={50} /></div></div>
  // }

  return (
    <div className="App">
      <AssessmentProgressContextProvider>
        <LanguageContextProvider>
          <DashboardContextProvider>
            <BrowserRouter>
              <AssessmentQuestionContextProvider>
                <DesignationContextProvider>
                  <AdditionalQuestionContextProvider>
                    <PreAdditionalQuestionContextProvider>
                      <QuestionsProgressContextProvider>
                        <Header />
                        <main className="main">
                          {/* <LeftLogo/> */}
                          {/*<AssessmentProgressSections />*/}
                          {(currentLocation.includes("/dashboard")) ? <Sidebar /> : null}
                          <Routes>
                            <Route path="/" exact index element={<Start setName={setName} />} />
                            <Route path="/start" exact element={<Start setName={setName} />} />
                            <Route path="/start/:startHash" exact element={<Start setName={setName} />} />
                            <Route path="/start/:type/:startHash" exact element={<Start setName={setName} />} />
                            <Route path="/login" exact index element={<Login />} />
                            {/* <Route path="/before-you-start" exact element={<PreAssessment />}></Route> */}
                            <Route path="/before-you-start/:id" exact element={<PreAssessment />}></Route>
                            <Route path="/before-you-start/:id/:pageId" exact element={<PreAssessment />}></Route>
                            {/* <Route path="/assessment" exact element={<Assessment />}></Route> */}
                            <Route path="/assessment/:id/:pageId" exact element={<AssessmentV3 />}></Route>
                            {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
                            {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
                            <Route path="/pre-additional-info/:id" exact element={<PreAdditionalInfo />}></Route>
                            <Route path="/pre-additional-questions/" exact element={<PreAdditionalQuestions />}></Route>
                            {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
                            {/* <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route> */}
                            {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
                            <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditionalV3 />}></Route>
                            <Route path="/details/:id" exact element={<Details />}></Route>
                            <Route path="/results/:id" exact element={<Results />}></Route>
                            {/* TODO: change /steward to /usertype later once they provide other users for results page */}
                            <Route path="/results/steward/:id" exact element={<ResultsSteward />}></Route>
                            <Route path="/invite-team/:id" exact element={<Invite />}></Route>
                            <Route path="/invite-team-thanks/:id" exact element={<InviteThankYou />}></Route>
                            {/* <Route path="/dashboard/:id" exact element={<Dashboard />}></Route> */}
                            <Route path="/dashboard/:id" exact element={<MultipleResults />}></Route>
                            <Route path="/dashboard/:id/overview" exact element={<DashboardOverview />} />
                            <Route path="/dashboard/:id/compare" exact element={<DashboardCompare />} />
                            <Route path="/dashboard/:id/insights" exact element={<DashboardInsights />} />
                            {/* <Route path="/dashboard/:id/downloads" exact element={<DashboardDownloads />} /> */}
                            <Route path="/dashboard/:id/resources" exact element={<DashboardResources/>} />
                            <Route path="/chart/:id/:pageId" exact element={<DonutChart />}></Route>
                            {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
                            <Route path="/intro/:id/:pageId" exact element={<Intro />}></Route>
                            <Route path="/intro" exact element={<Intro />}></Route>
                            <Route path="/first-form/:id/:pageId" exact element={<FirstForm />}></Route>
                            <Route path="/first-form/:id" exact element={<FirstForm />}></Route>
                            <Route path="/first-form" exact element={<FirstForm />}></Route>
                            <Route path="/charts/:id/:pageId" exact element={<Charts />}></Route>
                            <Route path="/progress-milestone/:id/:pageId" exact element={<ProgressMilestone />}></Route>
                          </Routes>
                        </main>
                        {
                          currentLocation.includes("/dashboard/")
                            ? null :
                            <Footer />
                        }
                      </QuestionsProgressContextProvider>
                    </PreAdditionalQuestionContextProvider>
                  </AdditionalQuestionContextProvider>
                </DesignationContextProvider>
              </AssessmentQuestionContextProvider>
            </BrowserRouter>
          </DashboardContextProvider>
        </LanguageContextProvider>
      </AssessmentProgressContextProvider>
    </div>
  );
}

export default App;
