export const StartPageDutch = ( text, type, section ) => {
  switch ( section ) {
    case "intro-heading": 
      return "Is uw parochie op de goede weg met haar missie?";
    case "intro-para1": 
      return "Als u wilt dat uw parochie meer missionair wordt, dan heeft zij een duidelijk beeld nodig van waar zij nu staat.";
    case "intro-para2": 
      return "Door anoniem mee te doen aan dit onderzoek (15 min) helpt u uw parochie om dat beeld te vormen en zo draagt u bij aan haar missionaire reis.";
    case "user-selection-heading": 
      return "Welke rol heeft u in de parochie?";
    case "category1": 
      return "Priester";
    case "category2": 
      return "Leider";
    case "category3": 
      return "Parochiaan";
    case "choose-user-text": 
      return "Weet u het niet zeker? Kies ‘Parochiaan’.";
    case "cta": 
      return "Start";
    default: 
      return text;
  }
}