export const DashboardCompareDutch = (text, type, section) => {
  switch (section) {
    case "compare-heading-info":
      return "Vergelijk hoe de leiders en parochianen de parochie zien.";
    case "holy-spirit":
      return "Heilige Geest";
    case "evangelization":
      return "Evangeisatie";
    case "leadership":
      return "Leiderschap"
    case "leaders":
      return "Leiders"
    case "parishioners":
      return "parochianen"
    case "experience-of-the-holy-spirit":
      return "Ervaring van de Heilige Geest"
    case "asking-and-discerning-in-faith":
      return "Vragen en onderscheiden in geloof"
    case "responding-in-faith":
      return "Vol geloof reageren"
    case "resources-aligned":
      return "Besteding middelen"
    case "primacy-of-programs":
      return "Voorrang voor trajecten"
    case "cultural-reinforcement":
      return "Cultuurversterking"
    case "vision":
      return "visie"
    case "planning-and-priorities":
      return "Planning en prioriteiten"
    case "leading-out-of-a-team":
      return "Leiden vanuit een team"
    case "growing-leadership-skills":
      return "Groeiende leiderschapsvaardigheden"
    case "not-enough-leaders-or-parishioners" :
      return "Nodig uw parochianen en leiders uit om de vragenlijst in te vullen om hun resultaten te bekijken."
    default:
      return text;
  }
}