import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";

// import Logo from '../../Assets/Images/logo.png';
import logoLeft from '../../../Assets/Images/divine-renovation.svg';
import logoLeftMobile from '../../../Assets/Images/leftlogomob.png';
// import logoLeftMobile from '../../../Assets/Images/left-logo-mobile.svg';
// import bleatLogo from '../../../Assets/Images/logo-bleat-desktop.png';
// import bleatLogo from '../../../Assets/Images/assessment-logo-right.svg';
import logo from '../../../Assets/Images/missional-waypoint-logo.svg'
import bleatLogo from '../../../Assets/Images/Bleatlogo.png';
import bleatLogoMobile from '../../../Assets/Images/bleat-logo-mobile.png';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import assessmentTitle from '../../../Assets/Images/assessment-name.png'
import DashboardDownArrowRed from "../../../Assets/Images/dashboardDownArrowRed.png"
import { motion } from 'framer-motion';
import AuthContext from '../../../Store/AuthContext';
import { MenuItem, Select } from '@mui/material';
import LanguageContext from '../../../Store/LanguageContext';
import DashboardContext from '../../../Store/DashboardContext';
import AdditionalQuestionContext from '../../../Store/AdditionalQuestionContext';
import { isJsonString } from '../../../utils/helper';


//Assers/Images/flags
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const flagImages = importAll(require.context('../../../Assets/Images/Bleat-flags', false, /\.(png|jpe?g|svg)$/));
const Header = props => {

  const { windowWidth } = useWindowDimensions();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const langCtx = useContext(LanguageContext);
  const dashboardCtx=useContext(DashboardContext)

  const [currentLocation, setCurrentLocation] = useState(location.pathname);
  // const [parishDetails, setParishDetails] = useState()
  const [languages, setLanguages] = useState([
    { "value": "English" },
    { "value": "Nederlands" }
  ])
  const [dropdown, setDropdown] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [formInfo, setFormInfo] = useState([]);

  const apiData = dashboardCtx.parishAPIData;
  const id = dashboardCtx.dashboardId;

  const parishDetails = useMemo(() => {
    const details = {};

    dashboardCtx.parishOverviewAPIData?.fields?.forEach((el) => {
      if (el?.field_name === 'attendanceSliderValue') {
        details.attendance = el?.value;
      }
      if (el?.field_name === 'locationType') {
        details.locationType = el?.value;
      }
      if (el?.field_name === 'socioEconomic') {
        details.socioEconomic = el?.value;
      }
    });
    return details;
  }, [dashboardCtx.parishOverviewAPIData])


  useEffect(() => {
    const firstFormData = localStorage.getItem('firstFormData')
    if(isJsonString(firstFormData)) {
      setFormInfo(JSON.parse(firstFormData))
    }
    getParishDetails(id);
  }, [id])

  const logoLeftBox = {
    initial: {
      position: "relative",
      top: "15px",
      opacity: 0
    },
    animate: {
      top: 0,
      opacity: 1,
      transition: {
        delay: 1.2,
        duration: 0.8,
        ease: [.175, .885, .32, 1.275],
      },
    },
  };

  const middleBox = {
    initial: {
      position: "relative",
      top: "15px",
      opacity: 0
    },
    animate: {
      top: 0,
      opacity: 1,
      transition: {
        delay: 1.6,
        duration: 0.8,
        ease: [.175, .885, .32, 1.275],
      },
    },
  };

  const rightLogoBox = {
    initial: {
      position: "relative",
      top: "15px",
      opacity: 0
    },
    animate: {
      top: 0,
      opacity: 1,
      transition: {
        delay: 2,
        duration: 0.8,
        ease: [.175, .885, .32, 1.275],
      },
    },
  };

  function getParishDetails(id) {
    if ((location.pathname.includes("/dashboard") && 
        (location.pathname.includes("/resources") || 
          location.pathname.includes("/insights") || 
          location.pathname.includes("/compare"))) && 
        (id || "" ).length > 0
    )
  {
    // load parishOverview data, if it doesn't exists.
    // check data instead of fields as data is always going to contain value
    if(Object.keys(dashboardCtx?.parishOverviewAPIData?.data || {}).length === 0) {
      fetch(process.env.REACT_APP_API_URI + '/v4/user/assessment/' + id + '/parishOverview', {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json()
        })
        .then(data => {
          if (data.status === "success") {
            dashboardCtx.setParishOverviewAPIData({data: data?.data || {}, fields: data?.fields || []})
          } else {
            // setOpenSnackbar(true);
          }
        });
    }

    // load parishAPIData if it doesn't exist.
      if( Object.keys(dashboardCtx?.parishAPIData || {}).length === 0 ) {
        fetch(process.env.REACT_APP_API_URI + "/v4/user/assessment/" + id + "/parish", {
          method: "GET",
          mode: "cors",
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            if (data.status === "success") {
              dashboardCtx.setParishAPIData(data?.data || {});
            }
          })
          .catch((error) => {
            console.error("There was a problem with the fetch:", error);
          });
      }
    }
  }

  const calculateAttendanceValue = (value) => {
    const minStep = 100;
    const maxStep = 5000;
    const totalSteps = 14;
    const perStep = 7.14;

    const currentStep = Math.round(value / perStep);

    const stepSize = 4900 / 14; 

    let resultValue;
    if (currentStep === 0) {
      resultValue = minStep;
    } else if (currentStep == 14) {
      resultValue = maxStep;
    }
    else {
      resultValue = stepSize * currentStep;
    }

    return resultValue
  };


  useEffect(() => {
    setCurrentLocation(location.pathname)
  }, [authCtx, window.location.pathname])

  useEffect(() => {
    langCtx.setLanguage(selectedLanguage)
  }, [selectedLanguage])


  return (
    <>
      <header>
        <div>
          {(!currentLocation.includes("/dashboard")) ?
            <div className="header-wrapper">

              {/* {windowWidth > 830 ?  */}
              {/*<motion.div className="logo-left" initial="initial" animate="animate" variants={logoLeftBox}>
            <img src={logoLeft} alt="Logo" />
  </motion.div>*/}

              {/* {windowWidth > 830 ?  */}
              {/*<motion.div className="assessment-title desktop" initial="initial" animate="animate" variants={middleBox} id="assessment-title">
            <p style={{ margin: "0 auto" }} className="logo-text"><span>Missional Waypoint</span></p>
            <p className="logo-subtext"><span>ASSESSMENT</span></p>
          </motion.div> */}
              <motion.div className="logo-left" initial="initial" animate="animate" variants={middleBox} id="assessment-title">
                <img src={logo} alt="Logo" />
              </motion.div>

              {/* {windowWidth > 830 ?  */}
              {/*<motion.div className="logo-left" initial="initial" animate="animate" variants={logoLeftBox}>
            {/* <img src={logoLeft} alt="Logo" /> 
</motion.div>*/}
              {/* <motion.div className="logo-right" initial="initial" animate="animate" variants={rightLogoBox} style={{}} >
            <img src={bleatLogo} width="auto" height={67.5} alt="Bleat" />
          </motion.div> */}
              <div className='translation-container'>
                <Select className="form-details-text"
                  value={selectedLanguage}
                  onFocus={(e) => { }}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                  displayEmpty={true}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <div className="select-placeholder">Select Language</div>
                    }
                    return selected
                  }}
                >
                  {languages.map((lang, i) => <MenuItem key={lang.value} value={lang.value}>{lang.value}</MenuItem>)}
                </Select>
              </div>
            </div>
            :
            <div className='header-container'>
              <img src={logo} alt="" className='logo' />
              <div className='translation-container' style={{ position: "relative", right: "-20%" }}>
                <Select className="form-details-text"
                  value={selectedLanguage}
                  onFocus={(e) => { }}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                  displayEmpty={true}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <div className="select-placeholder">Select Language</div>
                    }
                    return selected
                  }}
                >
                  {languages.map((lang, i) => <MenuItem key={lang.value} value={lang.value}>{lang.value}</MenuItem>)}
                </Select>
              </div>
              <div className='container-content'>
                <div className='user-details'>
                  <div className='flag'>
                    <img src={flagImages[`${apiData?.parishDetails[0]?.country}.png`]} className='flag' alt="" />
                  </div>
                  <div className='names'>
                    <h3>{apiData?.parishDetails[0]?.diocese}</h3>
                    <p>{apiData?.parishDetails[0]?.parish}</p>
                  </div>
                  <div className='dropdown' onClick={() => {
                    setDropdown(!dropdown)
                  }}>
                    <img src={DashboardDownArrowRed} alt="" className={dropdown ? "open" : "close"} width={'12px'} />
                  </div>
                </div>
                <div className='tooltip' style={{ display: dropdown ? "block" : "none" }}>
                  <div className="score-arrow"></div>
                  <div className='tooltip-content'>
                    <p>Avg. Weekend Attendance:<strong>{' '}{calculateAttendanceValue(parishDetails?.attendance)}</strong></p>
                    <p>Socio-Economic Group:<strong>{' '}{parishDetails?.socioEconomic}</strong></p>
                    <p>Parish Location:<strong>{' '}{parishDetails?.locationType}</strong></p>
                  </div>
                </div>
              </div>
            </div>}
        </div>

      </header>
    </>
  )
}

export default Header;
