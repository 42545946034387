export const DashboardResourcesDutch = (text, type, section) => {
  switch (section) {
    case "resources-heading-info":
      return " Draag zo actief mogelijk bij aan de vernieuwing van uw parochie door optimaal gebruik te maken van de Missionaire wegwijzer en de middelen van Divine Renovation."
    case "feel-your-parish-to-come-alive":
      return "Voel je parochie tot leven komen"
    case "discover-the-transformation-heading":
      return "Ontdek de transformatie van parochies die ‘missionair-zijn’ omarmen"
    case "pray-heading":
      return "Bid voor je parochie";
    case "grow-heading":
      return " Groei binnen je parochie";
    case "reflect-heading":
      return "Reflecteer op je parochie";
    case "pray-description":
      return "Als je parochie op weg is naar parochievernieuwing, hebben priesters en leiders de kracht en bemoediging nodig die voortkomen uit het bidden van bijvoorbeeld de rozenkrans.";
    case "grow-description":
      return "Vind boeken en bronnen die je kunnen ondersteunen op de reis van parochievernieuwing.";
    case "reflect-description":
      return "Denk na over hoe je je parochie kunt helpen om mensen dichter bij Jezus te brengen.";
    case "pray-cta":
      return " bid";
    case "discover-cta":
      return "Ontdek";
    case "learn-cta":
      return "leer";
    case "discover-the-transformation":
      return "Ontdek de transformatie van parochies die ‘missionair-zijn’ omarmen";
    case "discover-the-transformation-description":
      return "Hier zijn drie manieren om te leren van, en steun te geven aan evangelisatie buiten jullie parochie";
    case "read-good-news":
      return "Lees goed nieuws";
    case "invest-in-yourself":
      return "Investeer in uwzelf";
    case "support-the-mission":
      return "Steun de missie";
    case "read-good-news-description":
      return "Leiding geven aan verandering kan moeilijk zijn. Maar u bent niet alleen! Ontvang inspirerende verhalen over hoop en vernieuwing in uw inbox.";
    case "invest-in-yourself-description":
      return "Doe mee aan een evenement om van anderen te leren, te groeien in leiderschap en nieuwe hoop te krijgen.";
    case "support-the-mission-description":
      return "Jullie parochie neemt stappen om mensen dichter bij Jezus te brengen. Stelt u zich de vele andere plaatsen voor waar levens kunnen worden getransformeerd. Met uw steun kan dat werkelijkheid worden.";
    case "sign-up-cta":
      return "Aanmelden";
    case "register-cta":
      return "Registreer";
    case "give-cta":
      return "Geef";

    default:
      return text;
  }
}