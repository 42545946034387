export const FirstFormPageDutch = (text, type, section) => {
  switch (section) {
    case "first-form-heading":
      return "Voordat we beginnen, moeten we iets meer weten over uw parochie";
    case "first-form-country":
      return "Land";
    case "country-placeholder":
      return " Bijv. Nederland";
    case "first-form-diocese":
      return " Bisdom";
    case "diocese-placeholder":
      return " Bijv. Breda";
    case "age-25-and-below":
      return "Jonger dan 25";
    case "age-26-45":
      return "26-45";
    case "age-46-65":
      return "46-65";
    case "age-66-and-above":
      return "66 en ouder";
    case "first-form-parish":
      return "Parochie";
    case "parish-placeholder":
      return " Bijv. Augustinus Parochie";
    case "cta":
      return "Volgende";
    case "little-more-about-parish":
      return "Vertel ons iets meer over uw parochie"
    case "average-weekly-attendance":
      return "Gemiddeld aantal kerkbezoekers per weekend"
    case "socio-economics-group":
      return "Sociaaleconomische groep"
    case "describe-the-socio-economic-group":
      return "Beschrijf uit welke sociaaleconomische groepen uw parochie bestaat in verhouding tot de regio."
    case "location":
      return "Locatie"
    case "refer-to-cities":
      return "Verwijs naar steden. Veel mensen wonen en werken in steden. Steden zijn dichtbevolkte knooppunten van handel waar een snelle levensstijl de norm is."
    case "urban":
      return "Stad"
    case "sub-urban":
      return "Voorstedelijk"
    case "rural":
      return "Platteland"
    case "other":
      return "Anders"
    case "urban-info":
      return "Veel mensen wonen en werken in steden. Steden zijn dichtbevolkte handelscentra waar een snelle levensstijl de norm is."
    case "sub-urban-info":
      return "Gebieden die gebouwd zijn aan de rand van steden. Ze zijn meestal minder dichtbevolkt dan de steden zelf."
    case "rural-info":
      return "Plattelandsgebieden zijn dun(ner) bevolkt. Ze liggen buiten steden, verder weg dan voorsteden. Op het platteland zijn er meestal grote onbebouwde gebieden."
    case "other-info":
      return "‘Anders’ omvat universiteitspastoraten, militaire pastoraten, missies, enz."
    case "age-placeholder":
      return "Selecteer/voer in"
    case "first-form-age":
      return "Leeftijd"
    case "lower":
      return "Laag (20% laagste inkomens)"
    case "lower-middle":
      return "Laag-midden (onderste 50% inkomens, maar niet arm)"
    case "middle":
      return "midden"
    case "upper-middle":
      return "Hoog-midden (bovenste 50% inkomens, maar niet rijk)"
    case "upper":
      return "Hoog (5% hoogste inkomens)"
    case "select-placeholder" :
      return "Selecteer/voer in"
    default:
      return text;
  }
}