import { useState, useEffect, createContext } from 'react';
import { isJsonString } from '../utils/helper';

const DesignationContext = createContext({
  designation: '',
  priestDetails: '',
  priestHash: '',
  QrCode: '',
  updateDesignation: () => {},
  resetDesignation: () => {},
  updatePriestDetails: () => {},
  updatePriestHash: () => {},
  updateQrCode: () => {},
  resetQrCode: () => {},
});

export function DesignationContextProvider(props) {
  const [designation, setDesignation] = useState('');
  const [priestDetails, setPriestDetails] = useState('');
  const [priestHash, setPriestHash] = useState('');
  const [QrCode, setQrCode] = useState('');

  useEffect(() => {
    const storedDesignation = localStorage.getItem('designation');
    if (storedDesignation) {
      setDesignation(storedDesignation);
    }

    const storedPriestDetails = localStorage.getItem('priestDetails');
    if (storedPriestDetails && isJsonString(storedPriestDetails)) {
      setPriestDetails(JSON.parse(storedPriestDetails));
    }

    const storedPriestHash = localStorage.getItem('priestHash');
    if (storedPriestHash && isJsonString(storedPriestHash)) {
      setPriestHash(JSON.parse(storedPriestHash));
    }

    const storedQrCode = localStorage.getItem('QrCode');
    if (storedQrCode) {
      setQrCode(storedQrCode);
    }
  }, []);

  function updateDesignationHandler(newDesignation) {
    localStorage.setItem('designation', newDesignation);
    setDesignation(newDesignation);
  }

  function resetDesignationHandler() {
    setDesignation('');
    localStorage.removeItem('designation');
  }

  function updatePriestDetailsHandler(newDetails) {
    localStorage.setItem('priestDetails', JSON.stringify(newDetails));
    setPriestDetails(newDetails);
  }

  function updatePriestHashHandler(newDetails) {
    localStorage.setItem('priestHash', JSON.stringify(newDetails));
    setPriestHash(newDetails);
  }

  function updateQrCodeHandler(newQrCode) {
    localStorage.setItem('QrCode', newQrCode);
    setQrCode(newQrCode);
  }

  function resetQrCodeHandler() {
    setQrCode('');
    localStorage.removeItem('QrCode');
  }

  const context = {
    designation: designation,
    priestDetails: priestDetails,
    priestHash: priestHash,
    QrCode: QrCode,
    updateDesignation: updateDesignationHandler,
    resetDesignation: resetDesignationHandler,
    updatePriestDetails: updatePriestDetailsHandler,
    updatePriestHash: updatePriestHashHandler,
    updateQrCode: updateQrCodeHandler,
    resetQrCode: resetQrCodeHandler,
  };

  return (
    <DesignationContext.Provider value={context}>
      {props.children}
    </DesignationContext.Provider>
  );
}

export default DesignationContext;
