import { useState, useEffect, Fragment } from 'react';
import bleatLogoWhite from '../../../Assets/Images/Bleatlogo.png';
import logoleft2 from '../../../Assets/Images/logo-left-white-footer.png';
import logoLeft1 from '../../../Assets/Images/logo-left-black-footer.png';
import bleatLogoBlack from '../../../Assets/Images/bleat-logo.png';
import bleatMobileBlack from '../../../Assets/Images/bleat-logo-mobile.png';
import bleatMobileWhite from '../../../Assets/Images/logo-bleat-mobile-white.png';
import bleatMobDark from '../../../Assets/Images/bleatlogoMobDark.svg';
import leftLogoMobileWhite from '../../../Assets/Images/left-logo-mobile-white.png'
import leftLogoMobileDark from '../../../Assets/Images/left-logo-moble-dark.png'
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';


const Footer = props => {

  const { windowWidth } = useWindowDimensions();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname.split("/")[1];
  const [logo, setLogo] = useState(bleatMobileBlack);
  const [isBgDark, setIsBgDark] = useState(false);
  const [leftLogo, setLeftLogo] = useState(logoleft2)
  // console.log(currentLocation);
  // console.log(currentLocation.pathname.split("/")[1]);

  useEffect(() => {

    // paths with dark background
    // const pathsWithDarkBg = ["before-you-start"];
    const pathsWithDarkBg = [];
    // const pathsWithDarkBg = [];

    if (pathsWithDarkBg.includes(currentPath)) {
      // setLogo(bleatMobileWhite);
      setIsBgDark(true);
    } else {
      // setLogo(bleatMobileBlack);
      setIsBgDark(false);
    }

  }, [currentLocation, currentPath]);

  useEffect(() => {
    if (isBgDark) {
      if (windowWidth > 830) setLogo(bleatLogoWhite);
      else setLogo(bleatMobileWhite);
    }
    else {
      if (windowWidth > 830) setLogo(bleatLogoBlack);
      else setLogo(bleatMobileBlack);
    }
  }, [isBgDark, windowWidth]);

  // console.log(window.location.pathname)
  useEffect(() => {
    if (windowWidth < 550) {
      setLeftLogo(leftLogoMobileWhite)
    } else {
      setLeftLogo(logoleft2)
    }
  }, [windowWidth])

  return <Fragment>

    <footer style={isBgDark ? { marginTop: "0", background: "#fff", maxWidth: "none" } : { minHeight: '70px' }}>

      {/* <div className={'bleat-logo'}>
        {windowWidth > 830 ? <Link to="/start" className="logo-right" style={{}}><img src={logo} width="auto" height={67.5} alt="Bleat" /></Link> : <Link to="/" className="logo-right"></Link>}
      </div> */}

      {/* {windowWidth <= 830 &&
        <div className="bleat-logo-mobile">
          <img src={bleatMobileWhite} alt="bleat logo" height="43px" width="auto" />
        </div>} */}

      <div className="bleat-logo-mobile">
        {
          window.location.pathname.includes("/results") ?
            <img src={logoLeft1} alt="bleat logo" height="69px" width="auto" />
            :
            <img src={leftLogo} alt="bleat logo" height="69px" width="auto" />
        }
        {
          window.location.pathname.includes("/results") ?
            <img src={bleatLogoBlack} alt="bleat logo" height="69px" width="auto" />
            :
            <img src={bleatLogoWhite} alt="bleat logo" height="69px" width="auto" />
        }
      </div>

    </footer>
  </Fragment>
}

export default Footer;
