export const resultStyle = `<style>
.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab p,
.results,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab p,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab p {
    margin: 0
}

.results {
    background: #fff;
    color: #343333;
    max-width: 100%;
    width: 100%; 
    box-shadow: none
}

.results .banner-img,
.results .results-details-container .result-section-report .card .inner-card .report {
    width: 100%
}

.results .results-details-container {
    padding: 60px 15px 30px;
    width: 96%;
    margin: 20px auto 30px
}

.results .results-details-container .result-details-container-header-content {
    max-width: 1100px;
    margin: 40px auto 180px
}
.results .results-details-container .result-details-container-header-content .pdfheight {
    margin: 0 auto 400px !important;
}

.dashboard-details-container .result-details-container-header-content .results-people-pattern-header p,
.results .results-details-container .result-details-container-header-content .results-people-pattern-header p,
.results-details-container .result-details-container-header-content .results-people-pattern-header p {
    text-align: center
}

.results .results-details-container .result-details-container-header-content h1 {
    text-align: center;
    color: #84161d;
    font-family: Lato, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 31px;
    margin: 0;
    padding: 0
}

.dashboard-details-container .result-details-container-header-content .name,
.results .results-details-container .result-details-container-header-content .name,
.results-details-container .result-details-container-header-content .name {
    text-transform: capitalize;
    margin-top: 0
}

.results .results-details-container .result-details-container-header-content p {
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 31px;
    color: #121212;
    margin: 20px auto;
    max-width: 75ch
}

.dashboard-details-container .result-people-pattern-tabs-content-container .results-people-pattern-header p,
.results .results-details-container .result-people-pattern-tabs-content-container .results-people-pattern-header p,
.results-details-container .result-people-pattern-tabs-content-container .results-people-pattern-header p {
    text-align: center;
    color: #747474;
    font-family: "PT Serif", serif;
    font-size: 18px;
    font-weight: 500;
    margin: 54px 5px 20px
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab {
    cursor: pointer;
    background: #fff;
    color: grey;
    padding: 5px 14px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 14px
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-mentor,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-mentor,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-mentor {
    border: 2px solid #88a2c7
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-administrator,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-administrator,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-administrator {
    border: 2px solid #ba837e
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-catalyst,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-catalyst,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-catalyst {
    border: 2px solid #bcc793
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-relational,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-relational,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-relational {
    border: 2px solid #978fb2
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-overseer,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-overseer,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-overseer {
    border: 2px solid #97bece
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-mentor.active,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-mentor.active,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-mentor.active {
    background: #88a2c7;
    color: #fff
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-administrator.active,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-administrator.active,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-administrator.active {
    background: #ba837e;
    color: #fff
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-catalyst.active,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-catalyst.active,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-catalyst.active {
    background: #bcc793;
    color: #fff
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-relational.active,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-relational.active,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-relational.active {
    background: #978fb2;
    color: #fff
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-overseer.active,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-overseer.active,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tabs-container .tab-overseer.active {
    background: #97bece;
    color: #fff
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container {
    margin: auto;
    background-color: #e1e8f1;
    max-width: 650px;
    width: 98%;
    padding: 15px 36px 15px 25px;
    border: 1px solid grey;
    border-radius: 5px;
    box-sizing: border-box
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header {
    display: flex;
    align-items: center;
    gap: 6px
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header img,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header img,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header img {
    margin-top: 1px
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header h4,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header h4,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container .tab-text-header h4 {
    color: #747474;
    font-size: 18px;
    margin: 0
}

.dashboard-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container p,
.results .results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container p,
.results-details-container .result-people-pattern-tabs-content-container .result-people-pattern-tabs .tab-text-container p {
    color: #747474;
    font-family: "PT Serif", serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px
}

.results .results-details-container .result-details-container {
    background-color: #fff;
    width: 95%;
    border-radius: 5px;
    margin: 60px auto;
    max-width: 990px;
}

.results .results-details-container .result-details-container .result-details-title {
    color: #fff;
    text-align: left;
    margin: 2px auto 2px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    font-family: Lato, sans-serif
}

.results .selection-form,
.results .selection-form .form-details-text .MuiInput-root {
    font-size: 36px;
    font-family: Lato, sans-serif
}

.results .results-details-container .result-details-container .result-details-content {
    background-color: #fff;
    padding: 0
}

.results .results-details-container .result-details-container .result-details-content div {
    display: flex;
    justify-content: flex-start;
    algn-items: flex-start;
    gap: 16px;
    padding: 10px 20px;
    flex-direction: column !important;
}

.results .members-answered .answered-detail:nth-child(2n),
.results .results-details-container .result-details-container .result-details-content div:nth-of-type(2n) {
    background-color: #f4f4f4
}

.results .results-details-container .result-details-container .result-details-content div img {
    display: block !important;
    width: 30px !important;
    height: 30px !important;
}

.results .results-details-container .result-details-container .result-details-content div p {
    font-family: Lato, sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    margin: 0
}

.results .results-details-container .result-details-container .result-details-summary {
    padding: 16px 30px
}

.results .results-details-container .result-details-positive {
    border: 2px solid #87976e
}

.results .results-details-container .result-details-positive .result-details-summary,
.results .results-details-container .result-section-report .card .inner-card .report .progress-bar .current-progress.high-score {
    background-color: #87976e
}

.results .results-details-container .result-details-negative {
    border: 2px solid #84161d
}

.results .results-details-container .result-details-negative .result-details-summary,
.results .results-details-container .result-section-report .card .inner-card .report .progress-bar .current-progress.low-score {
    background-color: #84161d;
}

.results .results-details-container .result-section-report {
    width: 95%;
    max-width: 900px;
    margin-top: 20px;
    gap: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: auto auto 250px;
    flex-wrap: wrap
}

.results .results-details-container .result-section-report .card {
    width: calc(100% / 3 - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 275px
}

.results .results-details-container .result-section-report .card .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
    min-height: 190px;
}

.results .results-details-container .result-section-report .card .image-container img {
    height: 120px;
    width: auto
}

.results .results-details-container .result-section-report .card .image-container h1 {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    font-family: Lato, sans-serif;
    margin: 10px auto
}

.results .results-details-container .result-section-report .card .image-container p {
    width: 105px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    font-family: Lato, sans-serif;
    border-radius: 50px;
    text-align: center;
    padding: 5px;
    margin: 0
}

.results .results-details-container .result-section-report .card .image-container p.low-score {
    background-color: #84161d;
    color: #fff
}

.results .results-details-container .result-section-report .card .image-container p.medium-score {
    background-color: #ebbd79;
    color: #fff
}

.results .results-details-container .result-section-report .card .image-container p.high-score {
    background-color: #87976e;
    color: #fff
}

.results .results-details-container .result-section-report .card .inner-card {
    padding: 20px 30px;
    background-color: #f7f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 #000 40;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
    min-height: 350px;
    flex-shrink: 1;
    box-sizing: border-box
}

.results .results-details-container .result-section-report .card .inner-card .report h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    color: #747474;
    margin: 0;
    width: 100%;
    text-align: center
}

.results .results-details-container .result-section-report .card .inner-card .report .progress-bar {
    margin: 10px 0;
    width: 100%;
    background-color: #e8e8e8;
    height: 9px;
    border-radius: 10px;
    max-width: 100%
}

.results .results-details-container .result-section-report .card .inner-card .report .progress-bar .current-progress {
    height: 100%;
    border-radius: 10px
}

.results .results-details-container .result-section-report .card .inner-card .report .progress-bar .current-progress.medium-score {
    background-color: #ebbd79
}

.results .results-details-container .invite-pl-container {
    max-width: 990px;
    width: 95%;
    margin: 60px auto 0
}

.results .overall-score-subtext p span,
.results .results-details-container .invite-pl-container h1 {
    font-weight: 700
}

.results .results-details-container .invite-pl-container p {
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    padding: 0 1rem
}

.results .results-details-container .invite-pl-container .copy-url-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    text-align: center;
    border-radius: 20px;
    background-color: #f0f0f0
}

.results .results-details-container .invite-pl-container .copy-url-container p {
    padding: 4px 16px;
    border-radius: 20px;
    color: #000 66;
    margin: 0;
    flex-wrap: wrap
}

.results .results-details-container .invite-pl-container .copy-url-container .copy-text {
    background-color: #84161d;
    color: #fff;
    border-radius: 20px;
    padding: 4px 20px;
    cursor: pointer
}

.results .results-details-container .qr-recommend {
    justify-content: center;
    align-items: center
    display: flex;
    margin: 65px 0;
    font-size: 21px;
    width: 100%
}
.results .results-details-container .qr-recommend p {
    text-align: center
}

.results .results-details-container .view-dashboard-container {
    background-color: #f9f9f9;
    max-width: 990px;
    width: 95%;
    padding: 50px;
    margin: 60px auto 0;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center
}

.results .results-details-container .view-dashboard-container h1 {
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    color: #343333;
    text-align: center
}

.results .results-details-container .view-dashboard-container p {
    font-family: Lato, sans-serif;
    font-weight: 300;
    font-size: 21px;
    line-height: 32px;
    color: #343333;
    text-align: center;
    margin: 20px 0
}

.results .results-details-container .view-dashboard-container button {
    border: none;
    background-color: #84161d;
    color: #fff;
    border-radius: 100px;
    padding: 10px 15px;
    font-size: 21px;
    line-height: 30px;
    font-family: Lato, sans-serif;
    font-weight: 700;
    letter-spacing: 1px
}

.results .results-details-container .view-dashboard-container button:hover {
    background-color: #97a97c;
    transition: .3s;
    color: #fff
}

.results .results-details-container .download-results-cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: auto
}

.results .results-details-container .download-results-cta-container button {
    margin: auto;
    padding: 10px 20px;
    background: #792140;
    color: #fff;
    border-radius: 25px;
    font-family: Lato, sans-serif;
    font-size: 21px;
    font-weight: 700;
    outline: 0;
    border: none;
    cursor: pointer;
    line-height: 30px
}

.results .results-details-container .parish-support {
    padding: 0 28px 28px;
    border: 2px solid #84161d;
    max-width: 1200px;
    border-radius: 25px;
    margin: 140px auto 0;
    position: relative
}

.results .results-details-container .parish-support .header {
    width: 100%;
    text-align: center;
    background-color: #fff;
    width: fit-content;
    margin: auto auto 40px;
    padding: 0 60px
}

.results .results-details-container .parish-support .header h1 {
    font-size: 36px;
    line-height: 43px;
    font-weight: 700;
    font-family: Lato, sans-serif;
    color: #84161d;
    padding: 0;
    margin: -40px auto auto;
    max-width: 35ch
}

.results .results-details-container .parish-support .header .parish-support-description {
    text-align: center;
    margin-top: 20px
}

.results .results-details-container .parish-support .cards {
    display: flex;
    gap: 30px;
    align-items: stretch;
    justify-content: center
}

.results .results-details-container .parish-support .cards .card {
    background-color: #efefef;
    border-radius: 5px;
    padding: 30px 30px 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative
}

.results .results-details-container .parish-support .cards .card .image-container {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: #44546a;
    display: flex;
    justify-content: center;
    align-items: center
}

.results .results-details-container .parish-support .cards .card .image-container img {
    width: 75%
}

.results .results-details-container .parish-support .cards .card h1 {
    font-family: Lato, sans-serif;
    font-weight: 700;
    font-size: 21px;
    line-height: 30px;
    color: #343333;
    margin: 15px auto
}

.results .results-details-container .parish-support .cards .card p {
    font-family: Lato, sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 31px;
    color: #121212;
    text-align: center;
    margin: 0;
    max-width: 29ch
}

.results .results-details-container .parish-support .cards .card .btn-cta {
    display: block;
    padding: 10px 80px;
    background-color: #84161d;
    color: #fff;
    font-family: Lato, sans-serif;
    font-size: 21px;
    line-height: 30px;
    font-weight: 700;
    border: none;
    border-radius: 100px;
    margin-top: 20px;
    cursor: pointer;
    bottom: 30px
}

.results .spacer {
    height: 20px
}

.results .selection-form {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px auto;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    font-weight: 400
}

.results .selection-form .form-detail {
    flex: 0 1 100%;
    width: 60%
}

.results .selection-form .form-text {
    flex: 0 1 100%;
    text-align: center;
    font-size: 34px
}

.results .selection-form .form-details-text {
    background-color: #fff;
    border: 0 solid #efefef;
    border-bottom: 4px solid #17b582;
    padding: 3px 7px;
    max-width: 90%;
    text-align: center
}

.results .selection-form .form-details-text .MuiInput-root .select-custom-icon {
    position: absolute;
    right: -45px;
    padding: 5px 10px
}

.results .selection-form .form-details-text .MuiInput-root .select-custom-icon img {
    width: 24px;
    height: 14px
}

.results .overall-score-subtext {
    width: auto;
    max-width: 90%;
    box-sizing: border-box;
    margin: 25px auto 50px
}

.results .overall-score-subtext p {
    margin-top: 0;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;
    color: #121212
}

.results h1 {
    color: #343333;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    font-family: Lato, sans-serif
}

.results h1 .user-name {
    text-transform: capitalize
}

.results .single-group_assessment #group-sections .si_ass_result {
    padding: 7% 0 3%
}

.results span.msi_name {
    color: #17b582;
    font-size: 18px;
    font-weight: 900;
    font-family: Lato, sans-serif
}

.results .pro_top_details span {
    display: block;
    line-height: 30px;
    min-width: 180px
}

.results .pro_top_details {
    position: absolute;
    bottom: 45px;
    left: 0;
    max-width: 130px;
    width: 100%;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out
}

.results .progress-opacity-filter,
.results .si_ass_progress {
    position: relative;
    max-width: 700px;
    margin: 0 auto;
    height: 26px
}

.results .in-ass-result .si_ass_progress span,
.results .single-assesment .si_ass_progress span {
    position: absolute;
    font-size: 30px;
    bottom: 42px;
    left: 0;
    font-weight: 700;
    color: #4a4a4a;
    font-family: Lato, sans-serif
}

.results .single-group_assessment #group-sections .si_ass_progress:before {
    height: 45px
}

.results .si_ass_progress {
    border-radius: 12px;
    width: 100%;
    background: linear-gradient(90deg, rgba(220, 218, 218, .6) 0, rgba(220, 220, 220, .49) 100%)
}

.results .progress-opacity-filter {
    display: flex;
    bottom: -26px;
    z-index: 1;
    box-sizing: border-box
}

.results .si_ass_progress .progress-line {
    z-index: 1;
    width: 2px;
    height: 26px;
    content: ' ';
    left: 0;
    bottom: 0;
    position: absolute;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out
}

.results .si_ass_progress .progress-line .progress-text {
    position: absolute;
    top: -65px;
    left: -100px;
    width: 200px;
    font-size: 18px;
    color: #7ccee7;
    height: 30px;
    text-align: center
}

.results .si_ass_progress .msi_score {
    z-index: 2;
    background-color: #fdbf04;
    border-radius: 8px;
    padding: 2px 5px;
    display: block;
    position: absolute;
    font-size: 27px;
    font-weight: 700;
    color: #000;
    bottom: 55px;
    left: 0;
    margin-left: -30px;
    min-width: 45px;
    text-align: center
}

.results .si_ass_progress .score-arrow {
    z-index: 1;
    width: 20px;
    height: 20px;
    content: ' ';
    background-color: #fdbf04;
    left: 0;
    bottom: 50px;
    position: absolute;
    margin-left: -10px;
    transform: rotate(-45deg)
}

.results .pre-score-header {
    padding: 30px 15px 1.5rem;
    text-align: center;
    color: #4a4a4a;
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin: auto 0
}

.results .result-sections {
    margin: 50px 5%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 50px
}

.results .selection-form .form-details-text .MuiInput-root .MuiSelect-select {
    padding-right: 0;
    font-size: 34px;
    background: #f1f1f1
}

.results .members-answered {
    width: 96%;
    max-width: 840px;
    background: #fbfbfb;
    margin: 20px auto;
    padding: 0;
    font-family: Lato, sans-serif;
    font-size: 18px;
    color: #1f2a44;
    text-align: left;
    border-radius: 8px
}

.results .members-answered .section {
    text-transform: uppercase;
    background-color: #126496;
    color: #fff;
    padding: 10px 17px;
    margin: 0;
    font-weight: 700;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer
}

.results .members-answered .section .section-toggle {
    display: block;
    cursor: pointer;
    height: 24px
}

.results .members-answered .answered-detail {
    font-weight: 400;
    font-size: 18px;
    color: #7ccee7;
    padding: 10px 30px;
    width: calc(100% - 60px);
    display: flex;
    align-items: center
}

.results .members-answered .answered-detail .answer {
    flex: 1 1 305px;
    margin-left: 10px;
    text-align: right;
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal
}

.results .members-answered .answered-detail p {
    margin: 0;
    color: #27282a;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.4px
}

.dashboard-details-container,
.results-details-container {
    padding: 60px 15px
}

.dashboard-details-container .result-details-container-header-content h1,
.results-details-container .result-details-container-header-content h1 {
    text-align: center;
    color: #3a6891;
    font-family: Raleway, sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 31px;
    margin: 20px 0;
    padding: 0
}

.dashboard-details-container .result-details-container-header-content p,
.results-details-container .result-details-container-header-content p {
    text-align: center;
    font-family: "PT Serif", serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    color: #7a7a7a;
    margin: 0
}

.milestone-bar-cover {
    margin-bottom: 6rem;
}

.milestone-bar-cover .milestone-bar {
    position: relative;
    background-color: #84161d;
    height: 3px;
    width: 100%;
    margin-top: 2rem;
}

.milestone-bar-cover .milestone-bar .circle {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: #84161d;
}

.milestone-bar-cover .milestone-bar .triangle {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 14px solid #84161d;
}

.milestone-bar-cover .milestone-bar p {
    font-weight: 300;
    text-align: center;
    transform: translateX(-45%);
    font-size: 21px;
    color: #343333;
    padding-top: 10px;
}

.milestone-bar-cover .milestone-bar .location {
    display: none;
}

.milestone-bar-cover .milestone-bar .dot-line {
    height: 2px;
    width: 4px;
    background-color: #989898;
    margin-top: 0.4rem;
}

.milestone-bar-cover .milestone-bar .lookers {
    position: absolute;
    top: -5px;
    left: 5%;
}

.milestone-bar-cover .milestone-bar .on-shore {
    position: absolute;
    top: -5px;
    left: 15%;
}

.milestone-bar-cover .milestone-bar .casting-off {
    position: absolute;
    top: -5px;
    left: 43%;
}

.milestone-bar-cover .milestone-bar .raising-sails {
    position: absolute;
    top: -5px;
    left: 68%;
}

.milestone-bar-cover .milestone-bar .sailing-waves {
    position: absolute;
    top: -5px;
    left: 84%;
}

.milestone-bar-cover .milestone-bar .beacon-parish {
    position: absolute;
    top: -5px;
    left: 95%;
}

.milestone-bar-cover .milestone-bar .triangle-one {
    position: absolute;
    top: -5px;
    left: 28%;
}

.milestone-bar-cover .milestone-bar .triangle-two {
    position: absolute;
    top: -5px;
    left: 57%;
}

.milestone-bar-cover .milestone-bar .triangle-three {
    position: absolute;
    top: -5px;
    left: 99.3%;
}

.milestone-bar-cover .active .circle {
    background-color: #fff;
}

.milestone-bar-cover .active p {
    font-weight: 700;
    font-size: 24px;
    color: #1b608a;
}

.milestone-bar-cover .active .location {
    display: block;
    object-fit: cover;
    width: 50px;
    height: 100%;
    margin-top: 0;
    position: absolute;
    top: -114px;
    left: -19px;
}

.milestone-bar-cover .active .location-img {
    object-fit: contain;
    width: 100%;
    margin-top: 6rem;
}

.download-button {
    margin-bottom: 120px;
    border: 1px solid #84161D;
    color: white;
    background: #84161D;
    text-transform: capitalize;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 21px;
    font-weight: 700
}

a {
    text-decoration: none;
}
.banner-container {
  height: 170px;
  width: 170px;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  right: -50%;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-outer-container {
  position: relative;
  height: 0;
  width: 95%;
}
.banner-inner-logo{

  width:95%
}

main .results .results-details-container .result-section-report .card .inner-card {
  padding: 20px 30px;
    background-color: #F7F4F4;
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2509803922);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    box-sizing: border-box;
}

main .results .results-details-container .result-details-container-header-content h1 {
  text-align: center;
  color: #84161D;
  font-family: "Helvetica Now Display";
  font-size: 32px;
  font-weight: 700;
  line-height: 1.6;
  margin: 0px 0px;
  padding: 0px;
}

main .results .results-details-container .qr-recommend {
    justify-content: center;
    display: flex;
    margin: 65px 0;
    font-size: 21px;
}
</style>`