import { StartPageDutch } from "./languages/Dutch/StartPageDutch";
import { FirstFormPageDutch } from "./languages/Dutch/FirstFormPageDutch";
import { ResultPageDutch } from "./languages/Dutch/ResultsPageDutch";
import { MilestoneBarDutch } from "./languages/Dutch/MilestoneBarDutch";
import { ResultsStewardDutch } from "./languages/Dutch/ResultsStewardDutch";
import { PreAssessmentPageDutch } from "./languages/Dutch/PreAssessmentPageDutch";
import { DashboardOverviewDutch } from "./languages/Dutch/DashboardOverviewDutch";
import { DashboardSidebarDutch } from "./languages/Dutch/DashboardSidebarDutch";
import { ProgressMilestonePageDutch } from "./languages/Dutch/ProgressMilestonePageDutch";
import { ScoresDutch } from "./languages/Dutch/ScoresDutch";
import { DiscoverDutch } from "./languages/Dutch/DiscoverDutch";
import { DashboardInsightsDutch } from "./languages/Dutch/DashboardInsightsDutch";
import { DashboardCompareDutch } from "./languages/Dutch/DashboardCompareDutch";
import { DashboardDownloadsDutch } from "./languages/Dutch/DashboardDownloadsDutch";
import { DashboardResourcesDutch } from "./languages/Dutch/DashboardResourcesDutch";
import { DashboardAnswersDutch } from "./languages/Dutch/DashboardAnswersDutch";

export const translate = (text, type, section) => {
  // console.log("type: ",type)
  switch (type) {
    case "startPageDutch":
      return StartPageDutch(text, type, section);
    case "firstFormPageDutch":
      return FirstFormPageDutch(text, type, section);
    case "resultPageDutch":
      return ResultPageDutch(text, type, section);
    case "milestoneBarDutch":
      return MilestoneBarDutch(text, type, section);
    case "resultsStewardDutch":
      return ResultsStewardDutch(text, type, section)
    case "preAssessmentDutch":
      return PreAssessmentPageDutch(text, type, section)
    case "dashboardOverviewDutch":
      return DashboardOverviewDutch(text, type, section)
    case "dashboardSidebarDutch":
      return DashboardSidebarDutch(text, type, section)
    case "progressMilestonePageDutch":
      return ProgressMilestonePageDutch(text, type, section)
    case "scoresDutch":
      return ScoresDutch(text, type, section);
    case "discoverDutch":
      return DiscoverDutch(text, type, section)
    case "dashboardInsightsDutch":
      return DashboardInsightsDutch(text, type, section)
    case "dashboardCompareDutch":
      return DashboardCompareDutch(text, type, section)
    case "dashboardDownloadsDutch":
      return DashboardDownloadsDutch(text, type, section)
    case "dashboardResourcesDutch":
      return DashboardResourcesDutch(text, type, section)
    case "dashboardAnswersDutch":
      return DashboardAnswersDutch(text, type, section)
    default:
      return text;
  }
}