import React, { useContext } from 'react'
import { Link, useParams } from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import mobileExperience from '../../Assets/Images/mobileExperience.svg'
import DashboardContext from '../../Store/DashboardContext';
import LanguageContext from '../../Store/LanguageContext';
import { translate } from '../translate/translate';

const Discover = () => {

  const { windowWidth } = useWindowDimensions()
  const dashboardCtx = useContext(DashboardContext)
  const langCtx = useContext(LanguageContext)

  // console.log("dashboardCtx.location: ",dashboardCtx.location)

  const handleTranslate = (text, type) => {

    // console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "discoverDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  return (
    <div className="discover-container">
      <div className="discover-container-spacer">
        {
          windowWidth >= 831 ?
            <h3>{handleTranslate("Discover the best next steps for your parish", "discover-heading")}</h3>
            :
            <h3>Mobile experience improvements underway!</h3>
        }
        {
          windowWidth >= 831 ?
          <p>
            {handleTranslate("Parish renewal isn't easy, but you don't have to do it alone.", "discover-info-1")}<br />
            {handleTranslate("At Divine Renovation, we accompany priests and lay leaders who desire to see their parish come alive by providing them with the tools and coaching they need to bring about renewal.", "discover-info-2")}
            <br />{handleTranslate("Let's dive deeper into your results and what these insights might mean for your parish.","discover-info-3")}
          </p>
          :
          <p>Please visit us on a desktop for now. <br /> Thank you for your patience!</p>
        }

        {
          windowWidth < 831 && <div className="mobile-experience-image">
            <img src={mobileExperience} alt="" />
          </div>
        }

        <div className="cta-btn">
          {
            windowWidth >= 831 &&
              <Link to={`https://missionalwaypoint.org/next-steps/#${dashboardCtx.location}`} target='_blank'>
                <button>{handleTranslate("Get in touch", "get-in-touch-cta")}</button>
              </Link>
          }
        </div>

      </div>
    </div>
  );
}

export default Discover;
