import { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import prevArrow from "../../Assets/Images/prev-arrow.svg";
import nextArrow from "../../Assets/Images/next-arrow.svg";
import testimonialImage from "../../Assets/Images/testimonial.jpg";
import scheduledImage from "../../Assets/Images/scheduledImage.png";
import InviteTeamImage from "../../Assets/Images/InviteTeamImage.png";
import quotationImage from "../../Assets/Images/quotation.svg";
import InviteInfo from "../../Components/InviteInfo";
import Testimonial from "../../Components/Testimonial";
import Resources from "../../Components/Resources";
import DonutChart from "../../Components/DonutChart";
import SendInvite from "../../Components/SendInvite";
import { translate } from "../../Components/translate/translate";
import LanguageContext from "../../Store/LanguageContext";
// import DonutChartAccordion from "../Components/DonutChartAccordion";
import { Button, Accordion, AccordionSummary, AccordionDetails, Box, Typography, LinearProgress, CircularProgress, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, CircleOutlined, CircleTwoTone, ExpandMore, Remove, WbSunnyOutlined } from "@mui/icons-material";
import sampleDashboardTeamImg from '../../Assets/Images/sample-dashboard-team-img.png';
import sampleDashboardTeamImgMobile from '../../Assets/Images/sample-dashboard-team-img-mobile.png';
import sampleDashboardIndividualImg from '../../Assets/Images/sample-dashboard-individual-img.png';
import sampleDashboardIndividualImgMobile from '../../Assets/Images/sample-dashboard-individual-img-mobile.png';
import CopyImg from '../../Assets/Images/copycontent.svg';
import ImportantImg from '../../Assets/Images/importantsign.svg';
import shadowIcon from '../../Assets/Images/shadow.svg'
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { sectionsText } from "../../globalFunctions";
import Promo from "../../Components/Promo/Promo";
import HorizontalBarCharts from "../../Components/HorizontalBarCharts";
import ResultsText from "../../Components/ResultsText";
import ClickableCircularProgressBars from "../../Components/ClickableCircularProgressBars";
import resultsImg from '../../Assets/Images/results.jpg';
import holySpiritImage from '../../Assets/Images/holy-spirit.svg';
import evangelizationImage from '../../Assets/Images/evangelization.svg';
import leadershipImage from '../../Assets/Images/leadership.svg';
import parishPray from '../../Assets/Images/parish-pray.svg';
import parishCross from '../../Assets/Images/parish-cross.svg';
import parishGrow from '../../Assets/Images/parish-increase.svg';
import parishRead from '../../Assets/Images/parish-read.svg';
import parishInvest from '../../Assets/Images/parish-invest.svg';
import parishSupport from '../../Assets/Images/parish-support.svg';
import Steward from '../../Assets/Images/StewardOuter.svg'
import StewardInner from '../../Assets/Images/StewardInner.svg'

import steward from '../../Assets/Images/steward.png';



const ResultsSteward = (props) => {
  let navigate = useNavigate();
  const { id } = useParams();
  const userType = window.localStorage.getItem("userType");

  const { windowWidth } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([])
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScore6, setSubScore6] = useState(0);
  const [subScore7, setSubScore7] = useState(0);
  const [subScore8, setSubScore8] = useState(0);
  const [subScore9, setSubScore9] = useState(0);
  const [subScore10, setSubScore10] = useState(0);
  const [subScores, setSubScores] = useState();
  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [chartsData, setChartsData] = useState();
  const [sectionsData, setSectionsData] = useState();
  const [sectionScores, setSectionScores] = useState([0, 0, 0, 0]);

  const langCtx = useContext(LanguageContext)

  const handleTranslate = (text, type) => {

    console.log('langCtx.language: ', langCtx.language)

    switch (langCtx.language) {
      case "Nederlands": {
        return translate(text, "resultsStewardDutch", type)
      }
      default: {
        return text;
      }
    }

  }

  const resultPeoplePatternData = [
    {
      "tabName": "Mentor",
      "desc": "<p>One of your Middle Grounds is the Mentor Pattern. With some concentration and effort, you can coach and develop others.</p><p>Mentors are people who like to come alongside you and develop you to be your best. They hone who you are, fanning the flame of your ability. Mentors build into you and help you realize your potential. They coach and guide you to help you discover your whole self. As trusted allies, they are friendly faces who encourage you to grow, develop and change.</p><p>All these Mentor qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Administrator",
      "desc": "<p>One of your Middle Grounds is the Administrator Pattern. With some concentration and effort, you can be good with tactics, details, and following the plan.</p><p>Administrators help establish the plan and then follow it as it is laid out. They like policy and structure, and are good with details and budgets. Administrators focus on management and tend to be averse to taking risks. They are good with procedures and like to follow the rules.</p><p>All these Administrator qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Catalyst",
      "desc": "<p>One of your Middle Grounds is the Catalyst Pattern. With some concentration and effort, you can be an innovator.</p><p>Catalysts are people who will challenge you, and so take you to new ground. They innovate and experiment in life, motivating you to change. Catalysts seem to light fires under people, spurring them on to unconventional or entirely new methods. They can be revolutionary, stirring up people. As trusted critics, we know their words are speaking truth as they speed up change in our lives.</p><p>All these Catalyst qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Relational",
      "desc": "<p>One of your Middle Grounds is the Relational Pattern. With some concentration and effort, you can be a great team builder.</p><p>Relationals are people who are motivated by harmony and see the team as crucial. They want people to be whole and healthy, and so are good team builders. Relationals highly value grace and mercy, and can be quite loving and warm. People deeply matter to them. Decisions are guided by deep-seated values and personal connections to others.</p><p>All these Relational qualities are within your grasp and will come with moderate effort.</p>"
    },
    {
      "tabName": "Overseer",
      "desc": "<p>One of your Middle Grounds is the Overseer Pattern. With some concentration and effort, you can focus on strategy.</p><p>Overseers like to see the big picture. They work best with principles and concepts as their guiding force. Overseers prefer to work on strategy, aligning people with their right places to work. They think in terms of the vision and direction of things, tending to look at the horizon rather than at what is immediately in front of them.</p><p>All these Overseer qualities are within your grasp and will come with moderate effort.</p>"
    }
  ];

  useEffect(() => {
    document.body.style.backgroundColor = "#ffffff";
    fetchResults();
  }, []);

  useEffect(() => {
    // basic check for API data reliability 
    if (apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "The community around us is aware of our mission.",
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1 }
        },
        {
          title: "How many engagement opportunities did you host in the past year open to the larger community?",
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2 }
        },
        {
          title: "Throughout last year, how many community members participated in these engagement opportunities?",
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3 }
        },
        {
          title: "Over time, our church's engagement with our community has been...",
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4 }
        },
        {
          title: "Our community frequently utilizes our church's building facilities and programs.",
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5 }
        },
      ]);
    }
  }, [apiData]);

  console.log("chartsData", chartsData);

  // useEffect(() => {
  //   if (apiData?.variables) {
  //     const sectionData1 = apiData?.variables?.find((obj) => obj.variable === "priority_total");
  //     const sectionData2 = apiData?.variables?.find((obj) => obj.variable === "practising_total");
  //     const sectionData3 = apiData?.variables?.find((obj) => obj.variable === "modelling_total");
  //     const sectionData4 = apiData?.variables?.find((obj) => obj.variable === "promotion_total");
  //     const scoreTotal = apiData?.variables?.find((obj) => obj.variable === "score_total");
  //     // setScore(scoreTotal.score);
  //     setSectionScores([sectionData1.score, sectionData2.score, sectionData3.score, sectionData4.score]);
  //     setSubScore1(sectionData1.score);
  //     setSubScore2(sectionData2.score);
  //     setSubScore3(sectionData3.score);
  //     setSubScore4(sectionData4.score);
  //     setSubScores([sectionData1.score, sectionData2.score, sectionData3.score, sectionData4.score]);
  //   }
  // }, [apiData]);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };

    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9, subScore10]);

  }, [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9, subScore10]);


  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log('assessment data', data);

          setApiData(data);
          setName(data.name);
          setScore(data.score);
          // setScore(data.variables?.find((obj) => obj.variable === "score_total")?.score || 0);
          setAnswers(data?.answers)
          setSections(data.sections);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setSubScore6(data.subScore6);
          setSubScore7(data.subScore7);
          setSubScore8(data.subScore8);
          setSubScore9(data.subScore9);
          setSubScore10(data.subScore10);
          setIsMember(data.isMember);
          // console.log(data);

          setIsLoading(false);

        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const showAccordion = () => {
    return sections?.map((el, index) => {
      const asciiCode = 65 + index;
      const character = String.fromCharCode(asciiCode);
      const showAccountabilitySection = Math.round(sectionsAndSubScores?.[index]?.score) <= 35;
      console.log("el", el)
      return (
        <div className="charts-accordions-container" key={index}>
          <Accordion className="accordion-container" defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="result-typography">
                {`PART ${character}: ${el.section} : ${Math.round(sectionsAndSubScores?.[index]?.score)}%`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details-container">
                {el?.topQuestions?.length > 0 &&
                  <div className="result-container">
                    <div className="header">
                      <div>Areas to Celebrate</div>
                    </div>
                    <div className="description">
                      <p>First, let's celebrate the ways you already rely on the Lord through prayer in your daily life!</p>
                    </div>
                    <div className="content" >
                      {
                        el?.topQuestions?.map((tq, idx) => {
                          return <div className="item" key={idx}>
                            <div className="item-description" style={{ padding: showAccountabilitySection ? "10px 10px 10px 45px" : "10px 10px 10px 10px" }}>
                              {tq.question}
                            </div>
                            <div className="item-status">
                              {/* {getAnswer(tq.id)} */}
                              {tq?.answerText}
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>}
                {el?.bottomQuestions?.length > 0 &&
                  <div className="result-container result-container-second">
                    <div className="header">
                      <div>AREAS TO Improve</div>
                    </div>
                    <div className="description">
                      <p>
                        Here are some areas where your answers differed from those of world-changing leaders.
                      </p>
                      {showAccountabilitySection && <h4>Choose two areas below to ask God to work in during the upcoming month.</h4>}
                    </div>
                    <div className="content">
                      {el?.bottomQuestions?.map((question, idx) => {
                        const checked = false;
                        return <div className="item" key={idx}
                          style={{ cursor: showAccountabilitySection ? "pointer" : "auto" }}
                          onClick={() => {
                            if (showAccountabilitySection) {
                              handleRadioChange(question.id, question.question);
                            }
                          }}>
                          <div className="radio-question-container">
                            {showAccountabilitySection && <input type="radio"
                              onClick={() => {
                                // handleRadioChange(question.id, question.question)
                              }}
                              onChange={() => { }}
                              checked={selectedQuestions.some((q) => q.id === question.id)}
                            />}
                            <div className="item-description">{question.question}</div>
                          </div>
                          <div className="item-status">
                            {/* {getAnswer(question.id)} */}
                            {question?.answerText}
                          </div>
                        </div>
                      })}
                      {console.log("selectedQuestions", selectedQuestions)}
                    </div>
                  </div>}
                {showAccountabilitySection &&
                  <div className="result-container result-container-third">
                    <div className="header">
                      <div>ACCOUNTABILITY</div>
                    </div>
                    <div className="accountability-content">
                      <div className="description">
                        <p>Setting goals is a great first step! To help with your follow-through, add some accountability! In one study, 33 percent more people achieved their goals when they shared them with someone else. Accountability is crucial. </p>
                        <p>To dramatically increase the likelihood that you'll act on the changes you hope to make, copy and paste the message below and email or text it to someone who will hold you accountable.</p>

                      </div>
                      <div className="content">
                        <div className="content-items">
                          <div className="clipboard-txt-top">
                            <p>“I am working to become someone who leads more prayerfully.</p>
                            <p>Over the next month, I plan to ask God to help me as I focus on:</p>
                          </div>
                          <div className="clipboard-questions-txt">
                            {
                              getFilteredQuestions(el?.bottomQuestions)?.map((selectedQ, index) => {
                                return <p key={index}>{index + 1}. {selectedQ.question}</p>
                              })
                            }
                          </div>
                          <div className="clipboard-txt-bottom">Would you please pray and check with me weekly how these are coming along?”</div>
                        </div>
                      </div>
                      <div className="copy-clipboard"
                        onClick={() => handleCopyToClipboard(getFilteredQuestions(el?.bottomQuestions))}>
                        <Button>
                          <img src={CopyImg} alt="copy" />Copy Message
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    })
  }

  // const showTitleText = () => {
  //   if (score > 0 && score <= 50) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
  //   } else if (score > 50 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
  //   } else if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
  //   }
  // };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);

  const showTitleText = () => {
    if (score <= 50) {
      return <h1>{name}, based on what we’ve learned from global leaders, there are some places God may be inviting you to grow.</h1>
    } else if (score > 50 && score <= 75) {
      return <h1>{name}, it looks like you are growing in your practice of prayer.</h1>
    } else if (score > 75) {
      return <h1>{name}, let’s celebrate God’s goodness. You’ve drawn close to Him and encouraged others in your organization to do the same. We are rejoicing with you.</h1>
    }
  };

  const showHeaderContent = () =>
  (userType === "parishioner" ? <div className="result-details-container-header-content">
    <h1 className="name" >{name}</h1>
    <h1>{handleTranslate("Thank you for completing the Missional Waypoint Tool!", "thank-you-heading")}</h1>
    <p>
      {handleTranslate("Your responses will help your parish identify where it is on its missional journey and help the parish leaders discover the way forward. But parish renewal only happens in the power of God, and your continued prayer for the pastor and the parish leaders is one of the most powerful tools you have.", "thank-you-decription-1") }
    </p>
    <br />
    <p>{handleTranslate("Parish renewal doesn’t usually happen overnight, so the six stages of becoming missional help identify where your parish is on the journey, what challenges to expect and what to look forward to.", "thank-you-description-2")}</p>
    <p>{handleTranslate("Many parishes have travelled ahead on this path and are willing and able to help you on your own parish journey. Divine Renovation Ministry can help connect you with other parishes and provide resources and accompaniment to help you on your way.", "thank-you-description-3")}</p>
  </div> :

    <div className="result-details-container-header-content">
      <h1 className="name" >{name}</h1>
      <h1>{handleTranslate("According to your results you are a Steward", "result-steward-heading")}</h1>
      <p>{handleTranslate("Like the chief steward on a ship, the way you see your leadership ability and your desire for evangelization suggests you have strengths in welcoming people and making them feel at home.  This is crucial in a missional parish.  Serving on evangelization teams, programs like Alpha or in parish hospitality may be a good fit for you.", "steward-description")}
      </p>
    </div>
  );

  const showResultsFooter = () => {
    return <div className="results-footer-container">
      <p>Get access to additional prayer tools like this assessment at <a href="https://www.leadwithprayer.com/" target="_blank" rel="noreferrer">www.leadwithprayer.com</a></p>
    </div>
  }

  const showScoreText = () => {
    const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);

    // if(allScoresSame) {
    //   return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>We are glad to see that you maintain a balanced focus across all fronts of your strategy planning and execution.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips to take your strategy impact to the next level:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // } else {
    //     return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to <span style={{fontWeight: "700"}}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some tips:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p key={idx} style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // }

    if (score <= 50) {
      return <div className="result-text">
        <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
        {/* <p>1. If you have not already done so, try using a free AI tool, like ChatGPT, for just 5 minutes to see its capabilities. Experiment with prompts related to your work, e.g. “Write a weekly newsletter excerpt in 100 words around my sermon script.” Paste your sermon script for the AI in the same prompt. See how it responds.</p>
        <p>2. Reach out to 5 church leaders using group text to ask if they use AI to increase their ministry impact. This way, you can recognize if churches around you are embracing AI and how.</p> */}
        {/* <p>1. Invite your leadership team to take this test.</p>
        <p>2. Unlock your team's individual responses and team scores*.</p>
        <p>3. Receive four practical tips about AI for your unique church context.</p> */}
        <ol>
          <li>Invite your leadership team to take this test.</li>
          <li>Unlock your team's individual responses and team scores*.</li>
        </ol>
      </div>
    } else if (score > 50 && score <= 80) {
      return <div className="result-text">
        <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
        {/* <p>1. If you have not already done so, try using a free AI tool, like ChatGPT, for just 5 minutes to see its capabilities. Experiment with prompts related to your work, e.g. “Write a weekly newsletter excerpt in 100 words around my sermon script.” Paste your sermon script for the AI in the same prompt. See how it responds.</p>
        <p>2. Reach out to 5 church leaders using group text to ask if they use AI to increase their ministry impact. This way, you can recognize if churches around you are embracing AI and how.</p> */}
        {/* <p>1. Invite your leadership team to take this test.</p>
        <p>2. Unlock your team's individual responses and team scores*.</p>
        <p>3. Receive four practical tips about AI for your unique church context.</p> */}
        <ol>
          <li>Invite your leadership team to take this test.</li>
          <li>Unlock your team's individual responses and team scores*.</li>
        </ol>
      </div>
    } else if (score > 80) {
      return <div className="result-text">
        <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
        {/* <p>1. If you have not already done so, try using a free AI tool, like ChatGPT, for just 5 minutes to see its capabilities. Experiment with prompts related to your work, e.g. “Write a weekly newsletter excerpt in 100 words around my sermon script.” Paste your sermon script for the AI in the same prompt. See how it responds.</p>
        <p>2. Reach out to 5 church leaders using group text to ask if they use AI to increase their ministry impact. This way, you can recognize if churches around you are embracing AI and how.</p> */}
        <ol>
          <li>Invite your leadership team to take this test.</li>
          <li>Unlock your team's individual responses and team scores*.</li>
        </ol>
        {/* <p>1. Invite your leadership team to take this test.</p>
        <p>2. Unlock your team's individual responses and team scores*.</p>
        <p>3. Receive four practical tips about AI for your unique church context.</p> */}
      </div>
    }

  };


  const showSampleDashboard = () => {
    return <>
      <div className="sample-dashboard-text">
        <div className="right-line"></div>
        <h1>Sample Dashboard</h1>
        <div className="left-line"></div>
      </div>
      <div className="individual-group">
        <button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
        <button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
      </div>
      <div className="toggle-individual-group">
        <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
        <Switch
          checked={!toggleIndividual}
          onChange={toggleIndividualChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
      </div>
      <div className="dashboard-report-img-container">
        {windowWidth > 550 ?
          <><img src={currentView == 0 ? sampleDashboardTeamImg : sampleDashboardIndividualImg} alt="sample team dashboard" srcset="" /></>
          :
          <><img src={currentView == 0 ? sampleDashboardTeamImgMobile : sampleDashboardIndividualImgMobile} alt="sample individual dashboard" srcset="" /></>
        }
      </div>
    </>
  }

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) return null;
    else if (props?.showInvite === false) return null;
    else return (<InviteInfo />)
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <Testimonial />;
    } else {
      // return null;
      return <Testimonial />;
    }
  };

  const showResources = () => {
    return <Resources />
  }

  const sendInvite = () => {
    return <SendInvite />
  }

  const getLeftMargin = () => {
    if (windowWidth < 651 && score > 97) {
      return { left: 98 + "%" };
    } else if (windowWidth < 651 && score < 3) {
      return { left: 2 + "%" };
    } else {
      return { left: Math.round(score) + "%" };
    }
    // return { left: Math.round(score) + "%" };
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const getProgressBar = () => {
    return <div className="progress-bar">
      <div className="si_ass_result" style={{ position: "relative" }}>
        <div className="progress-opacity-filter" style={{ width: "100%" }}>
          <div className="transparent" style={{ width: `${Math.round(score)}%`, height: "26px", background: `linear-gradient(90deg, #73B76F 0%, #1FA099 100%)`, borderRadius: Math.round(score) == 100 ? "10px" : "10px 0px 0px 10px", }}></div>
          <div className="opaque" style={{ width: `${(100 - Math.round(score))}%`, height: "26px", borderRadius: Math.round(score) == 100 ? "10px" : Math.round(score) == 0 ? "10px" : "0px 10px 10px 0px", }}></div>
        </div>
        <div className="si_ass_progress">
          <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
            {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
          </div>
          <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
          <div className="score-arrow" style={{ left: Math.round(score) + '%' }}>
            {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
          </div>
        </div>
        <div className="pervalue">
          <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
          <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4>
          <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4>
          {/* <h4 className="progress-50-percent" style={{ minWidth: '10%' }}></h4> */}
          <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4>
          {/* <h4 className="progress-75-percent" style={{ minWidth: '25%' }}></h4> */}
          <h4 className="progress-80-percent" style={{ minWidth: '20%' }}></h4>
          <h4 className="progress-100-percent" style={{ minWidth: '20%' }}></h4>
          {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
        </div>


        <div className="si_pro_header">
          <h4 className="progress-0-percent" style={{ minWidth: '0%' }}></h4>
          <h4 className="progress-20-percent" style={{ minWidth: '20%' }}></h4>
          <h4 className="progress-40-percent" style={{ minWidth: '20%' }}></h4>
          {/* <h4 className="progress-50-percent" style={{ minWidth: '50%' }}></h4> */}
          <h4 className="progress-60-percent" style={{ minWidth: '20%' }}></h4>
          {/* <h4 className="progress-75-percent" style={{ minWidth: '25%' }}></h4> */}
          <h4 className="progress-80-percent" style={{ minWidth: '20%' }}></h4>
          <h4 className="progress-100-percent" style={{ minWidth: '20%' }}></h4>

          {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
          {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
        </div>

        {/* <div className="pervalue-text"> */}
        {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
        {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
        {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
        {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
        {/* </div> */}

        <div className="pervalue-text">
          {/* {
            windowWidth >= 551 ?
              <p className="not-aligned" style={{ width: "20%", fontWeight: score <= 50 ? 700 : 400 }}>Foundational<br />Practice</p>
            :
              <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}> Foundational<br />Practice</p>
          } */}
          {/* <p className="not-aligned" style={{ width: "20%", fontWeight: score > 20 && score <= 40 ? 700 : 400 }}>{windowWidth < 551 ? <span>Not Quite<br/>There</span> : <span>Not Quite There</span>}</p> */}

          {/* <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>{windowWidth <= 550 ? <span>Growing<br/>Practice</span> : <span>Growing<br/>Practice</span>}</p>
          <p className="some-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>{windowWidth <= 551 ? <span>Transformational<br />Practice</span> : <span>Transformational<br />Practice</span>}</p> */}

          <p className="high-aligned" style={{ width: "20%", fontWeight: score <= 20 ? 700 : 400 }}>Hesitant</p>
          <p className="high-aligned" style={{ width: "20%", fontWeight: score <= 40 ? 700 : 400 }}>Not Quite There</p>
          <p className="high-aligned" style={{ width: "20%", fontWeight: score <= 60 ? 700 : 400 }}>It’s a Start</p>
          <p className="high-aligned" style={{ width: "20%", fontWeight: score <= 80 ? 700 : 400 }}>Good Job</p>
          <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Superb!</p>
        </div>

      </div>
    </div>
  }


  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            <img src={scheduledImage} />
          </div>
          <p>Schedule a call with David Fletcher to debrief on your results from the Executive Indicator.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO FLETCH</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="legacy-invite-container">
          <div className="avatar">
            <img src={InviteTeamImage} />
          </div>
          <p>Invite your team to take the test and discover their individual strengths and challenges as church leaders!</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>INVITE YOUR TEAM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }

  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const handleCopyToClipboard = (content) => {
    // try {
    //   const input = textBoxRef.current;

    //   if (input) {
    //     input.select();
    //     document.execCommand("copy");
    //     input.setSelectionRange(0, 0);
    //     setIsCopied(true);

    //     if (navigator.clipboard) {
    //       await navigator.clipboard.writeText(input.value);
    //     }

    //     setTimeout(() => {
    //       setIsCopied(false);
    //     }, 1500);
    //   }
    // } catch (error) {
    //   console.error("Error copying text to clipboard:", error);
    // }

    const introText = "I am working to become someone who leads more prayerfully. \nOver the next month, I plan to ask God to help me as I focus on:\n";

    const outroText = "\nWould you please pray and check with me weekly how these are coming along?";

    const combinedText = introText + content.map((item, index) => `${index + 1}. ${item.question}`).join('\n') + outroText;

    navigator.clipboard.writeText(combinedText.trim());

  };

  const getAnswer = (questionId) => {
    const answer = answers.find(answer => answer.questionId === questionId);

    if (answer.answer === 1) {
      return "Strongly Disagree";
    }
    else if (answer.answer === 2) {
      return "Disagree";
    }
    else if (answer.answer === 3) {
      return "Neutral";
    }
    else if (answer.answer === 4) {
      return "Agree";
    }
    return "Strongly Agree";
  }

  const handleRadioChange = (questionId, question) => {
    const isSelected = selectedQuestions.some((q) => q.id === questionId);

    if (isSelected) {
      setSelectedQuestions((prevSelected) =>
        prevSelected.filter((q) => q.id !== questionId)
      );
    } else {
      if (selectedQuestions.length < 2) {
        setSelectedQuestions((prevSelected) => [
          ...prevSelected,
          { id: questionId, question },
        ]);
      } else {
        setSelectedQuestions((prevSelected) => [
          ...prevSelected.slice(1),
          { id: questionId, question },
        ]);
      }
    }
  };

  const getFilteredQuestions = (bottomQuestions) => {
    const uniqueIds = []; // To keep track of unique question ids
    const filteredQuestions = selectedQuestions?.filter((selectedQ) => {
      if (bottomQuestions.some((bottomQ) => bottomQ.id === selectedQ.id)) {
        // If the question id is not in the array, add it and include the question in the result
        if (!uniqueIds.includes(selectedQ.id)) {
          uniqueIds.push(selectedQ.id);
          return true;
        }
      }
      return false;
    });

    console.log('filteredQuestions', filteredQuestions);

    return filteredQuestions;
  };



  // if (isLoading) {
  //   return (
  //     <div className="container results loading">
  //       <CircularProgress style={{ color: '#792122' }} size={60} />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="container results">
        <div className="banner-img-container">
          <img src={resultsImg} className="banner-img" alt="" />
        </div>

        <div className="results-details-container">
          <div className="banner-outer-container">
            <div className="banner-container">
              <img src={steward} alt="logo" className="banner-inner-logo" />
            </div>
          </div>



          {/* {showTitleText()} */}
          {showHeaderContent()}
          <div className="paragraph-container">
            <div className="para-heading">
              <h3>{handleTranslate("Thank you for completing the Missional Waypoint Tool!", "thank-you-heading")} </h3>
            </div>
            <div className="paragraphs">
              <p className="para-1" >{handleTranslate("Your responses will help your parish identify where it is on its missional journey and help the parish leaders discover the way forward. But parish renewal only happens in the power of God, and your continued prayer for the pastor and the parish leaders is one of the most powerful tools you have.", "thank-you-description-1")}</p>
              <p className="para-2">{handleTranslate("Parish renewal doesn’t usually happen overnight, so the six stages of becoming missional help identify where your parish is on the journey, what challenges to expect and what to look forward to.", "thank-you-description-2")}</p>
              <p className="para-3">{handleTranslate("Many parishes have travelled ahead on this path and are willing and able to help you on your own parish journey. Divine Renovation Ministry can help connect you with other parishes and provide resources and accompaniment to help you on your way.", "thank-you-description-3")}</p>
            </div>
          </div>

          <div className="result-details-container result-details-positive" defaultExpanded={0}>
            <div className="result-details-summary">
              <p className="result-details-title">{handleTranslate("Observations of your strengths in your parish", "result-details-title")}</p>
            </div>
            <div className="result-details-content">
              <div className="content-div">
                <img src={holySpiritImage} />
                <p>You recognize the experience of the transformational power of the Holy Spirit in your parish.</p>
              </div>
              <div className="content-div">
                <img src={holySpiritImage} />
                <p>Your recognition and appreciation of the gifts of the Holy Spirit within your own life or those around you greatly enrich your parish's spiritual journey.</p>
              </div>
              <div className="content-div">
                <img src={holySpiritImage} />
                <p>Your ability to discern the voice of the Holy Spirit amidst life's complexities is a testament to your deep spiritual sensitivity within your parish.</p>
              </div>
            </div>
          </div>
          <div className="result-details-container result-details-negative" defaultExpanded={0}>
            <div className="result-details-summary">
              <p className="result-details-title">{handleTranslate("Areas in which you may want to grow", "result-details-title-2")}</p>
            </div>
            <div className="result-details-content">
              <div className="content-div">
                <img src={evangelizationImage} />
                <p>Parish renewal will only happen when the gifts and talents of parishioners are unleashed. Seeking out opportunities, even when they may not seem obvious, can help you and others ignite the renewal process.</p>
              </div>
              <div className="content-div">
                <img src={leadershipImage} />
                <p>Your joyful participation in celebrating individuals who respond to follow Jesus showcases your support for and encouragement of spiritual journeys within your parish.</p>
              </div>
              <div className="content-div">
                <img src={evangelizationImage} />
                <p>Your understanding that sharing the good news is a collective responsibility underscores your active participation in fostering a culture of evangelization within your parish.</p>
              </div>
            </div>
          </div>



          <div className="parish-support">
            <div className="header">
              <h1>{handleTranslate("Feel your parish come alive", "feel-the-parish-heading")}</h1>
              <p className="parish-support-description">{handleTranslate("Here are three ways to make the most of the Missional Waypoint Tool within your parish","feel-the-parish-description")}</p>
            </div>
            <div className="cards">
              <div className="card">
                <div className="image-container">
                  <img src={parishPray} alt="" />
                </div>
                <h1>{handleTranslate("Pray for your parish", "pray-heading")}</h1>
                <p>{handleTranslate("As your parish journeys towards parish renewal, priests and lay leaders need the strength and encouragement that comes from praying the Rosary.", "pray-description")}</p>
                <Link to={"https://divinerenovation.org/free-materials/#rosary"} target="_blank">
                <button className="btn-cta">{handleTranslate("Pray", "pray-cta")}</button>
                </Link>
              </div>
              <div className="card">
                <div className="image-container">
                  <img src={parishGrow} alt="" />
                </div>
                <h1>{handleTranslate("Grow in your parish", "grow-heading")}</h1>
                <p>{handleTranslate("Find books and resources that support you in the work of parish renewal.", "grow-description")}</p>
                <Link to={"https://divinerenovation.org/books/"} target="_blank">
                  <button className="btn-cta">{handleTranslate("Discover", "discover-cta")}</button>
                </Link>
              </div>
              <div className="card">
                <div className="image-container">
                  <img src={parishCross} alt="" />
                </div>
                <h1>{handleTranslate("Reflect on your parish", "reflect-heading")}</h1>
                <p>{handleTranslate("Reflect on how you can help your parish bring people to Jesus.", "reflect-description")}</p>

                <Link to={"https://divinerenovation.org/3-keys-study-guide/"} target="_blank">
                  <button className="btn-cta">{handleTranslate("Learn", "learn-cta")}</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="parish-support">
            <div className="header">
              <h1>{handleTranslate("Discover the transformation of parishes that are embracing mission", "discover-the-transformation")}</h1>
              <p className="parish-support-description">{handleTranslate("Here are three ways to learn from and support the mission outside of your parish", "discover-the-transformation-description")}</p>
            </div>
            <div className="cards">
              <div className="card">
                <div className="image-container">
                  <img src={parishRead} alt="" />
                </div>
                <h1>{handleTranslate("Read good news", "read-good-news")}</h1>
                <p>{handleTranslate("Leading change can be tough. But you are not alone! Get inspiring stories of hope and renewal delivered to your inbox.", "read-good-news-description")}</p>

                <Link to={"https://divinerenovation.org/newsletter/"} target="_blank">
                  <button className="btn-cta">{handleTranslate("Sign Up", "sign-up-cta")}</button>
                </Link>
              </div>
              <div className="card">
                <div className="image-container">
                  <img src={parishInvest} alt="" />
                </div>
                <h1>{handleTranslate("Invest in yourself", "invest-in-yourself")}</h1>
                <p>{handleTranslate("Join an event to learn from others, grow in your leadership, and renew your hope.", "invest-in-yourself-description")}</p>
                <Link to={"https://divinerenovation.org/events/"} target="_blank">
                  <button className="btn-cta">{handleTranslate("Register", "register-cta")}</button>
                </Link>
              </div>
              <div className="card">
                <div className="image-container">
                  <img src={parishSupport} alt="" />
                </div>
                <h1>{handleTranslate("Support the mission", "support-the-mission")}</h1>
                <p>{handleTranslate("Your parish is taking steps to bring people to Jesus. Imagine thousands more becoming places where lives are transformed. With your help, they can.", "support-the-mission-description")}</p>
                <Link to={"https://divinerenovation.org/give/"} target="_blank">
                  <button className="btn-cta">{handleTranslate("Give", "give-cta")}</button>
                </Link>
              </div>
            </div>
          </div>



          {/* {getProgressBar()} */}

          {/* <ClickableCircularProgressBars sectionsAndSubScores={sectionsAndSubScores} sortedSectionsAndSubScores={sortedSectionsAndSubScores} />
          
          <HorizontalBarCharts sectionsAndSubScores={sectionsAndSubScores} score={score} /> */}

          {/* <div className="progressbar-score-indicator-icon">
            <div className="score-details-and-desc-container">
              <div className="score-name-icon-container">
                <div className="score-icon">
                   <WbSunnyOutlined/>
                </div>
                <div className="score-name">Sunlight:</div>
              </div>
              <p>Your strength</p>
            </div>
            <div className="score-details-and-desc-container">
              <div className="score-name-icon-container">
                <div className="score-icon">
                  <CircleTwoTone/>
                </div>
                <div className="score-name">Middle ground:</div>
              </div>
              <p>Moderate effort required</p>
            </div>
            <div className="score-details-and-desc-container">
              <div className="score-name-icon-container">
                <div className="score-icon">
                  <img src={shadowIcon} alt="" srcset="" />
                </div>
                <div className="score-name">Shadow:</div>
              </div>
              <p>Challenging for you</p>
            </div>
          </div> */}

          {/* <div className="overall-score-subtext">
            <p>{name}, your overall score is <span>{Math.round(score)}%.</span><br />Below are your scores per category.</p>
          </div> */}

          {/* <div className="score-section-container">
            {sectionsAndSubScores?.map((sec, idx) => {
              const lowestScore = sortedSectionsAndSubScores[0]?.section;
              let customColor = "#7CCEE7";
              let customTextStyles = { color: "#343333", fontWeight: 700 };
              if (sec.section === lowestScore && sec?.score < 100) {
                customColor = "#F08D6E  !important;";
                customTextStyles = {  fontWeight: 700 };
              }
              return (
                <div key={sec.section} className="score-section">
                  <p className="score-txt">{Math.round(sec.score)}%</p>
                  <div className="score-slider">
                    <LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)}
                      sx={{
                        backgroundColor: customColor,
                        '& .MuiLinearProgress-bar': { backgroundColor: `${customColor} !important` },
                      }} />
                    <CircularProgressWithLabel size={136} customcolor={customColor} className="custom-bar" variant="determinate" value={Math.round(sec?.score)} thickness={3} />
                  </div>
                  <p className="score-name" style={windowWidth>=831 ? customTextStyles : {}}>{sec.section}</p>
                </div>)
            })}
          </div> */}

          {/* <div className="result-people-pattern-tabs-content-container">
            <div className="results-people-pattern-header">
              <p>Click on each tab to learn more about your people pattern</p>
            </div>
            <div className="result-people-pattern-tabs">
              <div className="tabs-container">
                {
                  resultPeoplePatternData?.map((el)=>{
                    return <div className={`tab tab-${el.tabName.toLowerCase()} ${el.tabName === tabName ? 'active' : ''}`} onClick={()=>{setTabName(el.tabName)}}>
                      <p>{el.tabName}</p>
                    </div>
                  })
                }
              </div>
              <div className="tab-text-container">
                <div className="tab-text-header">
                  <h4>{tabName}</h4>
                  <img src={tabTextCircle} alt="" />
                </div>
                <div className="tab-text" dangerouslySetInnerHTML={{ __html: getSelectedData() }}></div>
              </div>
            </div>
          </div> */}

          {/* <ResultsText/> */}

          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* <div className="charts-accordions-container">
            {chartsData?.map((chart, idx) => {
              return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <p>{chart?.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} />
                </AccordionDetails>
              </Accordion>);
              })
            }
          </div> */}

        </div>
      </div>

      {/* {
        !isMember ? <div className="result-text-container"> */}
      {/* {showScoreText()} */}

      {/* <div className="result-text">
          <p style={{ margin: "5px auto" }} className="heading">Here are your next steps:</p>
          <ol>
            <li>Invite your leadership team to take this test.</li>
            <li>Unlock your team's individual responses and team scores*.</li>
          </ol>
        </div> */}

      {/* <h3 className="pre-score-header">Here's how you scored on your 4 Ps:</h3> */}

      {/* <div className="btn-container">
          {!isMember && <div className="invite-cta-btn">
            <Link to={"/invite-team/" + id} target="_blank">
              <Button>INVITE MY TEAM</Button>
            </Link>
          </div>}
        </div>

        <div className="scroll-dashboard-tip">
          <p> *Scroll down to see what your dashboard may look like.</p>
        </div>
      </div> : <></>
      } */}

      {/* {showLegacyInvite()} */}




      {/* /new code */}

      {/* { showAccordion() } */}

      {/* <div className="result-important-notice">
        <img src={ImportantImg} alt="important"/>
        <div className="result-important-notice-txt">
          The research in <span>Lead with Prayer </span> revealed that becoming a praying leader is a journey. These results are designed to help you progress through the four phases of prayer, step by step. If you'd like to view the rest of your results, you may, but we strongly encourage you not to act on Part B until you're thriving in Part A, and to wait to tackle Part D before mastering Part C.
        </div>
      </div> */}


      {/* {
        sections.map((el,index)=>{
          const asciiCode = 65 + index;
          const character = String.fromCharCode(asciiCode);
          return <div className="charts-accordions-container">  
            <Accordion className="accordion-container" defaultExpanded={true}  >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="result-typography" PART {character}: {el.section} : {el.score}%</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordion-details-container">
                  <div className="result-container">
                    <div className="header">
                      <div>areas to Celebrate</div>
                    </div>
                    <div className="description">
                      <p>  
                        First, let's celebrate the ways you already rely on the Lord through prayer in your daily life!
                      </p>
                    </div>
                    <div className="content">
                      <div className="item">
                        <div className="item-description">
                          I schedule significant daily time with God.
                        </div>
                        <div className="item-status">
                          Strongly Agree
                        </div>
                      </div>
                      <div className="item" style={{ background: "white" }}>
                        <div className="item-description">
                          Honestly, I tend to see prayer as a waste of time.
                        </div>
                        <div className="item-status">
                          Strongly Disagree
                        </div>
                      </div>
                      <div className="item" style={{borderRadius:"0px 0px 9px 9px"}}>
                        <div className="item-description">
                          When I start to pray, it feels more like an obligation than a delight.
                        </div>
                        <div className="item-status">
                          Strongly Disagree
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="result-container result-container-second">
                    <div className="header">
                      <div>AREAS TO Improve</div>
                    </div>
                    <div className="description">
                      <p>
                        Here are some areas where your answers differed from those of world-changing leaders.
                      </p>
                      <h1>
                        Choose two areas below to ask God to work in during the upcoming month.
                      </h1>
                    </div>
                    <div className="content">
                      <div className="item">
                        <div className="wrapper"> 
                        <input type="radio" />
                        <div className="item-description">
                          I regularly retreat for a day or more to create space for intentionally relying on God rather than my own effort.
                          </div>
                        </div>
                        <div className="item-status">
                          Disagree
                        </div>
                      </div>
                      <div className="item" style={{ background: "white", height:"50px" }}>
                        <div className="wrapper">

                        <input type="radio" />

                        <div className="item-description" style={{height:"25px"}}>
                          When making decisions, I tend to be more Spirit-reliant than self-reliant.
                        </div>
                        </div>
                        <div className="item-status">
                        Neutral
                        </div>
                      </div>
                      <div className="item" style={{ borderRadius: "0px 0px 9px 9px", background:"#F0F0F0", height:"50px" }}>
                        <div className="wrapper">

                        <input type="radio" />

                        <div className="item-description" style={{height:"25px"}}>
                          During my workday, prayer is my first priority (not my last resort).
                        </div>
                        </div>
                        <div className="item-status">
                        Disagree
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="result-container result-container-third">
                    <div className="header">
                      <div>ACCOUNTABILITY</div>
                    </div>
                    <div className="description">
                      <p>
                        Setting goals is a great first step! To help with your follow-through, add some accountability! In one study, 33% more people achieved their goals when they shared them with someone else. Accountability is crucial.
                      </p>
                      <p className="bold">
                        Double the likelihood of accomplishing your new goals for growing in prayer.
                        <span>
                          Copy and paste the message below and email or text it to someone who will hold you accountable.

                        </span>

                      </p>
                    
                    </div>
                    <div className="content">
                      <div className="content-items">“I am working to become someone who leads more prayerfully.
                        <br />Over the next month, I plan to ask God to help me as I focus on:
                        <br />1. Regularly retreating for a day or more to create space for intentionally relying on God rather than my own effort.
                        <br />2. During my workday, making prayer my first priority (not my last resort).
                        <br />Would you please pray and check with me weekly how these are coming along?”</div>
                      
                    </div>
                    <div className="copy-clipboard">
                      <img src={CopyImg} alt="copy" />
                      <div>Copy this message to my clipboard.</div>
                    </div>
                  </div>
                  <div className="result-important-notice">
                    <img src={ImportantImg} alt="important"/>
                    <div className="result-important-notice-txt">
                      The research in <span>Lead with Prayer </span> revealed that becoming a praying leader is a journey. These results are designed to help you progress through the four phases of prayer, step by step. If you'd like to view the rest of your results, you may, but we strongly encourage you not to act on Part B until you're thriving in Part A, and to wait to tackle Part D before mastering Part C.
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        })
      } */}

      {/* <div className="footer-about">
        
        <p>Becoming a praying leader takes time. In the course, check your growth periodically and reflect on how you are progressing in your practice of personal and organizational prayer.</p>
        <p>We will email you a link to this assessment 30 days from today to help you reflect again on your progress.</p>

      </div> */}

      {/* <Accordion className="accordion-container" defaultExpanded={index === 0 ? true : false}>
        <AccordionSummary className="accordion-summary" expandIcon={<ExpandMore className="accordion-expand-icon" />} aria-controls="panel1a-content">
          <p className="accordion-title">{data.section}</p>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
         
        </AccordionDetails>
      </Accordion> */}

      {/* {!isMember && showSampleDashboard()} */}

      {/* {showTeamMember()} */}

      {/* {showLegacyInvite()} */}



      {/* {showInvite()} */}

      {/* {sendInvite()} */}

      {/* <Promo/> */}

      {/* { showResultsFooter() } */}

      {/* {showTestimonial()} */}

      {/* <div className="new-testimonial-container">
        <div className="new-testimonial">
        <div className="avatar">
          <img src={testimonialImage} alt="pastor" width={101} height={101} />
        </div>
        <div className="testimonial-and-name-container">
          <div className="testimonial">
              <p>
              “Fletch’s Indicator showed me how I need to activate others’ gifts on my team in areas where I am not strong. Coaching with Fletch was exceptional!”
              </p>
              <p></p>       
          </div>
          <div className="designation">
            Curt Neff | <span className="designation-inner">Executive Pastor</span> 
          </div>
        </div>
          <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div>

        </div>
      </div> */}

      {/* <div className="invite-team-and-schedule-call-container">
        { showLegacyInvite() }
        { showSchedule() } 
      </div>  */}

      {/* {showResources()} */}


      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

    </>

  );
};

export default ResultsSteward;



function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress variant="determinate"
        sx={{ color: "#E9E9E9 !important", position: 'absolute', }}
        size={40} thickness={4} {...props} value={100} />
      <CircularProgress variant="indeterminate" {...props}
        value={-props.value} // to show anticlockwise progress 
        sx={{
          '&': { color: `${props.customcolor} !important`, zIndex: 1, },
          '&.MuiCircularProgress-root': {
            strokeLinecap: 'round',
          },
        }} />
      <Box sx={{
        top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}>
        <p variant="caption" component="div" color="text.primary" style={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", margin: "0 auto" }}>
          {`${Math.round(props.value)}%`}
        </p>
      </Box>
    </Box>
  );
}


const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

