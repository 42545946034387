export const DashboardDownloadsDutch = (text, type, section) => {
  switch (section) {
    case "downloads-heading-info":
      return "Download de resultaten in meerdere formaten zodat je ze altijd kunt bekijken.";
    case "downloads":
      return "downloads";
    case "title":
      return "titel";
    case "format":
      return "bestandsindeling"
    case "my-results":
      return " mijn resultaten"
    case "parish-dashboard":
      return "Parochie dashboard"
    case "leadership-dashboard":
      return "Leiderschapsdashboard"
    case "parishioner-dashboard":
      return "Parochiedashboard"
      case "insights":
        return "inzichten"
        case "download-all":
          return "Download alles"
    default:
      return text;
  }
}